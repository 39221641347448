/*# sourceMappingURL=style.css.map */

@media only screen and (min-width: 1600px) and (max-width: 1733px) {
  .header {
    .logo.desktop {
      margin-left: 35px;
      margin-right: 35px;
    }
    .header-right .nav-main ul {
      min-width: 462px;
      li {
        margin-right: 20px;
        a {
          letter-spacing: 1.5px;
          margin: 0px 10px;
          &::before {
            right: -12px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .container {
    max-width: 1180px;
  }
  .header {
    .logo.desktop {
      margin: 0 16px -68px;
    }
    .logo {
      width: 80px;
      left: -2px;
      top: -8px;
    }
    .notification,
    .admin {
      width: auto;
      padding: 0;
    }
    .setting-head {
      top: 6px;
    }
    .admin .icon {
      width: 34px;
      height: 34px;
    }
    .notification {
      .dropdown-menu-noti {
        right: 28px;
        max-width: 450px;
        top: 85px;
      }
      ul li {
        margin-right: 10px;
      }
    }
    .header-right {
      width: calc(100% - 180px);
      // float: right;
      padding: 25px;
      .nav-main {
        ul {
          min-width: 400px;
          li {
            margin-right: 18px;
            a {
              letter-spacing: 1px;
              margin: 0 10px;
              &::before {
                right: -12px;
              }
            }
          }
        }
      }
    }
    .login-raw a.btn-login {
      padding-right: 15px;
    }
  }
  .affix .header {
    .logo {
      padding: 0;
    }
    .setting-head {
      top: -4px;
    }
    .notification .dropdown-menu-noti {
      top: 76px;
    }
  }
  .topbar .left-side h6 strong {
    font-weight: 800;
    letter-spacing: 1px;
  }
  h1 {
    font-size: 48px;
  }
  .league-wrapper {
    padding-top: 220px;
  }
  .table-wrapper {
    padding: 110px 0 90px;
  }
  .faqs-wrapper {
    .accordion {
      .card {
        .card-header {
          button {
            font-size: 22px;
          }
        }
      }
    }
  }
  .tooltipText {
    width: 280px;
    font-size: 16px;
  }
  .coins {
    .tooltipText {
      right: 40px;
      &::after {
        left: calc(50% - -34px);
      }
    }
  }
  .left-side h6:first-child {
    padding-right: 20px;
    margin-right: 20px;
  }
  .main-wrapper {
    padding-top: 112px;
  }
  .step-section-raw ul {
    padding: 40px 30px;
    &::before {
      width: 100.7%;
      height: 106%;
    }
    li {
      width: 33%;
      .step-col {
        > span {
          font-size: 70px;
        }
        .step-conn p {
          font-size: 12px;
        }
      }
    }
  }
  .game-lists-raw .gm-li-raw {
    padding: 0;
    .row {
      margin-right: -15px;
      margin-left: -15px;
      .gm-li {
        padding-right: 15px;
        padding-left: 15px;
        .gm-col {
          .img-box {
            height: 165px;
          }
          .about-game-raw {
            padding: 25px 15px 20px;
            .view-raw {
              font-size: 12px;
            }
            .about-game-conn {
              .right-conn {
                width: 25%;
                h3 {
                  font-size: 28px;
                }
              }
              .left-conn {
                width: 75%;
              }
            }
            .plateform-players-col li {
             /*  &:last-child {
                width: 45%;
              } */
              .btn-join {
                padding: 8px 25px 8px 10px;
                background-position: right 5px center;
                &:hover {
                  background-position: right 3px center;
                }
              }
            }
          }
        }
      }
    }
  }
  .title-raw {
    h1,
    h2 {
      span {
        font-size: 94px;
      }
    }
    h1,
    h2,
    h3 {
      font-size: 44px;
    }
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col {
    .img-box {
      height: 162px;
    }
    .about-game-raw .about-game-conn .left-conn {
      h5 {
        font-size: 18px;
      }
      h6 {
        font-size: 12px;
      }
    }
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 125px;
  }
  .trending-videos-raw {
    padding: 0;
    .gm-li-raw .row .gm-li .gm-col .img-box {
      height: 155px !important;
    }
  }
  .record-list-raw .record-list-col {
    padding: 0 5%;
    ul li {
      padding: 0 3%;
      h3 {
        font-size: 52px;
        .span {
          font-size: 14px;
        }
      }
    }
  }
  .social-connects-raw li {
    margin: 0px;
  }
  .terms-raw {
    width: 70%;
  }
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw {
    &.latest-news-raw {
      .latest-news-col {
        width: calc(100% - 10%);
      }
    }
  }
  .latest-news-raw {
    .latest-news-col {
      padding: 0 8%;
      .latest-news-li {
        .latest-news-box {
          padding: 30px 30px 30px 25px;
          .news-box {
            h4 {
              font-size: 22px;
            }
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  .btn-sm {
    padding: 12px 35px;
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw {
    .about-intro-raw {
      padding: 0px 20%;
    }
  }
  /*---------------------about-us-end----------------------*/
  /*------------------Store Start-------------------------*/
  .store-raw {
    .tab-content {
      padding: 0%;
    }
  }
  .store-raw.game-lists-raw {
    .gm-li-raw {
      padding: 0 40px;
    }
  }
  /*------------------Store-end-------------------------*/
  /*--------------leaderboard-start--------------*/
  .leaderboard-raw {
    .leader-list-raw {
      padding: 0 7%;
      .leader-list-col {
        margin-top: 60px;
      }
    }
  }
  /*---------------leaderboard-end--------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0 60px;
        .row {
          &.align-items-center {
            .gm-li {
              -ms-flex: 0 0 33.33%;
              flex: 0 0 33.33%;
              max-width: 33.33%;
              .gm-col {
                .img-box {
                  height: 185px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0 60px;
        .row {
          &.align-items-center {
            .gm-li {
              -ms-flex: 0 0 30%;
              flex: 0 0 30%;
              max-width: 30%;
              &.vs-raw {
                -ms-flex: 0 0 0%;
                flex: 0 0 20%;
                max-width: 20%;
              }
              .gm-col {
                .img-box {
                  height: 180px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*-------------challenge-end---------------*/
  /*-------------trophy-start---------------*/
  .trophy-raw {
    padding: 0 70px;
  }
  /*-------------trophy-end----------------*/
  /*-------------contact-us-start---------------*/
  .contact-us-form {
    form {
      padding: 0% 24% 0%;
    }
  }
  .contact-gaming-raw {
    .contact-sub {
      padding: 0% 24%;
    }
  }
  /*-------------contact-us-end---------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw {
    .wallet-ul {
      .wallet-li {
        width: 33%;
      }
    }
  }
  /*-------------Wallet-end---------------*/
  .common-page-raw {
    &::before {
      left: -30%;
      top: -30%;
      width: 800px;
      height: 800px;
    }
    &::after {
      right: -30%;
      top: -30%;
      width: 800px;
      height: 800px;
    }
  }
  /*----------------profile menu start-------------------*/
  .affix .header .dropdown-list,
  .header .dropdown-list {
    top: 51px;
  }
  /*----------------profile menu end-------------------*/
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section {
    top: 137px;
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw .team-overview-raw .the-team-raw .team-li .team-li-conn .team-details-col {
    padding: 30px 20px;
  }
  table tr td {
    letter-spacing: 1px;
  }
  /*-----------------profile team end-------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
  /*-----------------profile achievements end-------------------*/
  /*------------------cart-start--------------------------*/
  .cart-raw {
    &.all-games-raw {
      padding: 80px 0;
      .cart-wrap {
        padding: 0 25px;
      }
      .brought-wrap {
        .brought-slide {
          .slick-arrow {
            height: 35px;
            &.slick-prev {
              left: -30px;
            }
            &.slick-next {
              right: -30px;
            }
          }
          .brought-box {
            padding: 20px 10px;
            min-height: 250px;
            .img-box {
              line-height: 130px;
              img {
                width: 70%;
              }
            }
            .text-box {
              padding-top: 35px;
            }
          }
        }
      }
    }
  }
  /*------------------cart-end--------------------------*/
  /*-------------------tournament-details-start-----------*/
  .time-date-col {
    .timesate-li {
      .box-raw {
        min-height: 118px;
      }
    }
  }
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw {
    .timeline-raw {
      .tree {
        ul {
          li {
            a {
              min-width: 200px;
            }
          }
        }
      }
    }
  }
  /*----tournaments-brackets-end----------------*/
}

@media only screen and (min-width: 1121px) and (max-width: 1279px) {
  .container {
    max-width: 1040px;
  }
  .header {
    .logo.desktop {
      margin: 0 15px -68px;
    }
    .logo {
      width: 80px;
      left: -2px;
      top: -8px;
    }
    .notification,
    .admin {
      width: auto;
    }
    .admin .icon {
      width: 34px;
      height: 34px;
    }
    .notification {
      .dropdown-menu-noti {
        right: 30px;
        max-width: 440px;
        top: 92px;
      }
      ul li {
        margin-right: 10px;
      }
    }
    .header-right {
      width: calc(100% - 225px);
      // float: right;
      .nav-main {
        ul {
          min-width: 360px;
          margin-top: 12px;
          li {
            margin-right: 12px;
            a {
              letter-spacing: 0px;
              margin: 0 6px;
              &::before {
                right: -8px;
              }
            }
          }
        }
      }
    }
    .login-raw {
      min-width: 110px;
      padding: 5px 0;
      text-align: right;
      a {
        &.btn-login {
          padding-right: 0;
          font-size: 0px;
        }
        &.btn-main {
          padding: 8px 10px;
        }
      }
    }
  }
  .main-wrapper {
    padding-top: 115px;
    .carousel-inner {
      .carousel-item {
        height: 450px;
      }
    }
  }
  .affix .header {
    .logo {
      padding: 0;
    }
    .header-right .nav-main ul {
      margin-top: 2px;
    }
    .notification .dropdown-menu-noti {
      top: 82px;
    }
  }
  h1 {
    font-size: 44px;
  }
  .title-raw {
    h1,
    h2 {
      span {
        font-size: 84px;
      }
    }
    h1,
    h2,
    h3 {
      font-size: 40px;
    }
  }
  .league-wrapper {
    padding-top: 200px;
  }
  .table-wrapper {
    padding: 90px 0 90px;
  }
  .faqs-wrapper {
    .accordion {
      .card {
        .card-header {
          button {
            font-size: 20px;
          }
        }
        padding-top: 40px;
      }
    }
  }
  .footer-box-wrap {
    padding-top: 80px;
    &:before {
      top: -7px;
    }
  }
  .tooltipText {
    width: 280px;
    font-size: 16px;
  }
  .coins {
    .tooltipText {
      right: 90px;
      &::after {
        left: calc(50% - -84px);
      }
    }
  }
  .step-section-raw ul {
    padding: 30px 20px;
    &::before {
      width: 100.8%;
      height: 107%;
    }
    li {
      width: 33%;
      .step-col {
        > span {
          font-size: 60px;
          padding-right: 10px;
        }
        .img-fluid {
          max-width: 44px;
          max-height: 44px;
        }
        .step-conn {
          padding-left: 15px;
          width: 54%;
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
  /*----------------game--start---------------*/
  .all-games-raw {
    padding: 70px 0;
    &.game-lists-raw {
      .game-platform-raw {
        .gm-li-raw .row .gm-li .gm-col .about-game-raw {
          .about-game-conn .left-conn {
            h5 {
              text-align: center;
              font-size: 22px;
            }
          }
        }
      }
    }
  }
  .game-lists-raw {
    .gm-li-raw {
      padding: 0px;
      .row {
        margin-right: -10px;
        margin-left: -10px;
        .gm-li {
          padding-right: 10px;
          padding-left: 10px;
          .gm-col {
            .img-box {
              height: 150px;
            }
            .about-game-raw {
              padding: 25px 15px;
              .about-game-conn {
                .right-conn {
                  width: 25%;
                  h3 {
                    font-size: 26px;
                  }
                }
                .left-conn {
                  width: 75%;
                  h5 {
                    font-size: 16px;
                  }
                  h6 {
                    font-size: 10px;
                    padding-left: 8px;
                    background-size: 6px;
                  }
                }
              }
              .plateform-players-col li {
                /* &:last-child {
                  width: 45%;
                } */
                .btn-join {
                  padding: 8px 25px 8px 10px;
                  background-position: right 6px center;
                  &:hover {
                    background-position: right 4px center;
                  }
                }
              }
            }
          }
        }
      }
    }
    .competition-tab-raw .nav-tabs li .nav-link {
      font-size: 20px;
    }
  }
  .trending-videos-raw .gm-li-raw .row .gm-li .gm-col {
    .img-box {
      height: 125px !important;
    }
    .about-game-raw {
      .view-raw {
        .btn-view {
          font-size: 12px;
        }
      }
    }
  }
  /*----------------game--end---------------*/
  /*------------------news--detail-start-----------*/
  .details-head {
    .img-box {
      width: 150px;
      height: 150px;
    }
    h1 {
      margin-top: 30px;
    }
    .post-action {
      margin-top: 30px;
    }
  }
  /*------------------news--detail-end-----------*/
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw {
    &.latest-news-raw {
      .latest-news-col {
        width: calc(100% - 10%);
      }
    }
  }
  .latest-news-raw {
    .latest-news-col {
      padding: 0 5%;
      .latest-news-li {
        .latest-news-box {
          padding: 20px;
          height: 100%;
          .news-box h4 {
            font-size: 20px;
          }
        }
      }
    }
  }
  .ongoing-events-raw {
    padding-bottom: 150px;
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw {
    .about-intro-raw {
      padding: 0px 10%;
    }
  }
  /*---------------------about-us-end----------------------*/
  /*------------------Store Start-------------------------*/
  .store-raw {
    .tab-content {
      padding: 0%;
    }
  }
  .store-raw.game-lists-raw {
    .gm-li-raw {
      padding: 0 30px;
    }
  }
  .brought-box {
    padding: 30px 15px 20px;
    min-height: 250px;
    .img-box {
      line-height: 175px;
    }
    .text-box {
      padding-top: 40px;
      h5 {
        font-size: 24px;
      }
      .price {
        font-size: 20px;
      }
    }
  }
  .store-tab li .nav-link {
    font-size: 20px;
  }
  .addcart-next-raw {
    margin-top: 20px;
    .btn-ic,
    .add-qty-col,
    .btn-join {
      margin-top: 10px !important;
    }
  }
  /*------------------Store-end-------------------------*/
  /*------------------leaderboard-start-----------------*/
  .leaderboard-raw {
    .leader-list-raw {
      padding: 0 7%;
      .leader-list-col {
        margin-top: 60px;
      }
    }
  }
  /*-------------------leaderboard-end------------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0 50px;
        .row {
          &.align-items-center {
            .gm-li {
              -ms-flex: 0 0 33.33%;
              flex: 0 0 33.33%;
              max-width: 33.33%;
              .gm-col {
                .img-box {
                  height: 160px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0 50px;
        .row {
          &.align-items-center {
            .gm-li {
              -ms-flex: 0 0 40%;
              flex: 0 0 40%;
              max-width: 40%;
              &.vs-raw {
                -ms-flex: 0 0 0%;
                flex: 0 0 20%;
                max-width: 20%;
              }
              .gm-col {
                .img-box {
                  height: 205px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*-------------challenge-end---------------*/
  /*-------------trophy-start---------------*/
  .trophy-raw {
    padding: 0 50px;
  }
  /*-------------trophy-end----------------*/
  /*-------------contact-us----------------*/
  .contact-us-form {
    form {
      padding: 0% 20% 0%;
    }
  }
  .contact-gaming-raw {
    .contact-sub {
      padding: 0% 20%;
    }
  }
  /*-------------contact-us----------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw {
    .wallet-ul {
      .wallet-li {
        width: 33%;
      }
    }
  }
  /*-------------Wallet-end---------------*/
  table {
    tr {
      th.choose-game {
        width: 300px;
      }
    }
  }
  /*-------------------game detail start------------------*/
  .inner-banner .container-fluid .inner-banner-con .game-rules-col li h4 {
    font-size: 40px;
  }
  .record-list-raw .record-list-col {
    padding: 0 5%;
    ul li {
      padding: 0 3%;
      h3 {
        font-size: 52px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .about-section-raw {
    padding: 80px 4%;
    p {
      padding: 0 10%;
    }
  }
  .title-divider {
    font-size: 30px;
    &::before,
    &::after {
      width: 30%;
    }
  }
  .ptb10 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .mt50 {
    margin-top: 40px !important;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col {
    .img-box {
      height: 114px;
    }
    .about-game-raw .about-game-conn .left-conn h5 {
      font-size: 20px;
    }
  }
  /*--------------------game detail end-------------------*/
  /*----------------profile menu start-------------------*/
  .affix .header .dropdown-list,
  .header .dropdown-list {
    top: 51px;
  }
  /*----------------profile menu start-------------------*/
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section {
    top: 136px;
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw .team-overview-raw .the-team-raw .team-li .team-li-conn {
    h4 {
      font-size: 18px;
    }
    .team-img {
      width: 110px;
      height: 125px;
      line-height: 80px;
    }
    .team-details-col {
      padding: 20px 15px;
      table tr td h5 {
        font-size: 18px;
      }
    }
    .team-details-col table tr td {
      padding: 8px 5px;
    }
  }
  table tr td {
    letter-spacing: 0px;
    font-size: 18px;
  }
  /*-----------------profile team end-------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li .conn h4 {
    font-size: 26px !important;
    padding: 0 30px;
  }
  /*-----------------profile achievements end-------------------*/
  .product-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
    .gm-li {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  /*------------------cart-start--------------------------*/
  .cart-raw {
    &.all-games-raw {
      padding: 60px 0;
      .cart-wrap {
        padding: 0 20px;
        .cart-action {
          padding-top: 40px;
        }
      }
      .brought-wrap {
        .brought-slide {
          .slick-arrow {
            height: 30px;
            &.slick-prev {
              left: -25px;
            }
            &.slick-next {
              right: -25px;
            }
          }
          .brought-box {
            padding: 20px 10px;
            .img-box {
              line-height: 100px;
              img {
                width: 60%;
              }
            }
            .text-box {
              padding-top: 30px;
              .addcart-next-raw {
                .btn-join {
                  margin-top: 10px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  /*------------------cart-end--------------------------*/
  /*-------------------tournament-details-start-----------*/
  .time-date-col {
    .timesate-li {
      .box-raw {
        padding: 20px 10px;
      }
    }
  }
  /*-------------------tournament-details-end-----------*/
  /*------------------Rules-start----------------------*/
  .modal-raw {
    &.rules-modal {
      .modal-lg {
        .modal-content {
          .modal-body {
            padding: 10px 5% 40px;
          }
        }
      }
    }
  }
  /*------------------Rules-end------------------*/
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw {
    .timeline-raw {
      .tree {
        ul {
          li {
            a {
              min-width: 190px;
              i {
                width: 20px;
                height: 20px;
                font-size: 12px;
                &.ic-user {
                  left: 10px;
                  top: 10px;
                }
                &.ic-sts {
                  right: 10px;
                  top: 10px;
                  line-height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*----tournaments-brackets-end----------------*/
}

@media only screen and (min-width: 992px) and (max-width: 1120px) {
  .container {
    max-width: 100%;
    padding: 0 30px;
  }
  h1 {
    font-size: 40px;
  }
  h4 {
    font-size: 22px;
  }
  .title-raw {
    h1,
    h2 {
      span {
        font-size: 78px;
      }
    }
    h1,
    h2,
    h3 {
      font-size: 38px;
    }
  }
  .league-wrapper {
    padding-top: 170px;
  }
  table {
    tr {
      th {
        font-size: 18px;
      }
      td {
        font-size: 18px;
        padding: 15px 20px;
        img {
          height: 32px;
        }
      }
      td.team {
        padding: 8px 20px;
      }
      td.slash {
        padding: 10px 20px;
      }
    }
    tr.active {
      td.blank {
        background-size: contain;
      }
    }
  }
  .tooltip {
    margin-top: 2px;
  }
  .table-wrapper {
    padding: 70px 0;
  }
  .faqs-wrapper {
    .accordion {
      .card {
        .card-header {
          button {
            font-size: 18px;
          }
        }
        padding-top: 35px;
      }
    }
  }
  .footer-box-wrap {
    padding-top: 60px;
    &:before {
      top: -6px;
    }
  }
  .footer-wrapper {
    ul {
      li {
        a {
          font-size: 16px;
        }
      }
    }
  }
  .tooltipText {
    width: 280px;
    font-size: 16px;
  }
  .coins {
    .tooltipText {
      right: 90px;
      &::after {
        left: calc(50% - -84px);
      }
    }
  }
  .step-section-raw ul {
    padding: 30px 20px;
    &::before {
      width: 100.9%;
      height: 106.5%;
    }
    li {
      width: 33%;
      .step-col {
        > span {
          font-size: 60px;
          padding-right: 10px;
        }
        .img-fluid {
          max-width: 44px;
          max-height: 44px;
        }
        .step-conn {
          padding-left: 15px;
          width: 54%;
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
  .trending-videos-raw .gm-li-raw .row .gm-li .gm-col {
    .img-box {
      height: 105px !important;
    }
    .about-game-raw {
      .view-raw {
        .btn-view {
          font-size: 12px;
        }
      }
    }
  }
  /*----------------game--start---------------*/
  .all-games-raw {
    padding: 50px 0;
    &.game-lists-raw {
      .game-platform-raw {
        .gm-li-raw .row .gm-li .gm-col .about-game-raw {
          .about-game-conn .left-conn {
            h5 {
              text-align: center;
              font-size: 22px;
            }
          }
        }
      }
    }
  }
  .game-lists-raw {
    .gm-li-raw {
      margin-top: 20px;
      padding: 0px;
      .row {
        margin-right: -10px;
        margin-left: -10px;
        .gm-li {
          padding-right: 10px;
          padding-left: 10px;
          .gm-col {
            .img-box {
              height: 130px;
            }
            .about-game-raw {
              padding: 20px 15px 25px;
              .about-game-conn {
                .right-conn {
                  width: 30%;
                  h3 {
                    font-size: 22px;
                    margin: 5px 0 0;
                    span {
                      font-size: 12px;
                    }
                  }
                }
                .left-conn {
                  width: 70%;
                  h5 {
                    font-size: 16px;
                  }
                  h6 {
                    font-size: 10px;
                    padding-left: 8px;
                    background-size: 6px;
                  }
                }
              }
              .plateform-players-col li {
                .btn-join {
                  padding: 6px 25px 6px 10px;
                  background-position: right 6px center;
                  &:hover {
                    background-position: right 4px center;
                  }
                }
                /* &:first-child,
                &:nth-child(2) {
                  width: 48%;
                }
                &:last-child {
                  text-align: left;
                  width: 100%;
                  margin-top: 10px;
                } */
              }
            }
          }
        }
      }
    }
    .competition-tab-raw .nav-tabs li .nav-link {
      font-size: 20px;
    }
  }
  .btn-sm {
    padding: 12px 30px;
  }
  /*----------------game--end---------------*/
  /*------------------news--detail-start-----------*/
  .details-head {
    .img-box {
      width: 150px;
      height: 150px;
    }
    h1 {
      margin-top: 30px;
    }
    .post-action {
      margin-top: 30px;
    }
  }
  .news-details-raw {
    padding: 0px 0px 70px;
    &.line-arrow {
      padding-top: 90px !important;
    }
  }
  .details-body {
    .description {
      padding: 0 50px;
    }
  }
  /*------------------news--detail-end-----------*/
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw {
    &.latest-news-raw {
      .latest-news-col {
        width: calc(100% - 15%);
      }
    }
  }
  .latest-news-raw {
    .latest-news-col {
      padding: 0 5%;
      .latest-news-li {
        .latest-news-box {
          padding: 20px;
          height: 100%;
          .news-box h4 {
            font-size: 20px;
          }
        }
      }
    }
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw {
    .about-intro-raw {
      padding: 0px 5%;
    }
  }
  /*---------------------about-us-end----------------------*/
  /*------------------Store Start-------------------------*/
  .store-raw {
    .tab-content {
      padding: 0%;
    }
  }
  .brought-box {
    .text-box {
      padding-top: 30px;
      h5 {
        font-size: 22px;
      }
      .price {
        font-size: 20px;
      }
    }
  }
  /*------------------Store-end-------------------------*/
  .ongoing-events-raw {
    padding-bottom: 120px;
  }
  .mt500 {
    margin-top: 220px !important;
  }
  /*-------------------game detail start------------------*/
  .inner-banner {
    height: 450px;
    .container-fluid .inner-banner-con .game-rules-col li h4 {
      font-size: 40px;
    }
  }
  .record-list-raw .record-list-col {
    padding: 0 5%;
    ul li {
      padding: 0 3%;
      h3 {
        font-size: 52px;
        span {
          font-size: 14px;
        }
      }
    }
  }
  .about-section-raw {
    padding: 70px 4%;
    p {
      padding: 0 10%;
    }
  }
  .title-divider {
    font-size: 26px;
    &::before,
    &::after {
      width: 30%;
    }
  }
  h1,
  h2 {
    font-size: 40px;
  }
  .ptb10 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col {
    .img-box {
      height: 100px;
    }
    .about-game-raw .about-game-conn .left-conn h5 {
      font-size: 18px;
    }
  }
  /*--------------------game detail end-------------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0 50px;
        .row {
          &.align-items-center {
            .gm-li {
              -ms-flex: 0 0 33.33%;
              flex: 0 0 33.33%;
              max-width: 33.33%;
              .gm-col {
                .img-box {
                  height: 140px;
                }
              }
            }
          }
        }
      }
    }
  }
  .challenges-banner-raw {
    .inner-banner {
      .mt250 {
        margin-top: 60px !important;
      }
    }
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0 40px;
        .row {
          &.align-items-center {
            .gm-li {
              -ms-flex: 0 0 40%;
              flex: 0 0 40%;
              max-width: 40%;
              &.vs-raw {
                -ms-flex: 0 0 0%;
                flex: 0 0 20%;
                max-width: 20%;
              }
              .gm-col {
                .img-box {
                  height: 190px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*-------------challenge-end---------------*/
  /*-------------trophy-start---------------*/
  .trophy-raw {
    padding: 0 30px;
  }
  /*-------------trophy-end----------------*/
  /*-------------contact-us----------------*/
  .contact-us-form {
    form {
      padding: 0% 17% 0%;
    }
  }
  .contact-gaming-raw {
    .contact-sub {
      padding: 0% 17%;
    }
  }
  /*-------------contact-us----------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw {
    .wallet-ul {
      .wallet-li {
        width: 33%;
        &::before {
          height: 110px;
          top: 22px;
        }
        .box-conn {
          img {
            width: 150px;
          }
        }
      }
    }
  }
  /*-------------Wallet-end---------------*/
  .ongoing-events-raw {
    padding-bottom: 120px;
  }
  .mt500 {
    margin-top: 220px !important;
  }
  /*--------------leaderboard-start--------------*/
  .leaderboard-raw {
    .leader-list-raw {
      padding: 0 2%;
      .filter-raw {
        .row {
          &.justify-content-center {
            .text-center {
              -ms-flex: 0 0 30%;
              flex: 0 0 30%;
              max-width: 30%;
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
  /*---------------leaderboard-end--------------*/
  table {
    tr {
      th.choose-game {
        width: 300px;
      }
    }
  }
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section {
    top: 130px;
  }
  .main-wrapper.profile-raw .horizontal-tab-raw .nav-tabs-horizontal > li a {
    padding: 18px 25px;
    &.active::after {
      width: 65%;
    }
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw {
    .title-shadow h2 {
      font-size: 30px;
    }
    .team-overview-raw .the-team-raw .team-li .team-li-conn {
      h4 {
        font-size: 18px;
      }
      .team-img {
        width: 90px;
        height: 115px;
        line-height: 75px;
      }
      .team-details-col {
        padding: 20px 15px;
        table tr td h5 {
          font-size: 18px;
        }
      }
      .team-details-col table tr td {
        padding: 8px 5px;
      }
      .btn-edit {
        left: 10px;
        top: 5px;
      }
      .btn-close {
        right: 10px;
        top: 5px;
      }
    }
  }
  table tr td {
    letter-spacing: 0px;
  }
  /*-----------------profile team end-------------------*/
  /*-----------------profile trophie start-------------------*/
  .trophy-raw .trophy-li .trophy-col {
    padding: 30px 15px;
  }
  /*------------------profile trophie end--------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li .conn h4 {
    font-size: 26px;
    padding: 0 30px;
  }
  /*-----------------profile achievements end-------------------*/
  .line-arrow {
    padding-top: 80px;
  }
  .filter-raw {
    .filter-li {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
      margin-top: 25px;
    }
  }
  .store-tab li .nav-link {
    font-size: 20px;
  }
  .product-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
    .gm-li {
      padding-right: 15px;
      padding-left: 15px;
    }
  }
  .addcart-next-raw {
    margin-top: 15px;
    .btn-ic,
    .add-qty-col,
    .btn-join {
      margin-top: 10px !important;
    }
  }
  .panel-heading .btn-group .btn-filter {
    padding: 8px 20px;
  }
  /*------------------cart-start--------------------------*/
  .cart-raw {
    &.all-games-raw {
      padding: 40px 0;
      .cart-wrap {
        padding: 0 20px;
        .cart-action {
          padding-top: 40px;
        }
      }
      .brought-wrap {
        .brought-slide {
          .slick-arrow {
            height: 30px;
            &.slick-prev {
              left: -25px;
            }
            &.slick-next {
              right: -25px;
            }
          }
          .brought-box {
            padding: 20px 10px;
            .img-box {
              line-height: 100px;
              img {
                width: 60%;
              }
            }
            .text-box {
              padding-top: 30px;
              .addcart-next-raw {
                .btn-join {
                  margin-top: 10px !important;
                }
              }
            }
          }
        }
      }
    }
  }
  /*------------------cart-end--------------------------*/
  /*-------------------tournament-details-start-----------*/
  .tournament-details-col {
    .inner-banner {
      height: 650px;
    }
  }
  .time-date-col {
    .timesate-li {
      .box-raw {
        padding: 20px 10px;
      }
    }
  }
  /*-------------------tournament-details-end-----------*/
}

@media (max-width: 1120px) {
  .nav-open {
    .header {
      .header-right {
        .nav-main {
          ul {
            li {
              // @include transform (translate(0px, 0px));
              opacity: 1;
            }
          }
        }
      }
    }
    overflow: hidden;
  }
  .menu_open {
    overflow: hidden;
  }
  .header {
    .dropdown-list {
      top: 58px;
      left: inherit;
      border-left: 0px;
      right: -25px;
      border-left: 0px;
      text-align: right;
      li {
        a {
          border-left: 0px solid rgba(255, 255, 255, 0);
          border-right: 2px solid rgba(255, 255, 255, 0);
          &:hover,
          &.active {
            border-left: 0px solid rgba(255, 255, 255, 0);
            border-right: 2px solid rgba(255, 255, 255, 1);
            &::after {
              height: 100%;
              width: 100%;
              content: "";
              position: absolute;
              left: 0;
              bottom: 0;
              background: -moz-linear-gradient(left, rgba(0, 216, 255, 0) 0%, rgba(0, 216, 255, 0.2) 100%);
              background: -webkit-linear-gradient(left, rgba(0, 216, 255, 0) 0%, rgba(0, 216, 255, 0.2) 100%);
              background: linear-gradient(to right, rgba(0, 216, 255, 0) 0%, rgba(0, 216, 255, 0.2) 100%);
              z-index: -1;
            }
          }
        }
      }
    }
    .admin {
      &.login-search-ic {
        margin-top: 29px;
        position: absolute;
        left: 65px;
        width: 50px;
      }
    }
    .login-raw {
      margin-top: 5px;
    }
    .setting-head {
      position: absolute;
      margin: 20px 0px;
      left: 70px;
    }
    .notification {
      width: auto;
      margin-top: 8px;
      // margin-right: 40px;
      .dropdown-menu-noti {
        right: 40px;
        max-width: 440px;
        top: 105px;
        .top-section {
          border-bottom-width: 1px;
        }
        .bottom-section {
          border-bottom-width: 1px;
          a {
            font-size: 12px;
          }
        }
        .notification-list .table-list-notifies td {
          padding: 10px 0px;
        }
      }
      ul li {
        margin-right: 20px;
      }
    }
    .logo {
      width: 58px;
      margin: 0 auto;
      float: none;
      left: 0;
    }
    .header-right {
      .nav-main {
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        background-color: #000513;
        top: 0px;
        overflow: auto;
        // @include transition (none);
        // z-index: 1;
        padding: 130px 20px 90px 20px;
        display: none;
        ul {
          width: 100%;
          float: left;
          margin-top: 0px;
          li {
            width: 100%;
            padding: 12px 0px;
            text-align: left;
            margin: 0px;
            // @include transform (translate(0px, 30px));
            // @include transition (all 0.3s);
            opacity: 0;
            a {
              float: none;
              display: inline-block;
              height: auto;
              line-height: 110%;
              font-size: 16px;
              padding: 0;
              color: white;
              // @include transition (inherit);
              margin: 0 !important;
              &::after,
              &::before {
                display: none;
              }
              &:hover {
                span {
                  color: white;
                }
              }
              &.active {
                span {
                  color: white;
                  text-shadow: 0 0 15px rgba(80, 174, 255, 1);
                  -webkit-text-shadow: 0 0 15px rgba(80, 174, 255, 1);
                }
              }
            }
            .hover {
              display: none;
            }
          }
        }
        li {
          a {
            &::before {
              display: none;
            }
          }
        }
      }
      &::after {
        display: none;
      }
    }
    .admin {
      right: 0px;
      top: 0px;
      width: auto;
      float: right;
    }
    &:after {
      content: "";
      width: 80%;
      height: 2px;
      position: absolute;
      bottom: -1px;
      left: 50%;
      // @include transform(translate(-50%, 0));
      background: #070919;
      background: -moz-linear-gradient(left, #070919 0%, #0998f6 25%, #0998f6 50%, #0998f6 75%, #070919 100%);
      background: -webkit-linear-gradient(left, #070919 0%, #0998f6 25%, #0998f6 50%, #0998f6 75%, #070919 100%);
      background: linear-gradient(to right, #070919 0%, #0998f6 25%, #0998f6 50%, #0998f6 75%, #070919 100%);
    }
  }
  .affix .header {
    .logo {
      padding: 0px;
    }
    .notification {
      padding: 8px 0;
      margin-top: 0px;
    }
    .admin {
      top: 0px;
    }
    .dropdown-list {
      top: 58px;
    }
    .setting-head {
      top: 8px;
    }
    .login-raw {
      top: 0px;
    }
  }
  .scroll-wrap {
    width: 100%;
    float: left;
    overflow: auto;
    height: calc(100vh + -200px);
  }
  .nav_open {
    overflow: hidden;
  }
  .toggle-menu {
    display: block;
    width: 30px;
    left: 20px;
  }
  .topbar {
    padding: 12px 20px;
    position: relative;
    z-index: 999;
  }
  .mobile {
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
  .main-wrapper {
    padding-top: 125px;
  }
  .common-page-raw {
    &::before,
    &::after {
      display: none;
    }
  }
  .pt10 {
    padding-top: 90px !important;
  }
  .pb10 {
    padding-bottom: 90px !important;
  }
  .personal-info-section {
    .list-col .con-box {
      h6 {
        font-size: 18px;
      }
      h5 {
        font-size: 14px;
      }
    }
  }
  .gaming-plateform-raw .con-box h5 {
    font-size: 14px;
  }
  .achievements-raw {
    .row {
      margin-left: -15px;
      margin-right: -15px;
      .achievements-li {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  /*------------------Rules-start----------------------*/
  .modal-raw {
    &.rules-modal {
      .modal-lg {
        .modal-content {
          .modal-body {
            padding: 10px 2.5% 30px;
          }
        }
      }
    }
  }
  /*------------------Rules-end------------------*/
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw {
    .timeline-raw {
      .tree {
        overflow: auto;
        ul {
          li {
            a {
              min-width: 190px;
              i {
                width: 20px;
                height: 20px;
                font-size: 12px;
                &.ic-user {
                  left: 10px;
                  top: 10px;
                }
                &.ic-sts {
                  right: 10px;
                  top: 10px;
                  line-height: 30px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*----tournaments-brackets-end----------------*/
  /*----------challenge--pop-up-start----------*/
  .modal-raw {
    &.challenge-modal-box {
      &.modal-border-raw {
        .modal-dialog {
          margin: 10px auto;
          .modal-content {
            .modal-body {
              padding: 30px;
            }
          }
        }
      }
    }
    &.login-modal {
      .modal-dialog {
        margin: 10px auto;
        .modal-body {
          .social-list-raw {
            padding-top: 30px;
          }
        }
      }
    }
    &.register-modal {
      .modal-dialog {
        margin: 10px auto;
        .social-list-raw {
          padding-top: 30px;
        }
      }
    }
    &.challenge-sent-modal {
      .modal-dialog {
        margin: 10px auto;
      }
    }
    &.choose-your-team-modalbox {
      .modal-dialog {
        max-width: 100%;
        margin: 10px auto;
      }
    }
  }
  .header .login-raw {
    a {
      &.btn-login {
        padding-right: 0;
        font-size: 0px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 100%;
    padding: 0 25px;
  }
  .header {
    .header-right .nav-main {
      padding-top: 120px;
    }
    .notification .dropdown-menu-noti {
      max-width: 400px;
    }
  }
  h1 {
    font-size: 36px;
  }
  .affix .header .notification .dropdown-menu-noti {
    top: 105px;
  }
  .topbar {
    .left-side h6 {
      font-size: 12px;
      &:first-child {
        padding-right: 10px;
        margin-right: 10px;
      }
      &.uppercase {
        letter-spacing: 0.5px;
      }
      strong {
        font-size: 12px;
        letter-spacing: 0.5px;
      }
    }
    .right-side h6 {
      padding-left: 10px;
      letter-spacing: 0.5px;
      margin-left: 3px;
    }
  }
  ul.social-list-raw li {
    margin: 0 8px;
    a {
      font-size: 14px;
    }
  }
  h4 {
    font-size: 20px;
  }
  .title-raw {
    h1,
    h2 {
      span {
        font-size: 70px;
      }
    }
    h1,
    h2,
    h3 {
      font-size: 36px;
    }
  }
  .league-wrapper {
    padding-top: 150px;
  }
  table {
    tr {
      th {
        padding: 12px 15px;
        font-size: 16px;
      }
      td {
        font-size: 16px;
        padding: 13px 15px;
        img {
          height: 30px;
        }
        i {
          width: 5px;
          height: 24px;
        }
      }
      td.team {
        padding: 8px 15px;
      }
      td.slash {
        padding: 10px 15px;
      }
      th.rank {
        width: 80px;
      }
      th.team {
        width: 190px;
      }
      th.points {
        width: 110px;
      }
      th.coins {
        width: 160px;
      }
    }
    tr.active {
      td.blank {
        background-size: contain;
      }
    }
  }
  .tooltip {
    margin-top: 1px;
  }
  .table-wrapper {
    padding: 40px 0 70px;
  }
  .faqs-wrapper {
    .accordion {
      .card {
        .card-header {
          button {
            font-size: 16px;
            padding: 11px 60px;
            &:before {
              width: 28px;
              height: 28px;
            }
          }
        }
        padding-top: 25px;
      }
    }
  }
  .footer-box-wrap {
    padding-top: 60px;
    &:before {
      top: -5px;
    }
  }
  .footer-wrapper {
    ul {
      li {
        a {
          font-size: 16px;
        }
      }
    }
  }
  .footer-wrap .right-wrap .social-list-raw li {
    margin-right: 15px;
  }
  .tooltipText {
    width: 260px;
    font-size: 16px;
  }
  .coins {
    .tooltipText {
      right: 90px;
      &::after {
        left: calc(50% - -84px);
      }
    }
  }
  .step-section-raw ul {
    padding: 30px 20px;
    &::before {
      width: 100.8%;
      height: 108%;
    }
    li {
      width: 33%;
      background-size: 14px;
      .step-col {
        > span {
          font-size: 44px;
          padding-right: 10px;
        }
        .img-fluid {
          max-width: 34px;
          max-height: 34px;
        }
        .step-conn {
          padding: 0 10px;
          width: 54%;
          .h6 {
            font-size: 14px;
          }
          p {
            font-size: 10px;
          }
        }
      }
    }
  }
  .ongoing-competition-raw {
    padding: 120px 0;
  }
  .main-wrapper {
    padding-top: 125px;
  }
  .main-banner .carousel.slide .carousel-control-arrow span {
    width: 16px;
    height: 34px;
  }
  .mt50 {
    margin-top: 40px !important;
  }
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw {
    &.latest-news-raw {
      .latest-news-col {
        width: calc(100% - 15%);
      }
    }
  }
  .latest-news-raw {
    .latest-news-col {
      padding: 0 5%;
      .latest-news-li {
        .latest-news-box {
          padding: 20px;
          height: 100%;
          .news-box h4 {
            font-size: 20px;
          }
        }
      }
    }
  }
  .mt500 {
    margin-top: 150px !important;
  }
  .ongoing-events-raw {
    padding-bottom: 100px;
  }
  /*----------------------news-end-----------------------------*/
  /*----------------game--start---------------*/
  .all-games-raw {
    padding: 40px 0;
    &.game-lists-raw {
      .game-platform-raw {
        .gm-li-raw .row .gm-li .gm-col .about-game-raw {
          .about-game-conn .left-conn {
            h5 {
              text-align: center;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .game-lists-raw {
    .gm-li-raw {
      margin-top: 20px;
      padding: 0px;
      .row {
        margin-right: -10px;
        margin-left: -10px;
        .gm-li {
          padding-right: 10px;
          padding-left: 10px;
          margin-top: 30px;
          &.col-sm-3 {
            -ms-flex: 0 0 33.333%;
            flex: 0 0 33.333%;
            max-width: 33.333%;
          }
          .gm-col {
            .img-box {
              height: 100px;
            }
            .about-game-raw {
              padding: 20px 15px 25px;
              .about-game-conn {
                .right-conn {
                  width: 25%;
                  h3 {
                    font-size: 20px;
                    margin: 5px 0px 0px;
                    span {
                      font-size: 10px;
                    }
                  }
                }
                .left-conn {
                  width: 75%;
                  h5 {
                    font-size: 16px;
                  }
                  h6 {
                    font-size: 10px;
                    padding-left: 8px;
                    background-size: 6px;
                  }
                }
              }
              .plateform-players-col {
                padding-top: 10px;
                margin-top: 10px;
                li {
                  .btn-join {
                    padding: 6px 25px 6px 10px;
                    background-position: right 6px center;
                    &:hover {
                      background-position: right 4px center;
                    }
                    &.btn-cancel {
                      padding: 5px 14px;
                    }
                  }
                  /* &:first-child,
                  &:nth-child(2) {
                    width: 48%;
                  }
                  &:last-child {
                    text-align: left;
                    width: 100%;
                    margin-top: 10px;
                  } */
                }
              }
            }
          }
        }
      }
    }
    .competition-tab-raw .nav-tabs li .nav-link {
      font-size: 20px;
    }
  }
  .horizontal-tab-raw .nav-tabs-dropdown {
    font-size: 22px;
    padding: 15px 0;
  }
  .divide-raw::after {
    top: 57px;
  }
  .btn-sm {
    padding: 12px 30px;
  }
  .trending-videos-raw {
    padding: 0px;
    .gm-li-raw .row .gm-li .gm-col {
      .img-box {
        height: 95px !important;
      }
      .about-game-raw {
        .view-raw {
          .btn-view {
            font-size: 12px;
          }
        }
      }
    }
  }
  /*----------------game--end---------------*/
  /*------------------news--detail-start-----------*/
  .details-head {
    .img-box {
      width: 120px;
      height: 120px;
    }
    h1 {
      margin-top: 30px;
    }
    .post-action {
      margin-top: 30px;
    }
  }
  .news-details-raw {
    padding: 0px 0px 40px;
    &.line-arrow {
      padding-top: 60px !important;
    }
  }
  .details-body {
    .description {
      padding: 0 30px;
    }
  }
  /*------------------news--detail-end-----------*/
  /*------------------Store Start-------------------------*/
  .store-raw {
    .tab-content {
      padding: 0 5%;
    }
  }
  .brought-box {
    padding: 30px 15px 20px;
    .text-box {
      padding-top: 20px;
      h5 {
        font-size: 18px;
        min-width: inherit;
        width: 100%;
        padding: 0px;
      }
      .price {
        font-size: 20px;
      }
    }
  }
  /*------------------Store-end-------------------------*/
  /*-------------------game detail start------------------*/
  .inner-banner {
    height: 400px;
    .container-fluid .inner-banner-con .game-rules-col li h4 {
      font-size: 40px;
    }
  }
  .line-arrow {
    padding-top: 70px !important;
    &::before {
      height: 50px;
    }
  }
  .record-list-raw .record-list-col {
    padding: 0;
    ul li {
      padding: 0 2%;
      h3 {
        font-size: 46px;
        span {
          font-size: 12px;
        }
      }
    }
  }
  .about-section-raw {
    padding: 60px 0;
    p {
      padding: 0;
    }
  }
  .btn-ic {
    &.ic-challenge,
    &.ic-more-game {
      min-width: 200px;
    }
  }
  .title-divider {
    font-size: 26px;
    letter-spacing: 2px;
    &::before,
    &::after {
      width: 28%;
    }
  }
  h1,
  h2 {
    font-size: 40px;
  }
  .ptb10 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col {
    .img-box {
      height: 75px;
    }
    .about-game-raw .about-game-conn .left-conn {
      h5,
      h6 {
        font-size: 16px;
        padding: 0px;
      }
    }
  }
  .btn-link {
    font-size: 16px;
  }
  /*--------------------game detail end-------------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0 20px;
        .row {
          margin-right: -20px;
          margin-left: -20px;
          &.align-items-center {
            .gm-li {
              -ms-flex: 0 0 33.33%;
              flex: 0 0 33.33%;
              max-width: 33.33%;
              padding-right: 20px;
              padding-left: 20px;
              .gm-col {
                .img-box {
                  height: 120px;
                }
              }
            }
          }
        }
      }
    }
  }
  .challenges-banner-raw {
    .inner-banner {
      .mt250 {
        margin-top: 60px !important;
      }
    }
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0 20px;
        .row {
          &.align-items-center {
            .gm-li {
              -ms-flex: 0 0 40%;
              flex: 0 0 40%;
              max-width: 40%;
              &.vs-raw {
                -ms-flex: 0 0 0%;
                flex: 0 0 20%;
                max-width: 20%;
              }
              .gm-col {
                .img-box {
                  height: 145px;
                }
              }
            }
          }
        }
      }
      .challenge-info-raw {
        ul {
          li.price-money-raw {
            h6 {
              font-size: 26px;
            }
          }
        }
      }
    }
    .challenge-info-raw ul li h6 {
      font-size: 16px;
    }
  }
  /*-------------challenge-end---------------*/
  /*-------------contact-us----------------*/
  .contact-us-form {
    form {
      padding: 0% 8% 0%;
    }
  }
  .contact-gaming-raw {
    .contact-sub {
      padding: 0% 8%;
    }
  }
  /*-------------contact-us----------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw {
    .wallet-ul {
      .wallet-li {
        width: 33%;
        &::before {
          height: 90px;
          top: 12px;
        }
        .box-conn {
          img {
            width: 110px;
          }
        }
      }
    }
  }
  /*-------------Wallet-end---------------*/
  /*--------------leaderboard-start--------------*/
  .leaderboard-raw {
    .leader-list-raw {
      padding: 0 0%;
      .filter-raw {
        .row {
          &.justify-content-center {
            .text-center {
              -ms-flex: 0 0 30%;
              flex: 0 0 30%;
              max-width: 30%;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  /*---------------leaderboard-end--------------*/
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section {
    top: 129px;
  }
  .main-wrapper.profile-raw .horizontal-tab-raw {
    .nav-tabs-horizontal {
      > li a {
        padding: 16px 20px;
        font-size: 14px;
        &.active::after {
          width: 65%;
        }
      }
    }
  }
  .pt10 {
    padding-top: 80px !important;
  }
  .pb10 {
    padding-bottom: 80px !important;
  }
  .personal-info-section {
    .list-col .con-box {
      padding: 60px 0;
      &::before {
        background-size: contain;
      }
      h6 {
        font-size: 18px;
      }
      h5 {
        font-size: 14px;
      }
    }
  }
  .gaming-plateform-raw .con-box {
    margin-top: 10px;
    h5 {
      font-size: 14px;
    }
  }
  .follow-cointer-col .user-follower-col .counter-col {
    font-size: 42px;
  }
  .user-avtar-col {
    width: 200px;
  }
  .balance-earned-raw ul li {
    .coin-label {
      font-size: 24px;
    }
    span {
      letter-spacing: 4px;
    }
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw {
    .title-shadow h2 {
      font-size: 28px;
    }
    .team-overview-raw .the-team-raw .team-li {
      -ms-flex: 0 0 33.333%;
      flex: 0 0 33.333%;
      max-width: 33.333%;
      .team-li-conn {
        h4 {
          font-size: 18px;
        }
        .team-img {
          width: 90px;
          height: 115px;
          line-height: 75px;
        }
        .team-details-col {
          padding: 20px 15px;
          table tr td h5 {
            font-size: 18px;
          }
        }
        .team-details-col table tr td {
          padding: 8px 5px;
        }
        .btn-edit {
          left: 10px;
          top: 5px;
        }
        .btn-close {
          right: 10px;
          top: 5px;
        }
      }
    }
  }
  table tr td {
    letter-spacing: 0px;
  }
  /*-----------------profile team end-------------------*/
  /*-----------------profile trophie start-------------------*/
  .trophy-raw .trophy-li {
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    .trophy-col {
      padding: 30px 15px;
      .trophy-img {
        margin-top: -60px;
      }
    }
  }
  /*------------------profile trophie end--------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li {
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
    max-width: 33.333%;
    .conn h4 {
      font-size: 26px;
      padding: 0 30px;
    }
  }
  /*-----------------profile achievements end-------------------*/
  .line-arrow {
    padding-top: 80px;
  }
  .filter-raw {
    .filter-li {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
      margin-top: 25px;
    }
  }
  .panel-heading .btn-group .btn-filter {
    padding: 8px 12px;
    min-width: auto;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  .notification-raw .notification-table-col .date-action-col .select-col select.form-control {
    height: 35px;
    padding: 0 20px 0 15px;
    min-width: 120px;
    font-size: 14px;
  }
  /*--------upcoming-tournament-lobby-end----------*/
  .tournament-details-col {
    .upcoming-tournament-raw {
      .leaderboard-raw {
        .tournament-lobby-raw {
          .leader-list-col {
            .team-overview-raw {
              .the-team-raw {
                .team-li {
                  .team-li-conn {
                    .win-img {
                      img {
                        width: 130px;
                      }
                    }
                    .team-details-col {
                      table {
                        tr {
                          td {
                            padding: 8px 5px !important;
                            font-size: 12px;
                          }
                        }
                      }
                    }
                  }
                  &.winner-li {
                    .team-li-conn {
                      .win-img {
                        img {
                          width: 150px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .profile-details-raw .team-overview-raw .the-team-raw .team-li .team-li-conn .team-details-col table tr td {
    padding: 8px 5px !important;
    font-size: 12px;
  }
  .winners-col {
    .winner-li .win-img {
      img {
        width: 170px;
      }
    }
    .win-img {
      img {
        width: 140px;
      }
    }
  }
  /*--------upcoming-tournament-lobby-start-------*/
}

@media (max-width: 991px) {
  .footer-box-wrap {
    &:before {
      top: -4px;
    }
  }
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw {
    &.latest-news-raw {
      .latest-news-col {
        width: 100%;
      }
    }
  }
  .latest-news-raw {
    .latest-news-col {
      .latest-news-li {
        .latest-news-box {
          padding: 15px;
          height: 100%;
          .img-box {
            width: 100%;
            margin-bottom: 20px;
            img {
              width: 100px;
            }
          }
          .news-box {
            width: 100%;
            padding-left: 0px;
            p {
              margin-top: 15px;
              a {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw {
    .about-intro-raw {
      padding: 0% 2%;
    }
  }
  /*---------------------about-us-end----------------------*/
  /*------------------Store Start-------------------------*/
  .store-raw.game-lists-raw {
    .gm-li-raw {
      .row {
        .gm-li {
          padding-left: 10px;
          padding-right: 10px;
          .gm-col {
            .img-box {
              margin-top: 20px;
            }
            .about-game-raw .left-conn {
              h5 {
                font-size: 20px;
              }
              .price {
                font-size: 18px;
              }
              .btn-join {
                padding: 8px 40px 9px 15px;
              }
            }
          }
        }
      }
    }
    .product-li-raw {
      margin-top: 0px;
    }
  }
  .brought-box {
    .text-box {
      padding-top: 20px;
      h5 {
        font-size: 18px;
        min-width: inherit;
        width: 100%;
        padding: 0px;
      }
      .price {
        font-size: 20px;
      }
    }
  }
  .store-tab {
    li {
      .nav-link {
        font-size: 18px;
        padding: 12px 25px;
      }
    }
  }
  /*------------------Store-end-------------------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw {
    .disclaimer-raw {
      .btn-main {
        padding: 0 20px;
        &.bg-color {
          margin-top: 10px;
        }
      }
    }
  }
  .challenge-raw {
    .disclaimer-raw {
      .btn-main {
        padding: 0 20px;
        &.bg-color {
          margin-top: 10px;
        }
      }
    }
  }
  /*-------------challenge-end---------------*/
  /*-------------trophy-start---------------*/
  .trophy-raw {
    padding: 0 10px;
    .trophy-li {
      margin-top: 70px;
      .trophy-col {
        padding: 20px;
      }
    }
  }
  /*-------------trophy-end----------------*/
  .store-tab li .nav-link {
    font-size: 20px;
  }
  .product-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
    .gm-li {
      padding-right: 15px;
      padding-left: 15px;
      -ms-flex: 0 0 33.333%;
      flex: 0 0 33.333%;
      max-width: 33.333%;
    }
  }
  .addcart-next-raw {
    margin-top: 15px;
    .btn-ic,
    .add-qty-col,
    .btn-join {
      margin-top: 10px !important;
    }
  }
  .contact-banner {
    .inner-banner {
      height: auto;
      padding: 60px 0;
      .container-fluid .inner-banner-con {
        position: unset;
        transform: unset;
      }
    }
  }
  .contact-banner {
    .inner-banner {
      padding-bottom: 0px;
    }
  }
  /*------------------cart-start--------------------------*/
  .cart-raw {
    &.all-games-raw {
      padding: 20px 0;
      .cart-wrap {
        padding: 0px;
        .cart-action {
          padding-top: 20px;
          .btn-main {
            font-size: 16px;
            padding: 0px 10px;
            height: 40px;
            line-height: 40px;
          }
        }
        table {
          tbody {
            tr {
              td {
                &:first-child {
                  font-size: 24px;
                }
              }
              &.total {
                td {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
      .brought-wrap {
        .brought-slide {
          .slick-arrow {
            height: 20px;
            &.slick-prev {
              left: -10px;
            }
            &.slick-next {
              right: -10px;
              z-index: -1;
            }
          }
          .brought-box {
            padding: 20px 10px;
            .img-box {
              line-height: 100px;
              img {
                width: 60%;
              }
            }
            .text-box {
              padding-top: 30px;
              .price {
                font-size: 16px;
                span {
                  margin-left: 0px;
                }
              }
              .addcart-next-raw {
                margin-top: 20px;
                .btn-join {
                  margin-top: 10px !important;
                }
                .btn-ic {
                  margin: 0px;
                  &.add-to-cart {
                    width: 110px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*------------------cart-end--------------------------*/
  /*--------------igtv-start--------------*/
  .igl-tv-raw {
    .most-popular-raw {
      padding: 0%;
      ul {
        li {
          h5 {
            font-size: 20px;
          }
        }
      }
    }
    .tab-and-search-raw {
      .game-lists-raw {
        .gm-li-raw {
          .row {
            .gm-li {
              .gm-col {
                .about-game-raw {
                  padding: 20px 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  /*--------------igtv-end--------------*/
  /*-------------------tournament-details-start-----------*/
  .tournament-details-col {
    .inner-banner {
      height: 650px;
    }
  }
  .tournament-details-raw {
    .table-wrapper {
      .time-date-col {
        &.pdlr15 {
          padding-right: 0% !important;
          padding-left: 0% !important;
          .timesate-li {
            .box-raw {
              padding: 20px 10px;
            }
          }
        }
      }
    }
  }
  /*-------------------tournament-details-end-----------*/
  .leaderboard-raw .leader-list-raw.tournament-lobby-raw {
    padding: 0 0%;
  }
  .profile-details-raw .team-overview-raw .the-team-raw .team-li .team-li-conn .team-details-col table tr td {
    padding: 8px 5px !important;
    font-size: 12px;
  }
  /*--------------progress------------------*/
  .progress {
    &.progress-circle-raw {
      width: 120px;
      height: 120px;
      .progress-value {
        > div {
          font-size: 22px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
    padding: 0 15px;
  }
  .header {
    max-height: 70px;;
    .logo {
      width: 55px;
    }
    .admin {
      &.login-search-ic {
        margin-top: 14px;
        width: 50px;
      }
      .icon {
        width: 32px;
        height: 32px;
      }
    }
    .login-raw {
      min-width: inherit;
      padding: 7px 0px;
      a {
        font-size: 0px;
        &.btn-main {
          padding: 10px 25px;
          background: url(../images/ic-reg-user.svg) center no-repeat;
          background-color: #009bfb;
          background-size: 22px;
          padding: 0;
          width: 40px;
          height: 35px;
          border-radius: 4px;
        }
      }
    }
    .toggle-menu {
      left: 15px;
    }
    .logo.desktop {
      margin: 0 25px -68px;
      width: 100px;
    }
    .header-right {
      margin: 0 auto;
      padding: 15px;
      .nav-main {
        ul {
          min-width: inherit;
          overflow: hidden;
          li {
            margin-right: 25px !important;
            padding: 10px 0px !important;
          }
        }
        padding: 120px 20px 70px 20px;
      }
    }
    &::after {
      height: 1px;
      left: 0;
    }
  }
  .admin.mobile-view {
    display: block !important;
    position: relative;
    top: 9px;
  }
  .header {
    .notification {
      float: left;
      width: 100%;
      margin: 0px;
      text-align: left;
      position: fixed;
      bottom: 0;
      right: 0;
      background: #000513;
      z-index: 999;
      padding: 5px 0;
      ul {
        min-width: inherit !important;
        li {
          margin-right: 15px !important;
          width: auto !important;
        }
      }
    }
    .setting-head {
      display: none;
    }
    .dropdown-list {
      top: 51px;
      min-width: 170px;
    }
  }
  .affix .header {
    .dropdown-list {
      top: 45px;
    }
  }
  .main-wrapper {
    padding-top: 120px;
  }
  h1 {
    font-size: 30px;
  }
  h4 {
    font-size: 20px;
  }
  .desktop-view {
    display: none;
  }
  .desktop-mobile {
    display: block;
  }
  .topbar {
    padding: 10px 15px;
    .left-side {
      width: 100%;
      h6 {
        line-height: 120%;
        font-size: 12px;
        width: 50%;
        float: left;
        &:first-child {
          float: left;
          padding-right: 0;
          margin-right: 0;
          border: 0;
        }
        &.uppercase {
          float: right;
          text-align: right;
          font-size: 10px;
          span::after {
            display: none;
          }
          strong {
            font-size: 12px;
            margin: 0;
            width: 100%;
            float: left;
            letter-spacing: 1px;
          }
        }
        span {
          width: 100%;
          font-size: 10px;
          margin: 0px;
        }
      }
    }
    .right-side {
      display: none;
    }
  }
  .title-raw {
    h1,
    h2 {
      span {
        font-size: 60px;
      }
    }
    h1,
    h2,
    h3 {
      font-size: 30px;
    }
  }
  .league-wrapper {
    padding-top: 130px;
    .leacue-table-img {
      img {
        display: none;
      }
      h1 {
        position: initial;
        top: 0;
        // @include transform (translate(0, 0%));
      }
    }
    .expert-master {
      .progress-wrap {
        h5 {
          font-size: 16px;
          margin-top: 17px;
        }
        img {
          width: 330px;
        }
      }
      p {
        margin-top: 10px;
        padding-top: 0px;
      }
    }
  }
  .select-game {
    select {
      max-width: 350px;
    }
  }
  .faqs-wrapper {
    .accordion {
      .card {
        .table-box {
          .card-body {
            overflow: auto;
          }
        }
        .card-header {
          button {
            font-size: 16px;
            padding: 11px 60px;
            &:before {
              width: 28px;
              height: 28px;
              right: 15px;
            }
          }
        }
        padding-top: 25px;
      }
    }
  }
  table {
    width: 650px;
    max-width: 100%;
    tr {
      th {
        padding: 12px 15px;
        font-size: 16px;
      }
      td {
        font-size: 16px;
        padding: 13px 15px;
        img {
          height: 30px;
        }
        i {
          width: 5px;
          height: 24px;
        }
      }
      td.team {
        padding: 8px 15px;
      }
      td.slash {
        padding: 10px 15px;
      }
      th.rank {
        width: 80px;
      }
      th.team {
        width: 190px;
      }
      th.points {
        width: 110px;
      }
      th.coins {
        width: 160px;
      }
      th.choose-game {
        width: 300px;
      }
    }
    tr.active {
      td.blank {
        background-size: contain;
      }
    }
  }
  .tooltip {
    margin-top: 1px;
  }
  .table-wrapper {
    padding: 40px 0 60px;
  }
  .footer-wrapper {
    margin-top: 0px;
    background-color: #051830;
    background-image: none;
    ul {
      li {
        a {
          font-size: 16px;
        }
      }
    }
  }
  .footer-box-wrap {
    padding-top: 50px;
    &:before {
      display: none;
    }
  }
  .tooltipText {
    width: 210px;
    font-size: 14px;
    bottom: initial;
    top: 220%;
    &::after {
      bottom: initial;
      top: -18px;
      // @include transform (rotate(-180deg));
    }
  }
  .coins {
    .tooltipText {
      right: 90px;
      &::after {
        left: calc(50% - -84px);
      }
    }
  }
  .footer-wrap {
    display: initial;
    .right-wrap {
      width: 100%;
      padding-bottom: 30px;
      .col-md-4 {
        &:nth-child(1) {
          margin-bottom: 30px;
        }
        &:nth-child(2) {
          margin-bottom: 30px;
        }
      }
    }
    .left-wrap {
      width: 100%;
      text-align: center;
      padding-right: 0;
    }
  }
  .footer-logo {
    ul {
      padding-top: 0px;
    }
  }
  .scroll-wrap {
    height: calc(100vh + -180px);
  }
  .trending-videos-raw {
    padding: 0;
  }
  .btn-view {
    font-size: 12px;
  }
  .step-section-raw {
    text-align: center;
    margin-top: 40px;
    ul {
      padding: 20px;
      width: 300px;
      &::before {
        width: 102.8%;
        height: 102.8%;
      }
      li {
        width: 100%;
        background-size: 14px;
        position: relative;
        background-image: none;
        padding-bottom: 50px;
        &::before {
          background: transparent url(../images/arrow-next.png) center bottom no-repeat;
          position: absolute;
          content: "";
          right: 0;
          left: 0;
          margin: 0 auto;
          width: 14px;
          height: 28px;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          bottom: 10px;
          background-size: contain;
        }
        &:last-child {
          padding-bottom: 0px;
          &::before {
            display: none;
          }
        }
        .step-col {
          > span {
            font-size: 40px;
            padding-right: 15px;
          }
          .img-fluid {
            max-width: 34px;
            max-height: 34px;
          }
          .step-conn {
            padding: 0 0 0 15px;
            width: 68%;
            .h6 {
              font-size: 14px;
              display: block;
            }
            p {
              font-size: 10px;
              margin: 0px;
            }
          }
        }
      }
    }
  }
  .main-banner {
    margin-top: 121px;
    .carousel.slide .carousel-control-arrow span {
      width: 16px;
      height: 32px;
    }
  }
  /*--------------progress------------------*/
  .progress {
    &.progress-circle-raw {
      h5 {
        &.bottom-shadow {
          margin-bottom: 40px !important;
        }
      }
    }
  }
  /*----------------game--start---------------*/
  .all-games-raw {
    padding: 50px 0;
    &.game-lists-raw {
      .game-platform-raw {
        .gm-li-raw .row .gm-li .gm-col .about-game-raw {
          .about-game-conn .left-conn {
            h5 {
              text-align: center;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  .game-lists-raw {
    .gm-li-raw {
      margin-top: 0px;
      padding: 0px;
      .row {
        margin-right: -15px;
        margin-left: -15px;
        .gm-li {
          padding-right: 15px;
          padding-left: 15px;
          .gm-col {
            .img-box {
              height: auto;
            }
            .about-game-raw {
              padding: 25px 15px 20px;
              &::after {
                top: -28px;
              }
              .about-game-conn {
                .right-conn {
                  width: 100%;
                  h3 {
                    font-size: 24px;
                    text-align: left;
                    vertical-align: middle;
                    display: inline-block;
                    br {
                      display: none;
                    }
                    span {
                      font-size: 14px;
                      margin-left: 5px;
                    }
                  }
                }
                .left-conn {
                  width: 100%;
                  h5 {
                    font-size: 18px;
                  }
                  h6 {
                    font-size: 10px;
                    padding-left: 10px;
                    background-size: 6px;
                  }
                }
              }
              .plateform-players-col li {
                /* &:last-child {
                  width: 100%;
                  text-align: left;
                  margin-top: 10px;
                }
                &:first-child,
                &:nth-child(2) {
                  width: 100%;
                } */
                .btn-join {
                  padding: 8px 30px 8px 15px;
                  background-position: right 10px center;
                  &:hover {
                    background-position: right 8px center;
                  }
                }
              }
            }
          }
        }
      }
    }
    .competition-tab-raw {
      margin-top: 50px;
      .nav-tabs {
        flex-wrap: unset;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        li {
          .nav-link {
            white-space: nowrap;
            letter-spacing: 1px;
            font-size: 18px;
          }
        }
      }
    }
  }
  .horizontal-tab-raw {
    padding: 0 5%;
    .nav-tabs-dropdown {
      font-size: 22px;
      padding: 15px 0;
    }
  }
  .divide-raw::after {
    top: 58px;
  }
  .ongoing-competition-raw {
    padding: 70px 0;
  }
  .ongoing-events-raw {
    padding-bottom: 60px;
  }
  .btn-sm {
    font-size: 14px;
    padding: 12px 35px;
  }
  .mt500 {
    margin-top: 150px !important;
  }
  /*----------------game--end---------------*/
  /*------------------news--detail-start-----------*/
  .details-head {
    .img-box {
      width: 120px;
      height: 120px;
    }
    h1 {
      margin: 35px 0 20px;
    }
    .post-action {
      margin-top: 30px;
    }
    .btn-ac {
      &.like {
        width: 50%;
        margin-top: 20px;
      }
      &.comment {
        width: 50%;
        margin-top: 20px;
      }
      &.share {
        width: 50%;
        margin-top: 20px;
      }
    }
  }
  .news-details-raw {
    padding: 0px 0px 40px;
    &.line-arrow {
      padding-top: 60px !important;
    }
  }
  .details-body {
    .description {
      // text-align: center;
      padding: 0 0px;
    }
  }
  /*------------------news--detail-end-----------*/
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw {
    &.latest-news-raw {
      .latest-news-col {
        width: 100%;
      }
    }
  }
  .latest-news-raw {
    .latest-news-col {
      margin-top: 0px;
      padding: 0px;
      .latest-news-li {
        .latest-news-box {
          padding: 15px 15px 15px 15px;
          .news-box {
            padding-left: 15px;
            p {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw {
    .about-intro-raw {
      padding: 0% 0%;
    }
  }
  /*---------------------about-us-end----------------------*/
  .store-raw {
    .tab-content {
      padding: 0 0%;
    }
  }
  /*-------------------game detail start------------------*/
  .inner-banner {
    height: 350px;
    .container-fluid .inner-banner-con {
      .game-rules-col li {
        h4 {
          font-size: 34px;
          line-height: 100%;
        }
        h5 {
          font-size: 16px;
        }
      }
      .title-raw h6 {
        font-size: 16px;
      }
    }
  }
  .line-arrow {
    padding-top: 70px !important;
    border-width: 1px;
    &::before {
      height: 40px;
      width: 1px;
    }
  }
  .record-list-raw .record-list-col {
    padding: 0;
    ul li {
      padding: 0 1%;
      h3 {
        font-size: 36px;
        span {
          font-size: 10px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .about-section-raw {
    padding: 60px 0;
    p {
      padding: 0;
    }
  }
  .btn-ic {
    margin: 0px 10px;
    &.ic-challenge,
    &.ic-more-game {
      min-width: 180px;
    }
  }
  .title-divider {
    font-size: 22px;
    letter-spacing: 1px;
  }
  h1,
  h2 {
    font-size: 36px;
  }
  .ptb10 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .mt50 {
    margin-top: 40px !important;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col {
    .img-box {
      height: 75px;
    }
    .about-game-raw .about-game-conn .left-conn {
      h5,
      h6 {
        font-size: 16px;
        padding: 0px;
      }
    }
  }
  .btn-link {
    font-size: 16px;
  }
  .ig-tv-raw {
    .gm-li-raw .row .gm-li {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      .gm-col .img-box {
        height: 125px;
      }
    }
  }
  /*--------------------game detail end-------------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0px;
        .row {
          margin-right: -20px;
          margin-left: -20px;
          &.align-items-center {
            .gm-li {
              padding-right: 20px;
              padding-left: 20px;
              .gm-col {
                .img-box {
                  height: 125px;
                }
              }
            }
          }
        }
      }
    }
  }
  .challenges-banner-raw {
    .inner-banner {
      .mt250 {
        margin-top: 60px !important;
      }
      .container-fluid .inner-banner-con {
        top: 60%;
      }
    }
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw {
    &.game-lists-raw {
      .challenge-info-raw {
        padding-top: 30px;
        ul {
          display: flex;
          padding-top: 20px;
          li {
            width: 33.33%;
            margin: 0 4px;
            padding-top: 20px;
            background: transparent !important;
            > span {
              font-size: 16px;
            }
            h6 {
              font-size: 16px;
            }
            &.price-money-raw {
              background: transparent !important;
              h6 {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
  }
  /*-------------challenge-end---------------*/
  /*-------------contact-us----------------*/
  .contact-us-form {
    form {
      padding: 0%;
      .form-group {
        .btn-send {
          padding: 15px 20px;
        }
      }
    }
  }
  .contact-gaming-raw {
    .contact-sub {
      padding: 0% 0%;
      .btn-send {
        padding: 15px 20px;
      }
      h4 {
        font-size: 18px;
        line-height: initial;
      }
    }
  }
  /*-------------contact-us----------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw {
    .wallet-ul {
      .wallet-li {
        width: 48%;
        padding-bottom: 50px;
        &::before {
          display: none;
        }
        .box-conn {
          img {
            width: 110px;
          }
          h5 {
            font-size: 18px;
            letter-spacing: 1.5px;
            margin: 10px 0 10px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
  /*-------------Wallet-end---------------*/
  /*--------------leaderboard-start--------------*/
  .leaderboard-raw {
    .leader-list-raw {
      padding: 0;
    }
  }
  /*---------------leaderboard-end--------------*/
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section {
    top: 120px;
  }
  .main-wrapper.profile-raw {
    h1 {
      font-size: 34px;
    }
    .horizontal-tab-raw {
      .nav-tabs-horizontal {
        flex-wrap: unset;
        overflow-x: auto;
        overflow-y: hidden;
        display: flex !important;
        padding: 0 15px;
        > li a {
          padding: 16px 20px;
          font-size: 14px;
          &::before {
            width: 1px;
          }
          &.active::after {
            width: 65%;
          }
        }
      }
    }
  }
  .pt10 {
    padding-top: 70px !important;
  }
  .pb10 {
    padding-bottom: 70px !important;
  }
  .personal-info-section {
    .list-col {
      .con-box {
        padding: 50px 0;
        &::before {
          display: none;
        }
        h6 {
          font-size: 18px;
          word-break: break-all;
        }
        h5 {
          font-size: 14px;
          word-break: break-all;
        }
      }
      &:nth-child(3n) {
        .con-box {
          &::before,
          &::after {
            display: none;
          }
        }
      }
      &:first-child {
        border-right: 1px solid #00b3ff5c;
        border-bottom: 1px solid #00b3ff5c;
      }
      &:nth-child(2) {
        border-bottom: 1px solid #00b3ff5c;
      }
      &:nth-child(3) {
        border-right: 1px solid #00b3ff5c;
      }
    }
  }
  .follow-cointer-col .user-follower-col {
    span {
      font-size: 16px;
      letter-spacing: 3px;
    }
    .counter-col {
      font-size: 30px;
      height: 100px;
      width: 90px;
      line-height: 90px;
      background-size: contain;
    }
  }
  .user-avtar-col {
    width: 160px;
  }
  .balance-earned-raw ul li {
    margin: 0 10px;
    width: 45%;
    .coin-label {
      font-size: 22px;
      padding: 15px 25px;
    }
    span {
      letter-spacing: 4px;
    }
  }
  .gaming-plateform-raw {
    margin-top: 10px;
    h5 {
      font-size: 14px;
    }
    .platform-list-row {
      .platform-row {
        .list-col {
          background-image: none !important;
          &::after,
          &::before {
            display: none !important;
          }
          &:first-child {
            border-right: 1px solid #00b3ff5c;
            border-bottom: 1px solid #00b3ff5c;
          }
          &:nth-child(2),
          &:nth-child(4) {
            border-bottom: 1px solid #00b3ff5c;
          }
          &:nth-child(3) {
            border-right: 1px solid #00b3ff5c;
            border-bottom: 1px solid #00b3ff5c;
          }
        }
        &:nth-child(2) {
          .list-col {
            &:last-child,
            &:nth-child(3) {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
  .title-shadow {
    h2,
    h4 {
      font-size: 20px;
    }
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw {
    .title-shadow h2 {
      font-size: 22px;
      &::after {
        bottom: -85%;
      }
    }
    .team-overview-raw .the-team-raw .team-li {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      .team-li-conn {
        h4 {
          font-size: 18px;
        }
        .team-img {
          width: 90px;
          height: 115px;
          line-height: 75px;
          margin: -55px auto 0;
        }
        .team-details-col {
          padding: 20px 15px;
          table {
            width: 100%;
            tr td {
              padding: 8px 5px;
              h5 {
                font-size: 18px;
              }
            }
          }
        }
        .btn-edit {
          left: 10px;
          top: 5px;
        }
        .btn-close {
          right: 10px;
          top: 5px;
        }
      }
    }
  }
  table tr td {
    letter-spacing: 0px;
  }
  .btn-main {
    font-size: 14px;
  }
  /*-----------------profile team end-------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    .conn h4 {
      font-size: 22px !important;
      padding: 0 30px;
    }
  }
  /*-----------------profile achievements end-------------------*/
  .filter-raw {
    .filter-li {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      margin-top: 25px;
      .select-game select {
        font-size: 14px;
      }
    }
  }
  .store-tab li .nav-link {
    font-size: 18px;
    min-width: 170px;
  }
  .product-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
    .gm-li {
      padding-right: 15px;
      padding-left: 15px;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  .addcart-next-raw {
    margin-top: 15px;
    .btn-ic,
    .add-qty-col,
    .btn-join {
      margin-top: 10px !important;
    }
  }
  .news-detail-raw,
  .contact-banner,
  .tournament-details-col {
    .inner-banner {
      height: auto;
      padding: 60px 0;
      .container-fluid .inner-banner-con {
        position: unset;
        transform: unset;
      }
    }
  }
  .contact-banner {
    .inner-banner {
      padding-bottom: 0px;
    }
  }
  .details-head .post-by span {
    font-size: 18px;
  }
  .news-detail-raw {
    .news-comment{
      padding: 0;
    }
    .details-head {
      .post-action {
        margin-top: 0px;
        .btn-ac {
          width: auto;
          min-width: auto;
          padding: 0px 25px;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
  .contact-gaming-raw .contact-sub .form-group textarea.form-control {
    font-size: 14px;
  }
  .about-main-raw .banner-con .img-box a {
    img {
      width: 210px;
      height: auto;
    }
    &::after {
      width: 60px;
      height: 60px;
    }
  }
  .panel-heading .btn-group .btn-filter {
    padding: 8px 12px;
    min-width: auto;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  .notification-raw .notification-table-col .date-action-col .select-col select.form-control {
    height: 35px;
    padding: 0 20px 0 15px;
    min-width: 120px;
    font-size: 14px;
  }
  .panel-heading {
    display: flex;
    flex-direction: column-reverse;
    .btn-group {
      display: flex;
      overflow: auto;
      .btn-filter {
        white-space: nowrap;
      }
    }
    .date-action-col {
      margin-bottom: 20px;
    }
    > div {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .table-list-notifies .con-col .conn {
    .img-fluid {
      width: 46px;
      height: 46px;
    }
    h6 {
      font-size: 18px;
    }
  }
  input[type="checkbox"].checkbox-circle,
  input[type="checkbox"].checkbox-circle:not(.switch) {
    height: 18px;
    width: 18px;
  }
  input[type="checkbox"].checkbox-circle:not(.switch)::after {
    width: 4px;
    height: 9px;
    left: 7px;
    top: 3px;
  }
  /*--------------igtv-start--------------*/
  .igl-tv-raw {
    .most-popular-raw {
      ul {
        li {
          width: 49%;
        }
      }
    }
    .tab-and-search-raw {
      .igtv-raw {
        .nav-tabs {
          width: 100%;
          padding-left: 0px;
          .nav-item {
            width: 50%;
            .nav-link {
              padding: 5px 10px;
              text-align: center;
            }
          }
        }
        .search-game-raw {
          width: 100%;
          margin: 20px 0px;
          padding-right: 20px;
        }
      }
    }
  }
  /*--------------igtv-end--------------*/
  /*-------------------tournament-details-start-----------*/
  .tournament-details-col {
    .inner-banner {
      .container-fluid {
        .inner-banner-con {
          top: 50%;
        }
      }
      ul {
        li {
          padding: 10px 5px;
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
      .text-center {
        .btn-ac {
          width: auto;
          min-width: inherit;
          padding-left: 15px;
          padding-right: 15px;
          span {
            font-size: 14px;
          }
          &.join-btn {
            min-width: 150px !important;
            padding-left: 40px !important;
          }
        }
      }
    }
  }
  .tournament-details-raw {
    .table-wrapper {
      .time-date-col {
        &.pdlr15 {
          padding-right: 0% !important;
          padding-left: 0% !important;
          .timesate-li {
            margin-top: 20px;
            .box-raw {
              padding: 20px 10px;
            }
            &::before {
              display: none;
            }
          }
        }
      }
      .leader-list-raw {
        .leader-list-col {
          &.pdlr10 {
            padding-left: 0% !important;
            padding-right: 0% !important;
          }
          table {
            &.second-table {
              tr {
                td {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
  .upcoming-tournament-raw {
    .leader-list-raw {
      .leader-list-col {
        &.pdlr10 {
          padding-left: 0% !important;
          padding-right: 0% !important;
        }
      }
    }
  }
  .tournaments-raw {
    .table-wrapper {
      .time-date-col {
        &.pdlr15 {
          padding-right: 0% !important;
          padding-left: 0% !important;
          .timesate-li {
            margin-top: 20px;
            .box-raw {
              padding: 20px 10px;
              min-height: auto;
            }
            &::before {
              display: none;
            }
          }
        }
      }
    }
    .tournaments-tab-raw {
      &.section {
        &.mobiles {
          padding: 0;
          ul {
            li {
              width: auto;
              a {
                font-size: 15px;
                padding: 0px 16px;
              }
            }
          }
        }
      }
    }
    
  }
  /*-------------------tournament-details-end-----------*/
  /*------------------Rules-start----------------------*/
  .modal-raw {
    &.rules-modal {
      .modal-lg {
        .modal-content {
          .modal-body {
            padding: 10px 0% 10px;
          }
        }
      }
    }
  }
  /*------------------Rules-end------------------*/
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw {
    .timeline-raw {
      &.pdlr15 {
        padding-left: 0% !important;
        padding-right: 0% !important;
        .timeline {
          .events {
            ul {
              li {
                width: 23.65%;
              }
            }
          }
        }
      }
    }
  }
  /*----tournaments-brackets-end----------------*/
  /*----------challenge--pop-up-start----------*/
  .modal-raw {
    &.challenge-modal-box {
      &.modal-border-raw {
        .modal-dialog {
          .modal-content {
            .modal-body {
              .coins-col-box {
                .coins-col {
                  li {
                    // width: calc(50% - 32px);
                    margin: 20px 15px 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.login-modal {
      .modal-dialog {
        .modal-body {
          padding-right: 5%;
          padding-left: 5%;
        }
      }
    }
    &.register-modal {
      .modal-dialog {
          .modal-body {
            padding-right: 5%;
            padding-left: 5%;
          }
      
          .terms-raw {
            width: 100%;
          }
        }
    }
    &.choose-your-team-modalbox, &.create-team-modal {
      .bottom-section {
        .team-col {
          ul {
            padding: 15px;
            li {
              img {
                width: 30px;
                height: 30px;
              }
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
    &.trending-modal {
      .modal-dialog {
        .modal-body {
          .title-raw {
            h3 {
              span {
                font-size: 36px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .btn-ac {
    min-width: 140px;
    padding: 0 10px;
    margin: 0 3px;
    width: auto;
  }
  .tournament-details-col {
    .btm-btns .btn-ac {
      min-width: inherit;
    }
    ul li {
      font-size: 14px;
      strong {
        font-size: 16px;
      }
    }
    .inner-banner .container-fluid .inner-banner-con p {
      font-size: 18px;
      line-height: 120%;
    }
  }
  .inner-banner .container-fluid .back-btn {
    top: -30px;
    font-size: 16px;
    padding: 2px 14px 2px 32px;
    background-size: contain;
  }
  .time-date-col .timesate-li::before {
    display: none;
  }
  .time-date-col .timesate-li .box-raw {
    padding: 20px 10px;
    min-height: auto;
  }
  .leaderboard-raw .leader-list-raw.tournament-lobby-raw {
    padding: 0%;
  }
  .winners-col {
    .winner-li .win-img {
      img {
        width: 140px;
      }
    }
    .win-img {
      img {
        width: 110px;
      }
    }
  }
  
  .title-divider::before,
  .title-divider::after {
    width: 20%;
  }
  .igl-tv-raw {
    .most-popular-raw {
      .title-divider::before,
      .title-divider::after {
        width: 20%;
      }
    }
  }
  .main-wrapper {
    &.about-main-raw {
      .about-inner-banner {
        background-size: 250%;
        .banner-con {
          margin: 280px 0 50px;
        }
      }
    }
  }
  .cart-wrap {
    table {
      thead {
        tr {
          th {
            min-width: inherit;
            &.cart-price {
              width: 20px;
            }
            &.quantity {
              width: 50px;
            }
          }
        }
      }
    }
  }
  .cart-wrap .qty-drop select {
    min-width: 65px;
  }
  .myImg {
    .caption {
      bottom: 3%;
      .glow {
        .play_icon {
          width: 50px;
        }
        .sub-head-j {
          font-size: 16px;
          line-height: 1.5;
        }
      }
    }
  }
  .ComingSoonSection {
    margin: 60px auto;

    .ComingSoon {
      font-size: 42px;
    }
    .InprogressText {
      font-size: 16px;
    }
    .GoBack {
      font-size: 24px;
    }
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .game-lists-raw .gm-li-raw .row .gm-li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    .gm-col .about-game-raw .about-game-conn {
      .left-conn {
        width: 70%;
      }
      .right-conn {
        width: 30%;
      }
    }
  }
}

@media only screen and (max-device-width: 480px) {
  html {
    -webkit-text-size-adjust: none;
  }
  .thankyou{
    p{
      font-size: 16px;
    }
  }
}

@media (max-width: 479px) {
  .header {
    .logo {
      width: 60px;
    }
    .notification {
      ul {
        li {
          margin-right: 10px;
        }
      }
      margin-top: 0px;
    }
    .header-right {
      .nav-main {
        padding: 115px 20px 0 10px;
      }
    }
  }
  .affix .header .dropdown-list,
  .header .dropdown-list {
    top: 45px;
  }
  h1,
  h2 {
    font-size: 26px;
  }
  .league-wrapper {
    padding-top: 110px;
    .expert-master {
      .progress-wrap {
        h5 {
          font-size: 12px;
          margin: 0 5px;
        }
        img {
          margin-bottom: -10px;
        }
        .progress-bar-raw .progress-bar {
          font-size: 14px;
          height: 20px;
        }
      }
      p {
        margin-top: 5px;
      }
    }
  }
  .faqs-wrapper {
    .accordion {
      .card {
        .card-header {
          > span {
            font-size: 16px;
          }
          button {
            font-size: 14px;
            padding: 11px 50px;
            letter-spacing: 1px;
            &:before {
              width: 24px;
              height: 24px;
              right: 12px;
              background-size: 12px;
            }
          }
        }
        padding-top: 20px;
      }
    }
  }
  .table-wrapper {
    padding: 40px 0 50px;
  }
  .footer-box-wrap {
    padding-top: 40px;
  }
  .ongoing-competition-raw {
    padding: 40px 0;
  }
  .title-raw {
    h1,
    h2 {
      span {
        font-size: 42px;
        width: 98%;
      }
    }
    h1,
    h2,
    h3 {
      font-size: 22px;
    }
    p {
      font-size: 14px;
    }
  }
  .main-wrapper {
    padding-top: 125px;
  }
  .mt80 {
    margin-top: 50px !important;
  }
  .game-lists-raw {
    .gm-li-raw {
      margin-top: 0px;
      padding: 0px;
      .row {
        margin-right: -15px;
        margin-left: -15px;
        .gm-li {
          padding-right: 15px;
          padding-left: 15px;
          -ms-flex: 0 0 100%;
          flex: 0 0 100%;
          max-width: 100%;
          .gm-col {
            .img-box {
              height: auto;
            }
            .about-game-raw {
              padding: 25px 15px;
              &::after {
                top: -18px;
              }
              .about-game-conn {
                .right-conn {
                  width: auto;
                  text-align: center;
                  float: right;
                  h3 {
                    font-size: 24px;
                    text-align: center;
                    vertical-align: middle;
                    display: inline-block;
                    br {
                      display: inherit;
                    }
                    span {
                      font-size: 14px;
                      margin-left: 0px;
                    }
                  }
                }
                .left-conn {
                  width: 70%;
                  h5 {
                    font-size: 18px;
                  }
                  h6 {
                    font-size: 10px;
                    padding-left: 10px;
                    background-size: 6px;
                  }
                }
              }
              .plateform-players-col li {
                /* &:last-child {
                  width: 42%;
                  text-align: right;
                  margin-top: 2px;
                }
                &:first-child,
                &:nth-child(2) {
                  width: 28%;
                }
                &:last-child {
                  // width: 100%;
                  // text-align: left;
                  a {
                    margin-top: 10px;
                  }
                } */
                .btn-join {
                  padding: 8px 25px 8px 10px;
                  background-position: right 5px center;
                  &:hover {
                    background-position: right 8px center;
                  }
                }
              }
            }
          }
        }
      }
    }
    .competition-tab-raw {
      margin-top: 30px;
      .nav-tabs {
        li {
          .nav-link {
            white-space: nowrap;
            letter-spacing: 1px;
            font-size: 18px;
            padding: 15px 0;
            &::before {
              display: none;
            }
          }
        }
      }
    }
  }
  .store-raw.game-lists-raw #game-tabs-1 .gm-li-raw .row .gm-li {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mt500 {
    margin-top: 70px !important;
  }
  .mt50 {
    margin-top: 35px !important;
  }
  .step-section-raw ul {
    width: 280px;
    padding: 20px 15px;
    li .step-col > span {
      font-size: 36px;
    }
  }
  /*------------------news--detail-start-----------*/
  .details-head {
    .post-by {
      margin-top: 10px;
      span {
        margin-bottom: 15px;
        &:nth-child(2) {
          margin-bottom: 0px;
        }
      }
    }
  }
  /*------------------news--detail-end-----------*/
  /*----------------------news-start----------------------------*/
  .latest-news-raw {
    .latest-news-col {
      margin-top: 0;
      padding: 0px;
      .latest-news-li {
        .latest-news-box {
          .img-box {
            width: 100%;
            margin-bottom: 20px;
            img {
              width: 100px;
            }
          }
          .news-box {
            width: 100%;
            padding-left: 0px;
            p {
              margin-top: 15px;
              a {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
  }
  /*----------------------news-end-----------------------------*/
  /*------------------Store Start-------------------------*/
  .brought-box {
    padding: 20px 10px 20px;
    .text-box {
      padding-top: 20px;
      h5 {
        font-size: 18px;
        min-width: inherit;
        width: 100%;
        padding: 0px;
      }
      .price {
        font-size: 20px;
      }
      .btn-join,
      .btn-ic {
        margin: 0;
      }
    }
  }
  .store-tab {
    li {
      .nav-link {
        font-size: 16px;
        padding: 12px 15px;
        min-width: 150px;
      }
    }
  }
  .product-li-raw .row .gm-li {
    padding-right: 15px;
    padding-left: 15px;
  }
  .addcart-next-raw {
    .add-to-cart {
      width: auto;
    }
  }
  /*------------------Store-end-------------------------*/
  .horizontal-tab-raw .nav-tabs-dropdown {
    font-size: 20px;
    padding: 10px 0;
  }
  .divide-raw::after {
    top: 45px;
  }
  .line-arrow {
    padding-top: 60px !important;
    &::before {
      height: 40px;
    }
  }
  .inner-banner .container-fluid .inner-banner-con .game-rules-col li .hexagon-col {
    width: 88px;
    height: 96px;
    h4 {
      font-size: 26px;
    }
  }
  .record-list-raw .record-list-col ul li h3 {
    font-size: 26px;
    span {
      letter-spacing: 0px;
      font-size: 8px;
    }
  }
  .inner-banner {
    height: 300px;
  }
  .about-section-raw {
    .title-sec h2 {
      margin-bottom: 20px;
    }
    .btn-ic {
      margin: 10px 15px;
    }
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 195px;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw .about-game-conn .left-conn h5,
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw .about-game-conn .left-conn h6 {
    font-size: 18px;
  }
  /*-------------challenges-start---------------*/
  .challenges-raw {
    &.game-lists-raw {
      .gm-li-raw {
        padding: 0 0px;
        .row {
          margin-right: -20px;
          margin-left: -20px;
          &.align-items-center {
            .gm-li {
              -ms-flex: 0 0 100%;
              flex: 0 0 100%;
              max-width: 100%;
              padding-right: 20px;
              padding-left: 20px;
              .gm-col {
                .img-box {
                  height: 170px;
                }
              }
            }
          }
        }
      }
    }
  }
  .challenges-banner-raw .inner-banner {
    .btn-ic.ic-challenge {
      margin-top: 10px;
    }
    .mt250 {
      margin-top: 40px !important;
    }
  }
  /*-------------challenges-end---------------*/
  /*----------------profile start-------------------*/
  .follow-cointer-col .user-follower-col {
    span {
      font-size: 14px;
      letter-spacing: 0.2px;
    }
    .counter-col {
      font-size: 20px;
      height: 70px;
      width: 60px;
      line-height: 65px;
      margin: 0 auto;
    }
  }
  .user-avtar-col {
    margin: 0 15px;
    width: 120px;
  }
  .pt10 {
    padding-top: 60px !important;
  }
  .pb10 {
    padding-bottom: 60px !important;
  }
  .main-wrapper.profile-raw h1 {
    font-size: 24px;
    margin-top: 30px;
  }
  .balance-earned-raw ul li {
    margin: 0 5px;
    .coin-label {
      font-size: 18px;
      padding: 15px 10px;
    }
    span {
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
  .personal-info-section .list-col .con-box {
    padding: 30px 0;
  }
  /*-----------------profile end-------------------*/
  .wallet-raw {
    .wallet-ul {
      .wallet-li {
        width: 100%;
      }
    }
  }
  /*----------------profile team start-------------------*/
  .profile-details-raw {
    .title-shadow h2 {
      font-size: 22px;
    }
    .team-overview-raw .the-team-raw .team-li {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 80px;
    }
  }
  .trophy-raw .trophy-li {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    .trophy-col .trophy-img {
      margin-top: -60px;
    }
  }
  /*-----------------profile team end--------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    .conn h4 {
      font-size: 18px !important;
      padding: 0 30px;
      height: 50px;
      line-height: 48px;
    }
  }
  /*-----------------profile achievements end-------------------*/
  .filter-raw {
    .filter-li {
      .select-game select {
        font-size: 12px;
      }
    }
  }
  .product-li-raw .row {
    .gm-li {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .challenge-raw.game-lists-raw .challenge-info-raw ul li h6 {
    font-size: 14px;
  }
  .footer-wrap .right-wrap .social-list-raw li {
    margin-right: 15px;
  }
  .table-list-notifies .con-col .conn {
    .img-fluid {
      width: 36px;
      height: 36px;
      margin-right: 10px;
    }
    h6 {
      font-size: 16px;
      span {
        font-size: 10px;
      }
    }
  }
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw {
    .timeline-raw {
      &.pdlr15 {
        .timeline {
          .events {
            ul {
              li {
                h5 {
                  strong {
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*----tournaments-brackets-end----------------*/
  /*----------challenge--pop-up-start----------*/
  .modal-raw {
    
    .modal-body {
      padding: 30px 16px;
    }
    .form-col {
      background-size: contain;
      padding-bottom: 10px;
    }
    &.challenge-modal-box {
      &.modal-border-raw {
        .modal-dialog {
          .modal-content {
            .modal-body {
              padding: 30px;
              .coins-col-box {
                .coins-col {
                  li {
                    width: calc(50% - 32px);
                    margin: 0px 15px 0px;
                    .img-box {
                      .reflect {
                        canvas {
                          width: 100%;
                        }
                      }
                    }
                  }
                }
              }
              .challenger-list-col {
                ul {
                  li {
                    padding: 10px;
                    .avtar {
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .tournament-details-col {
    ul li {
      width: 24%;
      &::before {
        right: -3px;
      }
      strong {
        letter-spacing: 0px;
        font-size: 14px;
      }
    }
  }
  .modal-raw .form-control {
    font-size: 12px;
  }
  .winners-col {
    .winner-li .win-img {
      img {
        width: 110px;
      }
      margin-top: -60px;
    }
    .win-img {
      img {
        width: 90px;
      }
      margin-top: -40px;
    }
  }
  /* .ongoing-events-raw
    .game-lists-raw
    .competition-tab-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li:last-child,
  .tournaments-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw .plateform-players-col li:last-child {
    width: 40%;
    margin-top: 0;
    a {
      margin-top: 0px;
    }
  } */
  .igl-tv-raw {
    .most-popular-raw {
      .title-divider::before,
      .title-divider::after {
        width: 16%;
      }
    }
  }
  .main-wrapper {
    &.about-main-raw {
      .about-inner-banner {
        .banner-con {
          margin: 230px 0 50px;
        }
      }
    }
  }
  .achievements-raw .row .achievements-li {
    text-align: center;
    .achievements-col {
      max-width: 228px;
      float: none;
      display: inline-block;
    }
  }
  /*------6 march-------change---parth---end------*/

  /*------------------------*/
  .main-wrapper.profile-raw {
    background-size: 2500px !important;
    .user-avtar-col {
      bottom: 0;
      float: none;
    }
    .profile-intro {
      text-align: center;
      h1 {
        margin-top: 20px;
        font-size: 26px;
      }
      p {
        span {
          font-size: 20px;
        }
      }
    }
    .follow-points {
      top: 0;
      margin: 40px 0;
      overflow: hidden;
      padding: 0;
      width: 100%;
      float: left;
      ul {
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
        max-width: 500px;
        li {
          padding: 10px 0;
          margin: 0 20px;
          &::before {
            right: -20px;
          }
          span {
            font-size: 28px;
          }
          h6 {
            font-size: 10px;
          }
        }
      }
    }
  }
  .personal-info-section .personal-info-back {
    .left-conn,
    .right-conn {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .left-profile button.btn-edit {
      right: 42px;
    }
    button.btn-edit {
      right: 30px;
      top: 110px;
    }
    .left-profile {
      &.ptb10 {
        padding-bottom: 10px !important;
      }
    }
  }
  .gaming-plateform-raw {
    padding: 0px;
  }

  .modal-dialog {
    max-width: 100%;
  }
  /*------------------------*/
  .ComingSoonSection {
    margin: 60px auto;

    .ComingSoon {
      font-size: 42px;
    }
    .InprogressText {
      font-size: 16px;
    }
    .GoBack {
      font-size: 24px;
    }
  }
  
  .thankyou{
    p{
      font-size: 16px;
    }
  }
}

@media (max-width: 359px) {
  .record-list-raw .record-list-col ul li {
    padding: 0;
  }
  .store-tab li .nav-link {
    min-width: 130px;
  }
  
  .ComingSoonSection {
    margin: 60px auto;

    .ComingSoon {
      font-size: 42px;
    }
    .InprogressText {
      font-size: 16px;
    }
    .GoBack {
      font-size: 24px;
    }
  }
  .thankyou{
    p{
      font-size: 16px;
    }
  }
}