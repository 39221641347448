
.challenges-banner-raw .inner-banner{
        height: 400px;
}
.challenges-banner-raw .inner-banner .container-fluid .inner-banner-con{
       top:50%;

}
.challenges-banner-raw .line-arrow {
    padding-top: 0px !important;
}
