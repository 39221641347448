.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
  width: 100%;
  height: 190px;
}
.plateform-players-col {
  margin: 10px auto 0px;
  width: 50%;
}
.plateform-players-col button, .plateform-players-col button:focus {
  margin-top: 15px;
  background: linear-gradient(180deg, #00bfff -19.4%, #0f2540 106.25%);
  color: #fff;
  width: 100%;
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 2px;
  font-weight: 600;
  border-color: transparent;
}
.plateform-players-col button:hover{
    border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
    color:#060919;
}
/* .game-lists-raw .gm-li-raw .row .gm-li .gm-col {
  border: none;
  box-shadow: none;
} */
.img_position {
  position: relative;
  text-align: center;
  color: white;
}
.centered {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.top-right {
  border: 1px solid #00bfff;
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 10px;
  background: rgba(0, 114, 152, 0.60);
  backdrop-filter: blur(50px);
  border-radius: 5px;
}
/* .game-lists-raw .gm-li-raw {
  border-bottom: 1px solid #fff;
} */
div.gm-li-raw:last-child {
  border-bottom: none;
}
/* .game-lists-raw .gm-li-raw .row .gm-li {
  margin-bottom: 50px;
} */
.team_title h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
#confirm_vote .modal-content .modal-body::before {
  background: none;
}
#after_vote .login-modal .modal-dialog .modal-body {
  background: #000000;
}
#after_vote .modal-raw .modal-content {
  border: 3px solid #00bfff;
  border-radius: 8px;
}
#after_vote .modal-body::before {
  background: none !important;
}
#after_vote .modal-content {
  background: #090e26;
}
#after_vote .modal-body {
  background: linear-gradient(180deg, rgb(0 0 0 / 58%) 0%, #000000 100%),
    url("https://iglnetwork.com/assets/images/logo.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#after_vote .modal-raw .modal-content .modal-body .title-raw h3 {
  font-weight: 500;
}
.about-game-conn h4 {
  text-transform: capitalize;

  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
}

.left-conn h6 {
  font-size: 18px;
  /* letter-spacing: 2px; */
}
.left-conn h6 span {
  font-size: 14px;
}
.plateform-players-pupop {
  margin-top: 100px;
  clear: both;
}
.plateform-players-pupop .cancel {
  background: transparent;
  color: #fff;
  border: 2px solid #00d4ff;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  width: 100%;
}
.plateform-players-pupop .vote {
  color: #fff;
  border: 2px solid #0686b8;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 18px;
  width: 100%;
  background: linear-gradient(180deg, #00bfff -19.4%, #0f2540 106.25%);
}

.team-voting .btn:disabled{
  color: white;
  border-color: transparent;
}
@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .team-voting .title-raw h1 span{
    font-size: 115px;
  }  
}
@media only screen and (min-width: 1599px){
  .team-voting .title-raw h1 span{
    font-size: 135px;
  }  
  
}