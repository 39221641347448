// @font-face {
//     font-family: "D-DIN Condensed";
//     src: url("fonts/D-DINCondensed.eot");
//     src: url("fonts/D-DINCondensed.eot?#iefix") format("embedded-opentype"), url("fonts/D-DINCondensed.woff2") format("woff2"), url("fonts/D-DINCondensed.woff") format("woff"), url("fonts/D-DINCondensed.ttf") format("truetype"), url("fonts/D-DINCondensed.svg#D-DINCondensed") format("svg");
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }
@font-face {
    font-family: "D-DIN Condensed";
    src: url('fonts/d-dincondensed-webfont.eot');
    src: url('fonts/d-dincondensed-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/d-dincondensed-webfont.woff2') format('woff2'), url('fonts/d-dincondensed-webfont.woff') format('woff'), url('fonts/d-dincondensed-webfont.ttf') format('truetype'), url('fonts/d-dincondensed-webfont.svg#d-din_condensedregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: "D-DIN";
//     src: url("fonts/D-DIN.eot");
//     src: url("fonts/D-DIN.eot?#iefix") format("embedded-opentype"), url("fonts/D-DIN.woff2") format("woff2"), url("fonts/D-DIN.woff") format("woff"), url("fonts/D-DIN.ttf") format("truetype"), url("fonts/D-DIN.svg#D-DIN") format("svg");
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }
@font-face {
    font-family: "D-DIN";
    src: url('fonts/d-din-webfont.eot');
    src: url('fonts/d-din-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/d-din-webfont.woff2') format('woff2'), url('fonts/d-din-webfont.woff') format('woff'), url('fonts/d-din-webfont.ttf') format('truetype'), url('fonts/d-din-webfont.svg#d-dinregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "D-DIN";
    src: url('fonts/d-din-bold-webfont.eot');
    src: url('fonts/d-din-bold-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/d-din-bold-webfont.woff2') format('woff2'), url('fonts/d-din-bold-webfont.woff') format('woff'), url('fonts/d-din-bold-webfont.ttf') format('truetype'), url('fonts/d-din-bold-webfont.svg#d-dindin-bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

// @font-face {
//     font-family: "D-DIN Condensed";
//     src: url("fonts/D-DINCondensed-Bold.eot");
//     src: url("fonts/D-DINCondensed-Bold.eot?#iefix") format("embedded-opentype"), url("fonts/D-DINCondensed-Bold.woff2") format("woff2"), url("fonts/D-DINCondensed-Bold.woff") format("woff"), url("fonts/D-DINCondensed-Bold.ttf") format("truetype"), url("fonts/D-DINCondensed-Bold.svg#D-DINCondensed-Bold") format("svg");
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }
@font-face {
    font-family: "D-DIN Condensed";
    src: url('fonts/d-dincondensed-bold-webfont.eot');
    src: url('fonts/d-dincondensed-bold-webfont.eot?#iefix') format('embedded-opentype'), url('fonts/d-dincondensed-bold-webfont.woff2') format('woff2'), url('fonts/d-dincondensed-bold-webfont.woff') format('woff'), url('fonts/d-dincondensed-bold-webfont.ttf') format('truetype'), url('fonts/d-dincondensed-bold-webfont.svg#d-din_condenseddinCnBd') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "proximanova";
    src: url("fonts/Proxima-Nova-Extrabold.woff"), url("fonts/Proxima-Nova-Extrabold.otf"), url("fonts/Proxima-Nova-Extrabold.ttf"), url("fonts/Proxima-Nova-Extrabold.svg");
    font-weight: 900;
    font-style: normal;
}

@mixin transition($property) {
    -webkit-transition: $property;
    transition: $property;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    transform: $transform;
}

@mixin box-shadow($box-shadow) {
    -webkit-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}

@mixin border-radius($border-radius) {
    -webkit-border-radius: $border-radius;
    border-radius: $border-radius;
}

@mixin transition-duration($transition-duration) {
    -webkit-transition-duration: $transition-duration;
    transition-duration: $transition-duration;
}

@mixin transition-property($transition-property) {
    -webkit-transition-property: $transition-property;
    transition-property: $transition-property;
}

@mixin transition-timing-function($transition-timing-function) {
    -webkit-transition-timing-function: $transition-timing-function;
    transition-timing-function: $transition-timing-function;
}


/* remember to define focus styles! */


/* tables still need 'cellspacing="0"' in the markup */


/*  Header  Start */


/*-----------------res-nav-Start-------------------*/


/*-----------------res-nav-end-------------------*/


/*  Header  End */


/* Style text tooltip */


/*
font-family: 'D-DIN';

font-family: 'D-DIN Condensed';

font-family: 'Montserrat', sans-serif;
*/

* {
    padding: 0;
    margin: 0;
    list-style: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.section {
    float: left;
    width: 100%;
}

.text-wrap {
    float: left;
    width: 100%;
}

.img-wrap {
    float: left;
    width: 100%;
}

ul {
    float: left;
    width: 100%;
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    list-style: none;
    margin: 0;
    padding-left: 0 !important;
}

body {
    background: #060919;
    font-size: 100%;
    color: #444444;
    line-height: 100%;
    font-style: normal;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    padding: 0px !important;
    -webkit-font-smoothing: antialiased;
    border: 0;
    outline: 0;
    font-size: 100%;
}

html {
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    a {
        -webkit-font-smoothing: antialiased;
    }
}

a {
    @include transition(all 0.25s ease 0s);
    outline: none;
    cursor: pointer;
    text-decoration: none;
    // color: #FFF;
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    &:hover {
        text-decoration: none;
        text-decoration: none;
        color: #00d3ff;
    }
    &:focus {
        text-decoration: none;
        text-decoration: none;
        color: #00d3ff;
        outline: 0;
    }
}

div {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

span {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

applet {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

object {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

iframe {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

h1,
h2 {
    border: 0;
    outline: 0;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    line-height: 120%;
    font-size: 50px;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#00d3ff, #3385cc);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    overflow-wrap: break-word;
}

// h2 {
// 	border: 0;
// 	outline: 0;
// 	font-size: 100%;
// 	float: left;
// 	width: 100%;
// 	padding: 0;
// 	margin: 0;
// 	font-family: 'Montserrat', sans-serif;
// 	font-weight: 500;
// 	line-height: 120%;
// 	font-size: 70px;
// 	color: white;
// }
h3 {
    border: 0;
    outline: 0;
    font-size: 100%;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 120%;
    font-size: 60px;
    color: white;
}

h4 {
    border: 0;
    outline: 0;
    font-size: 100%;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 120%;
    font-size: 26px;
    color: #009cff;
    // font-family: 'D-DIN Condensed';
    font-weight: bold;
    line-height: 120%;
    letter-spacing: 2px;
}

h5 {
    border: 0;
    outline: 0;
    font-size: 100%;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 120%;
    font-size: 19px;
    color: white;
    // font-family: 'D-DIN Condensed';
    font-weight: 700;
}

h6 {
    // font-family: 'D-DIN Condensed';
    border: 0;
    outline: 0;
    font-size: 100%;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 120%;
    font-size: 12px;
    font-weight: 500;
    color: white;
}

p {
    // font-family: 'D-DIN Condensed';
    border: 0;
    outline: 0;
    float: left;
    width: 100%;
    padding: 0;
    margin: 0;
    // font-family: 'Montserrat', sans-serif;
    // font-weight: 500;
    // line-height: 120%;
    font-family: "Montserrat", sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #8b8b8b;
    line-height: 180%;
    &:last-child {
        padding-bottom: 0;
    }
}

blockquote {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    quotes: "" "";
    &:before {
        content: "";
    }
    &:after {
        content: "";
    }
}

pre {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

abbr {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

acronym {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

address {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

big {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

cite {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

code {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

del {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

dfn {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

em {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

font {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

img {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    border: 0 none;
    max-width: 100%;
}

ins {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

kbd {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

q {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    quotes: "" "";
    &:before {
        content: "";
    }
    &:after {
        content: "";
    }
}

s {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

samp {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

small {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

strike {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

strong {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
}

sub {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

sup {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

tt {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

var {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

dl {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

dt {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

dd {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

ol {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    list-style: none;
    margin: 0;
}

li {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

fieldset {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

form {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

label {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

legend {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

table {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    border-collapse: separate;
    border-spacing: 0;
    border: 0 none;
    border-collapse: collapse;
    border-spacing: 0;
    @extend .section;
    tr {
        &:first-child {
            position: relative;
        }
        th {
            &:first-child {
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    float: left;
                    height: 1px;
                    left: 0;
                    bottom: 0;
                    background: -moz-linear-gradient( left, rgba(1, 195, 236, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(1, 195, 236, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    background: linear-gradient( to right, rgba(1, 195, 236, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                }
            }
            &:last-child {
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    float: left;
                    height: 1px;
                    left: 0;
                    bottom: 0;
                    background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 100%);
                    background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 100%);
                    background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 100%);
                }
            }
            background-color: #0f213c !important;
            padding: 14px 20px;
            font-family: "D-DIN Condensed";
            font-weight: normal;
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            line-height: 120%;
            letter-spacing: 2px;
            position: relative;
        }
        th.blank {
            &:after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: #070919;
                background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
            }
        }
        th.rank {
            width: 140px;
        }
        th.team {
            width: 220px;
        }
        th.points {
            width: 170px;
        }
        th.coins {
            width: 190px;
        }
        td {
            font-family: "D-DIN Condensed";
            font-weight: normal;
            color: white;
            font-size: 20px;
            text-transform: uppercase;
            line-height: 120%;
            letter-spacing: 2px;
            position: relative;
            padding: 16px 20px;
            border-bottom: 1px solid #002335;
            img {
                display: inline-block;
                vertical-align: middle;
            }
            span {
                display: inline-block;
                vertical-align: middle;
                padding-left: 10px;
            }
            i {
                width: 7px;
                height: 31px;
                background-image: url(../images/slash-image.png);
                display: inline-block;
                vertical-align: top;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
        }
        td.team {
            padding: 10px 20px;
        }
        td.slash {
            padding: 13px 20px;
            line-height: 150%;
        }
    }
    tr.active {
        td {
            &:first-child {
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    float: left;
                    height: 1px;
                    left: 0;
                    bottom: 0;
                    background: -moz-linear-gradient( left, rgba(1, 195, 236, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(1, 195, 236, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    background: linear-gradient( to right, rgba(1, 195, 236, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                }
            }
            &:last-child {
                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    float: left;
                    height: 1px;
                    left: 0;
                    bottom: 0;
                    background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 100%);
                    background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 100%);
                    background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 100%);
                }
            }
            color: #00d8ff;
        }
        td.blank {
            &:after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: #070919;
                background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
            }
            background-image: url(../images/table-arrow-img.png);
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
}

caption {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    text-align: left;
    font-weight: normal;
}

tbody {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

tfoot {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

thead {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

tr {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
}

th {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    text-align: left;
    font-weight: normal;
}

td {
    font-family: "D-DIN Condensed";
    border: 0;
    outline: 0;
    font-size: 100%;
    text-align: left;
    font-weight: normal;
    vertical-align: top;
}

button {
    cursor: pointer;
    border: none;
}

input[type="checkbox"] {
    vertical-align: bottom;
    margin-right: 10px;
    // appearance: none;
    width: 15px;
    height: 15px;
    border: 2px solid #cbccd1;
}

input[type="radio"] {
    height: auto;
    vertical-align: top;
}

input[type="number"] {
    &::-webkit-outer-spin-button {
        appearance: none;
        -webkit-appearance: none;
    }
    &::-webkit-inner-spin-button {
        appearance: none;
        -webkit-appearance: none;
    }
}
.chk-style{
    color: rgba(255,255,255,.6);
}
.left {
    float: left;
}

.right {
    float: right;
}

.clear {
    clear: both;
}

.text_right {
    text-align: right;
}

.text_left {
    text-align: left;
}

.text_center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.clearfix {
    clear: both;
    display: block;
    font-size: inherit;
    height: 0;
    line-height: 0;
}

h6.uppercase {
    text-transform: uppercase;
}

b {
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
}

article {
    display: block;
}

aside {
    display: block;
}

details {
    display: block;
}

figcaption {
    display: block;
}

figure {
    display: block;
}

footer {
    display: block;
}

header {
    display: block;
}

hgroup {
    display: block;
}

nav {
    display: block;
}

section {
    display: block;
}

.relative {
    position: relative;
}

.mt0 {
    margin-top: 0px !important;
}

.mt50 {
    margin-top: 50px !important;
}

.mt80 {
    margin-top: 80px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mb30 {
    margin-bottom: 30px !important;
}

.mb80 {
    margin-bottom: 80px !important;
}

.mt250 {
    margin-top: 180px !important;
}

.ptb10 {
    padding-top: 110px !important;
    padding-bottom: 110px !important;
}

.pt10 {
    padding-top: 110px !important;
}

.pt50 {
    padding-top: 50px !important;
}

.pb50 {
    padding-bottom: 50px !important;
}

.ptb50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.pt70 {
    padding-top: 70px !important;
}

.pb70 {
    padding-bottom: 70px !important;
}

.pb10 {
    padding-bottom: 110px !important;
}

.pdlr5 {
    padding-left: 5% !important;
    padding-right: 5% !important;
}

.pdlr10 {
    padding-left: 10% !important;
    padding-right: 10% !important;
}

.pdlr15 {
    padding-left: 15% !important;
    padding-right: 15% !important;
}

.font20 {
    font-size: 20px !important;
}

.btn-main {
    background: transparent;
    width: auto;
    min-width: 170px;
    height: 46px;
    line-height: 44px;
    display: inline-block;
    width: auto;
    padding: 0 30px;
    margin-top: 20px;
    font-family: 'D-DIN';
    font-weight: bold;
    font-size: 16px;
    color: white;
    letter-spacing: 0.5px;
    border: 2px solid #009cff;
    @include transition(all 0.25s ease 0s);
    @include border-radius(100px);
    position: relative;
    // z-index: 9;
    text-transform: uppercase;
    text-align: center;
    text-shadow: 0px 0px 15px white;
    -webkit-text-shadow: 0px 0px 15px white;
    &:hover {
        background-color: #00d3ff !important;
        border-color: #00d3ff !important;
        color: white !important;
    }
    &:focus {
        background-color: #00d3ff !important;
        border-color: #00d3ff !important;
        color: white !important;
    }
    &.bg-trans {
        background-color: rgba(0, 156, 255, 0.4);
        &:hover {
            background-color: rgba(0, 156, 255, 0.7);
        }
    }
    &.bg-color {
        background-color: rgba(0, 156, 255, 0.5);
        color: white;
        border-color: #009cff;
        text-transform: uppercase;
        height: 50px;
        line-height: 48px;
        margin-top: 50px;
    }
    &.btn-sqr-gradient {
        @include border-radius(5px);
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        height: 50px;
        line-height: 50px;
        background: rgb(0, 216, 255);
        background: -moz-linear-gradient(left, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
        background: -webkit-linear-gradient(left, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
        background: linear-gradient(to right, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
        color: white;
        border: 0px;
        margin-top: 50px;
        &:hover {
            background: rgba(0, 156, 255, 1);
            background: -moz-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
            background: -webkit-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
            background: linear-gradient(to right, rgba(0, 156, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
        }
    }
}

.txt-shad-none {
    text-shadow: none;
    -webkit-text-shadow: none;
}

.gform_button {
    background: transparent;
    width: auto;
    min-width: 217px;
    height: 60px;
    line-height: 54px;
    display: inline-block;
    width: auto;
    padding: 0 30px;
    margin-top: 20px;
    font-family: "D-DIN Condensed";
    font-size: 18px;
    font-weight: bold;
    color: #8b8b8b;
    letter-spacing: 0.5px;
    border: 2px solid #8b8b8b;
    @include transition(all 0.25s ease 0s);
    @include border-radius(100px);
    position: relative;
    z-index: 9;
    text-transform: capitalize;
    text-align: center;
    &:hover {
        background-color: #00d3ff !important;
        border-color: #00d3ff !important;
        color: white !important;
    }
    &:focus {
        background-color: #00d3ff !important;
        border-color: #00d3ff !important;
        color: white !important;
    }
}

.btn-main.red {
    color: #ed1f24;
    border-color: #ed1f24;
    &:hover {
        background-color: #00d3ff !important;
        border-color: #00d3ff !important;
    }
    &:focus {
        background-color: #00d3ff !important;
        border-color: #00d3ff !important;
    }
}

.gform_button.red {
    color: #ed1f24;
    border-color: #ed1f24;
    &:hover {
        background-color: #00d3ff !important;
        border-color: #00d3ff !important;
    }
    &:focus {
        background-color: #00d3ff !important;
        border-color: #00d3ff !important;
    }
}

.btn-main.blue {
    color: #4e9ec3;
    border-color: #4e9ec3;
    &:hover {
        background-color: #4e9ec3 !important;
        border-color: #4e9ec3 !important;
        color: white;
    }
    &:focus {
        background-color: #4e9ec3 !important;
        border-color: #4e9ec3 !important;
        color: white;
    }
}

.gform_button.blue {
    color: #4e9ec3;
    border-color: #4e9ec3;
    &:hover {
        background-color: #4e9ec3 !important;
        border-color: #4e9ec3 !important;
        color: white;
    }
    &:focus {
        background-color: #4e9ec3 !important;
        border-color: #4e9ec3 !important;
        color: white;
    }
}

.form-control {
    float: left;
    width: 100%;
    background: #060919;
    border: 1px solid #00d8ff;
    height: 45px;
    padding: 0 25px;
    font-family: "D-DIN Condensed";
    font-size: 18px;
    font-weight: normal;
    color: white;
    line-height: 120%;
    @include transition(all 0.25s ease 0s);
    @include border-radius(100px);
    &::-webkit-input-placeholder {
        color: white;
    }
    &::-moz-placeholder {
        color: white;
    }
    &:-ms-input-placeholder {
        color: white;
    }
    &:-moz-placeholder {
        color: white;
    }
    &:focus {
        background-color: #000;
        border-color: white;
        -webkit-box-shadow: none;
        box-shadow: none;
    }
}

@import "hack";
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    z-index: 999999;
}

#status {
    width: 122px;
    height: 114px;
    position: absolute;
    left: 50%;
    top: 50%;
    background-image: url(../images/logo.png);
    background-repeat: no-repeat;
    background-position: center;
    @include transform(translate(-50%,
    -50%));
}

#header {
    float: left;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    @include transition(all 0.6s);
    background: #060919;
}

.header {
    padding: 0 25px;
    border-bottom: 1px solid transparent;
    @include transition(all 0.25s ease 0s);
    position: relative;
    .logo {
        display: inline-block;
        @include transition(all 0.25s ease 0s);
        width: 122px;
        position: relative;
        z-index: 10;
        left: -8px;
        vertical-align: top;
        a {
            width: auto;
            float: left;
            img {
                margin: 0 auto;
                display: inline-block;
            }
        }
    }
    .logo.desktop {
        margin: -10px 45px -68px;
    }
    .setting-head {
        width: auto;
        float: left;
        position: relative;
        top: 8px;
        @include transition(all 0.25s ease 0s);
    }
    .admin {
        // width: 160px;
        width: auto;
        float: left;
        text-align: left;
        display: flex;
        align-items: center;
        padding: 0;
        position: relative;
        z-index: 11;
        top: 0px;
        @include transition(all 0.25s ease 0s);
        &.login-search-ic {
            margin-top: 8px;
        }
        .icon {
            width: 38px;
            height: 38px;
            float: left;
            @include border-radius(100%);
            text-align: center;
            display: flex;
            align-items: center;
            background: -moz-linear-gradient(top, #009cff 0%, #00d8ff 100%);
            background: -webkit-linear-gradient(top, #009cff 0%, #00d8ff 100%);
            background: linear-gradient(to bottom, #009cff 0%, #00d8ff 100%);
            position: relative;
            z-index: 111;
            &::before {
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 5px solid #00b8ff;
                content: "";
                position: absolute;
                right: -14px;
                top: 50%;
                @include transform(translate(-50%,
                0));
            }
            img {
                display: block;
                margin: 0 auto;
            }
        }
    }
    .notification {
        width: 160px;
        float: right;
        padding: 3px 0;
        @include transition(all 0.25s ease 0s);
        >ul {
            width: 100%;
            float: left;
            text-align: right;
            // display: flex;
            align-items: center;
            >li {
                display: inline-block;
                vertical-align: middle;
                margin-right: 30px;
                &:last-child {
                    margin-right: 0px;
                }
                >a {
                    width: auto;
                    float: left;
                }
            }
        }
    }
    .login-raw {
        display: flex;
        align-items: center;
        a {
            //float: left;
            display: inline-block;
            vertical-align: middle;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 10px;
            &.btn-main {
                margin: 0;
                height: auto;
                min-width: initial;
                line-height: inherit;
                padding: 10px 15px;
                text-transform: uppercase;
            }
            &.btn-login {
                text-transform: uppercase;
                background: transparent url(../images/ic-login.png) left center no-repeat;
                padding-left: 35px;
                color: white;
                padding: 5px 25px 5px 35px;
            }
        }
    }
    .header-right {
        width: calc(100% - 320px);
        margin: 0 auto;
        margin-left: -45px;
        // float: right;
        position: relative;
        z-index: 9;
        @include transition(all 0.3s);
        text-align: center;
        padding: 25px;
        &:after {
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: -1px;
            left: 0;
            background: #070919;
            background: -moz-linear-gradient(left, #070919 0%, #0998f6 25%, #0998f6 50%, #0998f6 75%, #070919 100%);
            background: -webkit-linear-gradient(left, #070919 0%, #0998f6 25%, #0998f6 50%, #0998f6 75%, #070919 100%);
            background: linear-gradient(to right, #070919 0%, #0998f6 25%, #0998f6 50%, #0998f6 75%, #070919 100%);
        }
        .nav-main {
            display: inline-block;
            width: auto;
            ul {
                display: inline-block;
                width: auto;
                float: none;
                min-width: 562px;
                vertical-align: top;
                @include transition(all 0.3s);
            }
            li {
                float: none;
                display: inline-block;
                text-align: center;
                width: auto;
                position: relative;
                margin-right: 0 !important;
                @include transition(all 0.25s ease 0s);
                // min-width: 88px;
                &:last-child {
                    margin-right: 0px;
                }
                &::after {
                    height: 1px;
                    background: #00d3ff;
                    width: 100%;
                    @include transition(all 0.25s ease 0s);
                    contain: "";
                    position: absolute;
                    left: 0;
                    bottom: 0px;
                }
                &:hover {
                    &::after {
                        width: 100%;
                    }
                }
                a {
                    width: auto;
                    display: inline-block;
                    height: auto;
                    font-family: "D-DIN";
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 120%;
                    color: white;
                    letter-spacing: 2px;
                    text-decoration: none;
                    @include transition(all 0.25s ease 0s);
                    position: relative;
                    margin: 0 18px;
                    &::before {
                        width: 1px;
                        height: 100%;
                        content: "";
                        position: absolute;
                        right: -20px;
                        top: 0;
                        background: #0f243f;
                        // @include transform(translate(-50%,
                        // 0));
                    }
                    .icon {
                        max-height: 30px;
                        line-height: 30px;
                        width: auto;
                        clear: both;
                        display: block;
                        position: relative;
                        img,
                        svg {
                            max-height: 30px;
                            @include transition(all 0.25s ease 0s);
                        }
                        svg {
                            .cls-1,
                            .cls-1s {
                                stroke: #00a8ff;
                                @include transition(all 0.25s ease 0s);
                            }
                            .cls-2,
                            .cls-3 {
                                fill: #00a8ff;
                                @include transition(all 0.25s ease 0s);
                            }
                        }
                    }
                    &:hover,
                    &.active {
                        .icon {
                            svg {
                                filter: drop-shadow(0 0 0.75rem #00a8ff);
                                .cls-1,
                                .cls-1s {
                                    stroke: white;
                                }
                                .cls-2,
                                .cls-3 {
                                    fill: white;
                                }
                            }
                        }
                    }
                    &.active{
                        span {
                            color: #00c0ff;
                        }
                    }
                    span {
                        width: auto;
                        display: inline-block;
                        font-family: "D-DIN";
                        // color: #00c0ff;
                        color: white;
                        text-transform: uppercase;
                        padding-top: 0 !important;
                        @include transition(all 0.3s);
                        
                        &:hover {
                            // color: white;
                            color: #00c0ff;
                            &::after {
                                @include transform(scale(1));
                            }
                        }
                    }
                    &::after {
                        position: absolute;
                        content: "";
                        left: 0;
                        bottom: -6px;
                        width: 100%;
                        height: 1px;
                        background: #00d3ff;
                        @include transform(scale(0,
                        1));
                        @include transition(all 0.25s ease 0s);
                    }
                    &:hover {
                        // color: white;
                        color: #00c0ff;
                        &::after {
                            @include transform(scale(1));
                        }
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0px;
                        &::before {
                            display: none;
                        }
                    }
                }
                &:first-child {
                    a {
                        margin-left: 0px;
                    }
                }
            }
            li.current-menu-item {
                &::after {
                    width: 100%;
                }
                a {
                    color: white;
                    &::after {
                        @include transform(scale(1));
                    }
                }
            }
        }
        .head-action {
            float: left;
            width: auto;
            .btn-main {
                margin-top: 0;
                font-size: 14px;
                height: 38px;
                line-height: 34px;
                min-width: inherit;
            }
            .btn-main.login {
                background-color: #00d3ff;
                border-color: #00d3ff;
                color: white;
                margin-right: 15px;
            }
            .btn-main.register {
                &:hover {
                    background-color: #6dd847 !important;
                    border-color: #6dd847 !important;
                }
                &:focus {
                    background-color: #6dd847 !important;
                    border-color: #6dd847 !important;
                }
            }
        }
    }
}


.topbar {
    background: rgba(0, 157, 255, 0.35);
    background: -moz-linear-gradient( left, rgba(0, 157, 255, 0.35) 0%, rgba(0, 157, 255, 0.99) 50%, rgba(0, 157, 255, 1) 51%, rgba(0, 157, 255, 0.36) 100%);
    background: -webkit-gradient( left top, right top, color-stop(0%, rgba(0, 157, 255, 0.35)), color-stop(50%, rgba(0, 157, 255, 0.99)), color-stop(51%, rgba(0, 157, 255, 1)), color-stop(100%, rgba(0, 157, 255, 0.36)));
    background: -o-linear-gradient( left, rgba(0, 157, 255, 0.35) 0%, rgba(0, 157, 255, 0.99) 50%, rgba(0, 157, 255, 1) 51%, rgba(0, 157, 255, 0.36) 100%);
    background: -ms-linear-gradient( left, rgba(0, 157, 255, 0.35) 0%, rgba(0, 157, 255, 0.99) 50%, rgba(0, 157, 255, 1) 51%, rgba(0, 157, 255, 0.36) 100%);
    background: -webkit-linear-gradient( left, rgba(0, 157, 255, 0.35) 0%, rgba(0, 157, 255, 0.99) 50%, rgba(0, 157, 255, 1) 51%, rgba(0, 157, 255, 0.36) 100%);
    padding: 12px 30px;
    font-size: 0;
    .left-side {
        display: inline-block;
        width: 50%;
        vertical-align: middle;
        h6 {
            float: left;
            font-size: 14px;
            strong {
                font-weight: 800;
                font-size: 14px;
                letter-spacing: 1.5px;
            }
            span {
                font-size: 12px;
                float: left;
                font-style: italic;
                font-weight: 500;
                font-family: "Montserrat", sans-serif;
                margin-right: 10px;
            }
            &.uppercase {
                letter-spacing: 1px;
                font-weight: 500;
                font-size: 12px;
                span {
                    font-style: normal;
                    position: relative;
                    &::after {
                        content: ":";
                        display: inline-block;
                        margin-left: 2px;
                    }
                }
            }
        }
    }
    h6 {
        line-height: 150%;
        width: auto;
    }
    .right-side {
        display: flex;
        float: right;
        justify-content: flex-end;
        h6 {
            display: inline-flex;
            float: none;
            padding-left: 20px;
            margin-left: 10px;
            border-left: 1px solid rgba(255, 255, 255, 0.2);
            letter-spacing: 1px;
        }
        .social-list-raw{
            font-size: 16px;
        }
        .btn-ic{
            background: #009cff url(../images/ic-head-cart.png) left 20px center no-repeat;
            margin-left: 40px;
            padding: 10px 30px 10px 50px;
        }
    }
}

ul.social-list-raw {
    display: inline-flex;
    float: none;
    width: auto;
    vertical-align: middle;
    li {
        margin: 0 12px;
        i {
            color: white;
        }
    }
}

.left-side {
    h6 {
        font-weight: 700;
        font-size: 14px;
        span {
            font-weight: 500;
            letter-spacing: inherit;
        }
        &:first-child {
            padding-right: 30px;
            margin-right: 30px;
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            letter-spacing: 0.5px;
        }
    }
}

.scroll-wrap {
    @extend .section;
}

.mobile {
    display: none !important;
}

.toggle-menu {
    display: none;
    width: 72px;
    height: 22px;
    position: absolute;
    right: 0;
}

.nav-toggle {
    position: absolute;
    top: 3px;
    right: 0;
    z-index: 999999;
    padding: 5px 30px 13px 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
    span {
        background: #269ada;
        border: none;
        height: 2px;
        width: 100%;
        position: absolute;
        top: -2px;
        left: 0;
        @include transition(all 0.55s ease);
        cursor: pointer;
        &:nth-of-type(2) {
            top: 7px;
        }
        &:nth-of-type(3) {
            top: 16px;
        }
    }
}

.nav-toggle.active {
    .Top {
        @include transform(translateY(12px) translateX(0) rotate(45deg));
        background: #269ada;
        top: -5px;
    }
    .Bottom {
        @include transform(translateY(-7px) translateX(0) rotate(-45deg));
        background: #269ada;
        top: 14px;
    }
    .Middle {
        opacity: 0;
        background: #269ada;
    }
}

.container {
    padding: 0;
    max-width: 1520px;
}
.before-login{
    .main-wrapper {
        padding-top: 90px;
    }    
}
.main-wrapper {
    @extend .section;
    background-image: url(../images/main-banner.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    padding-top: 130px;
}

.league-wrapper {
    padding-top: 150px;
    .leacue-table-img {
        width: 100%;
        float: left;
        text-align: center;
        position: relative;
        margin-bottom: 50px;
    }
    .expert-master {
        width: 100%;
        float: left;
        text-align: center;
        position: relative;
        .progress-wrap {
            width: 100%;
            float: left;
            text-align: center;
            h5 {
                &:first-child {
                    color: #00ffcc;
                }
                font-style: italic;
                display: inline-block;
                vertical-align: middle;
                width: auto;
                float: none;
                text-transform: uppercase;
                margin: 0 20px 0;
                font-size: 18px;
                color: #00d8ff;
            }
            .redius-col {
                overflow: hidden;
                @extend .section;
                height: 100%;
                @include border-radius(25px);
                position: relative;
            }
            .progress-bar-raw {
                @include box-shadow(0 0 20px #01cdf2);
                vertical-align: middle;
                float: none;
                display: inline-block;
                width: 50%;
                display: inline-block;
                vertical-align: middle;
                border: 1px solid #01cdf2;
                padding: 2px;
                height: auto;
                @include border-radius(25px);
                background: transparent;
                position: relative;
                .progress-bar {
                    background-color: #06e2f5;
                    background-image: url(../images/progress-arrow.png);
                    background-size: contain;
                    animation-direction: reverse;
                    animation-duration: 2.5s;
                    height: 26px;
                    color: rgba(0, 0, 0, 0.5);
                    font-size: 18px;
                    font-family: "D-DIN Condensed";
                    font-weight: bold;
                    position: relative;
                    span {
                        position: absolute;
                        right: 10px;
                        color: #06152a;
                    }
                }
            }
        }
        p {
            font-family: "D-DIN Condensed";
            font-weight: normal;
            font-size: 15px;
            color: #2b5182;
            padding-top: 10px;
        }
    }
}

.label {
    font-family: "Aldrich", sans-serif;
    position: absolute;
    display: block;
    width: 40px;
    height: 30px;
    line-height: 30px;
    top: 38px;
    left: 0px;
    background: rgb(76, 76, 76);
    background: -moz-linear-gradient(top, rgba(76, 76, 76, 1) 0%, rgba(38, 38, 38, 1) 100%);
    background: -webkit-gradient( linear, left top, left bottom, color-stop(0%, rgba(76, 76, 76, 1)), color-stop(100%, rgba(38, 38, 38, 1)));
    background: -webkit-linear-gradient(top, rgba(76, 76, 76, 1) 0%, rgba(38, 38, 38, 1) 100%);
    background: -o-linear-gradient(top, rgba(76, 76, 76, 1) 0%, rgba(38, 38, 38, 1) 100%);
    background: -ms-linear-gradient(top, rgba(76, 76, 76, 1) 0%, rgba(38, 38, 38, 1) 100%);
    background: linear-gradient(to bottom, rgba(76, 76, 76, 1) 0%, rgba(38, 38, 38, 1) 100%);
    font-weight: bold;
    font-size: 12px;
    color: #fff;
    text-align: center;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
    text-shadow: 0px -1px 0px #000000, 0px 1px 1px #000000;
}

.label span {
    position: absolute;
    display: block;
    width: 12px;
    height: 9px;
    top: -9px;
    left: 14px;
    background: transparent;
    overflow: hidden;
}

.label span:before {
    position: absolute;
    display: block;
    content: "";
    width: 8px;
    height: 8px;
    top: 4px;
    left: 2px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: rgb(86, 86, 86);
    background: -moz-linear-gradient(-45deg, rgba(86, 86, 86, 1) 0%, rgba(76, 76, 76, 1) 50%);
    background: -webkit-gradient( linear, left top, right bottom, color-stop(0%, rgba(86, 86, 86, 1)), color-stop(50%, rgba(76, 76, 76, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(86, 86, 86, 1) 0%, rgba(76, 76, 76, 1) 50%);
    background: -o-linear-gradient(-45deg, rgba(86, 86, 86, 1) 0%, rgba(76, 76, 76, 1) 50%);
    background: -ms-linear-gradient(-45deg, rgba(86, 86, 86, 1) 0%, rgba(76, 76, 76, 1) 50%);
    background: linear-gradient(135deg, rgba(86, 86, 86, 1) 0%, rgba(76, 76, 76, 1) 50%);
    -webkit-box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.15);
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.15);
    -moz-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@-webkit-keyframes sparkle {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 -64px;
    }
}

@-moz-keyframes sparkle {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 -64px;
    }
}

@-o-keyframes sparkle {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 -64px;
    }
}

@keyframes sparkle {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 -64px;
    }
}

@import url(https://fonts.googleapis.com/css?family=Aldrich);
.table-wrapper {
    padding: 110px 0 100px;
}

.select-dropdown, .select-game {
    @extend .section;
    select,
    input {
        max-width: 450px;
        width: 100%;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: none;
        padding: 10px 30px 10px 10px;
        border-top: 1px solid #0180d3;
        border-bottom: 1px solid #0180d3;
        background-color: transparent;
        color: rgba(255, 255, 255, 0.6);
        font-size: 15px;
        font-family: "D-DIN Condensed";
        text-transform: uppercase;
        letter-spacing: 2px;
        background-image: url(../images/ic-select.png);
        background-repeat: no-repeat;
        background-position: right 10px center;
        @include transition(all 0.25s ease 0s);
        cursor: pointer;
        option {
            color: #0180d3;
        }
        // &::-webkit-input-placeholder {
        // 	color: rgba(255,255,255,0.80);
        // }
        // &::-moz-placeholder {
        // 	color: rgba(255,255,255,0.80);
        // }
        // &:-ms-input-placeholder {
        // 	color: rgba(255,255,255,0.80);
        // }
        // &:-moz-placeholder {
        // 	color: rgba(255,255,255,0.80);
        // }
        &:focus {
            outline: none;
        }
    }
    .date {
        background-image: url(../images/ic-calendar.png);
    }
}

.faqs-wrapper {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    .accordion {
        width: 100%;
        float: left;
        .card {
            width: 100%;
            float: left;
            padding-top: 50px;
            background-color: transparent;
            overflow: initial;
            border: none;
            .card-header {
                width: 100%;
                float: left;
                border: none;
                padding: 0px;
                margin: 0;
                position: relative;
                >span {
                    font-family: "Montserrat", sans-serif;
                    font-weight: 900;
                    font-size: 24px;
                    color: white;
                    text-transform: uppercase;
                    letter-spacing: 3px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    @include transform(translate(-50%,
                    -50%));
                    z-index: 1;
                }
                button {
                    width: 100%;
                    float: left;
                    border: none;
                    height: 50px;
                    padding: 13px 70px;
                    line-height: 120%;
                    background: #00d8ff;
                    background: -moz-linear-gradient(top, #00d8ff 1%, #009cff 100%);
                    background: -webkit-linear-gradient(top, #00d8ff 1%, #009cff 100%);
                    background: linear-gradient(to bottom, #00d8ff 1%, #009cff 100%);
                    @include border-radius(0);
                    position: relative;
                    text-decoration: none;
                    outline: none;
                    &:after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        float: left;
                        height: 1px;
                        left: 0;
                        bottom: 0;
                        background: -moz-linear-gradient( left, rgba(1, 195, 236, 1) 0%, rgba(1, 195, 236, 0) 25%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 75%, rgba(1, 195, 236, 1) 100%);
                        background: -webkit-linear-gradient( left, rgba(1, 195, 236, 1) 0%, rgba(1, 195, 236, 0) 25%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 75%, rgba(1, 195, 236, 1) 100%);
                        background: linear-gradient( to right, rgba(1, 195, 236, 1) 0%, rgba(1, 195, 236, 0) 25%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 75%, rgba(1, 195, 236, 1) 100%);
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        width: 34px;
                        height: 34px;
                        background-color: rgba(255, 255, 255, 0.5);
                        right: 20px;
                        top: 50%;
                        @include border-radius(100%);
                        @include transform(translate(0,
                        -50%));
                        background-image: url(../images/ic-dwon-arrow.png);
                        background-repeat: no-repeat;
                        background-position: center center;
                    }
                }
                button.collapsed {
                    &:before {
                        @include transform(rotate(180deg) translate(0,
                        50%));
                    }
                }
            }
            .table-box {
                width: 100%;
                float: left;
                .card-body {
                    width: 100%;
                    float: left;
                    padding: 0;
                }
            }
        }
        .card.card2 {
            .card-header {
                button {
                    background: rgb(0, 255, 204);
                    background: -moz-linear-gradient(top, rgba(0, 255, 204, 1) 0%, rgba(0, 153, 122, 1) 100%);
                    background: -webkit-linear-gradient(top, rgba(0, 255, 204, 1) 0%, rgba(0, 153, 122, 1) 100%);
                    background: linear-gradient(to bottom, rgba(0, 255, 204, 1) 0%, rgba(0, 153, 122, 1) 100%);
                    &:after {
                        background: -moz-linear-gradient( top, rgba(0, 245, 197, 1) 0%, rgba(0, 245, 197, 0) 25%, rgba(0, 245, 197, 1) 50%, rgba(0, 245, 197, 0) 75%, rgba(0, 245, 197, 1) 100%);
                        background: -webkit-linear-gradient( top, rgba(0, 245, 197, 1) 0%, rgba(0, 245, 197, 0) 25%, rgba(0, 245, 197, 1) 50%, rgba(0, 245, 197, 0) 75%, rgba(0, 245, 197, 1) 100%);
                        background: linear-gradient( to bottom, rgba(0, 245, 197, 1) 0%, rgba(0, 245, 197, 0) 25%, rgba(0, 245, 197, 1) 50%, rgba(0, 245, 197, 0) 75%, rgba(0, 245, 197, 1) 100%);
                    }
                }
            }
        }
        .card.card3 {
            .card-header {
                button {
                    background: rgb(0, 255, 6);
                    background: -moz-linear-gradient(top, rgba(0, 255, 6, 1) 0%, rgba(18, 140, 12, 1) 100%);
                    background: -webkit-linear-gradient(top, rgba(0, 255, 6, 1) 0%, rgba(18, 140, 12, 1) 100%);
                    background: linear-gradient(to bottom, rgba(0, 255, 6, 1) 0%, rgba(18, 140, 12, 1) 100%);
                    &:after {
                        background: -moz-linear-gradient( top, rgba(1, 244, 6, 1) 0%, rgba(1, 244, 6, 0) 25%, rgba(1, 244, 6, 1) 50%, rgba(1, 244, 6, 0) 75%, rgba(1, 244, 6, 1) 100%);
                        background: -webkit-linear-gradient( top, rgba(1, 244, 6, 1) 0%, rgba(1, 244, 6, 0) 25%, rgba(1, 244, 6, 1) 50%, rgba(1, 244, 6, 0) 75%, rgba(1, 244, 6, 1) 100%);
                        background: linear-gradient( to bottom, rgba(1, 244, 6, 1) 0%, rgba(1, 244, 6, 0) 25%, rgba(1, 244, 6, 1) 50%, rgba(1, 244, 6, 0) 75%, rgba(1, 244, 6, 1) 100%);
                    }
                }
            }
        }
        .card.card4 {
            .card-header {
                button {
                    background: rgb(255, 144, 0);
                    background: -moz-linear-gradient(top, rgba(255, 144, 0, 1) 0%, rgba(165, 93, 0, 1) 100%);
                    background: -webkit-linear-gradient(top, rgba(255, 144, 0, 1) 0%, rgba(165, 93, 0, 1) 100%);
                    background: linear-gradient(to bottom, rgba(255, 144, 0, 1) 0%, rgba(165, 93, 0, 1) 100%);
                    &:after {
                        background: -moz-linear-gradient( top, rgba(255, 144, 0, 1) 0%, rgba(237, 195, 1, 0) 25%, rgba(255, 144, 0, 1) 50%, rgba(255, 144, 0, 0) 75%, rgba(255, 144, 0, 1) 100%);
                        background: -webkit-linear-gradient( top, rgba(255, 144, 0, 1) 0%, rgba(237, 195, 1, 0) 25%, rgba(255, 144, 0, 1) 50%, rgba(255, 144, 0, 0) 75%, rgba(255, 144, 0, 1) 100%);
                        background: linear-gradient( to bottom, rgba(255, 144, 0, 1) 0%, rgba(237, 195, 1, 0) 25%, rgba(255, 144, 0, 1) 50%, rgba(255, 144, 0, 0) 75%, rgba(255, 144, 0, 1) 100%);
                    }
                }
            }
        }
        .card.card5 {
            .card-header {
                button {
                    background: rgb(255, 210, 0);
                    background: -moz-linear-gradient(top, rgba(255, 210, 0, 1) 0%, rgba(148, 122, 0, 1) 100%);
                    background: -webkit-linear-gradient(top, rgba(255, 210, 0, 1) 0%, rgba(148, 122, 0, 1) 100%);
                    background: linear-gradient(to bottom, rgba(255, 210, 0, 1) 0%, rgba(148, 122, 0, 1) 100%);
                    &:after {
                        background: -moz-linear-gradient( top, rgba(237, 195, 1, 1) 0%, rgba(237, 195, 1, 0) 25%, rgba(237, 195, 1, 1) 50%, rgba(237, 195, 1, 0) 75%, rgba(237, 195, 1, 1) 100%);
                        background: -webkit-linear-gradient( top, rgba(237, 195, 1, 1) 0%, rgba(237, 195, 1, 0) 25%, rgba(237, 195, 1, 1) 50%, rgba(237, 195, 1, 0) 75%, rgba(237, 195, 1, 1) 100%);
                        background: linear-gradient( to bottom, rgba(237, 195, 1, 1) 0%, rgba(237, 195, 1, 0) 25%, rgba(237, 195, 1, 1) 50%, rgba(237, 195, 1, 0) 75%, rgba(237, 195, 1, 1) 100%);
                    }
                }
            }
        }
        .card.card6 {
            .card-header {
                button {
                    background: rgb(154, 31, 255);
                    background: -moz-linear-gradient(top, rgba(154, 31, 255, 1) 0%, rgba(97, 14, 166, 1) 100%);
                    background: -webkit-linear-gradient(top, rgba(154, 31, 255, 1) 0%, rgba(97, 14, 166, 1) 100%);
                    background: linear-gradient(to bottom, rgba(154, 31, 255, 1) 0%, rgba(97, 14, 166, 1) 100%);
                    &:after {
                        background: -moz-linear-gradient( top, rgba(147, 29, 243, 1) 0%, rgba(147, 29, 243, 0) 25%, rgba(147, 29, 243, 1) 50%, rgba(147, 29, 243, 0) 75%, rgba(147, 29, 243, 1) 100%);
                        background: -webkit-linear-gradient( top, rgba(147, 29, 243, 1) 0%, rgba(147, 29, 243, 0) 25%, rgba(147, 29, 243, 1) 50%, rgba(147, 29, 243, 0) 75%, rgba(147, 29, 243, 1) 100%);
                        background: linear-gradient( to bottom, rgba(147, 29, 243, 1) 0%, rgba(147, 29, 243, 0) 25%, rgba(147, 29, 243, 1) 50%, rgba(147, 29, 243, 0) 75%, rgba(147, 29, 243, 1) 100%);
                    }
                }
            }
        }
    }
}

.footer-wrap {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .left-wrap {
        width: 35%;
        float: left;
        text-align: left;
        height: 300px;
        overflow: hidden;
        padding-right: 6%;
    }
    .right-wrap {
        width: 65%;
        float: left;
        -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
        order: 10;
        .social-list-raw {
            display: block;
            li {
                display: inline-block;
                float: none;
                width: auto;
                margin: 30px 20px 0 0;
                a,
                a i {
                    color: #009cff;
                    @include transition(all 0.55s ease);
                    &:hover {
                        color: white;
                    }
                }
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}

.card2 {
    table {
        tr {
            th {
                background-color: #0f3d3b;
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(0, 245, 197, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(0, 245, 197, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(0, 245, 197, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 100%);
                    }
                }
            }
            th.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 50%, rgba(0, 245, 197, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 50%, rgba(0, 245, 197, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 50%, rgba(0, 245, 197, 0) 100%);
                }
            }
        }
        tr.active {
            td {
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(0, 245, 197, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(0, 245, 197, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(0, 245, 197, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 100%);
                    }
                }
                color: #00f9c6;
            }
            td.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 50%, rgba(0, 245, 197, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 50%, rgba(0, 245, 197, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(0, 245, 197, 1) 50%, rgba(0, 245, 197, 0) 100%);
                }
            }
        }
    }
}

.card3 {
    table {
        tr {
            th {
                background-color: #0a4a08;
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(1, 244, 6, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(1, 244, 6, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(1, 244, 6, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 100%);
                    }
                }
            }
            th.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 50%, rgba(1, 244, 6, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 50%, rgba(1, 244, 6, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 50%, rgba(1, 244, 6, 0) 100%);
                }
            }
        }
        tr.active {
            td {
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(1, 244, 6, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(1, 244, 6, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(1, 244, 6, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 100%);
                    }
                }
                color: #00ff06;
            }
            td.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 50%, rgba(1, 244, 6, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 50%, rgba(1, 244, 6, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(1, 244, 6, 1) 50%, rgba(1, 244, 6, 0) 100%);
                }
            }
        }
    }
}

.card4 {
    table {
        tr {
            th {
                background-color: #563100;
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(255, 144, 0, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(255, 144, 0, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(255, 144, 0, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 100%);
                    }
                }
            }
            th.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 50%, rgba(255, 144, 0, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 50%, rgba(255, 144, 0, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 50%, rgba(255, 144, 0, 0) 100%);
                }
            }
        }
        tr.active {
            td {
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(255, 144, 0, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(255, 144, 0, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(255, 144, 0, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 100%);
                    }
                }
                color: #ff9000;
            }
            td.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 50%, rgba(255, 144, 0, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 50%, rgba(255, 144, 0, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(255, 144, 0, 1) 50%, rgba(255, 144, 0, 0) 100%);
                }
            }
        }
    }
}

.card5 {
    table {
        tr {
            th {
                background-color: #5f4e00;
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(237, 195, 1, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(237, 195, 1, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(237, 195, 1, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 100%);
                    }
                }
            }
            th.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 50%, rgba(237, 195, 1, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 50%, rgba(237, 195, 1, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 50%, rgba(237, 195, 1, 0) 100%);
                }
            }
        }
        tr.active {
            td {
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(237, 195, 1, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(237, 195, 1, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(237, 195, 1, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 100%);
                    }
                }
                color: #ffd200;
            }
            td.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 50%, rgba(237, 195, 1, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 50%, rgba(237, 195, 1, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(237, 195, 1, 1) 50%, rgba(237, 195, 1, 0) 100%);
                }
            }
        }
    }
}

.card6 {
    table {
        tr {
            th {
                background-color: #2e074e;
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(147, 29, 243, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(147, 29, 243, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(147, 29, 243, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 100%);
                    }
                }
            }
            th.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 50%, rgba(147, 29, 243, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 50%, rgba(147, 29, 243, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 50%, rgba(147, 29, 243, 0) 100%);
                }
            }
        }
        tr.active {
            td {
                &:first-child {
                    &:after {
                        background: -moz-linear-gradient( left, rgba(147, 29, 243, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(147, 29, 243, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        background: linear-gradient( to right, rgba(147, 29, 243, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                    }
                }
                &:last-child {
                    &:after {
                        background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 100%);
                        background: -webkit-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 100%);
                        background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 100%);
                    }
                }
                color: #9a1fff;
            }
            td.blank {
                &:after {
                    background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 50%, rgba(147, 29, 243, 0) 100%);
                    background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 50%, rgba(147, 29, 243, 0) 100%);
                    background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(147, 29, 243, 1) 50%, rgba(147, 29, 243, 0) 100%);
                }
            }
        }
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    align-items: center;
    margin-right: 0px;
    cursor: pointer;
    opacity: 1;
    line-height: 120%;
    vertical-align: top;
    margin-top: 4px;
    margin-left: 6px;
    font-size: 13px;
    z-index: 2;
    &:hover {
        .tooltipText {
            // opacity: 1;
            display: block;
            @include transform(translate(50%,
            0) translateY(-8px));
        }
    }
}

.tooltipText {
    background-color: white;
    position: absolute;
    bottom: 130%;
    padding: 10px 15px;
    line-height: 115%;
    // opacity: 0;
    display: none;
    transition: all 0.5s;
    right: 50%;
    @include transform(translate(50%,
    0) translateY(0px));
    width: 310px;
    font-family: "D-DIN Condensed";
    @include border-radius(50px);
    font-weight: normal;
    color: #000000;
    font-size: 18px;
    text-align: center;
    b {
        font-family: "D-DIN Condensed";
        font-weight: bold;
    }
    &::after {
        content: "";
        border-width: 10px;
        border-style: solid;
        border-color: white transparent transparent transparent;
        position: absolute;
        bottom: -18px;
        left: 50%;
        margin-left: -10px;
    }
}

.footer-wrapper {
    // background: -moz-linear-gradient(left, rgba(125,185,232,0) 0%, rgba(0,156,255,0.1) 50%, rgba(125,185,232,0) 100%);
    // background: -webkit-linear-gradient(left, rgba(125,185,232,0) 0%,rgba(0,156,255,0.1) 50%,rgba(125,185,232,0) 100%);
    // background: linear-gradient(to right, rgba(125,185,232,0) 0%,rgba(0,156,255,0.1) 50%,rgba(125,185,232,0) 100%);
    background: transparent url(../images/footer-bg.png) center top no-repeat;
    position: relative;
    // &::before {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     bottom: 0;
    //     width: 100%;
    //     height: 50%;
    //     background: -moz-linear-gradient(top, rgba(51, 133, 204, 0) 0%, rgba(51, 133, 204, 1) 100%);
    //     background: -webkit-linear-gradient(top, rgba(51, 133, 204, 0) 0%, rgba(51, 133, 204, 1) 100%);
    //     background: linear-gradient(to bottom, rgba(51, 133, 204, 0) 0%, rgba(51, 133, 204, 1) 100%);
    //     opacity: 0.3;
    // }
    h4 {
        margin-bottom: 10px;
        text-transform: uppercase;
        font-family: "D-DIN Condensed";
        font-weight: bold;
    }
    ul {
        width: 100%;
        float: left;
        li {
            width: 100%;
            float: left;
            margin-top: 6px;
            a {
                font-size: 18px;
                color: white;
                line-height: 120%;
                font-family: "D-DIN Condensed";
                font-weight: normal;
                letter-spacing: 2px;
                &:hover {
                    color: #009cff;
                }
            }
        }
    }
}

.footer-box-wrap {
    padding-top: 90px;
    position: relative;
    // &:before {
    // 	content: '';
    // 	position: absolute;
    // 	width: calc(100% - 30px);
    // 	height: 41px;
    // 	top: -8px;
    // 	left: 15px;
    // 	background-image: url(../images/footer-border-img.png);
    // 	background-repeat: no-repeat;
    // 	background-size: contain;
    // }
}

.footer-logo {
    ul {
        width: 100%;
        float: left;
        padding: 30px 0;
        li {
            width: auto;
            width: 148px;
            height: 45px;
            float: left;
            margin-right: 25px;
            &:last-child {
                margin-right: 0px;
            }
            img{
                width: 100%;
                height: 45px;
            }
        }
    }
}

.copyright {
    background-color: #009cff;
    padding: 10px 0;
    p {
        font-family: "D-DIN";
        text-transform: uppercase;
        font-size: 14px;
        line-height: 120%;
        color: white;
        font-weight: normal;
    }
    a {
        font-family: "D-DIN";
        text-transform: uppercase;
        font-size: 14px;
        line-height: 120%;
        color: white;
        font-weight: normal;
        display: inline-block;
        &:hover {
            color: #000000;
        }
    }
}


/*---------------index start---------------*/

.main-banner {
    margin-top: 112px;
    .carousel.slide {
        .carousel-control-arrow {
            width: 6%;
            z-index: 11;
            span {
                width: 21px;
                height: 41px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
            }
            &.carousel-control-prev {
                span {
                    background-image: url(../images/arrow-left.png);
                }
            }
            &.carousel-control-next {
                span {
                    background-image: url(../images/arrow-right.png);
                }
            }
        }
        .carousel-indicators {
            margin: 0 auto;
            bottom: 30px;
            display: none;
            li {
                height: 6px;
                width: 6px;
                @include border-radius(100px);
                background: rgba(255, 255, 255, 0.5);
                border: 0px;
                @include transition(all 0.25s ease all);
                &.active {
                    background: rgba(255, 255, 255, 1);
                    height: 8px;
                    width: 8px;
                    margin-top: -1px;
                }
            }
        }
        .carousel-inner {
            .banner-con {
                position: absolute;
                left: 50%;
                top: 86%;
                text-align: center !important;
                @include transform(translate(-50%,
                -50%));
                z-index: 1;
            }
        }
    }
}

.carousel-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-object-fit: cover;
}

$border: 5px;
.step-section-raw {
    margin-top: 80px;
    ul {
        text-align: center;
        width: auto;
        float: none;
        display: inline-block;
        background: rgba(15, 34, 61, 0.98);
        padding: 50px;
        border: 3px solid transparent;
        position: relative;
        margin: 5px;
        @include border-radius(10px);
        &::before {
            position: absolute;
            top: 1px;
            right: 0;
            bottom: 0;
            left: 1px;
            height: 105%;
            width: 100.55%;
            content: "";
            background: rgb(0, 216, 255);
            background: -moz-linear-gradient( left, rgba(0, 216, 255, 1) 0%, rgba(8, 14, 33, 1) 25%, rgba(0, 212, 255, 1) 50%, rgba(8, 14, 33, 1) 75%, rgba(0, 212, 255, 1) 100%);
            background: -webkit-linear-gradient( left, rgba(0, 216, 255, 1) 0%, rgba(8, 14, 33, 1) 25%, rgba(0, 212, 255, 1) 50%, rgba(8, 14, 33, 1) 75%, rgba(0, 212, 255, 1) 100%);
            background: linear-gradient( to right, rgba(0, 216, 255, 1) 0%, rgba(8, 14, 33, 1) 25%, rgba(0, 212, 255, 1) 50%, rgba(8, 14, 33, 1) 75%, rgba(0, 212, 255, 1) 100%);
            z-index: -1;
            margin: -$border;
            @include border-radius(10px);
        }
        li {
            display: inline-block;
            vertical-align: middle;
            width: 30%;
            background: transparent url(../images/arrow-next.png) center right no-repeat;
            &:last-child {
                background-image: none;
            }
            .step-col {
                @extend .section;
                >span {
                    font-family: "proximanova";
                    font-size: 80px;
                    background: -moz-linear-gradient(top, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
                    background: -webkit-linear-gradient(top, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
                    background: linear-gradient(to bottom, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
                    display: inline;
                    vertical-align: middle;
                    padding-right: 18px;
                }
                .img-fluid {
                    display: inline-block;
                    vertical-align: middle;
                }
                .step-conn {
                    display: inline-block;
                    vertical-align: middle;
                    text-align: left;
                    width: 50%;
                    padding-left: 18px;
                    .h6 {
                        font-size: 18px;
                        color: white;
                        line-height: 120%;
                        font-weight: 700;
                        //font-family: "proximanova";
                        font-family: "Montserrat", sans-serif;
                    }
                    p {
                        width: auto;
                        float: left;
                        font-size: 14px;
                        font-weight: 400;
                        color: white;
                        line-height: 120%;
                        margin-top: 5px;
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

.ongoing-events-raw {
    background: #060919 url(../images/homepage-bg.jpg) center top no-repeat;
    padding-bottom: 210px;
}

.title-raw {
    @extend .section;
    text-align: center;
    h1,
    h2,
    h3 {
        position: relative;
        font-size: 50px;
        text-transform: uppercase;
        letter-spacing: 0.6px;
        font-family: "Montserrat", sans-serif;
        font-weight: 800;
        background: -webkit-linear-gradient(#00d3ff, #3385cc);
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        span {
            position: absolute;
            left: 50%;
            top: 50%;
            @include transform(translate(-50%,
            -50%));
            width: 110%;
            text-align: center;
            margin: 0 auto;
            text-transform: uppercase;
            font-size: 120px;
            font-weight: 900;
            font-family: "Montserrat", sans-serif;
            color: transparent;
            -webkit-text-fill-color: transparent;
            -webkit-text-stroke-width: 1px;
            -webkit-text-stroke-color: #50aeff;
            opacity: 0.10;
        }
    }
    h3 {
        font-size: 34px;
        span {
            font-size: 56px;
        }
    }
}

.ongoing-competition-raw {
    padding: 180px 0;
}

@-webkit-keyframes blink {
    from {
        text-shadow: 0px 0px 14px #00d4ff;
    }
    to {
        text-shadow: none;
    }
}

@-moz-keyframes blink {
    from {
        text-shadow: 0px 0px 14px #00d4ff;
    }
    to {
        text-shadow: none;
    }
}

@keyframes blink {
    from {
        text-shadow: 0px 0px 14px #00d4ff;
    }
    to {
        text-shadow: none;
    }
}

.game-lists-raw {
    .competition-tab-raw {
        margin-top: 70px;
        .nav-tabs {
            text-align: center;
            display: block;
            border-bottom: 2px solid #265898c9;
            li {
                display: inline-block;
                vertical-align: middle;
                .nav-link {
                    background: transparent;
                    color: white;
                    font-size: 24px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    border: 0px;
                    padding: 20px 0;
                    position: relative;
                    @include transition(all 0.25s ease 0s);
                    margin: 0 20px;
                    &::before {
                        position: absolute;
                        content: "";
                        left: 0;
                        bottom: -1px;
                        background: white;
                        width: 100%;
                        height: 2px;
                        @include box-shadow(0 0 10px rgb(255,
                        255,
                        255));
                        @include transition(all 0.25s ease 0s);
                        opacity: 0;
                    }
                    &.active,
                    &:hover {
                        &::before {
                            opacity: 1;
                        }
                    }
                    &.live-tab {
                        background: -moz-linear-gradient(top, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
                        background: -webkit-linear-gradient(top, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
                        background: linear-gradient(to bottom, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        -moz-background-clip: text;
                        -moz-text-fill-color: transparent;
                        -webkit-animation: blink linear 1s infinite;
                        -moz-animation: blink linear 1s infinite;
                        animation: blink linear 1s infinite;
                        position: relative;
                        &::after {
                            position: absolute;
                            right: -5px;
                            top: 14px;
                            content: "";
                            width: 4px;
                            height: 4px;
                            @include border-radius(100px);
                            background: #ed1f24;
                        }
                    }
                }
            }
        }
    }
    .gm-li-raw {
        margin-top: 40px;
        padding: 0 60px;
        z-index: 11;
        .row {
            margin-right: -20px;
            margin-left: -20px;
            .gm-li {
                margin-top: 40px;
                .gm-col {
                    background: #060919;
                    box-shadow: 0 0 10px rgba(80, 174, 255, 0.3), inset 0 0 10px rgba(80, 174, 255, 0.3);
                    -webkit-box-shadow: 0 0 10px rgba(80, 174, 255, 0.3), inset 0 0 10px rgba(80, 174, 255, 0.3);
                    border: 1px solid rgba(51, 133, 204, 0.6);
                    @include border-radius(10px);
                    @extend .section;
                    overflow: hidden;
                    .img-box {
                        height: 190px;
                        overflow: hidden;
                        @extend .section;
                        img {
                            width: 100%;
                            @include transition(transform 0.5s ease);
                            @include transform(scale(1));
                        }
                    }
                    &:hover {
                        .img-box {
                            img {
                                @include transform(scale(1.1));
                            }
                        }
                    }
                    .about-game-raw {
                        padding: 25px 25px 20px;
                        @extend .section;
                        overflow: hidden;
                        position: relative;
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%;
                            background: -moz-linear-gradient( 45deg, rgba(51, 133, 204, 1) 0%, rgba(125, 185, 232, 0) 50%);
                            background: -webkit-linear-gradient( 45deg, rgba(51, 133, 204, 1) 0%, rgba(125, 185, 232, 0) 50%);
                            background: linear-gradient(45deg, rgba(51, 133, 204, 1) 0%, rgba(125, 185, 232, 0) 50%);
                            opacity: 0.4;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            left: 10%;
                            top: -15px;
                            width: 80%;
                            height: 20%;
                            background: -moz-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(125, 185, 232, 0) 70%);
                            background: -webkit-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(125, 185, 232, 0) 70%);
                            background: radial-gradient( ellipse at center, rgba(51, 133, 204, 1) 0%, rgba(125, 185, 232, 0) 70%);
                            opacity: 0.4;
                        }
                        .about-game-conn {
                            @extend .section;
                            position: relative;
                            .left-conn {
                                float: left;
                                width: 70%;
                                h5 {
                                    font-size: 20px;
                                    font-family: "D-DIN Condensed";
                                    font-weight: bold;
                                    color: #009cff;
                                }
                                h6 {
                                    //font-family: 'D-DIN Condensed';
                                    font-weight: 700;
                                    color: white;
                                    font-size: 12px;
                                    line-height: 120%;
                                    background: transparent url(../images/ic-play.png) left top 2px no-repeat;
                                    padding-left: 15px;
                                    margin-top: 6px;
                                }
                            }
                            .right-conn {
                                float: left;
                                width: 30%;
                                h3 {
                                    text-align: center;
                                    float: right;
                                    font-size: 34px;
                                    color: white;
                                    font-family: "D-DIN Condensed";
                                    font-weight: bold;
                                    line-height: unset;
                                    margin-top: 10px;
                                    position: relative;
                                    text-shadow: 0px 0px 30px #00d4ff;
                                    -webkit-text-shadow: 0px 0px 30px #00d4ff;
                                    span {
                                        font-size: 14px;
                                        text-transform: uppercase;
                                    }
                                }
                            }
                        }
                        .plateform-players-col {
                            border-top: 1px solid rgba(51, 233, 204, 0.1);
                            padding-top: 15px;
                            margin-top: 15px;
                            @extend .section;
                            // position: relative;
                            li {
                                display: inline-block;
                                vertical-align: middle;
                                span {
                                    font-family: "Montserrat", sans-serif;
                                    font-size: 10px;
                                    font-weight: 700;
                                    color: #009cff;
                                    text-transform: capitalize;
                                }
                                h6 {
                                    font-family: "Montserrat", sans-serif;
                                    font-size: 12px;
                                    // font-weight: 700;
                                    font-weight: 500;
                                    color: white;
                                    letter-spacing: 0.2px;
                                    &.sts-accepted {
                                        color: #03ff03;
                                    }
                                    &.sts-pending {
                                        color: #f6ff00;
                                    }
                                }
                                /* &:first-child,
                                &:nth-child(2) {
                                    width: 24%;
                                } */
                                /* &:last-child {
                                    text-align: right;
                                    width: 48%;
                                    position: absolute;
                                } */
                                .btn-join {
                                    position: sticky;
                                    padding: 8px 34px 8px 14px;
                                    font-size: 12px;
                                    &.btn-cancel {
                                        padding: 8px 14px;
                                        background-image: none;
                                    }
                                }
                            }
                        }
                    }
                }
                padding-right: 20px;
                padding-left: 20px;
            }
        }
    }
}

.game-listing-col .game-listing {
    display: none;
}

.btn-join,
.btn-ic {
    background: #07609f url(../images/ic-double-arrow.png) right 13px center no-repeat;
    border: 1px solid #50aeff;
    font-size: 14px;
    font-family: 'D-DIN';
    font-weight: bold;
    text-shadow: 0px 0px 12px white;
    -webkit-text-shadow: 0px 0px 12px white;
    padding: 8px 38px 8px 18px;
    color: white;
    display: inline-block;
    @include border-radius(20px);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    @include transition(all 0.25s ease 0s);
    &:hover {
        background-position: right 10px center;
        color: white;
    }
}

.btn-ic {
    font-family: 'D-DIN';
    font-weight: bold;
}

.trending-videos-raw {
    padding: 0 6%;
    .gm-li-raw {
        .row .gm-li .gm-col {
            .img-box {
                height: 160px;
                .btn-play {
                    position: relative;
                    float: left;
                    width: 100%;
                    img {
                        @include transition(all 0.45s ease 0s);
                    }
                    &::before {
                        background: transparent url(../images/play-button.png) center center no-repeat;
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        opacity: 0;
                        @include transition(all 0.45s ease 0s);
                    }
                    &:hover {
                        &::before {
                            opacity: 1;
                        }
                        img {
                            opacity: 0.1;
                        }
                    }
                }
            }
            .about-game-raw {
                .about-game-conn .left-conn {
                    width: 100% !important;
                    h6 {
                        background: none;
                        padding-left: 0px !important;
                    }
                }
                .view-raw {
                    border-top: 1px solid rgba(51, 233, 204, 0.1);
                    padding-top: 15px;
                    margin-top: 15px;
                    @extend .section;
                    position: relative;
                    // .btn-view {}
                }
            }
        }
    }
}

.btn-view {
    font-size: 14px;
    color: white;
    line-height: 120%;
    display: inline-block;
    font-family: "D-DIN";
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.btn-sm {
    border-color: #50aeff;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 15px 40px;
    height: auto;
    line-height: inherit;
    font-size: 16px;
    min-width: auto;
    letter-spacing: 1px;
}

.mt500 {
    margin-top: 500px !important;
}

.latest-news-box-raw {
    &.latest-news-raw {
        .latest-news-col {
            width: calc(100% - 24%);
            margin: 0 auto;
            float: none;
            padding: 0 4%;
        }
    }
}

.latest-news-raw {
    .latest-news-col {
        margin-top: 20px;
        padding: 0 16%;
        .latest-news-li {
            margin-top: 30px;
            .latest-news-box {
                background: rgba(38, 88, 182, 0.1);
                @include border-radius(10px);
                border: 2px solid rgba(38, 88, 182, 0.5);
                padding: 30px 45px 30px 25px;
                .img-box {
                    float: left;
                    width: 25%;
                    text-align: center;
                    img {
                        display: inline-block;
                        border: 2px solid #3385cc;
                        width: 128px;
                    }
                }
                .news-box {
                    float: left;
                    width: 75%;
                    padding-left: 20px;
                    h4 {
                        background: -moz-linear-gradient(top, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
                        background: -webkit-linear-gradient(top, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
                        background: linear-gradient(to bottom, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        -moz-background-clip: text;
                        -moz-text-fill-color: transparent;
                        //font-family: 'D-DIN Condensed';
                        letter-spacing: 0.2px;
                        font-family: 'D-DIN Condensed';
                        font-weight: normal;
                        font-size: 24px;
                        text-transform: uppercase;
                    }
                    h5 {
                        font-size: 16px;
                        letter-spacing: 1px;
                        font-family: 'D-DIN Condensed';
                        font-weight: normal;
                        sup {
                            text-transform: uppercase;
                            font-size: 10px;
                        }
                    }
                    p {
                        font-size: 12px;
                        color: rgba(255, 255, 255, 0.5);
                        font-family: "Montserrat", sans-serif;
                        font-weight: 500;
                        margin-top: 25px;
                        line-height: 140%;
                        letter-spacing: 0.5px;
                        a {
                            color: rgba(255, 255, 255, 1);
                            font-family: "Montserrat", sans-serif;
                            font-weight: 600;
                            text-transform: uppercase;
                            margin-left: 6px;
                            &:hover {
                                color: #00c0ff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modal-raw {
    
    .social-list-raw {
        padding-top: 60px;
        li {
            margin: 0 6px;
            a {
                width: 36px;
                height: 36px;
                display: inline-block;
                text-align: center;
                line-height: 36px;
                @include border-radius(100px);
                background: #009cff;
            }
        }
    }
    // &.trending-modal {}
    .modal-content {
        border: 2px solid #3385cc;
        // padding: 5px;
        background: transparent;
        @include border-radius(18px);
        position: relative;
        .btn-close-modal {
            position: absolute;
            right: 20px;
            top: 20px;
            background: transparent url(../images/ic-close-modal.png) center center no-repeat;
            width: 20px;
            height: 20px;
            z-index: 1;
            font-size: 0;
            &:focus {
                outline: none;
            }
        }
        .modal-body {
            @include border-radius(15px);
            overflow: hidden;
            padding: 30px 40px;
            // background: #090e26;
            // overflow: hidden;
            // background: -moz-linear-gradient(top, rgba(18,30,88,1) 24%, rgba(11,18,51,1) 99%);
            // background: -webkit-linear-gradient(top, rgba(18,30,88,1) 24%,rgba(11,18,51,1) 99%);
            // background: linear-gradient(to bottom, rgba(18,30,88,1) 24%,rgba(11,18,51,1) 99%);
            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#121e58+24,0b1233+100 */
            background: #121e58;
            background: -moz-linear-gradient(top, #121e58 24%, #0b1233 100%);
            background: -webkit-linear-gradient(top, #121e58 24%, #0b1233 100%);
            background: linear-gradient(to bottom, #121e58 24%, #0b1233 100%);
            position: relative;
            >div {
                position: relative;
                z-index: 0;
            }
            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: -15%;
                width: 100%;
                height: 100%;
                background: -moz-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
                background: -webkit-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
                background: radial-gradient(ellipse at center, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
                @include transform(translate(-50%,
                -50%));
                opacity: 0.55;
            }
            .title-raw {
                padding: 30px 0;
                position: relative;
                h3 {
                    background: -webkit-linear-gradient(#00d3ff, #3385cc);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
                    font-weight: 800;
                    span {
                        font-weight: 700;
                    }
                }
                h2 {
                    span {
                        font-size: 90px;
                    }
                }
            }
            .field-name {
                color: white;
                font-size: 14px;
                font-weight: 400;
                font-family: "Montserrat", sans-serif;
                // padding-left: 14px;
            }
            .form-group {
                margin-bottom: 25px;
            }
        }
    }
    iframe {
        width: 100%;
        height: 100%;
        float: left;
        min-height: 260px;
        @include border-radius(10px);
    }
    .form-control {
        border: 1px solid #065f9f;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 700;
        font-family: "Montserrat", sans-serif;
        background: transparent;
        height: 54px;
        /* Chrome, Safari, Edge, Opera */
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        /* Firefox */
        &[type="number"] {
            appearance: textfield;
            -moz-appearance: textfield;
        }
        &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
        }
        &::-moz-placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
        }
        &:-ms-input-placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
        }
        &:-moz-placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
        }
        &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
            color: rgba(255, 255, 255, 1);
        }
    }
    &.rules-modal {
        .modal-lg {
            max-width: 1700px;
            .modal-content {
                border: 0px;
                @include border-radius(15px);
                .btn-close-modal {
                    @include transition(all 0.25s ease 0s);
                    background: rgba(255, 255, 255, 0.05) url(../images/ic-close-blue.png) center center no-repeat;
                    width: 45px;
                    height: 45px;
                    @include border-radius(100px);
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.2);
                    }
                }
                .modal-body {
                    background: #06091a;
                    padding: 10px 8% 80px;
                    &::before {
                        display: none;
                    }
                    .title-raw {
                        margin-bottom: 30px;
                        position: relative;
                        background: transparent url(../images/modal-title-shadow.png) bottom center no-repeat;
                        padding-bottom: 70px;
                    }
                    h4 {
                        font-size: 16px;
                        text-transform: uppercase;
                        color: #009cff;
                        font-family: "D-DIN";
                        font-weight: bold;
                        letter-spacing: normal;
                        padding: 10px 15px;
                        border-left: 3px solid white;
                        background: -moz-linear-gradient(left, rgba(0, 156, 255, 0.2) 0%, rgba(125, 185, 232, 0) 100%);
                        background: -webkit-linear-gradient( left, rgba(0, 156, 255, 0.2) 0%, rgba(125, 185, 232, 0) 100%);
                        background: linear-gradient(to right, rgba(0, 156, 255, 0.2) 0%, rgba(125, 185, 232, 0) 100%);
                        margin: 20px 0 10px;
                    }
                    h5 {
                        font-size: 16px;
                        font-family: "D-DIN";
                        font-weight: normal;
                        color: white;
                        padding: 15px 20px;
                    }
                    ul {
                        padding: 10px 0;
                        li {
                            background: transparent url(../images/list-arrow.png) left top 6px no-repeat;
                            font-family: "D-DIN Condensed";
                            font-weight: normal;
                            padding: 0px 20px;
                            font-size: 16px;
                            color: rgba(255, 255, 255, 0.6);
                            line-height: 140%;
                            margin: 10px 0;
                            strong {
                                font-family: "D-DIN Condensed";
                                font-weight: bold;
                            }
                            &:first-child {
                                margin-top: 0px;
                            }
                            &:last-child {
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
    .mCustomScrollbar {
        max-height: 475px;
    }
}

.floating-label-group {
    position: relative;
    margin-top: 15px;
    margin-bottom: 25px;
    .floating-label {
        color: #cccccc;
        position: absolute;
        pointer-events: none;
        top: 20px;
        left: 20px;
        @include transition(all 0.25s ease);
        font-size: 14px;
        font-weight: 400;
        font-family: "Montserrat", sans-serif;
    }
    input:focus~.floating-label,
    input:not(:focus):valid~.floating-label {
        top: -25px;
        bottom: 0px;
        left: 0px;
        color: white;
    }
}

.dropdown-toggle {
    cursor: pointer;
    &::after {
        display: none;
    }
}

.notification-tab {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        right: -2px;
        top: 5px;
        width: 8px;
        height: 8px;
        background: #00ff00;
        @include border-radius(100px);
    }
}

.header {
    .notification {
        .dropdown-menu-noti {
            display: none;
            width: auto;
            float: left;
            left: inherit;
            right: 62px;
            max-width: 500px;
            top: 100px;
            background: #002f4e;
            background: -moz-linear-gradient(top, #002f4e 0%, #002137 100%);
            background: -webkit-linear-gradient(top, #002f4e 0%, #002137 100%);
            background: linear-gradient(to bottom, #002f4e 0%, #002137 100%);
            @include border-radius(15px);
            @include box-shadow(0 0 15px rgba(0,
            0,
            0,
            0.8));
            position: absolute;
            padding: 20px;
            .top-section {
                position: relative;
                padding-bottom: 20px;
                border-bottom: 2px solid #005070;
                &::before {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 15px 15px 15px;
                    border-color: transparent transparent #022b46 transparent;
                    content: "";
                    right: 15px;
                    top: -35px;
                    position: absolute;
                    @include transition(all 0.45s ease 0s);
                }
                h5 {
                    width: auto;
                    float: left;
                    font-size: 16px;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 600;
                    letter-spacing: 0.4px;
                }
                a {
                    float: right;
                    font-size: 12px;
                    letter-spacing: 1px;
                    color: #d1d5d9;
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    border-bottom: 1px solid #d1d5d9;
                    padding-bottom: 1px;
                    &:hover {
                        color: #00d3ff;
                        border-color: #00d3ff;
                    }
                }
            }
            .bottom-section {
                padding-top: 20px;
                border-top: 2px solid #005070;
                text-align: center;
                a {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    float: none;
                    color: white;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    &:hover {
                        color: #00d3ff;
                    }
                }
            }
            .notification-list {
                li {
                    @extend .section;
                    border-top: 1px solid #00324e;
                    &:first-child {
                        border-top: 0px;
                    }
                    >h6 {
                        margin-top: 20px;
                    }
                }
                h6 {
                    text-align: left;
                    color: #00d8ff;
                    font-size: 12px;
                    margin-top: 20px;
                    margin: 0px;
                }
                .table-list-notifies {
                    margin: 0px;
                    td {
                        border: 0px;
                        padding: 12px 0px;
                    }
                    .date-time-col {
                        padding: 0;
                        width: 20%;
                    }
                    .con-col .conn {
                        .img-fluid {
                            width: 32px;
                            height: 32px;
                            border: 0px;
                        }
                        h6 {
                            font-size: 16px;
                            color: white;
                            font-family: 'D-DIN Condensed';
                            font-weight: bold;
                            text-transform: initial;
                            span {
                                font-family: 'D-DIN Condensed';
                                font-weight: normal;
                                color: rgba(255, 255, 255, 0.5);
                                letter-spacing: 0.8px;
                            }
                        }
                    }
                }
            }
        }
    }
    .dropdown-list {
        top: 62px;
        left: inherit;
        right: -30px;
        min-width: 250px;
        padding: 0px;
        margin: 0px;
        background: #060919;
        border: 0px;
        li {
            position: relative;
            @extend .section;
            &:first-child {
                &::before {
                    height: 1px;
                    width: 100%;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: -moz-linear-gradient(left, rgba(0, 216, 255, 0.10) 0%, rgba(0, 216, 255, 0) 100%);
                    background: -webkit-linear-gradient(left, rgba(0, 216, 255, 0.10) 0%, rgba(0, 216, 255, 0) 100%);
                    background: linear-gradient(to right, rgba(0, 216, 255, 0.10) 0%, rgba(0, 216, 255, 0) 100%);
                }
            }
            &::after {
                height: 1px;
                width: 100%;
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                background: -moz-linear-gradient(left, rgba(0, 216, 255, 0.10) 0%, rgba(0, 216, 255, 0) 100%);
                background: -webkit-linear-gradient(left, rgba(0, 216, 255, 0.10) 0%, rgba(0, 216, 255, 0) 100%);
                background: linear-gradient(to right, rgba(0, 216, 255, 0.10) 0%, rgba(0, 216, 255, 0) 100%);
            }
            a {
                padding: 15px 20px;
                @extend .section;
                font-size: 16px;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                color: white;
                border-left: 2px solid rgba(255, 255, 255, 0);
                position: relative;
                &.active,
                &:hover {
                    border-left: 2px solid rgba(255, 255, 255, 1);
                    text-shadow: 0 0 15px rgba(80, 174, 255, 1);
                    -webkit-text-shadow: 0 0 15px rgba(80, 174, 255, 1);
                    &::after {
                        height: 100%;
                        width: 100%;
                        content: "";
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        background: -moz-linear-gradient(left, rgba(0, 216, 255, 0.20) 0%, rgba(0, 216, 255, 0) 100%);
                        background: -webkit-linear-gradient(left, rgba(0, 216, 255, 0.20) 0%, rgba(0, 216, 255, 0) 100%);
                        background: linear-gradient(to right, rgba(0, 216, 255, 0.20) 0%, rgba(0, 216, 255, 0) 100%);
                        z-index: -1;
                    }
                }
            }
        }
    }
}

.affix {
    .header {
        .logo {
            padding: 0 20px;
        }
        .header-right .nav-main ul {
            margin-top: 0px;
        }
        .notification {
            padding: 0;
            margin-top: -9px;
            .dropdown-menu-noti {
                top: 85px;
            }
        }
        .dropdown-list {
            top: 56px;
        }
        .setting-head {
            top: -4px;
        }
        .login-search-ic{
            top: -10px;
        }
        .login-raw{
            @include transition(all 0.25s ease 0s);
        }
    }
}


/*----------------index end----------------*/


/*----------------Game start------------------*/

.inner-banner {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    height: 650px;
    position: relative;
    &::before {
        height: 20%;
        width: 100%;
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        background: -moz-linear-gradient(top, rgba(6, 9, 25, 0) 0%, rgba(6, 9, 25, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(6, 9, 25, 0) 0%, rgba(6, 9, 25, 1) 100%);
        background: linear-gradient(to bottom, rgba(6, 9, 25, 0) 0%, rgba(6, 9, 25, 1) 100%);
    }
    .container-fluid {
        height: 100%;
        position: relative;
        .back-btn {
            position: absolute;
            left: 5%;
            top: 10%;
            color: rgba(255, 255, 255, 0.6);
            text-transform: uppercase;
            font-size: 22px;
            font-family: "D-DIN";
            font-weight: normal;
            background: transparent url(../images/ic-back.png) left 5px center no-repeat;
            @include transform(translate(0%,
            -50%));
            @include transition(all 0.25s ease 0s);
            padding: 14px 20px 14px 60px;
            z-index: 111;
            &:hover {
                background-position: left 0 center;
            }
        }
        .inner-banner-con {
            position: absolute;
            left: 50%;
            top: 50%;
            @include transform(translate(-50%,
            -50%));
            width: 100%;
            text-align: center;
            .title-raw {
                h6 {
                    font-size: 18px;
                    letter-spacing: 2px;
                    opacity: 0.8;
                    text-transform: uppercase;
                    vertical-align: middle;
                    display: inline-block;
                    float: none;
                    font-family: 'D-DIN Condensed';
                    font-weight: normal;
                    margin-top: 10px;
                    span {
                        background: transparent url(../images/ic-double-arrow-light.png) center center no-repeat;
                        height: 14px;
                        width: 20px;
                        display: inline-block;
                        vertical-align: middle;
                        margin-top: -8px;
                    }
                }
            }
            .game-rules-col {
                width: auto;
                display: inline-block;
                float: none;
                margin-top: 15px;
                li {
                    display: inline-block;
                    vertical-align: middle;
                    .hexagon-col {
                        width: 128px;
                        height: 146px;
                        background: transparent url(../images/hexagon-bg.png) center center no-repeat;
                        background-size: contain;
                        margin-bottom: 15px;
                        display: inline-block;
                        vertical-align: middle;
                        position: relative;
                    }
                    h5 {
                        font-size: 18px;
                        color: white;
                        text-transform: uppercase;
                        letter-spacing: 3px;
                        //font-family: 'D-DIN';
                        font-family: "D-DIN Condensed";
                        font-weight: bold;
                        opacity: 0.8;
                    }
                    h4 {
                        font-size: 50px;
                        font-family: "D-DIN Condensed";
                        font-weight: normal;
                        text-shadow: 0px 0px 50px #50aeff;
                        -webkit-text-shadow: 0px 0px 50px #50aeff;
                        width: auto;
                        float: none;
                        display: inline-block;
                        color: white;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        @include transform(translate(-50%,
                        -50%));
                        text-shadow: 0 0 15px #50aeff;
                        -webkit-text-shadow: 0 0 15px #50aeff;
                    }
                    &.like-col {
                        h4 {
                            border-bottom: 2px solid #03bde5;
                            padding-bottom: 5px;
                        }
                    }
                    &.profile-col {
                        a {
                            width: 100%;
                            height: 100%;
                            float: left;
                        }
                        // h4 {}
                        span {
                            float: left;
                            width: 100%;
                            text-shadow: none;
                            text-transform: uppercase;
                            //background: -webkit-linear-gradient(#00d3ff, #3385cc);
                            background: -webkit-linear-gradient(#00d8ff, #009cff);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            -moz-background-clip: text;
                            -moz-text-fill-color: transparent;
                            line-height: 120%;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

.horizontal-tab-raw {
    padding: 0 20%;
    text-align: center;
    margin-bottom: 10px;
    .divide-raw {
        position: relative;
        &::before {
            position: absolute;
            left: 0;
            top: -35px;
            content: "";
            height: 2px;
            width: 100%;
            background: rgb(0, 156, 255);
            background: -moz-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
            background: -webkit-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
            background: linear-gradient(to right, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
        }
        .nav-tabs-dropdown {
            display: block !important;
            margin: 0 auto 5px;
            font-size: 26px;
            line-height: 120%;
            padding: 20px 0;
            position: relative;
            color: white;
            text-transform: uppercase;
            letter-spacing: 2px;
            &::before {
                position: absolute;
                content: "";
                left: 50%;
                bottom: -3px;
                background: white;
                width: 15%;
                height: 2px;
                @include box-shadow(0 0 10px rgb(255,
                255,
                255));
                @include transition(all 0.25s ease 0s);
                @include transform(translate(-50%,
                0));
                z-index: 1;
            }
            &::after {
                display: inline-block;
                background: transparent url(../images/scroll-down.png) center center no-repeat;
                margin-left: 10px;
                content: "";
                width: 16px;
                height: 8px;
                position: relative;
                top: -3px;
            }
        }
        #nav-tabs-wrapper {
            display: none;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            text-align: center;
        }
        .nav-tabs-horizontal {
            background: rgb(18, 30, 88);
            background: -moz-linear-gradient(top, rgba(18, 30, 88, 1) 24%, rgba(11, 18, 51, 1) 99%);
            background: -webkit-linear-gradient(top, rgba(18, 30, 88, 1) 24%, rgba(11, 18, 51, 1) 99%);
            background: linear-gradient(to bottom, rgba(18, 30, 88, 1) 24%, rgba(11, 18, 51, 1) 99%);
            border: 0px;
            overflow: hidden;
            border-radius: 4px !important;
            -webkit-border-radius: 4px !important;
            -moz-border-radius: 4px !important;
            margin-top: -35px;
        }
        .nav-tabs-horizontal>li {
            float: none;
            border-radius: 0px;
        }
        .nav-tabs-horizontal>li,
        .nav-tabs-horizontal>li>a {
            background: transparent;
            width: 100%;
        }
        .nav-tabs-horizontal>li>a {
            border: 0px;
            padding: 15px 20px;
            font-size: 20px;
            text-transform: uppercase;
            color: white;
            border-radius: 0px;
            &.active {
                color: white;
                background: rgb(0, 156, 255);
                background: -moz-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
                background: -webkit-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
                background: linear-gradient(to right, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
            }
        }
        // .nav-tabs-horizontal  > li.active > a,
        // .nav-tabs-horizontal  > li.active > a:hover,
        // .nav-tabs-horizontal  > li.active > a:focus {
        // 	color: whitefff;
        // 	background-color: #428bca;
        // }
    }
}

.game-platform-raw {
    .horizontal-tab-raw {
        padding: 0 20%;
        text-align: center;
        margin-bottom: 10px;
        .divide-raw {
            position: relative;
            &::before {
                position: absolute;
                left: 0px;
                top: 0px;
                content: "";
                height: 2px;
                width: 100%;
                background: rgb(0, 156, 255);
                background: -moz-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
                background: -webkit-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
                background: linear-gradient(to right, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
            }
        }
    }
}

.common-page-raw {
  
    >.container {
        position: relative;
        z-index: 11;
    }
    background: #060919 url(../images/page-background.jpg) center top no-repeat;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        left: -30%;
        top: -40%;
        width: 1000px;
        height: 1000px;
        background: transparent url(../images/shadow.png) center center no-repeat;
        background-size: contain;
        z-index: 1;
    }
    &:after {
        content: "";
        position: absolute;
        right: -30%;
        top: -40%;
        width: 1000px;
        height: 1000px;
        background: transparent url(../images/shadow.png) center center no-repeat;
        background-size: contain;
        z-index: 1;
    }
}

.igl-tv-raw {
    &.common-page-raw {
        
        &:before,
        &:after {
            top: -30%;
        }
    }
}

.divide-raw {
    position: relative;
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        height: 2px;
        width: 100%;
        background: rgb(0, 156, 255);
        background: -moz-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
        background: -webkit-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
        background: linear-gradient(to right, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
    }
    &::after {
        position: absolute;
        left: 0;
        top: 72px;
        content: "";
        height: 2px;
        width: 100%;
        background: rgb(0, 156, 255);
        background: -moz-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
        background: -webkit-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
        background: linear-gradient(to right, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
    }
}

.all-games-raw {
    padding: 100px 0;
    position: relative;
    &.game-lists-raw {
        .game-platform-raw {
            .gm-li-raw .row .gm-li .gm-col .about-game-raw {
                .about-game-conn .left-conn {
                    width: 100%;
                    h5 {
                        text-align: center;
                        font-size: 28px;
                    }
                }
                .plateform-players-col li:last-child {
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }
}

.record-list-raw {
    .line-arrow {
        width: calc(100% - 16%);
        margin: 0 auto;
        float: none;
    }
    .record-list-col {
        //position: absolute;
        // left: 50%;
        // top: 50%;
        // @include transform(translate(-50%, -50%));
        width: 100%;
        text-align: center;
        padding: 0 13%;
        z-index: 1;
        ul {
            background: rgb(0, 216, 255);
            background: -moz-linear-gradient(top, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
            background: -webkit-linear-gradient(top, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
            background: linear-gradient(to bottom, rgba(0, 216, 255, 1) 0%, rgba(0, 156, 255, 1) 100%);
            padding: 3% 0;
            border-radius: 10px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: transparent url(../images/section-arrow.png) right center no-repeat;
                background-size: contain;
                @include transform(rotate(180deg));
            }
            &::after {
                content: "";
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: transparent url(../images/section-arrow.png) right center no-repeat;
                background-size: contain;
            }
            li {
                display: inline-block;
                text-align: center;
                background: transparent url(../images/seprator.png) right center no-repeat;
                padding: 0 4%;
                position: relative;
                z-index: 1;
                &:last-child {
                    background: none;
                }
                h3 {
                    color: white;
                    font-family: "D-DIN Condensed";
                    font-weight: bold;
                    font-size: 62px;
                    text-shadow: 2px 2px 2px rgba(150, 150, 150, 0.4);
                    -webkit-text-shadow: 2px 2px 2px rgba(150, 150, 150, 0.4);
                    span {
                        @extend .section;
                        text-shadow: none;
                        -webkit-text-shadow: none;
                        font-size: 16px;
                        font-family: "Montserrat", sans-serif;
                        line-height: 120%;
                        font-weight: 600;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

.about-section-raw {
    text-align: center;
    padding: 30px 0;
    //background: transparent url(../images/shadow.png) center center no-repeat;
    // position: relative;
    &::before {
        background: -moz-radial-gradient(center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
        background: -webkit-radial-gradient(center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
        background: radial-gradient(ellipse at center, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
        position: absolute;
        left: 50%;
        top: 50%;
        width: 150%;
        height: 150%;
        content: "";
        opacity: 0.5;
        @include transform(translate(-50%,
        -50%));
    }
    * {
        position: relative;
    }
    .title-sec {
        h2 {
            width: auto;
            display: inline-block;
            position: relative;
            float: none;
            font-family: 'D-DIN Condensed';
            font-weight: normal;
            background: -webkit-linear-gradient(#00d8ff, #009cff);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            margin-bottom: 30px;
            &::before,
            &::after {
                position: absolute;
                content: "";
                width: 22px;
                height: 1px;
                @include transform(translate(0%,
                -50%));
                background: rgb(0, 156, 255);
                background: -moz-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
                background: -webkit-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
                background: linear-gradient(to right, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
            }
            &::before {
                left: -40px;
                top: 50%;
            }
            &::after {
                right: -40px;
                top: 50%;
            }
        }
    }
    p {
        font-size: 18px;
        font-family: "D-DIN Condensed";
        font-weight: normal;
        color: white;
        line-height: 140%;
        padding: 0 15%;
        letter-spacing: 0.8px;
    }
    .btn-ic {
        margin: 0 15px;
    }
}

.btn-ic {
    background-color: transparent;
    text-shadow: none;
    -webkit-text-shadow: none;
    padding: 10px 20px 10px 50px;
    background-position: left 15px center;
    i {
        display: inline-block;
        margin-right: 8px;
        margin-top: -5px;
        width: 32px;
        height: 22px;
        background-repeat: no-repeat;
        background-position: center center;
        vertical-align: middle;
    }
    &.ic-challenge,
    &.ic-more-game {
        text-shadow: 0px 0px 15px white;
        -webkit-text-shadow: 0px 0px 15px white;
        background-image: none;
        padding: 10px 20px;
        min-width: 216px;
    }
    &.ic-challenge {
        padding-right: 24px;
        i {
            background-image: url(../images/ic-challenges-white.png);
        }
    }
    &.ic-more-game i {
        background-image: url(../images/ic-game-white.png);
    }
    &:hover {
        background-color: #009cff;
        text-shadow: 0px 0px 15px white;
        -webkit-text-shadow: 0px 0px 15px white;
        background-position: left 15px center;
    }
    &.bg-trans {
        background-color: rgba(0, 156, 255, 0.4);
        border-width: 2px;
        min-width: 210px;
        &:hover {
            background-color: rgba(0, 156, 255, 0.7);
        }
    }
}


/*-----------------Game end-------------------*/


/* Store Strat*/

.store-raw {
    .tab-content {
        @extend .section;
        padding: 0 9%;
        position: relative;
        &::before {
            background: -moz-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(51, 133, 204, 0) 70%);
            background: -webkit-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(51, 133, 204, 0) 70%);
            background: radial-gradient(ellipse at center, #3385cc 0%, rgba(51, 133, 204, 0) 70%);
            position: absolute;
            left: 50%;
            top: 40%;
            width: 100%;
            height: 100%;
            content: "";
            opacity: .2;
            @include transform(translate(-50%,
            -50%));
        }
        .gm-li-raw {
            .gm-li {
                .gm-col {
                    .about-game-raw {
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            width: 100%;
                            height: 100%;
                            background: -moz-linear-gradient(top, rgba(51, 133, 204, 0) 0%, rgba(51, 133, 204, 0) 2%, rgba(51, 133, 204, 0) 25%, rgba(51, 133, 204, 1) 100%);
                            background: -webkit-linear-gradient(top, rgba(51, 133, 204, 0) 0%, rgba(51, 133, 204, 0) 2%, rgba(51, 133, 204, 0) 25%, rgba(51, 133, 204, 1) 100%);
                            background: linear-gradient(to bottom, rgba(51, 133, 204, 0) 0%, rgba(51, 133, 204, 0) 2%, rgba(51, 133, 204, 0) 25%, rgba(51, 133, 204, 1) 100%);
                            opacity: .4;
                        }
                    }
                }
            }
        }
    }
}

.store-tab {
    .nav {
        display: block;
    }
    li {
        float: none;
        display: inline-block;
        position: relative;
        z-index: 9;
        margin: 0 -2px;
        .nav-link {
            min-width: 200px;
            font-weight: normal;
            font-size: 26px;
            text-transform: uppercase;
            color: white;
            letter-spacing: 2px;
            line-height: 120%;
            text-align: center;
            position: relative;
            padding: 12px 25px;
            &::before {
                border: 2px solid #082039;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                @include transform(skew(-35deg));
                @include transition(all 0.2s linear);
            }
            &::after {
                position: absolute;
                content: "";
                left: 0;
                bottom: 1px;
                background: white;
                width: 85%;
                height: 2px;
                @include box-shadow(0 0 10px rgb(255,
                255,
                255));
                @include transition(all 0.25s ease 0s);
                opacity: 0;
            }
            span {
                position: relative;
            }
            &.active {
                text-shadow: 1px 1px 1px white;
                -webkit-text-shadow: 1px 1px 1px white;
                &::before {
                    background: rgba(0, 157, 255, 1);
                    background: -moz-linear-gradient(left, rgba(0, 157, 255, 1) 0%, rgba(9, 188, 220, 1) 100%);
                    background: -webkit-gradient( left top, right top, color-stop(0%, rgba(0, 157, 255, 1)), color-stop(100%, rgba(9, 188, 220, 1)));
                    background: -webkit-linear-gradient(left, rgba(0, 157, 255, 1) 0%, rgba(9, 188, 220, 1) 100%);
                    background: -o-linear-gradient(left, rgba(0, 157, 255, 1) 0%, rgba(9, 188, 220, 1) 100%);
                    background: -ms-linear-gradient(left, rgba(0, 157, 255, 1) 0%, rgba(9, 188, 220, 1) 100%);
                    background: linear-gradient(to right, rgba(0, 157, 255, 1) 0%, rgba(9, 188, 220, 1) 100%);
                    border-color: 2px solid rgba(255, 255, 255, 0);
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }
}

.store-raw.game-lists-raw {
    .gm-li-raw {
        .row {
            .gm-li {
                .gm-col {
                    text-align: center;
                    .img-box {
                        margin-top: 40px;
                        height: auto;
                        padding-bottom: 15px;
                        text-align: center;
                        .img-fluid {
                            margin: 0 auto;
                            canvas {
                                opacity: 0.5;
                                margin-top: -8px;
                            }
                        }
                        img {
                            width: auto;
                        }
                    }
                    &:hover {
                        .img-box {
                            img {
                                @include transform(scale(1));
                            }
                        }
                    }
                    .about-game-raw .left-conn {
                        width: 100%;
                        position: relative;
                        h5 {
                            float: none;
                            width: auto;
                            padding-bottom: 5px;
                            border-bottom: 1px solid rgba(38, 88, 182, 0.3);
                            display: inline-block;
                            font-size: 26px;
                            font-family: "D-DIN Condensed";
                            font-weight: bold;
                            text-transform: uppercase;
                            text-shadow: 0px 0px 20px #00d4ff;
                            -webkit-text-shadow: 0px 0px 20px #00d4ff;
                        }
                        .price {
                            display: block;
                            margin-top: 5px;
                            font-family: "D-DIN Condensed";
                            font-weight: bold;
                            font-size: 22px;
                            color: white;
                            line-height: 120%;
                            span {
                                margin-left: 10px;
                                font-family: "D-DIN Condensed";
                                font-weight: normal;
                            }
                        }
                        .btn-join {
                            padding: 8px 40px 9px 30px;
                            margin-top: 20px;
                        }
                    }
                }
            }
        }
    }
}

.product-li-raw {
    margin-top: 40px;
    .row {
        margin-right: -20px;
        margin-left: -20px;
        .gm-li {
            margin-top: 40px;
            padding-right: 20px;
            padding-left: 20px;
            text-align: center;
        }
    }
}


/* Store End*/


/* Cart  Start*/

.cart-wrap {
    padding: 0 40px;
    table {
        thead {
            position: relative;
            &:before {
                background-color: rgba(255, 255, 255, 0.2);
                width: 100%;
                height: 1px;
                content: "";
                top: -7px;
                position: absolute;
            }
            &:after {
                background-color: rgba(255, 255, 255, 0.2);
                width: 100%;
                height: 1px;
                content: "";
                bottom: -7px;
                position: absolute;
            }
            tr {
                background-color: #0f223d;
                th {
                    font-weight: 700;
                    color: white;
                    text-transform: uppercase;
                    background: -webkit-linear-gradient(#00d3ff, #3385cc);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
                    &:after {
                        display: none;
                    }
                    &:first-child {
                        &:before {
                            display: none;
                        }
                    }
                    &:before {
                        background-color: rgba(255, 255, 255, 0.2);
                        width: 1px;
                        height: 70%;
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 8px;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    border-bottom-color: rgba(255, 255, 255, 0.2);
                    vertical-align: middle;
                    &:first-child {
                        font-weight: 700;
                        font-size: 32px;
                    }
                }
                &.total {
                    td {
                        background-color: #0f223d;
                        border: none;
                        font-weight: 700;
                        font-size: 26px;
                        text-transform: capitalize;
                        span {
                            vertical-align: top;
                            padding: 0;
                            font-weight: 400;
                        }
                        &:nth-child(-n+2) {
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
    .qty-drop select {
        background: rgba(255, 255, 255, 0.2) url(../images/ic-dropdown.png) no-repeat right 15px center;
        float: left;
        min-width: 140px;
        height: 40px;
        padding: 0 30px 0 15px;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border: none;
        color: white;
        option {
            color: #333;
        }
    }
}

.cart-action {
    padding-top: 60px;
    .btn-main {
        margin-left: 20px;
        margin-right: 20px;
    }
}

.brought-wrap {
    padding-top: 100px;
    text-align: center;
    .title {
        position: relative;
        z-index: 9;
        &:after {
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 13px;
            left: 0;
            background: rgba(38, 88, 182, 0.3);
            z-index: -9;
        }
        h4 {
            background-color: #06091a;
            float: none;
            display: inline-block;
            width: auto;
            padding: 0 50px;
            font-family: "D-DIN Condensed";
            text-transform: uppercase;
            color: white;
            position: relative;
            white-space: nowrap;
            .b_l_quad {
                display: none;
                .button_spots {
                    @for $i from 1 through 19 {
                        &:nth-of-type(#{$i}) {
                            animation: spot-#{$i} 0.7s 0.4/random (10)+random(10) / 10+s linear infinite;
                        }
                    }
                    @for $i from 20 through 40 {
                        &:nth-of-type(#{$i}) {
                            animation: spot-#{$i} 0.7s 0.4/random (10)+random(10) / 10+s linear infinite;
                        }
                    }
                    @for $i from 40 through 46 {
                        &:nth-of-type(#{$i}) {
                            animation: spot-#{$i} 0.7s 0.4/random (10)+random(10) / 10+s linear infinite;
                        }
                    }
                    @for $i from 46 through 54 {
                        &:nth-of-type(#{$i}) {
                            animation: spot-#{$i} 0.7s 0.4/random (10)+random(10) / 10+s linear infinite;
                        }
                    }
                }
            }
            .button_spots {
                position: absolute;
                border-radius: 100px;
                background: green;
                //opacity:0;
                animation: opacity 1s;
                @for $i from 1 through 52 {
                    &:nth-of-type(#{$i}) {
                        transform-origin: 90px - random(10) 20px - random(10);
                        background: hsla(350 + random(399), 57% - random(10), 65%, 1);
                        box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
                        transition: all 1s + random(10) / 10;
                    }
                }
            }
            &:before,
            &:after {
                width: 20px;
                height: 20px;
                border: 2px solid rgba(38, 88, 182, 0.3);
                content: "";
                position: absolute;
                top: 7px;
                left: 4px;
                @include transform(rotate(45deg));
            }
            &:after {
                left: inherit;
                right: 4px;
            }
        }
    }
}

@for $i from 1 through 20 {
    @keyframes spot-#{$i} {
        from {
            opacity: 0;
        }
        to {
            transform: translateY(30px) translatex(-20px + $i * 2);
            opacity: 0.6;
        }
    }
}

@for $i from 20 through 40 {
    @keyframes spot-#{$i} {
        from {
            opacity: 0;
        }
        to {
            transform: translateY(-30px) translatex(-50px + $i * 2);
            opacity: 0.6;
        }
    }
}

@for $i from 40 through 45 {
    @keyframes spot-#{$i} {
        from {
            opacity: 0;
        }
        to {
            transform: translateY(-86px + $i * 2) translatex(40px);
            opacity: 0.6;
        }
    }
}

@for $i from 46 through 54 {
    @keyframes spot-#{$i} {
        from {
            opacity: 0;
        }
        to {
            transform: translateY(-99px + $i * 2) translatex(-40px);
            opacity: 0.6;
        }
    }
}

@keyframes opacity {
    // from {}
    to {
        opacity: 0;
    }
}

@keyframes rotate {
    from {
        opacity: 0.8;
    }
    to {
        transform: rotate(360deg);
        opacity: 0.8;
    }
}

@keyframes down {
    from {
        left: 10px;
    }
    to {
        left: 57px;
    }
}

@keyframes spew {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.8;
    }
}

@keyframes final {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes finalbox {
    // from {}
    to {
        width: 50px;
    }
}

@keyframes tick {
    // from {}
    to {
        transform: scale(1) rotate(-90deg);
    }
}

.brought-slide {
    padding-top: 40px;
    .slick-arrow {
        position: absolute;
        top: 50%;
        width: 50px;
        height: 90px;
        font-size: 0;
        &:focus {
            outline: none !important;
        }
        &.slick-prev {
            background: url(../images/slide-arrow-prev.png) no-repeat center;
            left: -50px;
        }
        &.slick-next {
            background: url(../images/slide-arrow-next.png) no-repeat center;
            right: -50px;
        }
    }
    .slick-slide {
        margin-left: 15px;
        margin-right: 15px;
    }
}

.brought-box {
    float: left;
    width: 100%;
    padding: 30px 0 20px;
    // margin: 0 10px;
    border: 1px solid #04355e;
    @include border-radius(12px);
    min-height: 250px;
    position: relative;
    background: #060919;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: -moz-linear-gradient(top, rgba(30, 80, 128, 0) 0%, rgba(30, 80, 128, 1) 100%);
        background: -webkit-linear-gradient(top, rgba(30, 80, 128, 0) 0%, rgba(30, 80, 128, 1) 100%);
        background: linear-gradient(to bottom, rgba(30, 80, 128, 0) 0%, rgba(30, 80, 128, 1) 100%);
        // opacity: .4;
        @include border-radius(10px);
    }
    .img-box {
        display: inline-block;
        vertical-align: middle;
        line-height: 175px;
        position: relative;
        img {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .text-box {
        padding-top: 40px;
        text-align: center;
        position: relative;
        h5 {
            float: none;
            display: block;
            width: 80%;
            min-width: 150px;
            font-family: "D-DIN Condensed";
            font-weight: bold;
            font-size: 26px;
            padding: 0 10px 5px;
            margin: 0 auto 10px;
            color: white;
            text-transform: uppercase;
            background: -webkit-linear-gradient(#00d8ff, #009cff);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            border-bottom: 1px solid rgba(38, 88, 182, 0.3);
        }
        .price {
            float: left;
            width: 100%;
            font-family: "D-DIN Condensed";
            font-size: 20px;
            color: white;
            letter-spacing: 1px;
            font-weight: bold;
            span {
                font-weight: normal;
                margin-left: 10px;
                letter-spacing: 0;
            }
        }
        .btn-join,
        .btn-ic {
            margin: 30px 7px 0;
        }
        .btn-join {
            padding: 8px 35px 8px 20px;
            text-shadow: 0px 0px 15px white;
            -webkit-text-shadow: 0px 0px 15px white;
        }
        .btn-ic {
            background: url(../images/ic-cart.png) left 13px center no-repeat;
            padding: 8px 10px 8px 24px;
        }
    }
}

.addcart-next-raw {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-top: 30px;
    .add-to-cart {
        width: 120px;
        display: inline-block;
        vertical-align: middle;
        margin-top: 0px !important;
        font-size: 12px;
    }
    .add-qty-col {
        display: none;
        vertical-align: middle;
        width: 120px;
        span {
            display: inline-block;
            vertical-align: middle;
            &.minus-col,
            &.plus-col {
                width: 32px;
                height: 32px;
                line-height: 30px;
                vertical-align: middle;
                @include border-radius(100px);
                border: 2px solid #3486cc;
                font-size: 30px;
                color: white;
                font-family: "D-DIN Condensed";
                font-weight: normal;
            }
            &.minus-col {
                line-height: 30px;
            }
            &.qty-col {
                font-family: "D-DIN";
                font-weight: bold;
                font-size: 18px;
                color: white;
                margin: 0 10px;
            }
            &.plus-col {
                line-height: 24px;
            }
        }
    }
    .btn-join {
        display: inline-block;
        vertical-align: middle;
        margin-top: 0px !important;
        font-size: 12px;
    }
}


/* Cart  End*/

.news-details-raw {
    padding: 50px 0 100px;
}

.details-head {
    .img-box {
        display: inline-block;
        width: 186px;
        height: 186px;
        img {
            border: 2px solid #3385cc;
        }
    }
    h1 {
        font-family: "D-DIN Condensed";
        margin: 40px 0 25px;
    }
}

.btn-ac {
    background-color: rgba(0, 156, 255, 0.6);
    display: inline-block;
    border: 2px solid #009cff;
    min-width: 160px;
    height: 40px;
    line-height: 35px;
    padding: 0 15px;
    margin: 0 10px;
    @include border-radius(100px);
    &:hover {
        background-color: rgba(0, 156, 255, 1);
    }
    span {
        font-size: 16px;
        font-family: "D-DIN Condensed";
        font-weight: bold;
        color: white;
        text-transform: uppercase;
        padding-left: 20px;
        letter-spacing: 1px;
        text-shadow: 0 0 13px rgba(255, 255, 255, 0.5);
        -webkit-text-shadow: 0 0 13px rgba(255, 255, 255, 0.5);
    }
    &.like {
        span {
            background: url(../images/ic-like.png) no-repeat left;
            padding-left: 25px;
        }
    }
    &.comment {
        span {
            background: url(../images/ic-comment.png) no-repeat left;
        }
    }
    &.share {
        span {
            background: url(../images/ic-share.png) no-repeat left;
        }
    }
    &.ic-rules {
        span {
            background: url(../images/ic-rules.png) no-repeat left;
        }
    }
    &.ic-team {
        span {
            background: url(../images/ic-team.png) no-repeat left;
            padding-left: 30px;
        }
    }
    &.ic-brackets {
        span {
            background: url(../images/ic-arrow.png) no-repeat left;
        }
    }
    &.ic-checked-in {
        span {
            background: url(../images/ic-check.png) no-repeat left;
        }
    }
    &.ic-lobby {
        @include box-shadow(0 0 10px white);
        border: 1px solid white;
        span {
            background: url(../images/ic-lobby.png) no-repeat left;
        }
    }
}

.line-arrow {
    position: relative;
    border-top: 2px solid #2e313e;
    padding-top: 100px !important;
    &::before {
        position: absolute;
        content: "";
        left: 50%;
        top: 0px;
        background: white;
        width: 2px;
        height: 60px;
        @include box-shadow(0 0 10px rgb(255,
        255,
        255));
        @include transition(all 0.25s ease 0s);
        @include transform(translate(-50%,
        -50%));
    }
}

.details-body {
    .description {
        float: left;
        width: 100%;
        // padding: 0 80px;
        p {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.7);
            margin-bottom: 1rem;
        }
    }
}

.news-comment {
    padding-top: 60px;
    text-align: center;
    .item-comment{
        margin-bottom: 1.5rem;
    }
    .news-comment-heading{
        font-family: "D-DIN Condensed";
        margin: 30px 0 25px 0;
        text-align: left;
        color: #fff;
        font-size: 20px;
        display: inline-block;
        position: relative;
        text-transform: uppercase;
        &::before {
            content: '';
            position: absolute;
            left: -90px;
            top: 50%;
            border-top: 1px solid #ddd;
            height: 1px;
            width: 70px;
            left: 155px;
            width: -webkit-fill-available;
        }
    }
    .form-control {
        height: 90px;
        border: 2px solid #0e1f39;
        background-color: #0b162b;
        @include border-radius(12px);
        padding: 15px;
        color: rgba(255, 255, 255, 1);
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        font-size: 14px;
        &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
        &::-moz-placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
        &:-ms-input-placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
        &:-moz-placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
    }
    .btn-main {
        float: none;
        display: inline-block;
        min-width: inherit;
        padding: 0 25px;
        height: 40px;
        line-height: 35px;
        color: white;
        border-color: #009cff;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
}

.register-modal {
    &.modal-raw {
        .modal-content {
            padding: 0;
            .modal-body {
                padding: 30px 60px 60px;
                background: #090e26;
            }
        }
    }
    .modal-dialog {
        max-width: 784px;
    }
    .already-member-col {
        display: block;
        clear: both;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        margin-top: 20px;
        a {
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            color: #018feb;
            text-transform: uppercase;
            &:hover {
                color: #00c0ff;
            }
        }
    }
}

.login-modal {
    .modal-dialog {
        max-width: 600px;
        .modal-body {
            padding-left: 15%;
            padding-right: 15%;
            padding-bottom: 30px;
            background: #090e26;
            
        }
    }
    .forgot-pass-col {
        float: right;
        margin-top: 15px;
        font-size: 14px;
        color: #009cff;
        font-family: "Montserrat", sans-serif;
        font-weight: 600;
        &:hover {
            color: #00c0ff;
        }
    }
    .already-member-col {
        clear: both;
        display: block;
        margin: 20px 0 0;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        a {
            display: inline-block;
            margin-top: 5px;
            font-size: 14px;
            color: #009cff;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            &:hover {
                color: #00c0ff;
            }
        }
    }
    .ic-email {
        background-image: url(../images/ic-mail.png);
        background-position: left 15px center;
        background-repeat: no-repeat;
        padding-left: 45px;
        background-size: 20px;
    }
    .ic-pass {
        background-image: url(../images/ic-pass.png);
        background-position: left 18px center;
        background-repeat: no-repeat;
        padding-left: 45px;
        background-size: 14px;
    }
}

.terms-and-policy-col {
    margin-top: 20px;
    li {
        display: inline-block;
        vertical-align: middle;
        padding: 8px 15px;
        border-right: 1px solid #081c3a;
        &:last-child {
            border-right: 0px solid #081c3a;
        }
        a {
            color: rgba(255, 255, 255, 0.4);
            font-family: "Montserrat", sans-serif;
            font-weight: 400;
            font-size: 12px;
            &:hover {
                color: rgba(255, 255, 255, 1);
            }
        }
    }
}

// .reg-raw{
// 	margin-top: 30px;
// 	label{
// 		font-family: 'Montserrat', sans-serif;
// 		color: white;
// 		margin-bottom: 10px;
// 		font-size: 14px;
// 		a{
// 			font-family: 'Montserrat', sans-serif;
// 			font-size: 14px;
// 			color: #00d3ff;
// 		}
// 	}
// 	.btn-main{
// 		display: table;
// 		margin: 0 auto 20px;
// 	}
// }
.terms-raw {
    display: inline-block;
    width: 70%;
    text-align: left;
    margin-top: 10px;
    label {
        @extend .section;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.6);
        cursor: pointer;
        margin: 8px 0;
        a {
            font-size: 14px;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            color: #018feb;
            &:hover {
                color: #00c0ff;
            }
        }
        input[type="radio"] {
            margin-right: 10px;
            appearance: none;
            width: 20px;
            height: 20px;
            @include border-radius(100px);
            border: 2px solid #cbccd1;
            margin-top: -2px;
        }
        // input[type="checkbox"]:checked::before {
        // 	position: absolute;
        // 	left: -2px;
        // 	top: -2px;
        // 	font-family: 'Glyphicons Halflings';
        // 	content: "\e013";
        // 	width: 21px;
        // 	height: 21px;
        // 	color: #0097e6;
        // 	border: 2px solid #0097e6;
        // 	font-size:12px;
        // 	padding:1px 0 0 2px;
        // 	outline: none;
        // }
        input[type="radio"]:checked {
            background: #0097e6;
            border: 0px solid #0097e6;
            box-shadow: none;
            @include border-radius(100px);
            outline: none;
            background: -webkit-linear-gradient(#00d3ff, #3385cc);
        }
    }
}

.title-divider {
    position: relative;
    text-align: center;
    font-size: 32px;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#00d8ff, #009cff);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    font-family: "D-DIN Condensed";
    font-weight: bold;
    letter-spacing: 4px;
    &::before,
    &::after {
        position: absolute;
        content: "";
        width: 34%;
        height: 2px;
        @include transform(translate(0%,
        -50%));
        background: rgb(0, 156, 255);
        background: -moz-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
        background: -webkit-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
        background: linear-gradient(to right, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
    }
    &::before {
        left: 0;
        top: 50%;
    }
    &::after {
        right: 0;
        top: 50%;
    }
    &.line-none {
        padding: 20px 0;
        &::before,
        &::after {
            display: none;
        }
    }
}

.btn-link {
    font-size: 18px;
    text-transform: uppercase;
    color: white;
    display: inline-block;
    border-bottom: 2px solid #50aeff;
    padding-bottom: 10px;
    font-family: "D-DIN";
    font-weight: bold;
    letter-spacing: 1px;
    background: -webkit-linear-gradient(white, white);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    @include transition(all 0.25s ease 0s);
    &:hover,
    &:focus,
    &:active {
        background: -webkit-linear-gradient(#00d8ff, #009cff);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        text-decoration: none;
    }
}

.ig-tv-raw {
    .title-divider {
        &::before,
        &::after {
            width: 44%;
        }
    }
    &.game-lists-raw .gm-li-raw .row .gm-li .gm-col {
        border: 0px;
        box-shadow: none;
        border-radius: 0px;
        overflow: inherit;
        background: transparent;
        .about-game-raw {
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 0px;
            text-align: center;
            .about-game-conn .left-conn {
                width: 100%;
                h5 {
                    background: -webkit-linear-gradient(#00d8ff, #009cff);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
                    font-size: 22px;
                }
                h6 {
                    color: white;
                    background: none;
                    font-family: "D-DIN Condensed";
                    font-weight: normal;
                    font-size: 18px;
                }
            }
            &::before {
                display: none;
            }
            &::after {
                top: -10px;
            }
        }
        .img-box {
            overflow: unset;
            height: 145px;
            box-shadow: 0 0 20px rgba(80, 174, 255, 0.3), inset 0 0 20px rgba(80, 174, 255, 0.3);
            -webkit-box-shadow: 0 0 20px rgba(80, 174, 255, 0.3), inset 0 0 20px rgba(80, 174, 255, 0.3);
            @include border-radius(10px);
            overflow: hidden;
            img {
                @include border-radius(10px);
            }
            .btn-play {
                position: relative;
                float: left;
                width: 100%;
                img {
                    @include transition(all 0.45s ease 0s);
                }
                &::before {
                    background: transparent url(../images/play-button.png) center center no-repeat;
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    @include transition(all 0.45s ease 0s);
                }
                &:hover {
                    &::before {
                        opacity: 1;
                    }
                    img {
                        opacity: 0.1;
                    }
                }
            }
        }
        &:hover .img-box img {
            @include transform(scale(1));
        }
    }
}

.social-connects-raw {
    position: fixed;
    right: 30px;
    bottom: 6%;
    width: auto;
    z-index: 11;
    li {
        display: inline-block;
        margin: 0px;
        span .img-fluid {
            max-width: 50px;
        }
    }
}

.challenge-raw {
    &::before,
    &::after {
        display: none;
    }
    &.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw {
        .about-game-conn {
            .left-conn {
                h5 {
                    font-size: 22px;
                }
                h6 {
                    font-size: 12px;
                    font-family: "Montserrat", sans-serif;
                    font-weight: 600;
                    color: white;
                    padding-left: 0;
                    background: no-repeat;
                    span {
                        color: #009cff;
                        font-family: "Montserrat", sans-serif;
                        font-weight: 600;
                    }
                }
            }
        }
        // .plateform-players-col li {
        //     text-align: left;
        //     float: left;
        // }
    }
    .gm-li-raw {
        .gm-li {
            &:first-child::before,
            &:last-child::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 45%;
                width: 140%;
                height: 140%;
                background: -moz-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
                background: -webkit-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
                background: radial-gradient(ellipse at center, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
                @include transform(translate(-50%,
                -50%));
                opacity: 0.2;
            }
        }
    }
    .challenge-info-raw {
        padding-top: 70px;
        text-align: center;
        ul {
            position: relative;
            &::before {
                position: absolute;
                left: 0;
                top: 5PX;
                height: 1px;
                width: 100%;
                background: #2e313e;
                content: "";
            }
            li {
                display: inline-block;
                vertical-align: top;
                background: #05091a;
                position: relative;
                margin: 0 30px;
                >span {
                    @extend .section;
                    background: -webkit-linear-gradient(#00d8ff, #009cff);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
                    font-size: 18px;
                    font-family: "D-DIN Condensed";
                    font-weight: bold;
                    text-transform: uppercase;
                    letter-spacing: 1.5px;
                }
                h6 {
                    font-family: "Montserrat", sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                    text-transform: uppercase;
                    padding-top: 10px;
                }
                &.price-money-raw {
                    h6 {
                        font-size: 42px;
                        font-family: "D-DIN Condensed";
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 1.5px;
                        line-height: 100%;
                        text-shadow: 0px 0px 30px #00d4ff;
                        -webkit-text-shadow: 0px 0px 30px #00d4ff;
                        strong {
                            font-size: 16px;
                            font-family: "D-DIN Condensed";
                            font-weight: bold;
                            vertical-align: top;
                            line-height: 100%;
                        }
                    }
                }
            }
        }
    }
    .disclaimer-raw {
        p {
            font-size: 12px;
            color: #009cff;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            margin-top: 20px;
        }
        .btn-main {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 16px;
            height: 42px;
            line-height: 38px;
            padding: 0 40px;
        }
    }
}

.challenges-banner-raw {
    .btn-ic {
        font-size: 14px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .inner-banner .container-fluid .inner-banner-con {
        top: 70%;
    }
}

.challenges-raw {
    &.game-lists-raw .gm-li-raw .row {
        margin-right: -35px;
        margin-left: -35px;
        .gm-li {
            padding-right: 35px;
            padding-left: 35px;
            .gm-col {
                .about-game-raw {
                    &::after {
                        top: -5px;
                    }
                    .about-game-conn .left-conn {
                        width: 100%;
                        text-align: center;
                    }
                }
                .img-box {
                    height: 180px;
                }
            }
        }
    }
}

.btn-plus {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 31px;
    vertical-align: middle;
    text-align: center;
    background: #009cff;
    @include box-shadow(0 0 13px rgba(0,
    0,
    0,
    0.11));
    @include border-radius(100px);
    color: white;
    font-size: 30px;
    position: absolute;
    bottom: -14px;
    left: 0;
    right: 0;
    margin: 0 auto;
}


/*--------------leaderboard-start--------------*/

// .leaderboard-border{
// 	border-top: 1px solid rgba(255,255,255,.3);
// }
.leaderboard-raw {
    .leader-list-raw {
        padding: 0 15%;
        position: relative;
        z-index: 11;
        .filter-raw {
            .search-all {
                background: transparent;
                border: 0;
                float: right;
                box-shadow: none;
                &:hover {
                    outline: none;
                }
            }
        }
        .leader-list-col {
            margin-top: 60px;
            table tr th span {
                // background: -moz-linear-gradient(top, rgba(0,216,255,1) 0%, rgba(0,156,255,1) 100%);
                // background: -webkit-linear-gradient(top, rgba(0,216,255,1) 0%,rgba(0,156,255,1) 100%);
                // background: linear-gradient(to bottom, rgba(0,216,255,1) 0%,rgba(0,156,255,1) 100%);
                background: #0f223d;
                background: -webkit-linear-gradient(#00d3ff, #3385cc);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            }
            table tr {
                th {
                    &.choose-game {
                        &:after {
                            content: "";
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background: #070919;
                            background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                            background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                            background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                        }
                        &:before {
                            content: "";
                            width: 100%;
                            height: 1px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: #070919;
                            background: -moz-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                            background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                            background: linear-gradient( to right, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 50%, rgba(1, 195, 236, 0) 100%);
                        }
                    }
                    &:first-child {
                        &:before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            float: left;
                            height: 1px;
                            left: 0;
                            top: 0;
                            background: -moz-linear-gradient( left, rgba(1, 195, 236, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                            background: -webkit-linear-gradient( left, rgba(1, 195, 236, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                            background: linear-gradient( to right, rgba(1, 195, 236, 1) 0%, rgba(15, 33, 60, 0.01) 99%, rgba(15, 33, 60, 0) 100%);
                        }
                    }
                    &:last-child {
                        &:before {
                            content: "";
                            position: absolute;
                            width: 100%;
                            float: left;
                            height: 1px;
                            left: 0;
                            top: 0;
                            background: -moz-linear-gradient(left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 100%);
                            background: -webkit-linear-gradient( left, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 100%);
                            background: linear-gradient(to right, rgba(15, 33, 60, 0) 0%, rgba(1, 195, 236, 1) 100%);
                        }
                    }
                }
                td {
                    &:first-child:after,
                    &:last-child:after {
                        display: none;
                    }
                    padding: 16px 10px;
                }
            }
        }
        &.tournament-lobby-raw {
            padding: 0 8%;
        }
    }
}

.hexa-img {
    clip-path: polygon(48% 0, 100% 25%, 100% 75%, 53% 100%, 1% 75%, 0 25%);
    -webkit-clip-path: polygon(48% 0, 100% 25%, 100% 75%, 53% 100%, 1% 75%, 0 25%);
    object-fit: cover;
    -webkit-object-fit: cover;
    width: 32px;
    height: 38px;
    border: 2px solid #036b61;
}


/*---------------leaderboard-end--------------*/


/*--------------igtv-start--------------*/

.most-popular-raw {
    padding: 0 10%;
    ul {
        margin-top: 30px;
        text-align: center;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100%;
            background: -moz-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
            background: -webkit-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
            background: radial-gradient(ellipse at center, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
            @include transform(translate(-50%,
            -50%));
            opacity: 0.7;
        }
        li {
            display: inline-block;
            width: 19.5%;
            margin-top: 20px;
            padding: 0 15px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 10px;
                width: 50%;
                height: 30px;
                background: -moz-radial-gradient( center, ellipse cover, rgba(0, 68, 254, 1) 0%, rgba(0, 174, 255, 0) 72%, rgba(0, 215, 255, 0) 100%);
                background: -webkit-radial-gradient( center, ellipse cover, rgba(0, 68, 254, 1) 0%, rgba(0, 174, 255, 0) 72%, rgba(0, 215, 255, 0) 100%);
                background: radial-gradient( ellipse at center, rgba(0, 68, 254, 1) 0%, rgba(0, 174, 255, 0) 72%, rgba(0, 215, 255, 0) 100%);
                @include transform(translate(-50%,
                -50%));
            }
            a {
                @extend .section;
                position: relative;
                @include border-radius(10px);
                overflow: hidden;
                img {
                    width: 100%;
                    border: 1px solid rgba(51, 133, 204, 0.3);
                    @include border-radius(10px);
                }
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: -50%;
                    width: 100%;
                    height: 100%;
                    background: -moz-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
                    background: -webkit-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
                    background: radial-gradient(ellipse at center, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 70%);
                    opacity: 0.7;
                }
            }
            h5 {
                text-transform: uppercase;
                font-size: 22px;
                letter-spacing: 1px;
                font-family: "D-DIN Condensed";
                font-weight: 700;
                margin-top: 10px;
            }
        }
    }
}

.search-game-raw {
    .form-control {
        background-color: transparent;
        background-image: url(../images/search-ic.png);
        background-position: right 4px center;
        background-repeat: no-repeat;
        background-size: 34px;
        padding-right: 45px;
        letter-spacing: 1px;
        height: 45px;
        @include border-radius(100px);
        &::-webkit-input-placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
        }
        &::-moz-placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
        }
        &:-ms-input-placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
        }
        &:-moz-placeholder {
            color: rgba(255, 255, 255, 0.5);
            font-weight: 400;
        }
    }
}

.tab-and-search-raw {
    .igtv-raw {
        .search-game-raw {
            width: 40%;
            float: right;
            padding-right: 60px;
        }
        .nav-tabs {
            width: 50%;
            float: left;
            border: 0px;
            padding-left: 60px;
            .nav-link {
                border: 1px solid #051830;
                background: #051830;
                font-size: 18px;
                line-height: 120%;
                padding: 5px 30px;
                text-transform: uppercase;
                font-family: "D-DIN Condensed";
                font-weight: normal;
                color: white;
                @include border-radius(20px);
                margin-right: 20px;
                letter-spacing: 1px;
                &.active {
                    border: 1px solid #009cff;
                    background: #009cff;
                    color: white;
                    font-weight: bold;
                }
            }
        }
    }
    .game-lists-raw .gm-li-raw .row .gm-li {
        margin-top: 0px;
        margin-bottom: 40px;
    }
}

.mCustomScrollbar {
    // position: relative;
    // margin-top: 50px;
    // padding: 20px;
    // max-height: 500px;
    // background: #ddd;
    width: 100%;
    float: left;
    max-height: 550px;
    overflow: auto;
    .mCSB_scrollTools {
        .mCSB_draggerRail {
            background-color: rgba(66, 69, 85, 0.5);
        }
        .mCSB_dragger .mCSB_dragger_bar {
            background-color: rgba(0, 156, 255, 0.75);
        }
    }
}

.follow-cointer-col {
    .user-follower-col {
        display: inline-block;
        span {
            font-size: 18px;
            color: white;
            font-family: "D-DIN Condensed";
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 5px;
        }
        .counter-col {
            font-family: "D-DIN Condensed";
            font-weight: bold;
            text-transform: uppercase;
            color: white;
            font-size: 50px;
            text-shadow: 0px 0px 30px #00d4ff;
            -webkit-text-shadow: 0px 0px 30px #00d4ff;
            height: 120px;
            width: 120px;
            line-height: 100px;
            background: transparent url(../images/pentagonal.png) center center no-repeat;
            margin-top: 8px;
        }
    }
}

.user-avtar-col {
    width: 220px;
    display: inline-block;
    background: url(../images/hexagon-bg.png) center center no-repeat;
    background-size: cover;
    padding: 13px;
    margin: 0 40px;
    position: relative;
    img {
        clip-path: polygon(50% 2%, 100% 25%, 100% 75%, 51% 100%, 1% 74%, 0 25%);
        -webkit-clip-path: polygon(50% 2%, 100% 25%, 100% 75%, 51% 100%, 1% 74%, 0 25%);
    }
}

.main-wrapper {
    &.profile-raw {
        background: #080718 url(../images/profile-bg-new.jpg) top 115px center no-repeat;
        // background-attachment: fixed !important;
        background-size:contain !important;
        overflow: hidden;
        .user-avtar-col{
            float: left;
            bottom: 70px;
        }
        .profile-intro{
            float: left;
            width: auto;
            text-align: left;
            h1 {
                background: -moz-linear-gradient(top, #80ebff 0%, #039eff 100%);
                background: -webkit-linear-gradient(top, #80ebff 0%, #039eff 100%);
                background: linear-gradient(to bottom, #80ebff 0%, #039eff 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                font-size: 36px;
            }
            p{
                color: white;
                font-size: 14px;
                span{
                    font-size: 24px;
                    font-weight: 700;
                    color: white;
                    text-transform: uppercase;
                    padding-left: 5px;
                }
            }
        }
        .follow-points{
            width: auto;
            float: right;
            // position: relative;
            // top: 70px;
            ul{
                position: relative;
                li{
                    display: inline-block;
                    vertical-align: top;
                    margin: 0 10px;
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 1px;
                        height: 50%;
                        right: -10px;
                        top: 0;
                        background: -moz-linear-gradient(top, #80ebff 0%, #039eff 100%);
                        background: -webkit-linear-gradient(top, #80ebff 0%, #039eff 100%);
                        background: linear-gradient(to bottom, #80ebff 0%, #039eff 100%);
                    }
                    &:last-child{
                        &::before{
                            display: none;
                        }
                    }
                    span{
                        background: -moz-linear-gradient(top, #80ebff 0%, #039eff 100%);
                        background: -webkit-linear-gradient(top, #80ebff 0%, #039eff 100%);
                        background: linear-gradient(to bottom, #80ebff 0%, #039eff 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        -moz-background-clip: text;
                        -moz-text-fill-color: transparent;
                        font-size: 36px;
                        font-weight: 700;
                    }
                    h6{
                        text-transform: uppercase;
                        font-weight: normal;
                        font-size: 14px;
                        padding-top: 10px;
                    }
                }
            }
        }
        .horizontal-tab-raw {
            padding: 0px;
            z-index: 111;
            .nav-tabs-dropdown {
                display: none !important;
            }
            .nav-tabs-horizontal {
                display: block !important;
                background: -moz-linear-gradient( left, rgba(0, 156, 255, 0) 0%, rgba(0, 154, 253, 1) 50%, rgba(0, 156, 255, 0) 100%);
                background: -webkit-linear-gradient( left, rgba(0, 156, 255, 0) 0%, rgba(0, 154, 253, 1) 50%, rgba(0, 156, 255, 0) 100%);
                background: linear-gradient( to right, rgba(0, 156, 255, 0) 0%, rgba(0, 154, 253, 1) 50%, rgba(0, 156, 255, 0) 100%);
                overflow: visible;
                @include transition(all 1s ease 0s);
                >li {
                    display: inline-block;
                    width: auto;
                    a {
                        padding: 20px 30px;
                        background: transparent;
                        position: relative;
                        font-weight: normal;
                        font-size: 16px;
                        letter-spacing: 1px;
                        color: rgba(255, 255, 255, 0.8);
                        &.active {
                            text-shadow: 0px 0px 30px #00d4ff;
                            -webkit-text-shadow: 0px 0px 30px #00d4ff;
                            font-weight: bold;
                            color: rgba(255, 255, 255, 1);
                            &::after {
                                position: absolute;
                                content: "";
                                left: 50%;
                                bottom: 0px;
                                background: #fff;
                                width: 75%;
                                height: 2px;
                                -webkit-box-shadow: 0 0 10px #fff;
                                box-shadow: 0 0 10px #fff;
                                -webkit-transition: all 0.25s ease 0s;
                                transition: all 0.25s ease 0s;
                                @include transform(translate(-50%,
                                0));
                                z-index: 1;
                            }
                        }
                        &::before {
                            right: 0;
                            top: 50%;
                            height: 60%;
                            width: 2px;
                            background: #33709c;
                            content: "";
                            position: absolute;
                            @include transform(translate(0,
                            -50%));
                            opacity: 0.6;
                        }
                    }
                    &:last-child {
                        a::before {
                            display: none;
                        }
                    }
                }
            }
            .divide-raw {
                &::before {
                    height: 1px;
                }
                &::after {
                    bottom: 0;
                    top: inherit;
                    height: 1px;
                }
            }
        }
    }
}

.balance-earned-raw {
    margin-top: 40px;
    @extend .section;
    ul {
        text-align: center;
        li {
            display: inline-block;
            margin: 0 20px;
            span {
                font-size: 16px;
                color: white;
                font-family: "D-DIN Condensed";
                font-weight: normal;
                text-transform: uppercase;
                letter-spacing: 5px;
            }
            .coin-label {
                border: 1px solid #3385cc;
                @include border-radius(4px);
                font-family: "D-DIN Condensed";
                font-weight: bold;
                font-size: 28px;
                color: white;
                text-transform: uppercase;
                background: rgb(18, 30, 88);
                background: -moz-linear-gradient(top, rgba(18, 30, 88, 1) 0%, rgba(11, 18, 51, 1) 76%);
                background: -webkit-linear-gradient(top, rgba(18, 30, 88, 1) 0%, rgba(11, 18, 51, 1) 76%);
                background: linear-gradient(to bottom, rgba(18, 30, 88, 1) 0%, rgba(11, 18, 51, 1) 76%);
                text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
                -webkit-text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
                padding: 20px 40px;
                margin-top: 10px;
            }
        }
    }
}


/*--------------igtv-end--------------*/

.title-shadow {
    text-align: center;
    h2,
    h4 {
        width: auto;
        display: inline-block;
        float: none;
        position: relative;
        text-transform: uppercase;
        font-family: "D-DIN Condensed";
        font-weight: bold;
        font-size: 30px;
        letter-spacing: 5px;
        background: -moz-linear-gradient(top, #00d8ff 0%, #009eff 100%);
        background: -webkit-linear-gradient(top, #00d8ff 0%, #009eff 100%);
        background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
        background-clip: border-box;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -95%;
            width: 200%;
            height: 100%;
            @include transform(translate(-50%,
            0%));
            background: transparent url(../images/title-shadow.png) center center no-repeat;
            background-size: contain;
        }
    }
}

.profile-details-raw {
    // h2 {
    // 	font-size: 34px;
    // }
    .team-overview-raw {
        .the-team-raw {
            .team-li {
                margin-top: 100px;
                .team-li-conn {
                    @extend .section;
                    position: relative;
                    background: #060919;
                    // overflow: hidden;
                    box-shadow: 0 0 10px rgba(80, 174, 255, 0.3), inset 0 0 10px rgba(80, 174, 255, 0.3);
                    -webkit-box-shadow: 0 0 10px rgba(80, 174, 255, 0.3), inset 0 0 10px rgba(80, 174, 255, 0.3);
                    border: 1px solid rgba(51, 133, 204, 0.3);
                    @include border-radius(10px);
                    .btn-edit {
                        position: absolute;
                        left: 20px;
                        top: 10px;
                        background: transparent;
                        box-shadow: none;
                        border: 0;
                        z-index: 1;
                        padding: 10px;
                        &:focus {
                            outline: none;
                        }
                    }
                    .btn-close {
                        position: absolute;
                        right: 20px;
                        top: 10px;
                        background: transparent;
                        box-shadow: none;
                        border: 0;
                        z-index: 1;
                        padding: 10px;
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 50%;
                        // background: -moz-linear-gradient(top, rgba(30, 80, 128, 0) 0%, #1e5080 100%);
                        // background: -webkit-linear-gradient(top, rgba(30, 80, 128, 0) 0%, #1e5080 100%);
                        // background: linear-gradient(to bottom, rgba(30, 80, 128, 0) 0%, #1e5080 100%);
                        background: -moz-linear-gradient(top, rgba(30, 80, 128, 1) 0%, rgba(30, 80, 128, 0.02) 100%);
                        background: -webkit-linear-gradient(top, rgba(30, 80, 128, 1) 0%, rgba(30, 80, 128, 0.02) 100%);
                        background: linear-gradient(to bottom, rgba(30, 80, 128, 1) 0%, rgba(30, 80, 128, 0.02) 100%);
                        @include border-radius(10px);
                    }
                    .team-img {
                        display: block;
                        position: relative;
                        margin: -70px auto 0;
                        float: none;
                        width: 130px;
                        height: 150px;
                        line-height: 110px;
                        background: transparent url(../images/hexagon-team-bg.png) center center no-repeat;
                        background-size: contain;
                        padding: 20px;
                    }
                    h4 {
                        letter-spacing: 1px;
                        font-family: "Montserrat", sans-serif;
                        font-weight: 900;
                        font-size: 20px;
                        background: -moz-linear-gradient(top, #00d8ff 0%, #009eff 100%);
                        background: -webkit-linear-gradient(top, #00d8ff 0%, #009eff 100%);
                        background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
                        background-clip: border-box;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        -moz-background-clip: text;
                        -moz-text-fill-color: transparent;
                        margin: 30px 0;
                        position: relative;
                    }
                    .team-details-col {
                        @extend .section;
                        position: relative;
                        overflow: hidden;
                        padding: 30px;
                        &::before {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 0;
                            @include transform(translate(-50%,
                            -50%));
                            width: 60%;
                            height: 2px;
                            background: #062f56;
                            opacity: 0.8;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            left: 50%;
                            top: 0;
                            width: 60%;
                            height: 45px;
                            background: -moz-radial-gradient( center, ellipse cover, #3385cc 0%, rgba(125, 185, 232, 0) 70%);
                            background: -webkit-radial-gradient( center, ellipse cover, #3385cc 0%, rgba(125, 185, 232, 0) 70%);
                            background: radial-gradient(ellipse at center, #3385cc 0%, rgba(125, 185, 232, 0) 70%);
                            opacity: 0.4;
                            @include transform(translate(-50%,
                            -50%));
                        }
                        table {
                            tr td {
                                border: 0px;
                                text-align: center;
                                color: #009cff;
                                font-size: 14px;
                                font-family: "Montserrat", sans-serif;
                                font-weight: 600;
                                padding: 10px;
                                text-transform: capitalize;
                                vertical-align: middle;
                                &:first-child {
                                    text-align: right;
                                }
                                &:last-child {
                                    text-align: left;
                                }
                                h5 {
                                    color: #fefefe;
                                    font-size: 20px;
                                    font-family: "D-DIN Condensed";
                                    font-weight: normal;
                                    text-transform: capitalize;
                                    letter-spacing: initial;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .title-shadow {
        h2 {
            font-size: 34px;
            position: relative;
            font-family: "Montserrat", sans-serif;
            font-weight: 800;
            letter-spacing: 3px;
            &::after {
                width: 150%;
            }
        }
    }
}

.edit-member-btn {
    cursor: pointer;
}

.fixed {
    .horizontal-tab-raw.section {
        position: fixed;
        left: 0;
        top: 146px;
        .nav-tabs-horizontal {
            background: -moz-linear-gradient( left, rgba(5, 7, 21, 0) 0%, rgba(5, 7, 21, 1) 25%, rgba(5, 7, 21, 1) 51%, rgba(5, 7, 21, 1) 75%, rgba(5, 7, 21, 0) 100%);
            background: -webkit-linear-gradient( left, rgba(5, 7, 21, 0) 0%, rgba(5, 7, 21, 1) 25%, rgba(5, 7, 21, 1) 51%, rgba(5, 7, 21, 1) 75%, rgba(5, 7, 21, 0) 100%);
            background: linear-gradient( to right, rgba(5, 7, 21, 0) 0%, rgba(5, 7, 21, 1) 25%, rgba(5, 7, 21, 1) 51%, rgba(5, 7, 21, 1) 75%, rgba(5, 7, 21, 0) 100%);
        }
        .divide-raw {
            &::before,
            &::after {
                display: none;
            }
        }
    }
    .tabbing-raw {
        .tab-content {
            margin-top: 60px;
        }
    }
}

.personal-info-section {
    .personal-info-back{
        background: url(../images/profile-section-bg.jpg) center center no-repeat;
        background-size: cover;
        width: 100%;
        min-height: 574px;
        border-radius: 10px;
        
        button{
            &.btn-edit{
                position: absolute;
                right: 50px;
                top: 50px;
                background: transparent;
                box-shadow: none;
                border: 0;
                z-index: 1;
                padding: 10px;
            }
            text-transform: uppercase;
            font-size: 12px;
            font-family: "Montserrat", sans-serif;
            color: white;
        }
        
        .left-profile{
            .player-info{
                .img-wrap{
                    width: 58px;
                    height: 58px;
                    img{
                        display: inline-block;
                        border: 2px solid #3385cc;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .information-head{
                    h6{
                        width: calc(100% - 58px);
                        font-size: 20px;
                        float: left;
                        text-align: left;
                        font-family: "Montserrat", sans-serif;
                        font-weight: 700;
                        padding-left: 10px;
                        background: -moz-linear-gradient(top, #00d8ff 0%, #009eff 100%);
                        background: -webkit-linear-gradient(top, #00d8ff 0%, #009eff 100%);
                        background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
                        background-clip: text;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        -moz-background-clip: text;
                        -moz-text-fill-color: transparent;
                        letter-spacing: 1.5px;
                        text-transform: uppercase;
                    }
                }
            }
            .player-info-details{
                ul{
                    width: 100%;
                    float: left;
                    li{
                        display:flex;
                        width: 100%;
                        position: relative;
                        padding-top: 40px;
                        align-items: flex-end;
                        &::before{
                            position: absolute;
                            content: '';
                            background: -moz-linear-gradient(left,  rgba(2,86,148,1) 0%, rgba(4,75,123,0.1) 100%); 
                            background: -webkit-linear-gradient(left,  rgba(2,86,148,1) 0%,rgba(4,75,123,0.1) 100%);
                            background: linear-gradient(to right,  rgba(2,86,148,1) 0%,rgba(4,75,123,0.1) 100%);
                            width: 100%;
                            height: 1px;
                            left: 0;
                            bottom: -20px;
                        }
                        &:last-child{
                            &::before{
                                display: none;
                            }
                        }
                        span{
                            width: auto;
                            color: rgba(255,255,255,0.3);
                            font-family: "Montserrat", sans-serif;
                            font-weight: 300;
                            font-size: 12px;
                        }
                        h6{
                            width: auto;
                            padding-left: 10px;
                            font-size: 18px;
                            font-family: "D-DIN Condensed";
                            font-weight: normal;
                        }
                    }
                }
            }
            button{
                &.btn-edit{
                    position: absolute;
                    right: 0px;
                    top: 130px;
                    background: transparent;
                    box-shadow: none;
                    border: 0;
                    z-index: 1;
                    padding: 10px;
                }
            }
        }
    }
    .list-col {
        .con-box {
            padding: 20px 0;
            position: relative;
            &::before {
                position: absolute;
                right: -15px;
                top: 0;
                height: 100%;
                width: 2px;
                content: "";
                background: transparent url(../images/line-dot.png) center center no-repeat;
            }
            h6 {
                font-size: 20px;
                font-family: "D-DIN Condensed";
                font-weight: normal;
                letter-spacing: 1px;
                background: -moz-linear-gradient(top, #00d8ff 0%, #009eff 100%);
                background: -webkit-linear-gradient(top, #00d8ff 0%, #009eff 100%);
                background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
                background-clip: border-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            }
            h5 {
                position: relative;
                text-transform: uppercase;
                font-size: 30px;
                letter-spacing: 1px;
                color: white;
                font-family: "Montserrat", sans-serif;
                font-weight: 400;
                font-size: 16px;
                padding-top: 20px;
                margin-top: 15px;
                overflow: hidden;
                &::before {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 0;
                    @include transform(translate(-50%,
                    -50%));
                    width: 50%;
                    height: 1px;
                    background: #062f56;
                }
                &::after {
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: -5%;
                    width: 50%;
                    height: 100%;
                    background: -moz-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(125, 185, 232, 0) 70%);
                    background: -webkit-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(125, 185, 232, 0) 70%);
                    background: radial-gradient(ellipse at center, #3385cc 0%, rgba(125, 185, 232, 0) 70%);
                    opacity: 0.6;
                    @include transform(translate(-50%,
                    -50%));
                }
            }
        }
        &:last-child {
            .con-box {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.gaming-plateform-raw {
    padding: 0 50px 50px 120px;
    .con-box {
        padding: 35px 0;
        h5 {
            position: relative;
            text-transform: uppercase;
            font-size: 30px;
            letter-spacing: 1px;
            color: rgba(255,255,255,0.2)!important;
            font-family: "Montserrat", sans-serif;
            font-weight: 700;
            font-size: 16px;
            padding-top: 20px;
            margin-top: 15px;
            overflow: hidden;
            &::before {
                content: "";
                position: absolute;
                left: 50%;
                top: 0;
                @include transform(translate(-50%,
                -50%));
                width: 50%;
                height: 1px;
                background: #062f56;
            }
            &::after {
                content: "";
                position: absolute;
                left: 50%;
                top: -5%;
                width: 50%;
                height: 100%;
                background: -moz-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(125, 185, 232, 0) 70%);
                background: -webkit-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(125, 185, 232, 0) 70%);
                background: radial-gradient(ellipse at center, #3385cc 0%, rgba(125, 185, 232, 0) 70%);
                opacity: 0.6;
                @include transform(translate(-50%,
                -50%));
            }
        }
        .img-box {
            margin: 0;
            width: 100%;
            height: 120px;
            line-height: 120px;
            @include transition(all 0.25s ease 0s);
            img {
                display: inline-block;
                vertical-align: middle;
                @include transition(all 0.25s ease 0s);
                @include transform(scale(1));
            }
            &:hover {
                @include transform(scale(1.1));
                @include transition(all 0.25s ease 0s);
            }
        }
    }
    .list-col {
        background: transparent url(../images/Line-dot-1.png) right bottom 20px no-repeat;
        position: relative;
        &:last-child {
            background: none !important;
            &::after {
                display: none;
            }
            &::before {
                @include transform(rotate(180deg));
                background-position: right 15px center;
            }
        }
        &:nth-child(2),
        &:nth-child(3) {
            &::before {
                background: transparent url(../images/Line-3.png) bottom center no-repeat;
                background-size: 90%;
            }
        }
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            background: transparent url(../images/Line-dot-2.png) right 20px center no-repeat;
            width: 100%;
            height: 2px;
        }
        &::after {
            width: 4px;
            height: 4px;
            content: "";
            background: #009cff;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    .platform-list-row {
        margin-top: 50px;
        .platform-row {
            &:last-child {
                .list-col {
                    &::before,
                    &::after {
                        display: none;
                    }
                }
            }
            &:nth-child(2n) {
                .list-col {
                    background: transparent url(../images/Line-dot-1-reverse.png) right top 15px no-repeat;
                }
            }
        }
    }
}

.team-tournaments-raw {
    .title-shadow {
        margin-bottom: 60px;
    }
    p {
        font-size: 18px;
        color: white;
        font-family: "D-DIN Condensed";
        font-weight: normal;
        letter-spacing: 0.5px;
    }
}

$borderWidth: 10px;
$animationTime: 0.5s;
$border-color-default: #3cff00;
$border-color-fill: #10e6ff;
$size: 160px;
$howManySteps: 100; //this needs to be even.
.progress-circle-raw {
    &.progress {
        width: $size;
        height: $size;
        line-height: $size;
        background: none;
        margin: 0 auto;
        box-shadow: none;
        position: relative;
        overflow: inherit;
        &::before {
            position: absolute;
            left: -10%;
            top: -10%;
            background: rgba(0, 156, 255, 0.1);
            content: "";
            width: 120%;
            height: 120%;
            @include border-radius(100%);
            border: 1px solid rgba(0, 156, 255, 0.2);
        }
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: $borderWidth solid $border-color-default;
            position: absolute;
            top: 0;
            left: 0;
        }
        >span {
            width: 50%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            z-index: 1;
        }
        .progress-left {
            left: 0;
        }
        .progress-bar {
            width: 100%;
            height: 100%;
            background: none;
            border-width: $borderWidth;
            border-style: solid;
            position: absolute;
            top: 0;
            border-color: $border-color-fill;
        }
        .progress-left .progress-bar {
            left: 100%;
            border-top-right-radius: ($size/2);
            border-bottom-right-radius: ($size/2);
            border-left: 0;
            -webkit-transform-origin: center left;
            transform-origin: center left;
            //animation: loading-2 1.5s linear forwards 1.8s;
        }
        .progress-right {
            right: 0;
            .progress-bar {
                left: -100%;
                border-top-left-radius: ($size/2);
                border-bottom-left-radius: ($size/2);
                border-right: 0;
                -webkit-transform-origin: center right;
                transform-origin: center right;
                //animation: loading-1 1.8s linear forwards;
            }
        }
        .progress-value {
            display: flex;
            border-radius: 50%;
            font-size: 1rem;
            text-align: center;
            line-height: 20px;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
            font-weight: 300;
            >div {
                //margin-top: 10px;
                font-size: 30px;
                font-family: "D-DIN Condensed";
                font-weight: normal;
                padding: 10px;
                &.won-col {
                    color: #10e6ff;
                }
                &.lost-col {
                    color: #3cff00;
                }
                &:first-child {
                    border-right: 1px solid rgba(255, 255, 255, 0.2);
                }
                &:last-child {
                    border-left: 1px solid rgba(255, 255, 255, 0.2);
                }
            }
            span {
                font-size: 12px;
                text-transform: uppercase;
            }
        }
    }
}


/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/

@for $i from 1 through $howManySteps {
    $stepName: ($i * (100 / $howManySteps));
    //animation only the left side if below 50%
    @if $i <=($howManySteps/2) {
        .progress[data-percentage="#{$stepName}"] {
            .progress-right .progress-bar {
                animation: loading-#{$i} $animationTime linear forwards;
            }
            .progress-left .progress-bar {
                animation: 0;
            }
        }
    }
    //animation only the right side if above 50%
    @if $i>($howManySteps/2) {
        .progress[data-percentage="#{$stepName}"] {
            .progress-right .progress-bar {
                animation: loading-#{($howManySteps/2)}$animationTime linear forwards; //set the animation to longest animation
            }
            .progress-left .progress-bar {
                animation: loading-#{$i - ($howManySteps/2)} $animationTime linear forwards $animationTime;
            }
        }
    }
}

//animation
@for $i from 1 through ($howManySteps/2) {
    $degrees: (180/ ($howManySteps/2));
    $degrees: ($degrees * $i);
    @keyframes loading-#{$i} {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate($degrees);
            transform: rotate(#{$degrees}deg);
        }
    }
}

.bottom-shadow {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    font-size: 16px;
    background: -moz-linear-gradient(top, #00d8ff 0%, #009eff 100%);
    background: -webkit-linear-gradient(top, #00d8ff 0%, #009eff 100%);
    background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    position: relative;
    border-bottom: 1px solid rgba(0, 156, 255, 0.5);
    width: auto;
    display: inline-block;
    margin: 30px auto 0;
    float: none;
    text-transform: uppercase;
    padding: 0 0 10px;
    position: relative;
    &::before {
        background: transparent url(../images/sm-shadow.png) bottom center no-repeat;
        content: "";
        position: absolute;
        left: 50%;
        bottom: -20px;
        @include transform(translate(-50%,
        0) rotate(180deg));
        width: 100%;
        height: 20px;
    }
}

.section-team-detail {
    display: none;
    &.show {
        display: block;
    }
    .team-details-col {
        text-align: left;
        .img-box {
            display: inline-block;
            vertical-align: middle;
            img {
                border: 2px solid #3385cc;
                @include border-radius(100%);
                width: 70px;
                display: inline-block;
                vertical-align: middle;
            }
        }
        .member-con {
            display: inline-block;
            vertical-align: middle;
            padding-left: 20px;
            h5 {
                font-family: "D-DIN Condensed";
                font-size: 20px;
                font-weight: normal;
            }
            h6 {
                color: #009cff;
                font-size: 12px;
                font-weight: 700;
                padding-top: 2px;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
    .the-team-raw {
        .row {
            margin-right: -40px;
            margin-left: -40px;
            .team-li {
                padding-right: 40px;
                padding-left: 40px;
            }
        }
    }
}

.trophy-raw {
    text-align: center;
    padding: 0 100px;
    .trophy-li {
        margin-top: 100px;
        .trophy-col {
            padding: 30px;
            @extend .section;
            background: #060919;
            position: relative;
            border: 2px solid rgba(51, 133, 204, 0.3);
            @include border-radius(14px);
            &::before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 80%;
                background: -moz-linear-gradient(top, rgba(30, 80, 128, 0) 0%, rgba(30, 80, 128, 1) 100%);
                background: -webkit-linear-gradient(top, rgba(30, 80, 128, 0) 0%, rgba(30, 80, 128, 1) 100%);
                background: linear-gradient(to bottom, rgba(30, 80, 128, 0) 0%, rgba(30, 80, 128, 1) 100%);
                @include border-radius(12px);
                // opacity: .4;
            }
            .trophy-img {
                position: relative;
                text-align: center;
                margin: -80px auto 30px;
                max-width: 180px;
                padding: 0 30px;
                &::before {
                    background: -moz-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 67%, rgba(51, 133, 204, 0) 100%);
                    background: -webkit-radial-gradient( center, ellipse cover, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 67%, rgba(51, 133, 204, 0) 100%);
                    background: radial-gradient( ellipse at center, rgba(51, 133, 204, 1) 0%, rgba(51, 133, 204, 0) 67%, rgba(51, 133, 204, 0) 100%);
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 70%;
                    height: 160%;
                    content: "";
                    opacity: 0.4;
                    @include transform(translate(-50%,
                    -50%));
                }
                img {
                    position: relative;
                    display: inline-block;
                    margin: 0 auto;
                }
            }
            h5 {
                background: -moz-linear-gradient(top, #80ebff 0%, #039eff 100%);
                background: -webkit-linear-gradient(top, #80ebff 0%, #039eff 100%);
                background: linear-gradient(to bottom, #80ebff 0%, #039eff 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                font-family: "D-DIN Condensed";
                font-weight: bold;
                font-size: 24px;
                position: relative;
            }
            .devider {
                height: 1px;
                width: calc(100% - 40%);
                background: #3385cc;
                margin: 0 auto;
                display: block;
                clear: both;
                position: relative;
            }
            h6 {
                font-family: "D-DIN Condensed";
                font-weight: bold;
                font-size: 16px;
                color: white;
                position: relative;
                margin: 10px 0;
            }
        }
    }
}

.wallet-raw {
    .wallet-ul {
        text-align: center;
        .wallet-li {
            display: inline-block;
            width: 30%;
            position: relative;
            vertical-align: top;
            text-align: center;
            &::before {
                height: 140px;
                width: 2px;
                position: absolute;
                right: 0;
                top: 30px;
                content: "";
                background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
                background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
                background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
                opacity: 0.6;
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
            .box-conn {
                width: 220px;
                display: block;
                margin: 0 auto;
                float: none;
                h5 {
                    background: -webkit-linear-gradient(#00d3ff, #3385cc);
                    background-clip: border-box;
                    background-clip: border-box;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
                    font-size: 22px;
                    letter-spacing: 3px;
                    font-family: 'D-DIN Condensed';
                    font-weight: bold;
                    position: relative;
                    margin: 10px 0 30px;
                    padding-bottom: 10px;
                    text-transform: uppercase;
                    &::before {
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        @include transform(translate(-50%,
                        0));
                        width: 25px;
                        height: 2px;
                        content: "";
                        background: #077796;
                    }
                }
                .btn-main {
                    font-size: 14px;
                }
                p {
                    font-size: 16px;
                    font-family: 'D-DIN Condensed';
                    font-weight: normal;
                    color: white;
                    letter-spacing: 1px;
                    line-height: 140%;
                }
                .form-control {
                    text-align: center;
                    background: rgba(0, 0, 0, 0.4);
                    &::-webkit-input-placeholder {
                        color: rgba(255, 255, 255, 0.1);
                        text-align: center;
                    }
                    &::-moz-placeholder {
                        color: rgba(255, 255, 255, 0.1);
                        text-align: center;
                    }
                    &:-ms-input-placeholder {
                        color: rgba(255, 255, 255, 0.1);
                        text-align: center;
                    }
                    &:-moz-placeholder {
                        color: rgba(255, 255, 255, 0.1);
                        text-align: center;
                    }
                }
            }
        }
    }
}

.filter-list-col {
    margin-top: 50px;
    tr {
        th {
            background: #00d8ff;
            background: -moz-linear-gradient(top, #00d8ff 1%, #009cff 100%);
            background: -webkit-linear-gradient(top, #00d8ff 1%, #009cff 100%);
            background: linear-gradient(to bottom, #00d8ff 1%, #009cff 100%);
            font-size: 20px;
            font-family: "D-DIN Condensed";
            font-weight: bold;
            position: relative;
            &::before {
                position: absolute;
                content: "";
                background: #00d3ff;
                width: 1px;
                height: 80%;
                top: 50%;
                right: 0;
                @include transform(translate(-50%,
                -50%));
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
        }
        td {
            font-size: 20px;
            text-transform: capitalize;
        }
    }
}

.achievements-raw {
    &.pt10 {
        padding-top: 40px !important;
    }
    .row {
        margin-left: -25px;
        margin-right: -25px;
        .achievements-li {
            padding-left: 25px;
            padding-right: 25px;
            margin-top: 50px;
        }
    }
    .achievements-li {
        .achievements-col {
            text-align: center;
            position: relative;
            background: transparent url(../images/achive-hexa.png) no-repeat;
            background-size: 100% 100%;
            padding: 20px 0 50px;
            .img-box {
                position: relative;
                padding: 50px 0 30px;
                background: transparent url(../images/achive-hexa-bg.png) center center no-repeat;
                background-size: 85% 100%;
                &::before {
                    background: -moz-radial-gradient( center, ellipse cover, rgba(239, 170, 54, 1) 0%, rgba(239, 170, 54, 0) 60%, rgba(239, 170, 54, 0) 0%);
                    background: -webkit-radial-gradient( center, ellipse cover, rgba(239, 170, 54, 1) 0%, rgba(239, 170, 54, 0) 60%, rgba(239, 170, 54, 0) 0%);
                    background: radial-gradient( ellipse at center, rgba(239, 170, 54, 1) 0%, rgba(239, 170, 54, 0) 60%, rgba(239, 170, 54, 0) 0%);
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    @include transform(translate(-50%,
                    -50%));
                    width: 100%;
                    height: 100%;
                    opacity: 0.3;
                }
                .reflect {
                    margin: 0 auto;
                    position: relative;
                }
            }
            h3 {
                text-align: center;
                font-size: 30px;
                letter-spacing: 2px;
                color: white;
                font-family: "D-DIN Condensed";
                font-weight: bold;
                line-height: unset;
                margin-top: 20px;
                position: relative;
                text-shadow: 0px 0px 30px #00d4ff;
                -webkit-text-shadow: 0px 0px 30px #00d4ff;
                span {
                    font-size: 14px;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                }
            }
        }
        .conn {
            text-align: center;
            p {
                font-size: 22px;
                color: white;
                font-family: "D-DIN Condensed";
                font-weight: normal;
                line-height: 120%;
                margin: 20px 0;
                strong {
                    font-weight: bold;
                    font-family: "D-DIN Condensed";
                }
            }
            h4 {
                font-family: "D-DIN Condensed";
                font-weight: normal;
                font-size: 30px;
                color: rgba(255, 255, 255, 0.2);
                border: 1px solid #3385cc;
                @include border-radius(4px);
                background: rgb(18, 30, 88);
                background: -moz-linear-gradient(top, rgba(18, 30, 88, 1) 0%, rgba(11, 18, 51, 1) 76%);
                background: -webkit-linear-gradient(top, rgba(18, 30, 88, 1) 0%, rgba(11, 18, 51, 1) 76%);
                background: linear-gradient(to bottom, rgba(18, 30, 88, 1) 0%, rgba(11, 18, 51, 1) 76%);
                width: auto;
                float: none;
                display: inline-block;
                padding: 0 40px;
                position: relative;
                height: 60px;
                line-height: 58px;
                margin-top: 20px;
                min-width: 170px;
                &::before {
                    background: transparent url(../images/sm-shadow.png) bottom center no-repeat;
                    background-size: contain;
                    content: "";
                    position: absolute;
                    left: 50%;
                    top: -20px;
                    @include transform(translate(-50%,
                    0));
                    width: 90%;
                    height: 20px;
                }
                &::after {
                    background: transparent url(../images/sm-shadow.png) bottom center no-repeat;
                    background-size: contain;
                    content: "";
                    position: absolute;
                    left: 50%;
                    bottom: -20px;
                    @include transform(translate(-50%,
                    0) rotate(180deg));
                    width: 90%;
                    height: 20px;
                }
                strong {
                    font-weight: bold;
                    font-family: "D-DIN Condensed";
                    background: -moz-linear-gradient(top, #00d8ff 0%, #009eff 100%);
                    background: -webkit-linear-gradient(top, #00d8ff 0%, #009eff 100%);
                    background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
                    background-clip: border-box;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -moz-background-clip: text;
                    -moz-text-fill-color: transparent;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.tournament-details-col {
    .inner-banner .container-fluid .inner-banner-con {
        top: 65%;
        p {
            font-size: 24px;
            color: white;
            font-family: "D-DIN";
            font-weight: normal;
        }
    }
    ul {
        text-align: center;
        li {
            display: inline-block;
            text-align: center;
            padding: 20px;
            margin: 10px 0;
            color: rgba(255, 255, 255, 0.3);
            font-size: 16px;
            font-family: "D-DIN Condensed";
            font-weight: normal;
            position: relative;
            &::before {
                position: absolute;
                right: 0;
                top: 50%;
                content: "";
                width: 2px;
                height: 30px;
                background: #00c7ff;
                @include transform(translate(-50%,
                0%));
                @include border-radius(5px);
                @include box-shadow(0 0 8px #1f292d);
            }
            &:last-child {
                &::before {
                    display: none;
                }
            }
            strong {
                @extend .section;
                color: white;
                padding-top: 12px;
                letter-spacing: 1px;
                font-size: 20px;
                font-family: "D-DIN Condensed";
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }
    .btn-ac {
        margin-top: 20px;
    }
    .join-btn {
        background-color: rgba(0, 156, 255, 0);
        border: 2px solid #009cff;
        min-width: 160px;
        color: white;
        font-family: "D-DIN Condensed";
        font-weight: bold;
        text-transform: uppercase;
        position: relative;
        padding-left: 30px;
        letter-spacing: 1px;
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
        &:hover {
            background-color: rgba(0, 156, 255, 1);
        }
        i {
            width: 28px;
            height: 28px;
            @include border-radius(100px);
            background: #009cff url(../images/ic-plus.png) center center no-repeat;
            display: inline-block;
            position: absolute;
            left: 4px;
            top: 4px;
        }
    }
    .btm-btns {
        .btn-ac {
            min-width: 200px;
            background: #009cff;
            &:hover {
                background: #007cdf;
            }
        }
    }
}

.tournament-details-raw {
    table {
        position: relative;
        tr {
            position: relative;
            td {
                border: 0px;
                text-align: center;
                color: #009cff;
                font-size: 14px;
                font-family: "Montserrat", sans-serif;
                font-weight: 600;
                padding: 10px;
                text-transform: capitalize;
                vertical-align: middle;
                &:first-child {
                    text-align: right;
                }
                &:last-child {
                    text-align: left;
                }
            }
            th {
                span {
                    white-space: nowrap;
                }
            }
        }
        &.first-col {
            text-align: center;
            tr th {
                text-align: center;
                font-size: 22px;
                font-weight: 700;
            }
            &::before {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                top: 0;
                left: 0;
                background: #070919 url(../images/table-th-line.png) top center no-repeat;
                z-index: 1;
            }
            &::after {
                content: "";
                width: 100%;
                height: 1px;
                position: absolute;
                bottom: 0;
                left: 0;
                background: #070919 url(../images/table-th-line.png) bottom center no-repeat;
                z-index: 1;
            }
        }
        &.second-table {
            tr {
                td {
                    &:first-child,
                    &:last-child {
                        width: 45%;
                    }
                    &.desh {
                        width: 10%;
                        position: relative;
                        &::before {
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            content: "";
                            background: #95eeff;
                            height: 1px;
                            width: 15px;
                            box-shadow: 0 0 14px #009cff;
                            @include transform(translate(-50%,
                            -50%));
                        }
                    }
                    color: white;
                    font-family: "D-DIN Condensed";
                    font-size: 20px;
                    font-weight: normal;
                    border-top: 1px solid #002335;
                }
                &:first-child {
                    td {
                        border-top: 0px;
                    }
                }
            }
        }
    }
}

.time-date-col {
    text-align: center;
    .timesate-li {
        >span {
            margin-bottom: 15px;
            display: inline-block;
        }
        .box-raw {
            background: #00518a;
            border: 2px solid #50aeff;
            border-radius: 10px;
            padding: 25px 20px;
            min-height: 105px;
            span {
                color: white;
                font-size: 16px;
                text-transform: uppercase;
            }
            h4 {
                font-family: "D-DIN Condensed";
                font-weight: bold;
                letter-spacing: 0.5px;
                line-height: 120%;
                background: -webkit-linear-gradient(#84ecff, #009cff);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
            }
        }
        &::before {
            position: absolute;
            content: "";
            left: 100%;
            top: 15%;
            background: white;
            width: 70%;
            height: 2px;
            @include box-shadow(0 0 10px white);
            @include transition(all 0.25s ease 0s);
            @include transform(translate(-50%,
            -50%));
        }
        &:last-child {
            &::before {
                display: none;
            }
        }
    }
}

.tournament-team-col {
    .the-team-raw {
        .row {
            margin-right: -25px;
            margin-left: -25px;
            .team-li {
                padding-left: 25px;
                padding-right: 25px;
            }
        }
    }
}

.upcoming-tournament-raw {
    .leader-list-raw {
        h3 {
            font-size: 42px;
            font-family: "D-DIN Condensed";
            font-weight: normal;
            letter-spacing: 0.5px;
            line-height: 120%;
            background: -webkit-linear-gradient(#84ecff, #009cff);
            text-transform: uppercase;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            margin: 0 0 40px;
        }
    }
    .leader-list-col {
        table {
            tr {
                td {
                    //white-space: nowrap;
                    text-transform: capitalize;
                    letter-spacing: 0.5px;
                }
            }
        }
    }
    .buttons-col {
        text-align: center;
        .nav-tabs {
            display: inline-block;
            margin-top: 10px;
            float: none;
            text-align: center;
            border: 0px;
            li.nav-item {
                display: inline-block;
                a {
                    min-width: 200px;
                }
            }
        }
        .btn-main {
            margin-left: 10px;
            margin-right: 10px;
            span {
                padding-left: 25px;
            }
            &.ic-round {
                span {
                    background: transparent url(../images/ic-first-round.png) left center no-repeat;
                }
            }
            &.ic-winner {
                span {
                    background: transparent url(../images/ic-winner.png) left center no-repeat;
                }
            }
            &.active,
            &:hover,
            &:focus {
                background-color: rgba(0, 156, 255, .4) !important;
                border-width: 2px;
            }
        }
    }
}

.winners-col {
    .win-img {
        position: relative;
        margin: -60px 0 0;
    }
    .winner-li {
        .win-img {
            margin-top: -110px;
        }
        .team-details-col {
            margin-top: 0px;
        }
    }
    .team-details-col {
        margin-top: 25px;
        &::before,
        &::after {
            display: none;
        }
        table {
            tr {
                position: relative;
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    background: transparent url(../images/shadow-btm.png) top center no-repeat;
                    content: "";
                    width: 100%;
                    height: 15px;
                }
                &:first-child {
                    &::before {
                        background-image: url(../images/border-shadow.png);
                    }
                }
            }
        }
    }
}

.timeline {
    width: 100%;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    .events {
        position: relative;
        width: 100%;
        text-align: center;
        ul {
            list-style: none;
            position: relative;
            &::before {
                position: absolute;
                left: 0;
                top: 8px;
                height: 2px;
                width: 100%;
                content: "";
                background: rgb(0, 122, 10);
                background: -moz-linear-gradient(left, rgba(0, 122, 10, 1) 0%, rgba(119, 115, 10, 1) 66%, rgba(119, 122, 129, 1) 86%);
                background: -webkit-linear-gradient(left, rgba(0, 122, 10, 1) 0%, rgba(119, 115, 10, 1) 66%, rgba(119, 122, 129, 1) 86%);
                background: linear-gradient(to right, rgba(0, 122, 10, 1) 0%, rgba(119, 115, 10, 1) 66%, rgba(119, 122, 129, 1) 86%);
            }
            li {
                display: inline-block;
                width: 24.65%;
                margin: 0;
                padding: 0;
                &.winner-li {
                    a {
                        line-height: 28px;
                        box-shadow: 0 0 10px inset rgba(255, 255, 255, 0.5);
                        -webkit-box-shadow: 0 0 10px inset rgba(255, 255, 255, 0.5);
                    }
                    h5 {
                        strong {
                            text-shadow: 0px 0px 10px white;
                        }
                    }
                }
                a {
                    text-decoration: none;
                    position: relative;
                    top: -6px;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    display: inline-block;
                    @include border-radius(100px);
                    font-family: 'D-DIN Condensed';
                    font-weight: normal;
                    z-index: 111;
                }
                h5 {
                    strong {
                        font-family: 'D-DIN Condensed';
                        font-weight: bold;
                        font-size: 16px;
                    }
                    font-family: 'D-DIN Condensed';
                    font-weight: normal;
                    font-size: 14px;
                    letter-spacing: 1.5px;
                    text-transform: uppercase;
                }
                &:first-child,
                &:nth-child(2) {
                    a {
                        background: #001511;
                        color: #00ff0c;
                    }
                    h5 {
                        color: #00ff0c;
                        // strong {}
                    }
                }
                &:nth-child(3) {
                    a {
                        background: #1a1d11;
                        color: #fff000;
                    }
                    h5 {
                        color: #fff000;
                        // strong {}
                    }
                }
                &:nth-child(4) {
                    a {
                        background: #272a37;
                        color: white;
                    }
                    h5 {
                        color: white;
                        // strong {}
                    }
                }
            }
        }
    }
}

.tree {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-flex-direction: row-reverse;
    flex-direction: row-reverse;
    >ul>li {
        padding: 0px !important;
    }
    ul {
        padding-right: 20px;
        position: relative;
        @include transition(all 0.5s);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        &.last-child {
            padding-top: 10px;
            padding-bottom: 10px;
            li {
                padding-top: 0px;
                padding-bottom: 0px;
                &:first-child {
                    a {
                        @include border-radius(25px 25px 0 0);
                        border-bottom: 2px solid #06091a;
                    }
                }
                &:nth-child(2) {
                    a {
                        @include border-radius(0 0 25px 25px);
                        border-top: 2px solid #06091a;
                    }
                }
            }
        }
        li {
            text-align: center;
            list-style-type: none;
            position: relative;
            padding: 5px 20px 5px 0px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
            @include transition(all 0.5s);
            &::before,
            &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: 50%;
                border-right: 2px solid #005a90;
                width: 20px;
                height: 50%;
            }
            &::after {
                bottom: auto;
                top: 50%;
                border-top: 2px solid #005a90;
            }
            &:only-child::after,
            &:only-child::before {
                display: none;
            }
            &:only-child {
                padding-left: 0;
            }
            &:first-child::before,
            &:last-child::after {
                border: 0 none;
            }
            &:last-child::before {
                border-bottom: 2px solid #005a90;
            }
            a {
                background: #000b28;
                color: rgba(255, 255, 255, 0.2);
                padding: 12px 50px;
                text-decoration: none;
                font-family: 'D-DIN Condensed';
                font-weight: normal;
                font-size: 16px;
                text-transform: uppercase;
                display: inline-block;
                -ms-flex-item-align: center;
                -ms-grid-row-align: center;
                align-self: center;
                @include border-radius(25px);
                @include transition(all 0.5s);
                white-space: nowrap;
                min-width: 230px;
                position: relative;
                letter-spacing: 1px;
                text-align: left;
                &:hover,
                &:hover+ul li a {
                    background: #009cff;
                    color: white;
                }
                &:hover+ul li::after,
                &:hover+ul li::before,
                &:hover+ul::before,
                &:hover+ul ul::before {
                    border-color: #94a0b4;
                }
                i {
                    font-style: inherit;
                    width: 30px;
                    height: 30px;
                    line-height: 28px;
                    text-align: center;
                    @include border-radius(100px);
                    position: absolute;
                    text-align: center;
                    font-size: 14px;
                    &.ic-user {
                        left: 6px;
                        top: 6px;
                        border: 1px solid #009cff;
                        background: #333c53 url(../images/ic-user-icon1.png) center center no-repeat;
                    }
                    &.ic-sts {
                        right: 6px;
                        top: 6px;
                        line-height: 30px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        &.r2c {
                            background: #001a24;
                            color: #00ff0c;
                        }
                        &.frc {
                            background: #1a2224;
                            color: #fff000;
                        }
                    }
                }
                &.win-tree {
                    background: rgb(0, 156, 255);
                    background: -moz-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
                    background: -webkit-linear-gradient(left, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
                    background: linear-gradient(to right, rgba(0, 156, 255, 1) 0%, rgba(0, 216, 255, 1) 100%);
                    color: white;
                    @include box-shadow(0 0 20px rgba(255,
                    255,
                    255,
                    0.3));
                    i {
                        &.ic-user {
                            background-color: rgba(255, 255, 255, 0.8);
                            background-image: url(../images/ic-user-icon.png);
                        }
                        &.winc {
                            background: transparent url(../images/ic-winner.png) center center no-repeat;
                        }
                    }
                }
            }
        }
        ul::before {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            border-top: 2px solid #005a90;
            width: 20px;
            height: 0;
        }
    }
}


/*---------------------about-us-start----------------------*/

.about-main-raw {
    background-image: none;
    .about-inner-banner {
        background-repeat: no-repeat;
        background-position: top center;
        background-size: cover;
    }
    .about-intro-raw {
        text-align: center;
        position: relative;
        padding: 0px 32%;
        p {
            font-size: 16px;
            font-family: "D-DIN Condensed";
            font-weight: normal;
            color: white;
            line-height: 140%;
            letter-spacing: 0.8px;
        }
    }
    .banner-con {
        margin: 450px 0 50px;
        .img-box {
            margin-bottom: 20px;
            a {
                position: relative;
                height: 100%;
                display: inline-block;
                width: auto;
                float: none;
                margin: 0 auto;
                img {
                    width: 262px;
                    height: 148px;
                    @include border-radius(10px);
                    position: relative;
                    margin-bottom: 20px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 45%;
                    left: 50%;
                    width: 85px;
                    height: 85px;
                    background: transparent url(../images/video-play.png) center center no-repeat;
                    @include transform(translate(-50%,
                    -50%));
                    background-size: contain;
                }
                &::before {
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    content: "";
                    background: transparent url(../images/video-light.png) center top no-repeat;
                    width: 100%;
                    height: 16px;
                    @include transform(translate(-50%,
                    0));
                }
            }
            .light-below {
                margin-top: 5px;
            }
        }
    }
}

.myImg {
    position: relative;
    border: 0 none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    .panel-image-preview {
        width: 100%;
        margin-bottom: 10px;
    }
    .caption {
        /* width: inherit; */
        bottom: 230px;
        position: absolute;
        text-align: center;
        width: 100%;
        .glow {
            color: #fff;
            text-shadow: 3px 1px 0px #000000;
            .sub-head-j {
                font: 400 29px/35px 'Oswald', sans-serif;
                color: #ffffff;
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .sub-head-k {
                font: 400 23px/30px 'Oswald', sans-serif;
                color: #7dc1fa;
                letter-spacing: 5px;
            }
        }
    }
}

/*---------------------about-us-end----------------------*/


/*---------------------about-us-end----------------------*/


/*-----------notification start--------------*/

.notification-banner-rawm {
    .inner-banner {
        height: 450px;
    }
}

.notification-raw {
    .btn-group .btn {
        transition: background-color .3s ease;
        float: left;
        &.btn-filter {
            input {
                position: relative;
                z-index: 999999;
                cursor: pointer;
            }
        }
    }
    .panel-table .panel-body {
        padding: 0;
    }
    .table>thead>tr>th {
        border-bottom: none;
        border-top: 1px solid #03718c;
        border-bottom: 1px solid #03718c;
        background: transparent;
        &::after {
            display: none !important;
        }
    }
    .panel-footer,
    .panel-table .panel-body .table-bordered {
        border-style: none;
        margin: 0;
    }
    .panel-table .panel-body .table-bordered>thead>tr>th:first-of-type {
        text-align: center;
        width: 50px;
    }
    .panel-table .panel-body .table-bordered>thead>tr>th.col-tools {
        text-align: center;
        width: 120px;
    }
    .panel-table .panel-body .table-bordered>thead>tr>th:last-of-type,
    .panel-table .panel-body .table-bordered>tbody>tr>td:last-of-type {
        border-right: 0;
    }
    .panel-table .panel-body .table-bordered>thead>tr>th:first-of-type,
    .panel-table .panel-body .table-bordered>tbody>tr>td:first-of-type {
        border-left: 0;
    }
    .panel-table .panel-body .table-bordered>tbody>tr:first-of-type>td {
        border-bottom: 0;
    }
    .panel-table .panel-body .table-bordered>thead>tr:first-of-type>th {
        border-top: 0;
    }
    .notification-table-col {
        position: relative;
        z-index: 1;
        .date-action-col {
            .select-game {
                max-width: 150px;
                input {
                    padding-right: 25px;
                    background-image: url(../images/ic-calendar.png);
                    background-position: center right 5px;
                    background-repeat: no-repeat;
                    background-size: 18px;
                }
            }
            .select-col {
                margin-left: 35px;
                position: relative;
                // &::before {
                //     position: absolute;
                //     content: "";
                //     right: 17px;
                //     bottom: 6px;
                //     width: 0;
                //     height: 0;
                //     border-left: 10px solid transparent;
                //     border-right: 10px solid transparent;
                //     border-top: 9px solid #009cff;
                //     border-radius: 0;
                // }
                select.form-control {
                    height: 40px;
                    background: #009cff url(../images/ic-select.png) right 20px center no-repeat;
                    color: white;
                    appearance: none;
                    -webkit-appearance: none;
                    border: 0px;
                    padding: 0 40px 0 15px;
                    min-width: 160px;
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 0.5px;
                    cursor: pointer;
                }
            }
        }
        /* .table-list-notifies {
            // margin-top: 0;
            // border-collapse: separate;
            // border-spacing: 0 0.4em;
        } */
        tbody {
            tr {
                background: rgb(0, 33, 55);
                background: -moz-linear-gradient(left, rgba(0, 33, 55, 1) 0%, rgba(0, 47, 78, 1) 100%);
                background: -webkit-linear-gradient(left, rgba(0, 33, 55, 1) 0%, rgba(0, 47, 78, 1) 100%);
                background: linear-gradient(to right, rgba(0, 33, 55, 1) 0%, rgba(0, 47, 78, 1) 100%);
                td {
                    border: 0px;
                    // &:first-child {
                    // 	@include border-radius(5px 0 0 5px);
                    // }
                    // &:last-child {
                    // 	@include border-radius(0 5px 5px 0);
                    // }
                }
            }
        }
    }
}

.table-list-notifies {
    margin-top: 0;
    border-collapse: separate;
    border-spacing: 0 0.4em;
    .date-time-col {
        text-align: right;
        font-family: 'Montserrat', sans-serif;
        color: rgba(255, 255, 255, 0.30);
        text-transform: capitalize;
        font-size: 12px;
        vertical-align: middle;
        letter-spacing: 0.5px;
    }
    .check-col {
        text-align: center;
        vertical-align: middle;
    }
    .con-col {
        .conn {
            display: flex;
            align-items: center;
            .img-fluid {
                width: 56px;
                height: 56px;
                border: 2px solid #3385cc;
                @include border-radius(100px);
                margin-right: 20px;
            }
            h6 {
                font-size: 20px;
                color: white;
                font-family: 'D-DIN Condensed';
                font-weight: bold;
                letter-spacing: 0.5px;
                text-transform: capitalize;
                line-height: 100%;
                span {
                    font-size: 12px;
                    font-family: 'Montserrat', sans-serif;
                    letter-spacing: unset;
                    color: rgba(255, 255, 255, 0.3);
                    padding: 0;
                    font-weight: 400;
                }
            }
        }
    }
}

@supports(-webkit-appearance: none) or (-moz-appearance: none) {
    input[type='checkbox'].checkbox-circle {
        --active: #009cff;
        --active-inner: white;
        --focus: 2px rgba(39, 94, 254, .3);
        --border: #BBC1E1;
        --border-hover: #009cff;
        --background: transparent;
        --disabled: #F6F8FF;
        --disabled-inner: #E1E6F9;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        height: 25px;
        outline: none;
        display: inline-block;
        vertical-align: top;
        position: relative;
        z-index: 1;
        margin: 0;
        cursor: pointer;
        border: 1px solid #3385cc;
        background: var(--b, var(--background));
        transition: background .3s, border-color .3s, box-shadow .2s;
        &:after {
            content: '';
            display: block;
            left: 0;
            top: 0;
            position: absolute;
            transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
        }
        &:checked {
            --b: var(--active);
            --bc: var(--active);
            --d-o: .3s;
            --d-t: .6s;
            --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
        }
        &:disabled {
            --b: var(--disabled);
            cursor: not-allowed;
            opacity: .9;
            &:checked {
                --b: var(--disabled-inner);
                --bc: var(--border);
            }
        }
        &:hover {
            &:not(:checked) {
                &:not(:disabled) {
                    --bc: var(--border-hover);
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0 var(--focus);
        }
        &:not(.switch) {
            width: 25px;
            &:after {
                opacity: var(--o, 0);
            }
            &:checked {
                --o: 1;
            }
        }
    }
    input[type='checkbox'].checkbox-circle {
        &:not(.switch) {
            border-radius: 100px;
            &:after {
                width: 6px;
                height: 12px;
                border: 2px solid var(--active-inner);
                border-top: 0;
                border-left: 0;
                left: 9px;
                top: 5px;
                transform: rotate(var(--r, 20deg));
            }
            &:checked {
                --r: 43deg;
            }
        }
        &.switch {
            width: 38px;
            border-radius: 11px;
            &:after {
                left: 2px;
                top: 2px;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                background: var(--ab, var(--border));
                transform: translateX(var(--x, 0));
            }
            &:checked {
                --ab: var(--active-inner);
                --x: 17px;
            }
            &:disabled {
                &:not(:checked) {
                    &:after {
                        opacity: .6;
                    }
                }
            }
        }
    }
}

.panel-heading {
    .btn-group {
        .btn-filter {
            border: 2px solid #009cff;
            color: white;
            border-radius: 25px !important;
            -webkit-border-radius: 25px !important;
            background: transparent;
            position: relative;
            line-height: 120%;
            padding: 10px 25px;
            margin-right: 10px;
            min-width: 100px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            &:last-child {
                margin-right: 0px;
            }
            &.active {
                background: #009cff;
            }
            &:hover {
                outline: none;
                box-shadow: none;
            }
            &:focus,
            &.focus {
                outline: none;
                box-shadow: none;
            }
            input {
                position: absolute !important;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
    }
}

.modal-border-raw {
    &.modal-raw {
        .modal-dialog {
            max-width: 600px;
            .modal-content {
                border: 2px solid #27678c;
                padding: 5px;
                // .btn-close-modal {}
                .title-raw {
                    h4 {
                        color: white;
                    }
                }
            }
        }
    }
}

// #add-game-id-modal {}

.title-raw {
    &.downarrow {
        &::before {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -15px;
            width: 30px;
            height: 26px;
            @include transform(translate(-50%,
            0));
            background: transparent url(../images/multi-down-arrow.png) center center no-repeat;
        }
    }
    h3 span {
        font-weight: 600;
    }
}

.challenge-sent-modal {
    .title-raw {
        h3 {
            span {
                font-size: 44px;
                line-height: 92%;
            }
        }
    }
    .user-avtar-col {
        width: 150px;
        padding: 15px;
    }
    &.modal-raw .modal-content .modal-body {
        background-color: #0f1949;
        background-image: url(../images/glow-line-box.png);
        background-position: bottom center;
        background-repeat: no-repeat;
        overflow: hidden;
        padding-bottom: 40px;
        &::after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: -70%;
            width: 100%;
            height: 100%;
            background: -moz-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(51, 133, 204, 0) 70%);
            background: -webkit-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(51, 133, 204, 0) 70%);
            background: radial-gradient(ellipse at center, #3385cc 0%, rgba(51, 133, 204, 0) 70%);
            -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
        }
    }
    h5 {
        font-size: 18px;
        font-family: 'D-DIN Condensed';
        font-weight: bold;
        margin: 0 0 20px;
        position: relative;
    }
    h6 {
        text-transform: uppercase;
        font-family: 'D-DIN Condensed';
        font-weight: bold;
        letter-spacing: 2px;
        font-size: 14px;
    }
    h4 {
        text-transform: uppercase;
        font-family: 'D-DIN Condensed';
        font-weight: bold;
        font-size: 22px;
        color: white;
    }
    p {
        color: rgba(255, 255, 255, 0.20);
        font-size: 16px;
        text-transform: uppercase;
        margin-top: 10px;
        font-family: 'D-DIN Condensed';
        font-weight: normal;
    }
}

.challenge-modal-box {
    h3 {
        font-size: 34px;
        color: white;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        span {
            font-size: 44px;
        }
    }
    h4 {
        font-size: 20px;
    }
    .coins-col-box {
        margin: 25px 0 0;
        h4 {
            letter-spacing: 1px;
            text-transform: uppercase;
            color: white;
            font-family: 'D-DIN Condensed';
            font-weight: bold;
            font-size: 24px;
        }
        .coins-col {
            text-align: center;
            margin-top: 55px;
            li {
                display: inline-block;
                margin: 0px 15px 0;
                .img-box {
                    margin-top: -30px;
                    canvas {
                        opacity: 0.4;
                    }
                }
            }
        }
        .btn-main {
            vertical-align: middle;
            background: white;
            color: #00cbff;
            letter-spacing: 1px;
            position: relative;
            border-width: 2px;
            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: transparent;
                border: 2px solid #172649;
                @include border-radius(25px);
            }
            svg {
                width: 24px;
                display: inline-block;
                vertical-align: middle;
                margin-top: -10px;
                margin-right: 5px;
                @include transition(all 0.25s ease 0s);
                // stroke:#00A8FF;
            }
            &:hover {
                svg {
                    .cls-1 {
                        stroke: white;
                    }
                }
            }
        }
        p {
            font-size: 16px;
            color: white;
            font-family: 'D-DIN Condensed';
            font-weight: normal;
            margin-top: 15px;
            letter-spacing: 0.5px;
        }
    }
    .modal-content .modal-body::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: -75%;
        width: 130%;
        height: 100%;
        background: -moz-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(51, 133, 204, 0) 70%);
        background: -webkit-radial-gradient(center, ellipse cover, #3385cc 0%, rgba(51, 133, 204, 0) 70%);
        background: radial-gradient(ellipse at center, #3385cc 0%, rgba(51, 133, 204, 0) 70%);
        @include transform(translate(-50%,
        0));
        opacity: .5;
    }
}


/*---------------notification end---------------*/


/*--------------contact-us-start---------------*/

.mgtp-minus {
    margin-top: -50px;
}

.contact-gaming-raw {
    .contact-line-raw {
        text-align: center;
        ul {
            li {
                width: 100%;
                float: left;
                padding-bottom: 30px;
                .location-raw {
                    background: url(..//images/contact-location.png) no-repeat;
                    background-size: contain;
                    width: 18px;
                    height: 27px;
                    display: inline-block;
                    margin-bottom: 20px;
                }
                .mail-raw {
                    background: url(..//images/contact-mail.png) no-repeat;
                    background-size: contain;
                    width: 22px;
                    height: 15px;
                    display: inline-block;
                }
                .mobile-raw {
                    background: url(..//images/contact-mobile.png) no-repeat;
                    background-size: contain;
                    width: 18px;
                    height: 32px;
                    display: inline-block;
                }
                .contact-text-wrap {
                    margin-bottom: 30px;
                    p {
                        font-size: 18px;
                        color: white;
                        span {
                            text-transform: uppercase;
                            background: #009CFF;
                            background: -webkit-linear-gradient(bottom, #009CFF, #00D8FF);
                            background: -moz-linear-gradient(bottom, #009CFF, #00D8FF);
                            background: linear-gradient(to top, #009CFF, #00D8FF);
                            background-clip: text;
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            -moz-background-clip: text;
                            -moz-text-fill-color: transparent;
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 700;
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
    .contact-sub {
        text-align: center;
        padding: 0% 25%;
        h4 {
            color: white;
            text-transform: uppercase;
            font-size: 26px;
            line-height: 40px;
        }
        .form-group {
            margin-top: 20px;
            @extend .section;
            textarea.form-control {
                height: 100px;
                padding-top: 15px;
                padding-bottom: 15px;
                @include border-radius(5px);
                font-size: 16px;
                letter-spacing: 1px;
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;
                background: rgba(255, 255, 255, 0.01);
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: rgba(255, 255, 255, 0.7);
                    text-align: center;
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: rgba(255, 255, 255, 0.7);
                    text-align: center;
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: rgba(255, 255, 255, 0.7);
                    text-align: center;
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: rgba(255, 255, 255, 0.7);
                    text-align: center;
                }
            }
        }
        .btn-send {
            padding: 18px 36px;
            background: #009cff;
            background: -moz-linear-gradient(left, #009cff 0%, #00d8ff 100%);
            background: -webkit-linear-gradient(left, #009cff 0%, #00d8ff 100%);
            background: linear-gradient(to right, #009cff 0%, #00d8ff 100%);
            font-size: 14px;
            color: white;
            line-height: 0.875em;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            font-weight: 700;
            letter-spacing: 0.5px;
            border-radius: 25px;
            top: -25px;
            position: relative;
        }
    }
}

.contact-us-form {
    width: 100%;
    float: left;
    margin-top: 8%;
    h1 {
        text-transform: uppercase;
        background: #009CFF;
        background: -webkit-linear-gradient(bottom, #009CFF, #00D8FF);
        background: -moz-linear-gradient(bottom, #009CFF, #00D8FF);
        background: linear-gradient(to top, #009CFF, #00D8FF);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        font-weight: 800;
    }
    form {
        padding: 0% 28% 0%;
        .form-group {
            margin-top: 20px;
            margin-bottom: 0px;
            .form-control {
                @include border-radius(5px);
                background: transparent;
                height: 52px;
                background: rgba(6, 9, 25, .40);
                border-color: #036193;
                color: white;
                font-family: 'Montserrat', sans-serif;
                font-size: 14px;
                &:focus {
                    font-weight: 700;
                }
                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: rgba(255, 255, 255, 0.7);
                }
                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: rgba(255, 255, 255, 0.7);
                }
                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: rgba(255, 255, 255, 0.7);
                }
                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: rgba(255, 255, 255, 0.7);
                }
            }
            textarea.form-control {
                height: 100px;
                padding-top: 15px;
                padding-bottom: 15px;
            }
            .btn-send {
                padding: 18px 36px;
                background: #009cff;
                background: -moz-linear-gradient(left, #009cff 0%, #00d8ff 100%);
                background: -webkit-linear-gradient(left, #009cff 0%, #00d8ff 100%);
                background: linear-gradient(to right, #009cff 0%, #00d8ff 100%);
                font-size: 14px;
                color: white;
                line-height: 0.875em;
                text-transform: uppercase;
                font-family: 'Montserrat', sans-serif;
                font-weight: 700;
            }
        }
    }
}


/*------------------contact-us-end------------------*/


/*---------------------popups start------------------*/

.form-col {
    background: transparent url(../images/shadow-btm1.png) center bottom no-repeat;
    padding-bottom: 23px;
    margin-top: 20px;
    .form-control {
        font-size: 12px;
        @include border-radius(25px);
        height: 35px;
    }
    button {
        position: absolute;
        right: 0;
        top: 0;
        background: transparent;
        color: white;
        text-shadow: 0 0 10px rgb(255, 255, 255);
        text-transform: uppercase;
        font-family: 'D-DIN Condensed';
        font-weight: bold;
        letter-spacing: 1px;
        padding: 9px 15px;
        &.search-challenger-col {
            background: transparent url(../images/ic-search-white.png) center center no-repeat;
            height: 35px;
            padding-left: 25px;
            padding-right: 25px;
        }
    }
}

.devider-col {
    background: transparent url(../images/devider-line-shadow.png) center top no-repeat;
    height: 25px;
    position: relative;
    z-index: 1;
    background-size: contain;
    &.reverse-col {
        @include transform(rotate(180deg));
    }
}

.challenger-list-col {
    @extend .section;
    ul {
        li {
            background: rgba(0, 156, 255, 0.6);
            @include border-radius(10px);
            @include box-shadow(0 8px 10px rgba(0,
            0,
            0,
            0.08));
            @extend .section;
            display: flex;
            padding: 20px;
            align-items: center;
            margin-bottom: 8px;
            .avtar {
                width: 55px;
                height: 55px;
                @include border-radius(100px);
                text-align: left;
            }
            .user-con {
                width: 65%;
                text-align: left;
                padding-left: 15px;
                h6 {
                    color: white;
                    font-size: 16px;
                    font-family: 'D-DIN Condensed';
                    font-weight: bold;
                    letter-spacing: 0.4px;
                    &.sub {
                        font-size: 14px;
                        font-family: 'D-DIN Condensed';
                        font-weight: normal;
                        color: rgba(255, 255, 255, 0.5);
                    }
                }
            }
            .sts {
                width: 20%;
                font-size: 10px;
                color: white;
                font-family: 'Montserrat', sans-serif;
                text-align: right;
                i {
                    width: 10px;
                    height: 10px;
                    background: #06ff00;
                    @include border-radius(100px);
                    display: inline-block;
                    margin-right: 2px;
                    margin-bottom: -1px;
                }
            }
        }
    }
}

.find-challenge-modal,
.choose-team-modal {
    .modal-content .modal-body {
        padding-bottom: 0px;
    }
}

.choose-team-modal {
    .form-col {
        button.search-challenger-col {
            background-image: url(../images/search-ic.png);
            background-size: 26px;
            padding-left: 18px;
            padding-right: 18px;
        }
    }
    .challenger-list-col {
        margin-top: -23px;
        padding: 0 0px 0 25px;
        ul li {
            background: #002e5c;
        }
    }
    .mCSB_scrollTools {
        padding: 20px 0 !important;
        .mCSB_buttonUp {
            background-image: url(../images/scroll-up.png);
            background-position: center center;
            margin-top: -20px;
        }
        .mCSB_buttonDown {
            background-image: url(../images/scroll-down.png);
            background-position: center center;
        }
        .mCSB_draggerRail {
            background-color: rgba(255, 255, 255, .20);
        }
        .mCSB_dragger .mCSB_dragger_bar {
            background: white;
        }
    }
    .devider-col {
        margin-top: 25px
    }
}

.btn-main.btn-two-line {
    vertical-align: middle;
    background: #00d8ff;
    background: -moz-linear-gradient(left, #00d8ff 0%, #009cff 100%);
    background: -webkit-linear-gradient(left, #00d8ff 0%, #009cff 100%);
    background: linear-gradient(to right, #00d8ff 0%, #009cff 100%);
    color: white;
    letter-spacing: 1px;
    position: relative;
    border-width: 2px;
    font-size: 16px;
    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        border: 2px solid #172649;
        @include border-radius(25px);
    }
}

.create-team-modal {
    &.modal-border-raw.modal-raw .modal-dialog {
        max-width: 950px;
    }
    .modal-form {
        margin-top: -23px;
        .user-avtar-col {
            width: 170px;
            height: 170px;
            background-image: url(../images/polygon-bg.png);
            background-size: contain;
            margin-bottom: 20px;
            .upload-img {
                width: 30px;
                height: 30px;
                @include border-radius(100px);
                background: white url(../images/edit-image.png) center center no-repeat;
                position: absolute;
                right: 0;
                top: 25px;
                padding: 13px;
                @include box-shadow(0 0 20px rgba(0,
                0,
                0,
                0.40));
            }
        }
        .form-grop,
        .section-title {
            text-align: left;
            @extend .section;
            margin-bottom: 25px;
            label {
                font-size: 14px;
                color: #009cff;
                font-family: 'D-DIN';
                font-weight: bold;
                text-transform: uppercase;
                margin: 0;
                padding-left: 15px;
            }
            p {
                font-family: 'D-DIN Condensed';
                font-weight: normal;
                font-size: 14px;
                color: rgba(255, 255, 255, 0.5);
                line-height: 140%;
                padding-left: 15px;
            }
            .form-control {
                height: 40px;
                border: 3px solid #565e7a;
                @include border-radius(25px);
                background: white;
                margin-top: 10px;
                color: #333333;
                font-family: 'D-DIN Condensed';
                font-weight: normal;
                padding: 0 15px;
            }
            select.form-control {
                background-image: url(../images/select-icon.png);
                background-position: right 15px center;
                background-repeat: no-repeat;
                appearance: none;
                -webkit-appearance: none;
            }
        }
        .section-title {
            margin: 5px 0 15px !important;
            display: flex;
            label {
                font-size: 16px;
                color: white;
            }
            .addmore-btn {
                color: white;
                font-size: 14px;
                text-transform: uppercase;
                color: white;
                font-family: 'D-DIN Condensed';
                font-weight: bold;
                border-bottom: 2px solid white;
                padding-bottom: 3px;
            }
        }
        form {
            .modal-form-scroll {
                overflow: hidden;
                height: 500px;
                .modal-form-scroll-col {
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                    margin-left: 17px;
                    padding-right: 17px;
                    padding-top: 30px;
                    padding-top: 20px;
                }
            }
        }
    }
}

.choose-your-team-modalbox {
    p {
        color: white;
        font-size: 16px;
        font-family: 'D-DIN Condensed';
        letter-spacing: 1.5px;
        &.uppercase {
            text-transform: uppercase;
        }
    }
    .btn-main {
        margin-top: 10px;
    }
    .upper-section {
        .check-label {
            color: rgba(255, 255, 255, 0.5);
            margin-top: 10px;
            .checkbox-circle {
                width: 16px !important;
                height: 16px !important;
                border: 1px solid white;
                margin-right: 8px;
            }
        }
        background: transparent url(../images/shadow-btm1.png) center bottom no-repeat;
        padding-bottom: 30px;
        background-size: 350px;
    }
    .bottom-section {
        margin-top: 25px;
        .team-col {
            .title-divider {
                margin-bottom: 10px;
                &::before,
                &::after {
                    width: 28%;
                    height: 1px;
                    background: rgba(255, 255, 255, 0.3);
                }
            }
            h4 {
                text-transform: uppercase;
                letter-spacing: .6px;
                font-family: "Montserrat", sans-serif;
                font-weight: 900;
                background: -webkit-linear-gradient(#00d3ff, #3385cc);
                background-clip: border-box;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-background-clip: text;
                -moz-text-fill-color: transparent;
                position: relative;
            }
            ul {
                background: #002e5c;
                padding: 30px 30px;
                margin: 30px 0px;
                @include border-radius(15px);
                @include box-shadow(0 8px 10px rgba(0,
                0,
                0,
                0.08));
                float: none;
                display: flex;
                text-align: center;
                li {
                    width: 33.33%;
                    float: left;
                    img {
                        width: 45px;
                        height: 45px;
                        border-radius: 100%;
                    }
                    p {
                        text-transform: uppercase;
                        color: white;
                        letter-spacing: 0.5px;
                        margin-top: 5px
                    }
                }
            }
        }
    }
    .modal-dialog {
        width: 670px;
        .modal-content {
            border: 0px !important;
            .btn-close-modal {
                right: 25px;
                top: 25px;
            }
        }
    }
}

.pointer {
    cursor: pointer;
}

.tournament-lobby-raw {
    .hexa-img {
        margin-right: 14px;
    }
    tr {
        td {
            padding: 8px 10px 6px !important;
        }
        th {
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            // &::before {
            //     position: absolute;
            //     right: 0;
            //     top: 50%;
            //     @include transform(translate(50%,
            //     0));
            //     content: "";
            //     background: #8e97a3;
            //     width: 1px;
            //     height: 20px;
            // }
        }
    }
}

.round-tab-raw {
    .nav-tabs {
        text-align: center;
        display: inline-block;
        float: none;
        margin: 20px 0;
        border: 0;
        .nav-item {
            display: inline-block;
            text-align: center;
            margin: 0 5px;
            .btn-round {
                border: 1px solid #00a8f3;
                width: 30px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #585a5f;
                font-size: 14px;
                font-weight: bold;
                font-family: 'D-DIN Condensed';
                background: #000513;
                @include transition(all 0.25s ease 0s);
                @include border-radius(100px);
                display: inline-block;
                &.active,
                &:hover {
                    background: #00d3f9;
                    background: -moz-linear-gradient(top, #00d3f9 0%, #0099f9 100%);
                    background: -webkit-linear-gradient(top, #00d3f9 0%, #0099f9 100%);
                    background: linear-gradient(to bottom, #00d3f9 0%, #0099f9 100%);
                    color: #000;
                }
            }
        }
    }
}

.modal-backdrop.show {
    opacity: 0.7 !important;
}

.desktop-mobile {
    display: none;
}

.admin.mobile-view {
    display: none !important;
}
.header {
    .dropdown-list {
        display: block !important;
    }
}
/*---------------------popups end------------------*/

@import "responsive";
// font-family: 'D-DIN Condensed';
// font-family: 'D-DIN';
// font-family: 'proximanova';
// font-family: 'Montserrat', sans-serif;
// @include box-shadow(0 0 10px rgb(255, 255, 255));
// @include transition(all 0.25s ease 0s);
// @include transform(translate(-50%,0));
// @include border-radius(10px);


.tournaments-raw{
    .tabbing-raw{
        .tournaments-tab-raw{
            padding: 0px 70px;
        }
        ul.nav{
            display: flex !important;
            justify-content: center;
            padding-bottom: 16px;
            border-bottom: 1px solid #052647 !important;
            li{
                a{
                    text-transform: uppercase;
                    color: rgba(255, 255, 255, 0.5);
                    letter-spacing: 2px;
                    font-size: 26px;
                    background: none !important;
                    padding: 10px 55px;
                    position: relative;
                    &.active{
                      color: rgba(255, 255, 255, 1);
                      border-color: none !important;
                      &:before{
                          position: absolute;
                          content: '';
                          width: 100%;
                          height: 1px;
                          background: white;
                          bottom: -15px;
                          left: 0;
                          box-shadow: 0px 0px 15px 4px rgba(80,174,255,0.43);
                          -webkit-box-shadow: 0px 0px 15px 4px rgba(80,174,255,0.43);
                          -moz-box-shadow: 0px 0px 15px 4px rgba(80,174,255,0.43);
                      }
                    }
                    &:hover{
                        border: none !important;
                        background: none !important;
                    }
                    &.nav-link{
                      border: none !important;
                      border-top-left-radius: inherit !important;
                      border-top-right-radius: inherit !important; 
                    }
                }
            }
        }
    }
}

// ***************** tournaments brackets styling start ****************************

$color_1: white;
$color_2: #fff;
$color_3: #00ff0c;
$font-family_1: "D-DIN Condensed";

.bracket_round_2 {
	.bracket-mrgin1 {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}
.bracket_round_3 {
	.bracket-mrgin1 {
		padding-top: 120px;
		padding-bottom: 120px;
	}
}
.bracket_round_4 {
	.bracket-mrgin1 {
		padding-top: 280px;
		padding-bottom: 280px;
	}
}
.bracket_round_5 {
	.bracket-mrgin1 {
		padding-top: 600px;
		padding-bottom: 600px;
	}
}
.bracket_round_6 {
	.bracket-mrgin1 {
		padding-top: 1240px;
		padding-bottom: 1240px;
	}
}
.bracket_round_7 {
	.bracket-mrgin1 {
		padding-top: 2440px;
		padding-bottom: 2440px;
	}
}
.bracket_round_8 {
	.bracket-mrgin1 {
		padding-top: 4920px;
		padding-bottom: 4920px;
	}
}
.bracket-mrgin1 {
	margin-bottom: 40px;
}
.bracket-mrgin2 {
	margin-bottom: 150px;
}
.bracket-mrgin3 {
	margin-bottom: 360px;
}
.bracket-mrgin4 {
	margin-bottom: 720px;
}
a.bracket-compatitore-link.left-br {
	&:hover {
		background: #009cff !important;
		color: $color_1 !important;
	}
}
.tournament {
	position: relative;
	min-height: 300px;
	padding: 30px 0 0;
	overflow-x: scroll;
}
.tournament__grid {
	font-size: 0;
	margin-bottom: 30px;
	line-height: 0;
	display: flex;
	align-items: stretch;
	min-height: 400px;
	width: 1290px;
}
.tournament__round {
	position: relative;
	flex: 1 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	&:last-child {
		.tournament__match__team {
			&:after {
				display: none;
			}
		}
	}
	&:first-child {
		.tournament__match__team {
			&:before {
				display: none;
			}
		}
	}
}
.tournament__match {
	a {
		&:last-child {
			-webkit-border-radius: 0 0 25px 25px;
			border-radius: 25px;
			border-top: 2px solid #06091a;
		}
		&:first-child {
			-webkit-border-radius: 25px 25px 0 0;
			border-radius: 25px;
			border-bottom: 2px solid #06091a;
		}
		i.ic-sts.r2c {
			background: #001a24;
			color: $color_3;
		}
		i.ic-user {
			left: 6px;
			top: 6px;
			border: 1px solid #009cff;
			background: #333c53 url(../images/ic-user-icon1.png) center center no-repeat;
		}
		i {
			font-style: inherit;
			width: 25px;
			height: 25px;
			line-height: 28px;
			text-align: center;
            border-radius: 50%;
			// -webkit-border-radius: 100px;
			position: absolute;
			text-align: center;
			font-size: 14px;
			right: 4px;
			top: 6px;
		}
	}
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	flex: 1 0;
	align-items: center;
	min-height: 110px;
	&:first-child {
		margin: 0 !important;
	}
	&:after {
		content: "";
		position: absolute;
		right: 0;
		width: 2px;
		background: #005a90;
		top: 25%;
		bottom: 25%;
	}
}
.tournament__round--first-round {
	.tournament__match {
		padding-left: 0;
	}
}
.tournament__round--winner {
	.tournament__match {
		padding-right: 0;
		height: 32px;
		&:after {
			display: none;
		}
	}
}
.tournament__round--end-point {
	.tournament__match {
		&:after {
			display: none;
		}
	}
	.tournament__match__team {
		&:after {
			display: none;
		}
	}
}
.tournament__match__team {
	font-size: 13px;
	font-weight: normal;
	transition: color 0.3s ease;
	color: $color_2;
	text-decoration: none;
	box-sizing: border-box;
	background: #000b28;
	display: block;
	position: relative;
	height: 40px;
	line-height: 40px;
	padding: 0 36px 0 36px;
	margin: auto;
	text-transform: uppercase;
	font-family: $font-family_1;
	align-self: center;
	letter-spacing: 1px;
	text-align: left;
	min-width: 140px;
	&:focus {
		color: $color_2;
	}
	&:hover {
		color: $color_2;
	}
	&:before {
		content: "";
		position: absolute;
		top: 50%;
		width: 999px;
		height: 2px;
		margin-top: -1px;
		background: #005a90;
		right: 100%;
	}
	&:after {
		content: "";
		position: absolute;
		top: 50%;
		width: 999px;
		height: 2px;
		margin-top: -1px;
		background: #005a90;
		left: 100%;
	}
}
.tournament__round.tournament__round--first-round {
	li {
		width: 33% !important;
	}
}
.tournament__round.tournament__round--final {
	li {
		width: 33% !important;
	}
}
.tournament__round.tournament__round--winner {
	li {
		width: 33% !important;
	}
}
.rounds {
	margin-top: 10px;
}
.brradiusf {
	border-radius: 25px 25px 0 0 !important;
}
.brradiusl {
	border-radius: 0 0 25px 25px !important;
}

// ***************** tournaments brackets styling end ****************************

// ***************** Pagination styling start *****************
.pagination {
    display: flex;
    justify-content: flex-end;
    .page-link{
        color: #fff !important;
        background-color: #060919 !important;
    }
    .active {
        .page-link {
            color:  #060919 !important;
            background-color:  #fff !important;
            border-color: #fff !important;
        }
    }
    .page-link.active {
        color:  #060919 !important;
        background-color:  #fff !important;
        border-color: #fff !important;
    }
}
// ****************** Pagination styling end *******************

.btn-border{
    border: 1px solid #065f9f;
}
.btn-text{
    color: #FFF;
}

// ************************** news styling starts ************************** 
.news-detail-raw {
    .img-box{
        img {
            display: inline-block;
            /* border: 2px solid #3385cc; */
            border: none;
            width: 100%;
            height: 240px;
        }
    }

    h4 {
        background: -moz-linear-gradient(top, #00d8ff 0, #009cff 100%);
        background: -webkit-linear-gradient(top, #00d8ff 0, #009cff 100%);
        background: linear-gradient(to bottom, #00d8ff 0, #009cff 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-background-clip: text;
        -moz-text-fill-color: transparent;
        letter-spacing: .2px;
        font-family: "D-DIN Condensed";
        font-weight: 400;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
    }

    p {
        font-size: 14px;
        color: #fff;
        font-family: Montserrat, sans-serif;
        font-weight: 100;
        line-height: 140%;
        letter-spacing: .5px;

        a {
            color: #fff;
            font-family: Montserrat, sans-serif;
            font-weight: 600;
            text-transform: uppercase;
            margin-left: 6px;
            color: white;
            margin-left: 0;
            text-decoration: underline;
        }
    }

    .post-by,
    .post-card-info {
        ul {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 1rem;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;

            li {
                font-size: 1rem;
                color: #fff;
                text-transform: capitalize;
                font-weight: 500;
            }
        }
    }

    .box-author {
        display: flex;
        align-items: center;

        img {
            display: inline-block;
            // margin-right: 15px;
            height: 50px !important;
            width: 50px !important;
            border-radius: 50% !important;
        }
    }

}
// ************************** news styling end ************************** 

// ******************* Auth styling starts **************************

.AuthWrapper{
    padding: 12rem 0 8rem;
}
// ******************* Auth styling end **************************

// ******************* Coming soon styling start **************************
.ComingSoonSection{
    margin: 80px auto;
}
.ComingSoon {
    max-width: 100%;
    font-family: 'Goldman';
    font-style: normal;
    font-weight: 400;
    font-size: 140px;
    letter-spacing: 0.05em;
    background: radial-gradient(82% 82% at 50% 50%, #00BFFF 0%, rgba(0, 0, 0, 0) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
    // text-fill-color: transparent;
}
.InprogressText{
    color: #fff;
    text-align: center;
    margin: 16px auto;
    font-size: 24px;
}
.GoBack{
    font-family: 'Goldman';
    font-size: 32px;
    line-height: 33px;
    cursor: pointer;
    color: #FFFFFF;
    text-align: center;
}

.MuiAutocomplete-input{
    font-size: 11px;
}

// ******************* Coming soon styling end **************************