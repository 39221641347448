@import url(https://fonts.googleapis.com/css?family=Aldrich);
@font-face {
  font-family: "D-DIN Condensed";
  src: url("fonts/d-dincondensed-webfont.eot");
  src: url("fonts/d-dincondensed-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/d-dincondensed-webfont.woff2") format("woff2"),
    url("fonts/d-dincondensed-webfont.woff") format("woff"),
    url("fonts/d-dincondensed-webfont.ttf") format("truetype"),
    url("fonts/d-dincondensed-webfont.svg#d-din_condensedregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "D-DIN";
  src: url("fonts/d-din-webfont.eot");
  src: url("fonts/d-din-webfont.eot?#iefix") format("embedded-opentype"),
    url("fonts/d-din-webfont.woff2") format("woff2"),
    url("fonts/d-din-webfont.woff") format("woff"),
    url("fonts/d-din-webfont.ttf") format("truetype"),
    url("fonts/d-din-webfont.svg#d-dinregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "D-DIN";
  src: url("fonts/d-din-bold-webfont.eot");
  src: url("fonts/d-din-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("fonts/d-din-bold-webfont.woff2") format("woff2"),
    url("fonts/d-din-bold-webfont.woff") format("woff"),
    url("fonts/d-din-bold-webfont.ttf") format("truetype"),
    url("fonts/d-din-bold-webfont.svg#d-dindin-bold") format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "D-DIN Condensed";
  src: url("fonts/d-dincondensed-bold-webfont.eot");
  src: url("fonts/d-dincondensed-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("fonts/d-dincondensed-bold-webfont.woff2") format("woff2"),
    url("fonts/d-dincondensed-bold-webfont.woff") format("woff"),
    url("fonts/d-dincondensed-bold-webfont.ttf") format("truetype"),
    url("fonts/d-dincondensed-bold-webfont.svg#d-din_condenseddinCnBd")
      format("svg");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "proximanova";
  src: url("fonts/Proxima-Nova-Extrabold.woff"),
    url("fonts/Proxima-Nova-Extrabold.otf"),
    url("fonts/Proxima-Nova-Extrabold.ttf"),
    url("fonts/Proxima-Nova-Extrabold.svg");
  font-weight: 900;
  font-style: normal;
}
/*  */
/* font goldman */
@font-face {
  font-family: "Goldman";
  src: url("fonts/Goldman/Goldman-Regular.ttf");
}
.font-Goldman {
  font-family: "Goldman";
}
@font-face {
  font-family: "MontserratRegular";
  src: url("fonts/Montserrat/Montserrat-Regular.ttf");
}
.font-MonsterratRegular {
  font-family: "MontserratRegular";
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default !important;
}
.font-D-DinCondensed {
  font-family: "D-DIN Condensed";
  font-weight: normal;
  font-style: normal;
}
.font-D-DinCondensedBold {
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-style: bold;
}
/* remember to define focus styles! */
/* tables still need 'cellspacing="0"' in the markup */
/*  Header  Start */
/*-----------------res-nav-Start-------------------*/
/*-----------------res-nav-end-------------------*/
/*  Header  End */
/* Style text tooltip */
/*
font-family: 'D-DIN';

font-family: 'D-DIN Condensed';

font-family: 'Montserrat', sans-serif;
*/
* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.section,
.create-team-modal .modal-form .form-grop,
.create-team-modal .modal-form .section-title,
.challenger-list-col ul li,
.challenger-list-col,
.contact-gaming-raw .contact-sub .form-group,
.tournament-details-col ul li strong,
.trophy-raw .trophy-li .trophy-col,
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-details-col,
.profile-details-raw .team-overview-raw .the-team-raw .team-li .team-li-conn,
.balance-earned-raw,
.most-popular-raw ul li a,
.challenge-raw .challenge-info-raw ul li > span,
.terms-raw label,
.store-raw .tab-content,
.record-list-raw .record-list-col ul li h3 span,
.header .dropdown-list li a,
.header .dropdown-list li,
.header .notification .dropdown-menu-noti .notification-list li,
.trending-videos-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw .view-raw,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col,
.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw .about-game-conn,
.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw,
.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box,
.game-lists-raw .gm-li-raw .row .gm-li .gm-col,
.title-raw,
.step-section-raw ul li .step-col,
.select-dropdown,
.select-game,
.league-wrapper .expert-master .progress-wrap .redius-col,
.main-wrapper,
.scroll-wrap,
table {
  float: left;
  width: 100%;
}

.text-wrap {
  float: left;
  width: 100%;
}

.img-wrap {
  float: left;
  width: 100%;
}

ul {
  float: left;
  width: 100%;
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  list-style: none;
  margin: 0;
  padding-left: 0 !important;
}

body {
  background: #060919;
  font-size: 100%;
  color: #444444;
  line-height: 100%;
  font-style: normal;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  padding: 0px !important;
  -webkit-font-smoothing: antialiased;
  border: 0;
  outline: 0;
  font-size: 100%;
}

html {
  overflow-x: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}
html a {
  -webkit-font-smoothing: antialiased;
}

a {
  transition: all 0.25s ease 0s;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}
a:hover {
  text-decoration: none;
  text-decoration: none;
  color: #00d3ff;
}
a:focus {
  text-decoration: none;
  text-decoration: none;
  color: #00d3ff;
  outline: 0;
}

div {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

span {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

applet {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

object {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

iframe {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

h1,
h2 {
  border: 0;
  outline: 0;
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  line-height: 120%;
  font-size: 50px;
  color: white;
  font-weight: 900;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#00d3ff, #3385cc);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  overflow-wrap: break-word;
}

h3 {
  border: 0;
  outline: 0;
  font-size: 100%;
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 120%;
  font-size: 60px;
  color: white;
}

h4 {
  border: 0;
  outline: 0;
  font-size: 100%;
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 120%;
  font-size: 26px;
  color: #009cff;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 2px;
}

h5 {
  border: 0;
  outline: 0;
  font-size: 100%;
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 120%;
  font-size: 19px;
  color: white;
  font-weight: 700;
}

h6 {
  border: 0;
  outline: 0;
  font-size: 100%;
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 120%;
  font-size: 12px;
  font-weight: 500;
  color: white;
}

p {
  border: 0;
  outline: 0;
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  font-weight: 500;
  color: #8b8b8b;
  line-height: 180%;
}
p:last-child {
  padding-bottom: 0;
}

blockquote {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  quotes: "" "";
}
blockquote:before {
  content: "";
}
blockquote:after {
  content: "";
}

pre {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

abbr {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

acronym {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

address {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

big {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

cite {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

code {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

del {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

dfn {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

em {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

font {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

img {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  border: 0 none;
  max-width: 100%;
}

ins {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

kbd {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

q {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  quotes: "" "";
}
q:before {
  content: "";
}
q:after {
  content: "";
}

s {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

samp {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

small {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

strike {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

strong {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

sub {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

sup {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

tt {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

var {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

dl {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

dt {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

dd {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

ol {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  list-style: none;
  margin: 0;
}

li {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

fieldset {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

form {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

label {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

legend {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

table {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 0 none;
  border-collapse: collapse;
  border-spacing: 0;
}
table tr:first-child {
  position: relative;
}
table tr th {
  background-color: #0f213c !important;
  padding: 14px 20px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 120%;
  letter-spacing: 2px;
  position: relative;
}
table tr th:first-child:after {
  content: "";
  position: absolute;
  width: 100%;
  float: left;
  height: 1px;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgb(1, 195, 236) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
table tr th:last-child:after {
  content: "";
  position: absolute;
  width: 100%;
  float: left;
  height: 1px;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 195, 236) 100%
  );
}
table tr th.blank:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #070919;
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 195, 236) 50%,
    rgba(1, 195, 236, 0) 100%
  );
}
table tr th.rank {
  width: 140px;
}
table tr th.team {
  width: 220px;
}
table tr th.points {
  width: 170px;
}
table tr th.coins {
  width: 190px;
}
table tr td {
  font-family: "D-DIN Condensed";
  font-weight: normal;
  color: white;
  font-size: 20px;
  text-transform: uppercase;
  line-height: 120%;
  letter-spacing: 2px;
  position: relative;
  padding: 16px 20px;
  border-bottom: 1px solid #002335;
}
table tr td img {
  display: inline-block;
  vertical-align: middle;
}
table tr td span {
  display: inline-block;
  vertical-align: middle;
  padding-left: 10px;
}
table tr td i {
  width: 7px;
  height: 31px;
  background-image: url(../images/slash-image.png);
  display: inline-block;
  vertical-align: top;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
table tr td.team {
  padding: 10px 20px;
}
table tr td.slash {
  padding: 13px 20px;
  line-height: 150%;
  text-align: center;
}
table tr.active td,
table tr.active td a {
  color: #00d8ff;
}
table tr.active td:first-child:after {
  content: "";
  position: absolute;
  width: 100%;
  float: left;
  height: 1px;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgb(1, 195, 236) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
table tr.active td:last-child:after {
  content: "";
  position: absolute;
  width: 100%;
  float: left;
  height: 1px;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 195, 236) 100%
  );
}
table tr.active td.blank {
  background-image: url(../images/table-arrow-img.png);
  background-position: center center;
  background-repeat: no-repeat;
}
table tr.active td.blank:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #070919;
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 195, 236) 50%,
    rgba(1, 195, 236, 0) 100%
  );
}

caption {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  text-align: left;
  font-weight: normal;
}

tbody {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

tfoot {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

thead {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

tr {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
}

th {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  text-align: left;
  font-weight: normal;
}

td {
  font-family: "D-DIN Condensed";
  border: 0;
  outline: 0;
  font-size: 100%;
  text-align: left;
  font-weight: normal;
  vertical-align: top;
}

button {
  cursor: pointer;
  border: none;
}

input[type="checkbox"] {
  vertical-align: bottom;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  border: 2px solid #cbccd1;
}

input[type="radio"] {
  height: auto;
  vertical-align: top;
}

input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
}
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

.chk-style {
  color: rgba(255, 255, 255, 0.6);
}

.left {
  float: left;
}

.right {
  float: right;
}

.clear {
  clear: both;
}

.text_right {
  text-align: right;
}

.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.clearfix {
  clear: both;
  display: block;
  font-size: inherit;
  height: 0;
  line-height: 0;
}

h6.uppercase {
  text-transform: uppercase;
}

b {
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

article {
  display: block;
}

aside {
  display: block;
}

details {
  display: block;
}

figcaption {
  display: block;
}

figure {
  display: block;
}

footer {
  display: block;
}

header {
  display: block;
}

hgroup {
  display: block;
}

nav {
  display: block;
}

section {
  display: block;
}

.relative {
  position: relative;
}

.mt0 {
  margin-top: 0px !important;
}

.mt50 {
  margin-top: 50px !important;
}

.mt80 {
  margin-top: 80px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mb80 {
  margin-bottom: 80px !important;
}

.mt250 {
  margin-top: 180px !important;
}

.ptb10 {
  padding-top: 110px !important;
  padding-bottom: 110px !important;
}

.pt10 {
  padding-top: 110px !important;
}

.pt50 {
  padding-top: 50px !important;
}

.pb50 {
  padding-bottom: 50px !important;
}

.ptb50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}

.pt70 {
  padding-top: 70px !important;
}

.pb70 {
  padding-bottom: 70px !important;
}

.pb10 {
  padding-bottom: 110px !important;
}

.pdlr5 {
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.pdlr10 {
  padding-left: 10% !important;
  padding-right: 10% !important;
}

.pdlr15 {
  padding-left: 15% !important;
  padding-right: 15% !important;
}

.font20 {
  font-size: 20px !important;
}
.btn.btn-7.btn-7a.zmdi-shopping-cart {
  border-color: transparent;
}
.btn-main {
  background: transparent;
  width: auto;
  min-width: 170px;
  height: 46px;
  line-height: 44px;
  display: inline-block;
  width: auto;
  padding: 0 30px;
  margin-top: 20px;
  font-family: "D-DIN";
  font-weight: bold;
  font-size: 16px;
  color: white;
  letter-spacing: 0.5px;
  border: 2px solid #009cff;
  transition: all 0.25s ease 0s;
  border-radius: 100px;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 0px 15px white;
  -webkit-text-shadow: 0px 0px 15px white;
}
.btn-main:hover {
  background-color: #00d3ff !important;
  border-color: #00d3ff !important;
  color: white !important;
}
.btn-main:focus {
  background-color: #00d3ff !important;
  border-color: #00d3ff !important;
  color: white !important;
}
.btn-main.bg-trans {
  background-color: rgba(0, 156, 255, 0.4);
}
.btn-main.bg-trans:hover {
  background-color: rgba(0, 156, 255, 0.7);
}
.btn-main.bg-color {
  background-color: rgba(0, 156, 255, 0.5);
  color: white;
  border-color: #009cff;
  text-transform: uppercase;
  height: 50px;
  line-height: 48px;
  margin-top: 50px;
}
.btn-main.btn-sqr-gradient {
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  background: rgb(0, 216, 255);
  background: linear-gradient(
    to right,
    rgb(0, 216, 255) 0%,
    rgb(0, 156, 255) 100%
  );
  color: white;
  border: 0px;
  margin-top: 50px;
}
.btn-main.btn-sqr-gradient:hover {
  background: rgb(0, 156, 255);
  background: linear-gradient(
    to right,
    rgb(0, 156, 255) 0%,
    rgb(0, 156, 255) 100%
  );
}

.txt-shad-none {
  text-shadow: none;
  -webkit-text-shadow: none;
}

.gform_button {
  background: transparent;
  width: auto;
  min-width: 217px;
  height: 60px;
  line-height: 54px;
  display: inline-block;
  width: auto;
  padding: 0 30px;
  margin-top: 20px;
  font-family: "D-DIN Condensed";
  font-size: 18px;
  font-weight: bold;
  color: #8b8b8b;
  letter-spacing: 0.5px;
  border: 2px solid #8b8b8b;
  transition: all 0.25s ease 0s;
  border-radius: 100px;
  position: relative;
  z-index: 9;
  text-transform: capitalize;
  text-align: center;
}
.gform_button:hover {
  background-color: #00d3ff !important;
  border-color: #00d3ff !important;
  color: white !important;
}
.gform_button:focus {
  background-color: #00d3ff !important;
  border-color: #00d3ff !important;
  color: white !important;
}

.btn-main.red {
  color: #ed1f24;
  border-color: #ed1f24;
}
.btn-main.red:hover {
  background-color: #00d3ff !important;
  border-color: #00d3ff !important;
}
.btn-main.red:focus {
  background-color: #00d3ff !important;
  border-color: #00d3ff !important;
}

.gform_button.red {
  color: #ed1f24;
  border-color: #ed1f24;
}
.gform_button.red:hover {
  background-color: #00d3ff !important;
  border-color: #00d3ff !important;
}
.gform_button.red:focus {
  background-color: #00d3ff !important;
  border-color: #00d3ff !important;
}

.btn-main.blue {
  color: #4e9ec3;
  border-color: #4e9ec3;
}
.btn-main.blue:hover {
  background-color: #4e9ec3 !important;
  border-color: #4e9ec3 !important;
  color: white;
}
.btn-main.blue:focus {
  background-color: #4e9ec3 !important;
  border-color: #4e9ec3 !important;
  color: white;
}

.gform_button.blue {
  color: #4e9ec3;
  border-color: #4e9ec3;
}
.gform_button.blue:hover {
  background-color: #4e9ec3 !important;
  border-color: #4e9ec3 !important;
  color: white;
}
.gform_button.blue:focus {
  background-color: #4e9ec3 !important;
  border-color: #4e9ec3 !important;
  color: white;
}

.form-control {
  float: left;
  width: 100%;
  background: #060919;
  border: 1px solid #009cff;
  height: 45px;
  padding: 0 25px;
  font-family: "D-DIN Condensed";
  font-size: 18px;
  font-weight: normal;
  color: white;
  line-height: 120%;
  transition: all 0.25s ease 0s;
  border-radius: 100px;
}
.form-control::-webkit-input-placeholder {
  color: white;
}
.form-control::-moz-placeholder {
  color: white;
}
.form-control:-ms-input-placeholder {
  color: white;
}
.form-control:-moz-placeholder {
  color: white;
}
.form-control:focus {
  background-color: #000;
  border-color: white;
  box-shadow: none;
  color: #fff;
}

::-moz-selection {
  background: #00bcff5e;
  color: white;
  text-shadow: none;
}

::selection {
  background: #00bcff5e;
  color: white;
  text-shadow: none;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 999999;
}

#status {
  width: 122px;
  height: 114px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(../images/logo.png);
  background-repeat: no-repeat;
  background-position: center;
  transform: translate(-50%, -50%);
}

#header {
  float: left;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  transition: all 0.6s;
  background: #060919;
}

.header {
  padding: 0 25px;
  border-bottom: 1px solid transparent;
  transition: all 0.25s ease 0s;
  position: relative;
  height: 70px;
}
#header .logo {
  transition: all 0.25s ease 0s;
  width: 122px;
  vertical-align: top;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 12px;
  z-index: 10;
}
.header .logo a {
  width: auto;
  float: left;
}
.header .logo a img {
  margin: 0 auto;
  display: inline-block;
}
/* .header .logo.desktop {
  margin: -10px 45px -68px;
} */
.header .setting-head {
  width: auto;
  float: left;
  position: relative;
  top: 8px;
  transition: all 0.25s ease 0s;
}
.header .admin {
  width: auto;
  float: left;
  text-align: left;
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  z-index: 11;
  top: 0px;
  transition: all 0.25s ease 0s;
}
.header .admin.login-search-ic {
  margin-top: 8px;
}
.header .admin .icon {
  width: 38px;
  height: 38px;
  float: left;
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  background: linear-gradient(to bottom, #009cff 0%, #00d8ff 100%);
  position: relative;
  z-index: 111;
}
.header .admin .icon::before {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid #00b8ff;
  content: "";
  position: absolute;
  right: -14px;
  top: 50%;
  transform: translate(-50%, 0);
}
.header .admin .icon img {
  display: block;
  margin: 0 auto;
}
.header .notification {
  width: 160px;
  float: right;
  padding: 3px 0;
  transition: all 0.25s ease 0s;
}
.header .notification > ul {
  width: 100%;
  float: left;
  text-align: right;
  align-items: center;
}
.header .notification > ul > li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 30px;
}
.header .notification > ul > li:last-child {
  margin-right: 0px;
}
.header .notification > ul > li > a {
  width: auto;
  float: left;
}
.header .notification-count {
  background: red;
  color: white;
  border-radius: 50px;
  font-size: 9px;
  aspect-ratio: 1/1;
  width: 16px;
  height: 16px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  left: 9px;
  font-family: "Montserrat", sans-serif;
  position: absolute;
  border: 2px inset transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
}
.header .login-raw {
  display: flex;
  align-items: center;
}
.header .login-raw a {
  display: inline-block;
  vertical-align: middle;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 10px;
}
.header .login-raw a.btn-main {
  margin: 0;
  height: auto;
  min-width: initial;
  line-height: inherit;
  padding: 10px 15px;
  text-transform: uppercase;
}
.header .login-raw a.btn-login {
  text-transform: uppercase;
  background: transparent url(../images/ic-login.png) left center no-repeat;
  padding-left: 35px;
  color: white;
  padding: 5px 25px 5px 35px;
}
.header .header-right {
  width: calc(100% - 320px) !important;
  margin: 0 auto;
  /* margin-left: -45px; */
  position: relative;
  z-index: 9;
  transition: all 0.3s;
  text-align: center;
  /* padding: 25px; */
  padding: 20px;
}

.header .header-right:after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -1px;
  left: 0;
  background: #070919;
  background: linear-gradient(
    to right,
    #070919 0%,
    #0998f6 25%,
    #0998f6 50%,
    #0998f6 75%,
    #070919 100%
  );
}
@media screen and (min-width: 992px) and (max-width: 1120px) {
  .login-raw {
    margin-left: 40px;
  }
}
.header .header-right .nav-main {
  display: inline-block;
  width: auto;
}
.header .header-right .nav-main ul {
  display: inline-block;
  width: auto;
  float: none;
  min-width: 562px;
  vertical-align: top;
  transition: all 0.3s;
}
.header .header-right .nav-main li {
  float: none;
  display: inline-block;
  text-align: center;
  width: auto;
  position: relative;
  margin-right: 0 !important;
  transition: all 0.25s ease 0s;
}
.header .header-right .nav-main li:last-child {
  margin-right: 0px;
}
.header .header-right .nav-main li::after {
  height: 1px;
  background: #00d3ff;
  width: 100%;
  transition: all 0.25s ease 0s;
  contain: "";
  position: absolute;
  left: 0;
  bottom: 0px;
}
.header .header-right .nav-main li:hover::after {
  width: 100%;
}
.header .header-right .nav-main li a {
  width: auto;
  display: inline-block;
  height: auto;
  font-family: "D-DIN";
  font-weight: normal;
  font-size: 12px;
  line-height: 120%;
  color: white;
  letter-spacing: 2px;
  text-decoration: none;
  transition: all 0.25s ease 0s;
  position: relative;
  margin: 0 18px;
}
.header .header-right .nav-main li a::before {
  width: 1px;
  height: 100%;
  content: "";
  position: absolute;
  right: -20px;
  top: 0;
  background: #0f243f;
}
.header .header-right .nav-main li a .icon {
  max-height: 30px;
  line-height: 30px;
  width: auto;
  clear: both;
  display: block;
  position: relative;
}
.header .header-right .nav-main li a .icon img,
.header .header-right .nav-main li a .icon svg {
  max-height: 30px;
  transition: all 0.25s ease 0s;
}
.header .header-right .nav-main li a .icon svg .cls-1,
.header .header-right .nav-main li a .icon svg .cls-1s {
  stroke: #00a8ff;
  transition: all 0.25s ease 0s;
}
.header .header-right .nav-main li a .icon svg .cls-2,
.header .header-right .nav-main li a .icon svg .cls-3 {
  fill: #00a8ff;
  transition: all 0.25s ease 0s;
}
.header .header-right .nav-main li a:hover .icon svg,
.header .header-right .nav-main li a.active .icon svg {
  filter: drop-shadow(0 0 0.75rem #00a8ff);
}
.header .header-right .nav-main li a:hover .icon svg .cls-1,
.header .header-right .nav-main li a:hover .icon svg .cls-1s,
.header .header-right .nav-main li a.active .icon svg .cls-1,
.header .header-right .nav-main li a.active .icon svg .cls-1s {
  stroke: white;
}
.header .header-right .nav-main li a:hover .icon svg .cls-2,
.header .header-right .nav-main li a:hover .icon svg .cls-3,
.header .header-right .nav-main li a.active .icon svg .cls-2,
.header .header-right .nav-main li a.active .icon svg .cls-3 {
  fill: white;
}
.header .header-right .nav-main li a.active span {
  color: #00c0ff;
}
.header .header-right .nav-main li a span {
  width: auto;
  display: inline-block;
  font-family: "D-DIN";
  color: white;
  text-transform: uppercase;
  padding-top: 0 !important;
  transition: all 0.3s;
  letter-spacing: 0.25px;
}
.header .header-right .nav-main li a span:hover {
  color: #00c0ff;
}
.header .header-right .nav-main li a span:hover::after {
  transform: scale(1);
}
.header .header-right .nav-main li a::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -6px;
  width: 100%;
  height: 1px;
  background: #00d3ff;
  transform: scale(0, 1);
  transition: all 0.25s ease 0s;
}
.header .header-right .nav-main li a:hover {
  color: #00c0ff;
}
.header .header-right .nav-main li a:hover::after {
  transform: scale(1);
}
.header .header-right .nav-main li:last-child a {
  margin-right: 0px;
}
.header .header-right .nav-main li:last-child a::before {
  display: none;
}
.header .header-right .nav-main li:first-child a {
  margin-left: 0px;
}
.header .header-right .nav-main li.current-menu-item::after {
  width: 100%;
}
.header .header-right .nav-main li.current-menu-item a {
  color: white;
}
.header .header-right .nav-main li.current-menu-item a::after {
  transform: scale(1);
}
.header .header-right .head-action {
  float: left;
  width: auto;
}
.header .header-right .head-action .btn-main {
  margin-top: 0;
  font-size: 14px;
  height: 38px;
  line-height: 34px;
  min-width: inherit;
}
.header .header-right .head-action .btn-main.login {
  background-color: #00d3ff;
  border-color: #00d3ff;
  color: white;
  margin-right: 15px;
}
.header .header-right .head-action .btn-main.register:hover {
  background-color: #6dd847 !important;
  border-color: #6dd847 !important;
}
.header .header-right .head-action .btn-main.register:focus {
  background-color: #6dd847 !important;
  border-color: #6dd847 !important;
}

.topbar {
  background: rgba(0, 157, 255, 0.35);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(0, 157, 255, 0.35)),
    color-stop(50%, rgba(0, 157, 255, 0.99)),
    color-stop(51%, rgb(0, 157, 255)),
    color-stop(100%, rgba(0, 157, 255, 0.36))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(0, 157, 255, 0.35) 0%,
    rgba(0, 157, 255, 0.99) 50%,
    rgb(0, 157, 255) 51%,
    rgba(0, 157, 255, 0.36) 100%
  );
  padding: 12px 30px;
  font-size: 0;
}
.topbar .left-side {
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
.topbar .left-side h6 {
  float: left;
  font-size: 14px;
}
.topbar .left-side h6 strong {
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 1.5px;
}
.topbar .left-side h6 span {
  font-size: 12px;
  float: left;
  font-style: italic;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  margin-right: 10px;
}
.topbar .left-side h6.uppercase {
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 12px;
}
.topbar .left-side h6.uppercase span {
  font-style: normal;
  position: relative;
}
.topbar .left-side h6.uppercase span::after {
  content: ":";
  display: inline-block;
  margin-left: 2px;
}
.topbar h6 {
  line-height: 150%;
  width: auto;
}
.topbar .right-side {
  display: flex;
  float: right;
  justify-content: flex-end;
}
.topbar .right-side h6 {
  display: inline-flex;
  float: none;
  padding-left: 20px;
  margin-left: 10px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  letter-spacing: 1px;
}
.topbar .right-side .social-list-raw {
  font-size: 16px;
}
.topbar .right-side .btn-ic {
  background: #009cff url(../images/ic-head-cart.png) left 20px center no-repeat;
  margin-left: 40px;
  padding: 10px 30px 10px 50px;
}

ul.social-list-raw {
  display: inline-flex;
  float: none;
  width: auto;
  vertical-align: middle;
}
ul.social-list-raw li {
  margin: 0 12px;
}
ul.social-list-raw li i {
  color: white;
}

.left-side h6 {
  font-weight: 700;
  font-size: 14px;
}
.left-side h6 span {
  font-weight: 500;
  letter-spacing: inherit;
}
.left-side h6:first-child {
  padding-right: 30px;
  margin-right: 30px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  letter-spacing: 0.5px;
}

.mobile {
  display: none !important;
}

.toggle-menu {
  display: none;
  width: 72px;
  height: 22px;
  position: absolute;
  right: 0;
}

.nav-toggle {
  position: absolute;
  top: 3px;
  right: 0;
  z-index: 999999;
  padding: 5px 30px 13px 0;
  cursor: pointer;
}
.nav-toggle:focus {
  outline: none;
}
.nav-toggle span {
  background: #269ada;
  border: none;
  height: 2px;
  width: 100%;
  position: absolute;
  top: -2px;
  left: 0;
  transition: all 0.55s ease;
  cursor: pointer;
}
.nav-toggle span:nth-of-type(2) {
  top: 7px;
}
.nav-toggle span:nth-of-type(3) {
  top: 16px;
}

.nav-toggle.active .Top {
  transform: translateY(12px) translateX(0) rotate(45deg);
  background: #269ada;
  top: -5px;
}
.nav-toggle.active .Bottom {
  transform: translateY(-7px) translateX(0) rotate(-45deg);
  background: #269ada;
  top: 14px;
}
.nav-toggle.active .Middle {
  opacity: 0;
  background: #269ada;
}

.container {
  padding: 0;
  max-width: 1520px;
}

.before-login .main-wrapper {
  padding-top: 90px;
}

.main-wrapper {
  background-image: url(../images/main-banner.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  padding-top: 115px;
}

.league-wrapper {
  padding-top: 150px;
}
.league-wrapper .leacue-table-img {
  width: 100%;
  float: left;
  text-align: center;
  position: relative;
  margin-bottom: 50px;
}
.league-wrapper .expert-master {
  width: 100%;
  float: left;
  text-align: center;
  position: relative;
}
.league-wrapper .expert-master .progress-wrap {
  width: 100%;
  float: left;
  text-align: center;
}
.league-wrapper .expert-master .progress-wrap h5 {
  font-style: italic;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  float: none;
  text-transform: uppercase;
  margin: 0 20px 0;
  font-size: 18px;
  color: #00d8ff;
}
.league-wrapper .expert-master .progress-wrap h5:first-child {
  color: #00ffcc;
}
.league-wrapper .expert-master .progress-wrap .redius-col {
  overflow: hidden;
  height: 100%;
  border-radius: 25px;
  position: relative;
}
.league-wrapper .expert-master .progress-wrap .progress-bar-raw {
  box-shadow: 0 0 20px #01cdf2;
  vertical-align: middle;
  float: none;
  display: inline-block;
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #01cdf2;
  padding: 2px;
  height: auto;
  border-radius: 25px;
  background: transparent;
  position: relative;
}
.league-wrapper .expert-master .progress-wrap .progress-bar-raw .progress-bar {
  background-color: #06e2f5;
  background-image: url(../images/progress-arrow.png);
  background-size: contain;
  animation-direction: reverse;
  animation-duration: 2.5s;
  height: 26px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  position: relative;
}
.league-wrapper
  .expert-master
  .progress-wrap
  .progress-bar-raw
  .progress-bar
  span {
  position: absolute;
  right: 10px;
  color: #06152a;
}
.league-wrapper .expert-master p {
  font-family: "D-DIN Condensed";
  font-weight: normal;
  font-size: 15px;
  color: #2b5182;
  padding-top: 10px;
}

.label {
  font-family: "Aldrich", sans-serif;
  position: absolute;
  display: block;
  width: 40px;
  height: 30px;
  line-height: 30px;
  top: 38px;
  left: 0px;
  background: rgb(76, 76, 76);
  background: linear-gradient(
    to bottom,
    rgb(76, 76, 76) 0%,
    rgb(38, 38, 38) 100%
  );
  font-weight: bold;
  font-size: 12px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.3);
  text-shadow: 0px -1px 0px #000000, 0px 1px 1px #000000;
}

.label span {
  position: absolute;
  display: block;
  width: 12px;
  height: 9px;
  top: -9px;
  left: 14px;
  background: transparent;
  overflow: hidden;
}

.label span:before {
  position: absolute;
  display: block;
  content: "";
  width: 8px;
  height: 8px;
  top: 4px;
  left: 2px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: rgb(86, 86, 86);
  background: linear-gradient(135deg, rgb(86, 86, 86) 0%, rgb(76, 76, 76) 50%);
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.15);
  transform: rotate(45deg);
}
@keyframes sparkle {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 -64px;
  }
}
.table-wrapper {
  padding: 110px 0 100px;
}
.select-dropdown p {
  padding: 0px 6px;
  margin: 0;
  /* color: rgba(255, 255, 255, 0.6); */
  color: #0180d3;
  font-size: 14px;
  font-family: "D-DIN Condensed";
  text-transform: uppercase;
}
.select-dropdown p:hover {
  color: white;
  background-color: #0180d3;
}
.select-dropdown div {
  background-color: white;
  max-height: 250px;
  overflow: auto;
}
.select-dropdown select,
.select-dropdown input,
.select-game select,
.select-game input,
.select-dropdown label,
.custom-date {
  max-width: 450px;
  width: 100%;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  padding: 10px 30px 10px 10px;
  border-top: 1px solid #0180d3;
  border-bottom: 1px solid #0180d3;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  font-family: "D-DIN Condensed";
  text-transform: uppercase;
  letter-spacing: 2px;
  background-image: url(../images/ic-select.png);
  background-repeat: no-repeat;
  background-position: right 10px center;
  transition: all 0.25s ease 0s;
  cursor: pointer;
}
.select-dropdown select option,
.select-dropdown input option,
.select-game select option,
.select-game input option,
.select-game p {
  color: #0180d3;
}
.select-dropdown select:focus,
.select-dropdown input:focus,
.select-game select:focus,
.select-game input:focus,
.select-game label:focus {
  outline: none;
}
.select-dropdown .date,
.select-game .date {
  background-image: url(../images/ic-calendar.png);
}

.faqs-wrapper {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
}
.faqs-wrapper .accordion {
  width: 100%;
  float: left;
}
.faqs-wrapper .accordion .card {
  width: 100%;
  float: left;
  padding-top: 50px;
  background-color: transparent;
  overflow: initial;
  border: none;
}
.faqs-wrapper .accordion .card .card-header {
  width: 100%;
  float: left;
  border: none;
  padding: 0px;
  margin: 0;
  position: relative;
}
.faqs-wrapper .accordion .card .card-header > span {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 24px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.faqs-wrapper .accordion .card .card-header button {
  width: 100%;
  float: left;
  border: none;
  height: 50px;
  padding: 13px 70px;
  line-height: 120%;
  background: #00d8ff;
  background: linear-gradient(to bottom, #00d8ff 1%, #009cff 100%);
  border-radius: 0;
  position: relative;
  text-decoration: none;
  outline: none;
}
.faqs-wrapper .accordion .card .card-header button:after {
  content: "";
  position: absolute;
  width: 100%;
  float: left;
  height: 1px;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgb(1, 195, 236) 0%,
    rgba(1, 195, 236, 0) 25%,
    rgb(1, 195, 236) 50%,
    rgba(1, 195, 236, 0) 75%,
    rgb(1, 195, 236) 100%
  );
}
.faqs-wrapper .accordion .card .card-header button:before {
  content: "";
  position: absolute;
  width: 34px;
  height: 34px;
  background-color: rgba(255, 255, 255, 0.5);
  right: 20px;
  top: 50%;
  border-radius: 100%;
  transform: translate(0, -50%);
  background-image: url(../images/ic-dwon-arrow.png);
  background-repeat: no-repeat;
  background-position: center center;
}
.faqs-wrapper .accordion .card .card-header button.collapsed:before {
  transform: rotate(180deg) translate(0, 50%);
}
.faqs-wrapper .accordion .card .card-header button:focus {
  box-shadow: none;
}
.faqs-wrapper .accordion .card .table-box {
  width: 100%;
  float: left;
}
.faqs-wrapper .accordion .card .table-box .card-body {
  width: 100%;
  float: left;
  padding: 0;
}
.faqs-wrapper .accordion .card.card2 .card-header button {
  background: rgb(0, 255, 204);
  background: linear-gradient(
    to bottom,
    rgb(0, 255, 204) 0%,
    rgb(0, 153, 122) 100%
  );
}
.faqs-wrapper .accordion .card.card2 .card-header button:after {
  background: linear-gradient(
    to bottom,
    rgb(0, 245, 197) 0%,
    rgba(0, 245, 197, 0) 25%,
    rgb(0, 245, 197) 50%,
    rgba(0, 245, 197, 0) 75%,
    rgb(0, 245, 197) 100%
  );
}
.faqs-wrapper .accordion .card.card3 .card-header button {
  background: rgb(0, 255, 6);
  background: linear-gradient(
    to bottom,
    rgb(0, 255, 6) 0%,
    rgb(18, 140, 12) 100%
  );
}
.faqs-wrapper .accordion .card.card3 .card-header button:after {
  background: linear-gradient(
    to bottom,
    rgb(1, 244, 6) 0%,
    rgba(1, 244, 6, 0) 25%,
    rgb(1, 244, 6) 50%,
    rgba(1, 244, 6, 0) 75%,
    rgb(1, 244, 6) 100%
  );
}
.faqs-wrapper .accordion .card.card4 .card-header button {
  background: rgb(255, 144, 0);
  background: linear-gradient(
    to bottom,
    rgb(255, 144, 0) 0%,
    rgb(165, 93, 0) 100%
  );
}
.faqs-wrapper .accordion .card.card4 .card-header button:after {
  background: linear-gradient(
    to bottom,
    rgb(255, 144, 0) 0%,
    rgba(237, 195, 1, 0) 25%,
    rgb(255, 144, 0) 50%,
    rgba(255, 144, 0, 0) 75%,
    rgb(255, 144, 0) 100%
  );
}
.faqs-wrapper .accordion .card.card5 .card-header button {
  background: rgb(255, 210, 0);
  background: linear-gradient(
    to bottom,
    rgb(255, 210, 0) 0%,
    rgb(148, 122, 0) 100%
  );
}
.faqs-wrapper .accordion .card.card5 .card-header button:after {
  background: linear-gradient(
    to bottom,
    rgb(237, 195, 1) 0%,
    rgba(237, 195, 1, 0) 25%,
    rgb(237, 195, 1) 50%,
    rgba(237, 195, 1, 0) 75%,
    rgb(237, 195, 1) 100%
  );
}
.faqs-wrapper .accordion .card.card6 .card-header button {
  background: rgb(154, 31, 255);
  background: linear-gradient(
    to bottom,
    rgb(154, 31, 255) 0%,
    rgb(97, 14, 166) 100%
  );
}
.faqs-wrapper .accordion .card.card6 .card-header button:after {
  background: linear-gradient(
    to bottom,
    rgb(147, 29, 243) 0%,
    rgba(147, 29, 243, 0) 25%,
    rgb(147, 29, 243) 50%,
    rgba(147, 29, 243, 0) 75%,
    rgb(147, 29, 243) 100%
  );
}

.footer-wrap {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.footer-wrap .left-wrap {
  width: 35%;
  float: left;
  text-align: left;
  height: 300px;
  overflow: hidden;
  padding-right: 6%;
}
.footer-wrap .right-wrap {
  width: 65%;
  float: left;
  order: 10;
}
.footer-wrap .right-wrap .social-list-raw {
  display: block;
}
.footer-wrap .right-wrap .social-list-raw li {
  display: inline-block;
  float: none;
  width: auto;
  margin: 30px 20px 0 0;
}
.footer-wrap .right-wrap .social-list-raw li a,
.footer-wrap .right-wrap .social-list-raw li a i {
  color: #009cff;
  transition: all 0.55s ease;
}
.footer-wrap .right-wrap .social-list-raw li a:hover,
.footer-wrap .right-wrap .social-list-raw li a i:hover {
  color: white;
}
.footer-wrap .right-wrap .social-list-raw li:last-child {
  margin-right: 0px;
}

.card2 table tr th {
  background-color: #0f3d3b;
}
.card2 table tr th:first-child:after {
  background: linear-gradient(
    to right,
    rgb(0, 245, 197) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card2 table tr th:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(0, 245, 197) 100%
  );
}
.card2 table tr th.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(0, 245, 197) 50%,
    rgba(0, 245, 197, 0) 100%
  );
}
.card2 table tr.active td {
  color: #00f9c6;
}
.card2 table tr.active td:first-child:after {
  background: linear-gradient(
    to right,
    rgb(0, 245, 197) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card2 table tr.active td:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(0, 245, 197) 100%
  );
}
.card2 table tr.active td.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(0, 245, 197) 50%,
    rgba(0, 245, 197, 0) 100%
  );
}

.card3 table tr th {
  background-color: #0a4a08;
}
.card3 table tr th:first-child:after {
  background: linear-gradient(
    to right,
    rgb(1, 244, 6) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card3 table tr th:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 244, 6) 100%
  );
}
.card3 table tr th.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 244, 6) 50%,
    rgba(1, 244, 6, 0) 100%
  );
}
.card3 table tr.active td {
  color: #00ff06;
}
.card3 table tr.active td:first-child:after {
  background: linear-gradient(
    to right,
    rgb(1, 244, 6) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card3 table tr.active td:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 244, 6) 100%
  );
}
.card3 table tr.active td.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 244, 6) 50%,
    rgba(1, 244, 6, 0) 100%
  );
}

.card4 table tr th {
  background-color: #563100;
}
.card4 table tr th:first-child:after {
  background: linear-gradient(
    to right,
    rgb(255, 144, 0) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card4 table tr th:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(255, 144, 0) 100%
  );
}
.card4 table tr th.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(255, 144, 0) 50%,
    rgba(255, 144, 0, 0) 100%
  );
}
.card4 table tr.active td,
.card4 table tr.active td a {
  color: #ff9000;
}
.card4 table tr.active td:first-child:after {
  background: linear-gradient(
    to right,
    rgb(255, 144, 0) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card4 table tr.active td:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(255, 144, 0) 100%
  );
}
.card4 table tr.active td.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(255, 144, 0) 50%,
    rgba(255, 144, 0, 0) 100%
  );
}

.card5 table tr th {
  background-color: #5f4e00;
}
.card5 table tr th:first-child:after {
  background: linear-gradient(
    to right,
    rgb(237, 195, 1) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card5 table tr th:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(237, 195, 1) 100%
  );
}
.card5 table tr th.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(237, 195, 1) 50%,
    rgba(237, 195, 1, 0) 100%
  );
}
.card5 table tr.active td {
  color: #ffd200;
}
.card5 table tr.active td:first-child:after {
  background: linear-gradient(
    to right,
    rgb(237, 195, 1) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card5 table tr.active td:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(237, 195, 1) 100%
  );
}
.card5 table tr.active td.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(237, 195, 1) 50%,
    rgba(237, 195, 1, 0) 100%
  );
}

.card6 table tr th {
  background-color: #2e074e;
}
.card6 table tr th:first-child:after {
  background: linear-gradient(
    to right,
    rgb(147, 29, 243) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card6 table tr th:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(147, 29, 243) 100%
  );
}
.card6 table tr th.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(147, 29, 243) 50%,
    rgba(147, 29, 243, 0) 100%
  );
}
.card6 table tr.active td,
.card6 table tr.active td a {
  color: #9a1fff;
}
.card6 table tr.active td:first-child:after {
  background: linear-gradient(
    to right,
    rgb(147, 29, 243) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.card6 table tr.active td:last-child:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(147, 29, 243) 100%
  );
}
.card6 table tr.active td.blank:after {
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(147, 29, 243) 50%,
    rgba(147, 29, 243, 0) 100%
  );
}

.tooltip {
  position: relative;
  display: inline-block;
  align-items: center;
  margin-right: 0px;
  cursor: pointer;
  opacity: 1;
  line-height: 120%;
  vertical-align: top;
  margin-top: 4px;
  margin-left: 6px;
  font-size: 13px;
  z-index: 2;
}
.tooltip:hover .tooltipText {
  /* opacity: 1; */
  display: block;
  transform: translate(50%, 0) translateY(-8px);
}

.tooltipText {
  background-color: white;
  position: absolute;
  bottom: 130%;
  padding: 10px 15px;
  line-height: 115%;
  /* opacity: 0; */
  display: none;
  transition: all 0.5s;
  right: 50%;
  transform: translate(50%, 0) translateY(0px);
  width: 310px;
  font-family: "D-DIN Condensed";
  border-radius: 50px;
  font-weight: normal;
  color: #000000;
  font-size: 18px;
  text-align: center;
}
.tooltipText b {
  font-family: "D-DIN Condensed";
  font-weight: bold;
}
.tooltipText::after {
  content: "";
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  position: absolute;
  bottom: -18px;
  left: 50%;
  margin-left: -10px;
}

.footer-wrapper {
  background: transparent url(../images/footer-bg.png) center top no-repeat;
  position: relative;
}
.footer-wrapper h4 {
  margin-bottom: 10px;
  text-transform: uppercase;
  font-family: "D-DIN Condensed";
  font-weight: bold;
}
.footer-wrapper ul {
  width: 100%;
  float: left;
}
.footer-wrapper ul li {
  width: 100%;
  float: left;
  margin-top: 6px;
}
.footer-wrapper ul li a {
  font-size: 18px;
  color: white;
  line-height: 120%;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  letter-spacing: 2px;
}
.footer-wrapper ul li a:hover {
  color: #009cff;
}

.footer-box-wrap {
  padding-top: 90px;
  position: relative;
}

.footer-logo ul {
  width: 100%;
  float: left;
  padding: 30px 0;
}
.footer-logo ul li {
  width: auto;
  width: 148px;
  height: 45px;
  float: left;
  margin-right: 25px;
}
.footer-logo ul li:last-child {
  margin-right: 0px;
}
.footer-logo ul li img {
  width: 100%;
  height: 45px;
}

.copyright {
  background-color: #009cff;
  padding: 10px 0;
}
.copyright p {
  font-family: "D-DIN";
  text-transform: uppercase;
  font-size: 14px;
  line-height: 120%;
  color: white;
  font-weight: normal;
}
.copyright a {
  font-family: "D-DIN";
  text-transform: uppercase;
  font-size: 14px;
  line-height: 120%;
  color: white;
  font-weight: normal;
  display: inline-block;
}
.copyright a:hover {
  color: #000000;
}

/*---------------index start---------------*/
.main-banner {
  margin-top: 112px;
}
.main-banner .carousel.slide .carousel-control-arrow {
  width: 6%;
  z-index: 11;
}
.main-banner .carousel.slide .carousel-control-arrow span {
  width: 21px;
  height: 41px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
.main-banner
  .carousel.slide
  .carousel-control-arrow.carousel-control-prev
  span {
  background-image: url(../images/arrow-left.png);
}
.main-banner
  .carousel.slide
  .carousel-control-arrow.carousel-control-next
  span {
  background-image: url(../images/arrow-right.png);
}
.main-banner .carousel.slide .carousel-indicators {
  margin: 0 auto;
  bottom: 30px;
  display: none;
}
.main-banner .carousel.slide .carousel-indicators li {
  height: 6px;
  width: 6px;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.5);
  border: 0px;
  transition: all 0.25s ease all;
}
.main-banner .carousel.slide .carousel-indicators li.active {
  background: rgb(255, 255, 255);
  height: 8px;
  width: 8px;
  margin-top: -1px;
}
.main-banner .carousel.slide .carousel-inner .banner-con {
  position: absolute;
  left: 50%;
  top: 86%;
  text-align: center !important;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.carousel-inner img {
  width: 100%;
  /* height:calc( 100vh - 70px); */
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-object-fit: cover;
}

.step-section-raw {
  margin-top: 80px;
}
.step-section-raw ul {
  text-align: center;
  width: auto;
  float: none;
  display: inline-block;
  background: rgba(15, 34, 61, 0.98);
  padding: 50px;
  border: 3px solid transparent;
  position: relative;
  margin: 5px;
  border-radius: 10px;
}
.step-section-raw ul::before {
  position: absolute;
  top: 1px;
  right: 0;
  bottom: 0;
  left: 1px;
  height: 105%;
  width: 100.55%;
  content: "";
  background: rgb(0, 216, 255);
  background: linear-gradient(
    to right,
    rgb(0, 216, 255) 0%,
    rgb(8, 14, 33) 25%,
    rgb(0, 212, 255) 50%,
    rgb(8, 14, 33) 75%,
    rgb(0, 212, 255) 100%
  );
  z-index: -1;
  margin: -5px;
  border-radius: 10px;
}
.step-section-raw ul li {
  display: inline-block;
  vertical-align: middle;
  width: 30%;
  background: transparent url(../images/arrow-next.png) center right no-repeat;
}
.step-section-raw ul li:last-child {
  background-image: none;
}
.step-section-raw ul li .step-col > span {
  font-family: "proximanova";
  font-size: 80px;
  background: linear-gradient(
    to bottom,
    rgb(0, 216, 255) 0%,
    rgb(0, 156, 255) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  display: inline;
  vertical-align: middle;
  padding-right: 18px;
}
.step-section-raw ul li .step-col .img-fluid {
  display: inline-block;
  vertical-align: middle;
}
.step-section-raw ul li .step-col .step-conn {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding-left: 18px;
}
.step-section-raw ul li .step-col .step-conn .h6 {
  font-size: 18px;
  color: white;
  line-height: 120%;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
.step-section-raw ul li .step-col .step-conn p {
  width: auto;
  float: left;
  font-size: 14px;
  font-weight: 400;
  color: white;
  line-height: 120%;
  margin-top: 5px;
  opacity: 0.8;
}

.ongoing-events-raw {
  background: #060919 url(../images/homepage-bg.jpg);
  padding-bottom: 120px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.title-raw {
  text-align: center;
}
.title-raw h1,
.title-raw h2,
.title-raw h3 {
  position: relative;
  font-size: 50px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  background: -webkit-linear-gradient(#00d3ff, #3385cc);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.title-raw-itc h1,
.title-raw-itc h2,
.title-raw-itc h3 {
  background: -webkit-linear-gradient(#ffcd1e, #ffcd1e);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.title-raw h1 span,
.title-raw h2 span,
.title-raw h3 span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 120px;
  font-weight: 900;
  font-family: "Montserrat", sans-serif;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #50aeff;
  opacity: 0.1;
}
.title-raw h3 {
  font-size: 34px;
}
.title-raw h3 span {
  font-size: 56px;
}

.ongoing-competition-raw {
  padding: 52px 0 0 0;
}
@keyframes blink {
  from {
    text-shadow: 0px 0px 14px #00d4ff;
  }
  to {
    text-shadow: none;
  }
}
.game-lists-raw .competition-tab-raw {
  margin-top: 70px;
}
.game-lists-raw .competition-tab-raw .nav-tabs {
  text-align: center;
  display: block;
  border-bottom: 2px solid rgba(38, 88, 152, 0.7882352941);
}
.game-lists-raw .competition-tab-raw .nav-tabs li {
  display: inline-block;
  vertical-align: middle;
}
.game-lists-raw .competition-tab-raw .nav-tabs li .nav-link {
  background: transparent;
  color: white;
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: 0px;
  padding: 20px 0;
  position: relative;
  transition: all 0.25s ease 0s;
  margin: 0 20px;
}
.game-lists-raw .competition-tab-raw .nav-tabs li .nav-link::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1px;
  background: white;
  width: 100%;
  height: 2px;
  box-shadow: 0 0 10px rgb(255, 255, 255);
  transition: all 0.25s ease 0s;
  opacity: 0;
}
.game-lists-raw .competition-tab-raw .nav-tabs li .nav-link.active::before,
.game-lists-raw .competition-tab-raw .nav-tabs li .nav-link:hover::before {
  opacity: 1;
}
.game-lists-raw .competition-tab-raw .nav-tabs li .nav-link.live-tab {
  background: linear-gradient(
    to bottom,
    rgb(0, 216, 255) 0%,
    rgb(0, 156, 255) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  animation: blink linear 1s infinite;
  position: relative;
}
.game-lists-raw .competition-tab-raw .nav-tabs li .nav-link.live-tab::after {
  position: absolute;
  right: -5px;
  top: 14px;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100px;
  background: #ed1f24;
}
.game-lists-raw .gm-li-raw {
  margin-top: 40px;
  padding: 0 60px;
  z-index: 11;
}
.game-lists-raw .gm-li-raw .row {
  margin-right: -20px;
  margin-left: -20px;
}
.game-lists-raw .gm-li-raw .row .gm-li {
  margin-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
}
.game-lists-raw .gm-li-raw .row .gm-li .gm-col {
  background: #060919;
  box-shadow: 0 0 10px rgba(80, 174, 255, 0.3),
    inset 0 0 10px rgba(80, 174, 255, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(80, 174, 255, 0.3),
    inset 0 0 10px rgba(80, 174, 255, 0.3);
  border: 1px solid rgba(51, 133, 204, 0.6);
  border-radius: 10px;
  overflow: hidden;
}
.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
  height: 190px;
  overflow: hidden;
}
.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box a {
  height: 190px;
  display: block;
}
.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box img {
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
  transform: scale(1);
  /* object-fit: contain; */
  object-fit: cover;
  background-color: #fff;
}
.game-lists-raw .gm-li-raw .row .gm-li .gm-col:hover .img-box img {
  transform: scale(1.1);
}
.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw {
  padding: 18px 12px 18px;
  overflow: hidden;
  position: relative;
}
.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgb(51, 133, 204) 0%,
    rgba(125, 185, 232, 0) 50%
  );
  opacity: 0.4;
}
.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw::after {
  content: "";
  position: absolute;
  left: 10%;
  top: -15px;
  width: 80%;
  height: 20%;
  background: radial-gradient(
    ellipse at center,
    rgb(51, 133, 204) 0%,
    rgba(125, 185, 232, 0) 70%
  );
  opacity: 0.4;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn {
  position: relative;
  height: 100%;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  a {
  color: #009cff;
  z-index: 1;
  position: relative;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn {
  float: left;
  width: 70%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn
  h5 {
  font-size: 20px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  color: #009cff;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn
  h6 {
  /* font-weight: 700; */
  font-weight: 500;
  color: white;
  font-size: 12px;
  line-height: 120%;
  background: transparent url(../images/ic-play.png) left top 2px no-repeat;
  padding-left: 15px;
  margin-top: 6px;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .right-conn {
  float: left;
  width: 30%;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .right-conn
  h3 {
  text-align: center;
  float: right;
  font-size: 34px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  line-height: unset;
  margin-top: 10px;
  position: relative;
  text-shadow: 0px 0px 30px #00d4ff;
  -webkit-text-shadow: 0px 0px 30px #00d4ff;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .right-conn
  h3
  span {
  font-size: 14px;
  text-transform: uppercase;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col {
  border-top: 1px solid rgba(51, 233, 204, 0.1);
  padding-top: 15px;
  margin-top: 15px;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col
  li {
  display: inline-block;
  vertical-align: middle;
  /* &:first-child,
  &:nth-child(2) {
      width: 24%;
  } */
  /* &:last-child {
      text-align: right;
      width: 48%;
      position: absolute;
  } */
}
.btn span {
  display: inline-block;
  width: 190px;
  color: #fff;
  margin-left: 15px;
  font-size: 20px;
}
#btn_container {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  float: left;
  position: relative;
}
.btn img {
  left: 50%;
  margin-left: -15px;
  top: 36%;
  margin-top: -15px;
  position: absolute;
  width: 50px;
  height: auto;
}
.action_btn ul li {
  margin-bottom: 25px;
}
.action_btn {
  margin-bottom: 25px;
}
.action_btn {
  padding: 45px 15px 15px 15px;
}
.find_us_on {
  padding: 80px 0;
}
.action_btn {
  padding: 45px 15px 15px 15px;
}
#btn_container {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  float: left;
  position: relative;
}

.btn img {
  left: 50%;
  margin-left: -15px;
  top: 36%;
  margin-top: -15px;
  position: absolute;
  width: 50px;
  height: auto;
}

.btn span {
  display: inline-block;
  width: 190px;
  color: #fff;
  margin-left: 15px;
  font-size: 20px;
}

.action_btn ul li {
  margin-bottom: 25px;
}
.action_btn {
  margin-bottom: 25px;
}
.div_contain {
  padding: 25px;
}
.find_us_bg {
  background: url(https://iglnetwork.com/assets/images/right_side_img.png);
  background-size: 650px;
  background-repeat: no-repeat;
  background-position: right;
}
.app_store h4:hover {
  color: #00bfff;
}
.play_store h4:hover {
  color: #1df66a;
}
.find_us_on_box {
  background: linear-gradient(to bottom, #0499d0 0, #0e3555 100%);
  border-radius: 40px;
}
@media only screen and (max-device-width: 480px) {
  .find_us_bg {
    background-image: none;
  }
  .div_contain {
    padding: 20px 0 0 0;
  }
  .find_us_on_box {
    border-radius: 15px !important;
  }
  .div_contain {
    padding: 20px 0 0 0 !important;
  }
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col
  li
  span {
  font-family: "Montserrat", sans-serif;
  font-size: 10px;
  font-weight: 700;
  color: #009cff;
  text-transform: capitalize;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col
  li
  h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  /* font-weight: 700; */
  font-weight: 500;
  color: white;
  letter-spacing: 0.2px;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col
  li
  h6.sts-accepted {
  color: #03ff03;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col
  li
  h6.sts-pending {
  color: #f6ff00;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col
  li
  .btn-join {
  position: sticky;
  padding: 8px 34px 8px 14px;
  font-size: 12px;
}
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col
  li
  .btn-join.btn-cancel {
  padding: 8px 14px;
  background-image: none;
}

.game-listing-col .game-listing {
  display: none;
}

.btn-join,
.btn-ic {
  background: #07609f url(../images/ic-double-arrow.png) right 13px center
    no-repeat;
  border: 1px solid #50aeff;
  font-size: 14px;
  font-family: "D-DIN";
  font-weight: bold;
  text-shadow: 0px 0px 12px white;
  -webkit-text-shadow: 0px 0px 12px white;
  padding: 8px 38px 8px 18px;
  color: white;
  display: inline-block;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.25s ease 0s;
}
.btn-join:hover,
.btn-ic:hover {
  background-position: right 10px center;
  color: white;
}

.btn-ic {
  font-family: "D-DIN";
  font-weight: bold;
}

.trending-videos-raw {
  padding: 120px 0 0px 0;
}
.trending-videos-raw .gm-li-raw .row .gm-li .gm-col .img-box {
  height: 160px;
}
.trending-videos-raw .gm-li-raw .row .gm-li .gm-col .img-box .btn-play {
  position: relative;
  float: left;
  width: 100%;
}
.trending-videos-raw .gm-li-raw .row .gm-li .gm-col .img-box .btn-play img {
  transition: all 0.45s ease 0s;
}
.trending-videos-raw .gm-li-raw .row .gm-li .gm-col .img-box .btn-play::before {
  background: transparent url(../images/play-button.png) center center no-repeat;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.45s ease 0s;
}
.trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .img-box
  .btn-play:hover::before {
  opacity: 1;
}
.trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .img-box
  .btn-play:hover
  img {
  opacity: 0.1;
}
.trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn {
  width: 100% !important;
}
.trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn
  h6 {
  background: none;
  padding-left: 0px !important;
}
.trending-videos-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw .view-raw {
  border-top: 1px solid rgba(51, 233, 204, 0.1);
  padding-top: 15px;
  margin-top: 15px;
  position: relative;
}

.btn-view {
  font-size: 14px;
  color: white;
  line-height: 120%;
  display: inline-block;
  font-family: "D-DIN";
  font-weight: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.btn-sm {
  border-color: #50aeff;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 15px 40px;
  height: auto;
  line-height: inherit;
  font-size: 16px;
  min-width: auto;
  letter-spacing: 1px;
}

.mt500 {
  margin-top: 500px !important;
}

.latest-news-box-raw.latest-news-raw .latest-news-col {
  width: 76%;
  margin: 0 auto;
  float: none;
  padding: 0 4%;
}

.latest-news-raw .latest-news-col {
  margin-top: 20px;
  padding: 0 16%;
}
.latest-news-raw .latest-news-col .latest-news-li {
  margin-top: 30px;
}
.latest-news-raw .latest-news-col .latest-news-li .latest-news-box {
  background: rgba(38, 88, 182, 0.1);
  border-radius: 10px;
  border: 2px solid rgba(38, 88, 182, 0.5);
  padding: 30px 45px 30px 25px;
}
.latest-news-raw .latest-news-col .latest-news-li .latest-news-box .img-box {
  float: left;
  width: 25%;
  text-align: center;
}
.latest-news-raw
  .latest-news-col
  .latest-news-li
  .latest-news-box
  .img-box
  img {
  display: inline-block;
  border: 2px solid #3385cc;
  width: 128px;
}
.latest-news-raw .latest-news-col .latest-news-li .latest-news-box .news-box {
  float: left;
  width: 75%;
  padding-left: 20px;
}
.latest-news-raw
  .latest-news-col
  .latest-news-li
  .latest-news-box
  .news-box
  h4 {
  background: linear-gradient(
    to bottom,
    rgb(0, 216, 255) 0%,
    rgb(0, 156, 255) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  letter-spacing: 0.2px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  font-size: 24px;
  text-transform: uppercase;
}
.latest-news-raw
  .latest-news-col
  .latest-news-li
  .latest-news-box
  .news-box
  h5 {
  font-size: 16px;
  letter-spacing: 1px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
}
.latest-news-raw
  .latest-news-col
  .latest-news-li
  .latest-news-box
  .news-box
  h5
  sup {
  text-transform: uppercase;
  font-size: 10px;
}
.latest-news-raw .latest-news-col .latest-news-li .latest-news-box .news-box p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  margin-top: 25px;
  line-height: 140%;
  letter-spacing: 0.5px;
}
.latest-news-raw
  .latest-news-col
  .latest-news-li
  .latest-news-box
  .news-box
  p
  a {
  color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 6px;
}
.latest-news-raw
  .latest-news-col
  .latest-news-li
  .latest-news-box
  .news-box
  p
  a:hover {
  color: #00c0ff;
}

.modal-raw .social-list-raw {
  padding-top: 60px;
}
.modal-raw .social-list-raw li {
  margin: 0 6px;
}
.modal-raw .social-list-raw li a {
  width: 36px;
  height: 36px;
  display: inline-block;
  text-align: center;
  line-height: 36px;
  border-radius: 100px;
  background: #009cff;
}
.modal-raw .modal-content {
  border: 2px solid #3385cc;
  background: transparent;
  border-radius: 18px;
  position: relative;
}
.modal-raw .modal-content .btn-close-modal {
  position: absolute;
  right: 20px;
  top: 20px;
  background: transparent url(../images/ic-close-modal.png) center center
    no-repeat;
  width: 20px;
  height: 20px;
  z-index: 1;
  font-size: 0;
}
.modal-raw .modal-content .btn-close-modal:focus {
  outline: none;
}
.modal-raw .modal-content .modal-body {
  border-radius: 15px;
  overflow: hidden;
  padding: 30px 40px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#121e58+24,0b1233+100 */
  background: #121e58;
  background: linear-gradient(to bottom, #121e58 24%, #0b1233 100%);
  position: relative;
}
.modal-raw .modal-content .modal-body > div {
  position: relative;
  z-index: 0;
}
.modal-raw .modal-content .modal-body::before {
  content: "";
  position: absolute;
  left: 50%;
  top: -15%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    ellipse at center,
    rgb(51, 133, 204) 0%,
    rgba(51, 133, 204, 0) 70%
  );
  transform: translate(-50%, -50%);
  opacity: 0.55;
}
.modal-raw .modal-content .modal-body .title-raw {
  padding: 25px 0;
  position: relative;
}
.modal-raw .modal-content .modal-body .title-raw h3 {
  background: -webkit-linear-gradient(#00d3ff, #3385cc);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-weight: 800;
}
.modal-raw .modal-content .modal-body .title-raw h3 span {
  font-weight: 700;
}
.modal-raw .modal-content .modal-body .title-raw h2 span {
  font-size: 90px;
}
.modal-raw .modal-content .modal-body .field-name {
  color: white;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
.modal-raw .modal-content .modal-body .form-group {
  margin-bottom: 25px;
}
.modal-raw iframe {
  width: 100%;
  height: 100%;
  float: left;
  min-height: 260px;
  border-radius: 10px;
}
.modal-raw .form-control {
  border: 1px solid #065f9f;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  background: transparent;
  height: 54px;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.modal-raw .form-control::-webkit-outer-spin-button,
.modal-raw .form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.modal-raw .form-control[type="number"] {
  -webkit-appearance: textfield;
  appearance: textfield;
  -moz-appearance: textfield;
}
.modal-raw .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.modal-raw .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.modal-raw .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.modal-raw .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.modal-raw .form-control:focus {
  box-shadow: none;
  color: rgb(255, 255, 255);
}
.modal-raw.rules-modal .modal-lg {
  max-width: 1700px;
}
.modal-raw.rules-modal .modal-lg .modal-content {
  border: 0px;
  border-radius: 15px;
}
.modal-raw.rules-modal .modal-lg .modal-content .btn-close-modal {
  transition: all 0.25s ease 0s;
  background: rgba(255, 255, 255, 0.05) url(../images/ic-close-blue.png) center
    center no-repeat;
  width: 45px;
  height: 45px;
  border-radius: 100px;
}
.modal-raw.rules-modal .modal-lg .modal-content .btn-close-modal:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body {
  background: #06091a;
  padding: 10px 8% 80px;
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body::before {
  display: none;
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body .title-raw {
  margin-bottom: 30px;
  position: relative;
  background: transparent url(../images/modal-title-shadow.png) bottom center
    no-repeat;
  padding-bottom: 70px;
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body h4 {
  font-size: 16px;
  text-transform: uppercase;
  color: #009cff;
  font-family: "D-DIN";
  font-weight: bold;
  letter-spacing: normal;
  padding: 10px 15px;
  border-left: 3px solid white;
  background: linear-gradient(
    to right,
    rgba(0, 156, 255, 0.2) 0%,
    rgba(125, 185, 232, 0) 100%
  );
  margin: 20px 0 10px;
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body h5 {
  font-size: 16px;
  font-family: "D-DIN";
  font-weight: normal;
  color: white;
  padding: 15px 20px;
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body ul {
  padding: 10px 0;
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body ul li {
  background: transparent url(../images/list-arrow.png) left top 6px no-repeat;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  padding: 0px 20px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 140%;
  margin: 10px 0;
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body ul li strong {
  font-family: "D-DIN Condensed";
  font-weight: bold;
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body ul li:first-child {
  margin-top: 0px;
}
.modal-raw.rules-modal .modal-lg .modal-content .modal-body ul li:last-child {
  margin-bottom: 0px;
}
.modal-raw .mCustomScrollbar {
  max-height: 475px;
}

.floating-label-group {
  position: relative;
  margin-top: 15px;
  margin-bottom: 25px;
}
.floating-label-group .floating-label {
  color: #cccccc;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  transition: all 0.25s ease;
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
.floating-label-group input:focus ~ .floating-label,
.floating-label-group input:not(:focus):valid ~ .floating-label {
  top: -25px;
  bottom: 0px;
  left: 0px;
  color: white;
}

.dropdown-toggle {
  cursor: pointer;
}
.dropdown-toggle::after {
  display: none;
}

.notification-tab {
  position: relative;
}
.notification-tab::before {
  content: "";
  position: absolute;
  right: -2px;
  top: 5px;
  width: 8px;
  height: 8px;
  background: #00ff00;
  border-radius: 100px;
}

.header .notification .dropdown-menu-noti {
  display: none;
  width: auto;
  float: left;
  left: inherit;
  right: 62px;
  max-width: 500px;
  top: 100px;
  background: #002f4e;
  background: linear-gradient(to bottom, #002f4e 0%, #002137 100%);
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
  position: absolute;
  padding: 20px;
}
.header .notification .dropdown-menu-noti .top-section {
  position: relative;
  padding-bottom: 20px;
  border-bottom: 2px solid #005070;
}
.header .notification .dropdown-menu-noti .top-section::before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 15px 15px;
  border-color: transparent transparent #022b46 transparent;
  content: "";
  right: 15px;
  top: -35px;
  position: absolute;
  transition: all 0.45s ease 0s;
}
.header .notification .dropdown-menu-noti .top-section h5 {
  width: auto;
  float: left;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  letter-spacing: 0.4px;
}
.header .notification .dropdown-menu-noti .top-section a {
  float: right;
  font-size: 12px;
  letter-spacing: 1px;
  color: #d1d5d9;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  border-bottom: 1px solid #d1d5d9;
  padding-bottom: 1px;
}
.header .notification .dropdown-menu-noti .top-section a:hover {
  color: #00d3ff;
  border-color: #00d3ff;
}
.header .notification .dropdown-menu-noti .bottom-section {
  padding-top: 20px;
  border-top: 2px solid #005070;
  text-align: center;
}
.header .notification .dropdown-menu-noti .bottom-section a {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
  float: none;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.header .notification .dropdown-menu-noti .bottom-section a:hover {
  color: #00d3ff;
}
.header .notification .dropdown-menu-noti .notification-list li {
  border-top: 1px solid #00324e;
}
.header .notification .dropdown-menu-noti .notification-list li:first-child {
  border-top: 0px;
}
.header .notification .dropdown-menu-noti .notification-list li > p {
  margin-top: 20px;
}
.header .notification .dropdown-menu-noti .notification-list p {
  text-align: left;
  color: #00d8ff;
  font-size: 12px;
  margin-top: 20px;
  margin: 0px;
}
/* .notification-list img {
  width: 75px !important;
} */
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .table-list-notifies {
  margin: 0px;
}
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .table-list-notifies
  td {
  border: 0px;
  padding: 12px 0px;
}
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .table-list-notifies
  .date-time-col {
  padding: 0;
  width: 20%;
}
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .table-list-notifies
  .con-col
  .conn
  .img-fluid {
  width: 32px;
  height: 32px;
  border: 0px;
}
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .table-list-notifies
  .con-col
  .conn
  h6 {
  font-size: 16px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  text-transform: initial;
}
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .table-list-notifies
  .con-col
  .conn
  h6
  span {
  font-family: "D-DIN Condensed";
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 0.8px;
}
.header .dropdown-list {
  top: 62px;
  left: inherit;
  right: -30px;
  min-width: 250px;
  padding: 0px;
  margin: 0px;
  background: #060919;
  border: 0px;
  transition: 0.3s ease-in-out;
  height: 0px;
  overflow: hidden;
}
.header .dropdown-list {
  display: block !important;
}

.header .dropdown-list.notification-list {
  width: 425px;
  right: 0;
  /* display: none !important; */
  height: 0px;
  transition: 0.3s ease-in-out;
  margin-top: 7px;
}
.header .dropdown-list.notification-list li {
  color: #00c0ff;
  line-height: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .dropdown-list.notification-list li span {
  white-space: pre;
  font-size: 12px;
  color: #929292;
}

.header .dropdown-list.notification-list.show {
  height: 80vh !important;
  max-height: 600px;
  display: block !important;
  overflow-y: auto;
  border: 1px solid #00b9ff1f;
}
.header .dropdown-list.notification-list::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.main-notificationbox {
  display: grid;
  gap: 8px;
  margin: 16px;
}

.inner-card {
  background: #0c1730;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid #212d46;
}
.points-box {
  background: #010a20;
  padding: 10px;
  border-radius: 4px;
  color: #92e6ff;
}
.notification-card h1 {
  font-size: 12px;
  font-weight: 700;
  color: #00c0ff;
  background: none;
  -webkit-text-fill-color: #00c0ff;
}
.notification-card p {
  font-size: 12px;
  line-height: normal;
}

.notify-btn {
  background: #07609f url(../images/ic-double-arrow.png) right 13px center;
  background-repeat: no-repeat;
  border: 1px solid #50aeff;
  width: 100%;
  font-size: 14px;
  font-family: "D-DIN";
  font-weight: bold;
  text-shadow: 0px 0px 12px white;
  -webkit-text-shadow: 0px 0px 12px white;
  padding: 8px 38px 8px 18px;
  color: white;
  display: inline-block;
  border-radius: 20px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: all 0.25s ease 0s;
}

.trophy-image {
  position: relative;
  border-radius: 4px;
  padding: 4px;
  background: linear-gradient(179deg, #000000, #010a20);
}
.image-cover {
  width: 34px;
  height: 34px;
}
.image-cover img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.noti-desc {
  color: #00c5ff;
}
.noti-date {
  font-size: 10px !important;
}
.trophy-image::before {
  background: linear-gradient(180deg, #000, #00c5ff);
  border-radius: 5px;
  content: "";
  inset: 0;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  padding: 1px;
  pointer-events: none;
  position: absolute;
}

.dropdown-list.show {
  height: 230px !important;
}
.header .dropdown-list li {
  position: relative;
  z-index: 99 !important;
}
.header .dropdown-list li:first-child::before {
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    to right,
    rgba(0, 216, 255, 0.1) 0%,
    rgba(0, 216, 255, 0) 100%
  );
}
.header .dropdown-list li::after {
  height: 1px;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(0, 216, 255, 0.1) 0%,
    rgba(0, 216, 255, 0) 100%
  );
}
.header .dropdown-list li a {
  padding: 15px 20px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: white;
  border-left: 2px solid rgba(255, 255, 255, 0);
  position: relative;
}
.header .dropdown-list li a.active,
.header .dropdown-list li a:hover {
  border-left: 2px solid rgb(255, 255, 255);
  text-shadow: 0 0 15px rgb(80, 174, 255);
  -webkit-text-shadow: 0 0 15px rgb(80, 174, 255);
}
.header .dropdown-list li a.active::after,
.header .dropdown-list li a:hover::after {
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to right,
    rgba(0, 216, 255, 0.2) 0%,
    rgba(0, 216, 255, 0) 100%
  );
  z-index: -1;
}

/* .affix .header .logo { */
.affix .logo {
  /* padding: 0 20px; */
  width: 85px !important;
}
.affix .header .header-right .nav-main ul {
  margin-top: 0px;
}
.affix .header .notification {
  padding: 0;
  margin-top: -9px;
}
.affix .header .notification .dropdown-menu-noti {
  top: 85px;
}
.affix .header .dropdown-list {
  top: 56px;
}
.affix .header .setting-head {
  top: -4px;
}
.affix .header .login-search-ic {
  top: -10px;
}
.affix .header .login-raw {
  transition: all 0.25s ease 0s;
}

/*----------------index end----------------*/
/*----------------Game start------------------*/
.inner-banner {
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 650px;
  position: relative;
}
.inner-banner::before {
  height: 20%;
  width: 100%;
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(6, 9, 25, 0) 0%,
    rgb(6, 9, 25) 100%
  );
}
.inner-banner .container-fluid {
  height: 100%;
  position: relative;
}
.inner-banner .container-fluid .back-btn {
  position: absolute;
  left: 5%;
  top: 10%;
  color: rgba(255, 255, 255, 0.6);
  text-transform: uppercase;
  font-size: 22px;
  font-family: "D-DIN";
  font-weight: normal;
  background: transparent url(../images/ic-back.png) left 5px center no-repeat;
  transform: translate(0%, -50%);
  transition: all 0.25s ease 0s;
  padding: 14px 20px 14px 60px;
  z-index: 111;
}
.inner-banner .container-fluid .back-btn:hover {
  background-position: left 0 center;
}
.inner-banner .container-fluid .inner-banner-con {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
.inner-banner .container-fluid .inner-banner-con .title-raw h6 {
  font-size: 18px;
  letter-spacing: 2px;
  opacity: 0.8;
  text-transform: uppercase;
  vertical-align: middle;
  display: inline-block;
  float: none;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  margin-top: 10px;
}
.inner-banner .container-fluid .inner-banner-con .title-raw h6 span {
  background: transparent url(../images/ic-double-arrow-light.png) center center
    no-repeat;
  height: 14px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -8px;
}
.inner-banner .container-fluid .inner-banner-con .game-rules-col {
  width: auto;
  display: inline-block;
  float: none;
  margin-top: 15px;
}
.inner-banner .container-fluid .inner-banner-con .game-rules-col li {
  display: inline-block;
  vertical-align: middle;
}
.inner-banner
  .container-fluid
  .inner-banner-con
  .game-rules-col
  li
  .hexagon-col {
  width: 128px;
  height: 146px;
  background: transparent url(../images/hexagon-bg.png) center center no-repeat;
  background-size: contain;
  margin-bottom: 15px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.inner-banner .container-fluid .inner-banner-con .game-rules-col li h5 {
  font-size: 18px;
  color: white;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  opacity: 0.8;
}
.inner-banner .container-fluid .inner-banner-con .game-rules-col li h4 {
  font-size: 50px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  text-shadow: 0px 0px 50px #50aeff;
  -webkit-text-shadow: 0px 0px 50px #50aeff;
  width: auto;
  float: none;
  display: inline-block;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 0 0 15px #50aeff;
  -webkit-text-shadow: 0 0 15px #50aeff;
}
.inner-banner
  .container-fluid
  .inner-banner-con
  .game-rules-col
  li.like-col
  h4 {
  border-bottom: 2px solid #03bde5;
  padding-bottom: 5px;
}
.inner-banner
  .container-fluid
  .inner-banner-con
  .game-rules-col
  li.profile-col
  a {
  width: 100%;
  height: 100%;
  float: left;
}
.inner-banner
  .container-fluid
  .inner-banner-con
  .game-rules-col
  li.profile-col
  span {
  float: left;
  width: 100%;
  text-shadow: none;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#00d8ff, #009cff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  line-height: 120%;
  font-size: 18px;
}

.horizontal-tab-raw {
  padding: 0 20%;
  text-align: center;
  margin-bottom: 10px;
}
.horizontal-tab-raw .divide-raw {
  position: relative;
}
.horizontal-tab-raw .divide-raw::before {
  position: absolute;
  left: 0;
  top: -25px;
  content: "";
  height: 2px;
  width: 100%;
  background: rgb(0, 156, 255);
  background: linear-gradient(
    to right,
    rgb(0, 156, 255) 0%,
    rgb(0, 216, 255) 100%
  );
}
.horizontal-tab-raw .divide-raw .nav-tabs-dropdown {
  display: block !important;
  margin: 0 auto 5px;
  font-size: 26px;
  line-height: 120%;
  padding: 20px 0;
  position: relative;
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.horizontal-tab-raw .divide-raw .nav-tabs-dropdown::before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -3px;
  background: white;
  width: 15%;
  height: 2px;
  box-shadow: 0 0 10px rgb(255, 255, 255);
  transition: all 0.25s ease 0s;
  transform: translate(-50%, 0);
  z-index: 1;
}
.horizontal-tab-raw .divide-raw .nav-tabs-dropdown::after {
  display: inline-block;
  background: transparent url(../images/scroll-down.png) center center no-repeat;
  margin-left: 10px;
  content: "";
  width: 16px;
  height: 8px;
  position: relative;
  top: -3px;
}
.horizontal-tab-raw .divide-raw #nav-tabs-wrapper {
  display: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  text-align: center;
}
.horizontal-tab-raw .divide-raw .nav-tabs-horizontal {
  background: rgb(18, 30, 88);
  background: linear-gradient(
    to bottom,
    rgb(18, 30, 88) 24%,
    rgb(11, 18, 51) 99%
  );
  border: 0px;
  overflow: hidden;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  margin-top: -25px;
}
.horizontal-tab-raw .divide-raw .nav-tabs-horizontal > li {
  float: none;
  border-radius: 0px;
}
.horizontal-tab-raw .divide-raw .nav-tabs-horizontal > li,
.horizontal-tab-raw .divide-raw .nav-tabs-horizontal > li > a {
  background: transparent;
  width: 100%;
}
.horizontal-tab-raw .divide-raw .nav-tabs-horizontal > li > a {
  border: 0px;
  padding: 15px 20px;
  font-size: 20px;
  text-transform: uppercase;
  color: white;
  border-radius: 0px;
}
.horizontal-tab-raw .divide-raw .nav-tabs-horizontal > li > a.active {
  color: white;
  background: rgb(0, 156, 255);
  background: linear-gradient(
    to right,
    rgb(0, 156, 255) 0%,
    rgb(0, 216, 255) 100%
  );
}

.game-platform-raw .horizontal-tab-raw {
  padding: 0 20%;
  text-align: center;
  margin-bottom: 10px;
}
.game-platform-raw .horizontal-tab-raw .divide-raw {
  position: relative;
}
.game-platform-raw .horizontal-tab-raw .divide-raw::before {
  position: absolute;
  left: 0px;
  top: 0px;
  content: "";
  height: 2px;
  width: 100%;
  background: rgb(0, 156, 255);
  background: linear-gradient(
    to right,
    rgb(0, 156, 255) 0%,
    rgb(0, 216, 255) 100%
  );
}

.common-page-raw {
  background: #060919 url(../images/page-background.jpg) center top no-repeat;
  position: relative;
  overflow: hidden;
  background-size: 100% 100% !important;
}
.common-page-raw > .container {
  position: relative;
  z-index: 11;
}
.common-page-raw:before {
  content: "";
  position: absolute;
  left: -30%;
  top: -40%;
  width: 1000px;
  height: 1000px;
  background: transparent url(../images/shadow.png) center center no-repeat;
  background-size: contain;
  z-index: 1;
}
.common-page-raw:after {
  content: "";
  position: absolute;
  right: -30%;
  top: -40%;
  width: 1000px;
  height: 1000px;
  background: transparent url(../images/shadow.png) center center no-repeat;
  background-size: contain;
  z-index: 1;
}

.igl-tv-raw.common-page-raw:before,
.igl-tv-raw.common-page-raw:after {
  top: -30%;
}

.divide-raw {
  position: relative;
}
.divide-raw::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 2px;
  width: 100%;
  background: rgb(0, 156, 255);
  background: linear-gradient(
    to right,
    rgb(0, 156, 255) 0%,
    rgb(0, 216, 255) 100%
  );
}
.divide-raw::after {
  position: absolute;
  left: 0;
  top: 72px;
  content: "";
  height: 2px;
  width: 100%;
  background: rgb(0, 156, 255);
  background: linear-gradient(
    to right,
    rgb(0, 156, 255) 0%,
    rgb(0, 216, 255) 100%
  );
}

.all-games-raw {
  padding: 100px 0;
  position: relative;
}
.all-games-raw.game-lists-raw
  .game-platform-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn {
  width: 100%;
}
.all-games-raw.game-lists-raw
  .game-platform-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn
  h5 {
  text-align: center;
  font-size: 28px;
}
.all-games-raw.game-lists-raw
  .game-platform-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .plateform-players-col
  li:last-child {
  text-align: center;
  width: 100%;
}

.record-list-raw .line-arrow {
  width: 84%;
  margin: 0 auto;
  float: none;
}
.record-list-raw .record-list-col {
  width: 100%;
  text-align: center;
  padding: 0 13%;
  z-index: 1;
}
.record-list-raw .record-list-col ul {
  background: rgb(0, 216, 255);
  background: linear-gradient(
    to bottom,
    rgb(0, 216, 255) 0%,
    rgb(0, 156, 255) 100%
  );
  padding: 3% 0;
  border-radius: 10px;
  position: relative;
}
.record-list-raw .record-list-col ul::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: transparent url(../images/section-arrow.png) right center
    no-repeat;
  background-size: contain;
  transform: rotate(180deg);
}
.record-list-raw .record-list-col ul::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: transparent url(../images/section-arrow.png) right center
    no-repeat;
  background-size: contain;
}
.record-list-raw .record-list-col ul li {
  display: inline-block;
  text-align: center;
  background: transparent url(../images/seprator.png) right center no-repeat;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}
.record-list-raw .record-list-col ul li:last-child {
  background: none;
}
.record-list-raw .record-list-col ul li h3 {
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 62px;
  text-shadow: 2px 2px 2px rgba(150, 150, 150, 0.4);
  -webkit-text-shadow: 2px 2px 2px rgba(150, 150, 150, 0.4);
}
.record-list-raw .record-list-col ul li h3 span {
  text-shadow: none;
  -webkit-text-shadow: none;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  line-height: 120%;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.about-section-raw {
  text-align: center;
  padding: 30px 0;
}
.about-section-raw::before {
  background: radial-gradient(
    ellipse at center,
    rgb(51, 133, 204) 0%,
    rgba(51, 133, 204, 0) 70%
  );
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150%;
  height: 150%;
  content: "";
  opacity: 0.5;
  transform: translate(-50%, -50%);
}
.about-section-raw * {
  position: relative;
}
.about-section-raw .title-sec h2 {
  width: auto;
  display: inline-block;
  position: relative;
  float: none;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  background: -webkit-linear-gradient(#00d8ff, #009cff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin-bottom: 30px;
}
.about-section-raw .title-sec h2::before,
.about-section-raw .title-sec h2::after {
  position: absolute;
  content: "";
  width: 22px;
  height: 1px;
  transform: translate(0%, -50%);
  background: rgb(0, 156, 255);
  background: linear-gradient(
    to right,
    rgb(0, 156, 255) 0%,
    rgb(0, 216, 255) 100%
  );
}
.about-section-raw .title-sec h2::before {
  left: -40px;
  top: 50%;
}
.about-section-raw .title-sec h2::after {
  right: -40px;
  top: 50%;
}
.about-section-raw p {
  font-size: 18px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  color: white;
  line-height: 140%;
  padding: 0 15%;
  letter-spacing: 0.8px;
}
.about-section-raw .btn-ic {
  margin: 0 15px;
}

.btn-ic {
  background-color: transparent;
  text-shadow: none;
  -webkit-text-shadow: none;
  padding: 10px 20px 10px 50px;
  background-position: left 15px center;
}
.btn-ic i {
  display: inline-block;
  margin-right: 8px;
  margin-top: -5px;
  width: 32px;
  height: 22px;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: middle;
}
.btn-ic.ic-challenge,
.btn-ic.ic-more-game {
  text-shadow: 0px 0px 15px white;
  -webkit-text-shadow: 0px 0px 15px white;
  background-image: none;
  padding: 10px 20px;
  min-width: 216px;
}
.btn-ic.ic-challenge {
  padding-right: 24px;
}
.btn-ic.ic-challenge i {
  background-image: url(../images/ic-challenges-white.png);
}
.btn-ic.ic-more-game i {
  background-image: url(../images/ic-game-white.png);
}
.btn-ic:hover {
  background-color: #009cff;
  text-shadow: 0px 0px 15px white;
  -webkit-text-shadow: 0px 0px 15px white;
  background-position: left 15px center;
}
.btn-ic.bg-trans {
  background-color: rgba(0, 156, 255, 0.4);
  border-width: 2px;
  min-width: 210px;
}
.btn-ic.bg-trans:hover {
  background-color: rgba(0, 156, 255, 0.7);
}

/*-----------------Game end-------------------*/
/* Store Strat*/
.store-raw .tab-content {
  padding: 0 9%;
  position: relative;
}
.store-raw .tab-content::before {
  background: radial-gradient(
    ellipse at center,
    #3385cc 0%,
    rgba(51, 133, 204, 0) 70%
  );
  position: absolute;
  left: 50%;
  top: 40%;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.2;
  transform: translate(-50%, -50%);
}
.store-raw .tab-content .gm-li-raw .gm-li .gm-col .about-game-raw::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(51, 133, 204, 0) 0%,
    rgba(51, 133, 204, 0) 2%,
    rgba(51, 133, 204, 0) 25%,
    rgb(51, 133, 204) 100%
  );
  opacity: 0.4;
}

.store-tab .nav {
  display: block;
}
.store-tab li {
  float: none;
  display: inline-block;
  position: relative;
  z-index: 9;
  margin: 0 -2px;
}
.store-tab li .nav-link {
  min-width: 200px;
  font-weight: normal;
  font-size: 26px;
  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  line-height: 120%;
  text-align: center;
  position: relative;
  padding: 12px 25px;
}
.store-tab li .nav-link::before {
  border: 2px solid #082039;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform: skew(-35deg);
  transition: all 0.2s linear;
}
.store-tab li .nav-link::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 1px;
  background: white;
  width: 85%;
  height: 2px;
  box-shadow: 0 0 10px rgb(255, 255, 255);
  transition: all 0.25s ease 0s;
  opacity: 0;
}
.store-tab li .nav-link span {
  position: relative;
}
.store-tab li .nav-link.active {
  text-shadow: 1px 1px 1px white;
  -webkit-text-shadow: 1px 1px 1px white;
}
.store-tab li .nav-link.active::before {
  background: rgb(0, 157, 255);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgb(0, 157, 255)),
    color-stop(100%, rgb(9, 188, 220))
  );
  background: linear-gradient(
    to right,
    rgb(0, 157, 255) 0%,
    rgb(9, 188, 220) 100%
  );
  border-color: 2px solid rgba(255, 255, 255, 0);
}
.store-tab li .nav-link.active::after {
  opacity: 1;
}

.store-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col {
  text-align: center;
}
.store-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
  margin-top: 40px;
  height: auto;
  padding-bottom: 15px;
  text-align: center;
}
.store-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box .img-fluid {
  margin: 0 auto;
}
.store-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .img-box
  .img-fluid
  canvas {
  opacity: 0.5;
  margin-top: -8px;
}
.store-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box img {
  width: auto;
}
.store-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col:hover .img-box img {
  transform: scale(1);
}
.store-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .left-conn {
  width: 100%;
  position: relative;
}
.store-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .left-conn
  h5 {
  float: none;
  width: auto;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(38, 88, 182, 0.3);
  display: inline-block;
  font-size: 26px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 0px 0px 20px #00d4ff;
  -webkit-text-shadow: 0px 0px 20px #00d4ff;
}
.store-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .left-conn
  .price {
  display: block;
  margin-top: 5px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 22px;
  color: white;
  line-height: 120%;
}
.store-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .left-conn
  .price
  span {
  margin-left: 10px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
}
.store-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .left-conn
  .btn-join {
  padding: 8px 40px 9px 30px;
  margin-top: 20px;
}

.product-li-raw {
  margin-top: 40px;
}
.product-li-raw .row {
  margin-right: -20px;
  margin-left: -20px;
}
.product-li-raw .row .gm-li {
  margin-top: 40px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
}

/* Store End*/
/* Cart  Start*/
.cart-wrap {
  padding: 0 40px;
}
.cart-wrap table thead {
  position: relative;
}
.cart-wrap table thead:before {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 1px;
  content: "";
  top: -7px;
  position: absolute;
}
.cart-wrap table thead:after {
  background-color: rgba(255, 255, 255, 0.2);
  width: 100%;
  height: 1px;
  content: "";
  bottom: -7px;
  position: absolute;
}
.cart-wrap table thead tr {
  background-color: #0f223d;
}
.cart-wrap table thead tr th {
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#00d3ff, #3385cc);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.cart-wrap table thead tr th:after {
  display: none;
}
.cart-wrap table thead tr th:first-child:before {
  display: none;
}
.cart-wrap table thead tr th:before {
  background-color: rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 70%;
  content: "";
  position: absolute;
  left: 0;
  top: 8px;
}
.cart-wrap table tbody tr td {
  border-bottom-color: rgba(255, 255, 255, 0.2);
  vertical-align: middle;
}
.cart-wrap table tbody tr td:first-child {
  font-weight: 700;
  font-size: 32px;
}
.cart-wrap table tbody tr.total td {
  background-color: #0f223d;
  border: none;
  font-weight: 700;
  font-size: 26px;
  text-transform: capitalize;
}
.cart-wrap table tbody tr.total td span {
  vertical-align: top;
  padding: 0;
  font-weight: 400;
}
.cart-wrap table tbody tr.total td:nth-child(-n + 2) {
  background-color: transparent;
}
.cart-wrap .qty-drop select {
  background: rgba(255, 255, 255, 0.2) url(../images/ic-dropdown.png) no-repeat
    right 15px center;
  float: left;
  min-width: 140px;
  height: 40px;
  padding: 0 30px 0 15px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  color: white;
}
.cart-wrap .qty-drop select option {
  color: #333;
}

.cart-action {
  padding-top: 60px;
}
.cart-action .btn-main {
  margin-left: 20px;
  margin-right: 20px;
}

.brought-wrap {
  padding-top: 100px;
  text-align: center;
}
.brought-wrap .title {
  position: relative;
  z-index: 9;
}
.brought-wrap .title:after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 13px;
  left: 0;
  background: rgba(38, 88, 182, 0.3);
  z-index: -9;
}
.brought-wrap .title h4 {
  background-color: #06091a;
  float: none;
  display: inline-block;
  width: auto;
  padding: 0 50px;
  font-family: "D-DIN Condensed";
  text-transform: uppercase;
  color: white;
  position: relative;
  white-space: nowrap;
}
.brought-wrap .title h4 .b_l_quad {
  display: none;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(1) {
  animation: spot-1 0.7s 0.4 / random 10.5s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(2) {
  animation: spot-2 0.7s 0.4 / random 10.9s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(3) {
  animation: spot-3 0.7s 0.4 / random 11s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(4) {
  animation: spot-4 0.7s 0.4 / random 10.6s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(5) {
  animation: spot-5 0.7s 0.4 / random 10.7s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(6) {
  animation: spot-6 0.7s 0.4 / random 10.3s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(7) {
  animation: spot-7 0.7s 0.4 / random 10.5s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(8) {
  animation: spot-8 0.7s 0.4 / random 10.5s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(9) {
  animation: spot-9 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(10) {
  animation: spot-10 0.7s 0.4 / random 10.2s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(11) {
  animation: spot-11 0.7s 0.4 / random 10.3s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(12) {
  animation: spot-12 0.7s 0.4 / random 10.4s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(13) {
  animation: spot-13 0.7s 0.4 / random 11s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(14) {
  animation: spot-14 0.7s 0.4 / random 10.5s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(15) {
  animation: spot-15 0.7s 0.4 / random 10.5s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(16) {
  animation: spot-16 0.7s 0.4 / random 11s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(17) {
  animation: spot-17 0.7s 0.4 / random 10.4s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(18) {
  animation: spot-18 0.7s 0.4 / random 10.7s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(19) {
  animation: spot-19 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(20) {
  animation: spot-20 0.7s 0.4 / random 10.8s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(21) {
  animation: spot-21 0.7s 0.4 / random 10.9s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(22) {
  animation: spot-22 0.7s 0.4 / random 10.8s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(23) {
  animation: spot-23 0.7s 0.4 / random 10.6s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(24) {
  animation: spot-24 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(25) {
  animation: spot-25 0.7s 0.4 / random 10.6s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(26) {
  animation: spot-26 0.7s 0.4 / random 11s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(27) {
  animation: spot-27 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(28) {
  animation: spot-28 0.7s 0.4 / random 10.8s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(29) {
  animation: spot-29 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(30) {
  animation: spot-30 0.7s 0.4 / random 11s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(31) {
  animation: spot-31 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(32) {
  animation: spot-32 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(33) {
  animation: spot-33 0.7s 0.4 / random 10.3s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(34) {
  animation: spot-34 0.7s 0.4 / random 10.5s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(35) {
  animation: spot-35 0.7s 0.4 / random 10.2s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(36) {
  animation: spot-36 0.7s 0.4 / random 10.4s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(37) {
  animation: spot-37 0.7s 0.4 / random 11s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(38) {
  animation: spot-38 0.7s 0.4 / random 10.9s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(39) {
  animation: spot-39 0.7s 0.4 / random 10.4s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(40) {
  animation: spot-40 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(40) {
  animation: spot-40 0.7s 0.4 / random 10.5s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(41) {
  animation: spot-41 0.7s 0.4 / random 10.9s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(42) {
  animation: spot-42 0.7s 0.4 / random 10.7s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(43) {
  animation: spot-43 0.7s 0.4 / random 10.9s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(44) {
  animation: spot-44 0.7s 0.4 / random 10.8s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(45) {
  animation: spot-45 0.7s 0.4 / random 10.8s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(46) {
  animation: spot-46 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(46) {
  animation: spot-46 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(47) {
  animation: spot-47 0.7s 0.4 / random 10.5s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(48) {
  animation: spot-48 0.7s 0.4 / random 11s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(49) {
  animation: spot-49 0.7s 0.4 / random 10.2s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(50) {
  animation: spot-50 0.7s 0.4 / random 10.9s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(51) {
  animation: spot-51 0.7s 0.4 / random 10.2s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(52) {
  animation: spot-52 0.7s 0.4 / random 10.1s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(53) {
  animation: spot-53 0.7s 0.4 / random 10.7s linear infinite;
}
.brought-wrap .title h4 .b_l_quad .button_spots:nth-of-type(54) {
  animation: spot-54 0.7s 0.4 / random 11s linear infinite;
}
.brought-wrap .title h4 .button_spots {
  position: absolute;
  border-radius: 100px;
  background: green;
  animation: opacity 1s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(1) {
  transform-origin: 80px 15px;
  background: hsl(331, 52%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(2) {
  transform-origin: 81px 12px;
  background: hsl(195, 53%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.6s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(3) {
  transform-origin: 87px 14px;
  background: hsl(76, 56%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(4) {
  transform-origin: 89px 17px;
  background: hsl(340, 51%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.4s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(5) {
  transform-origin: 81px 16px;
  background: hsl(114, 54%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.6s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(6) {
  transform-origin: 81px 19px;
  background: hsl(125, 54%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.7s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(7) {
  transform-origin: 88px 19px;
  background: hsl(343, 47%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(8) {
  transform-origin: 84px 11px;
  background: hsl(224, 53%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.8s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(9) {
  transform-origin: 80px 19px;
  background: hsl(3, 56%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.7s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(10) {
  transform-origin: 85px 12px;
  background: hsl(165, 50%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(11) {
  transform-origin: 81px 11px;
  background: hsl(43, 56%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(12) {
  transform-origin: 85px 11px;
  background: hsl(300, 48%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.4s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(13) {
  transform-origin: 84px 12px;
  background: hsl(290, 49%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.6s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(14) {
  transform-origin: 85px 12px;
  background: hsl(42, 47%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.3s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(15) {
  transform-origin: 84px 17px;
  background: hsl(282, 48%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.5s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(16) {
  transform-origin: 81px 16px;
  background: hsl(207, 53%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.7s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(17) {
  transform-origin: 84px 12px;
  background: hsl(9, 53%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(18) {
  transform-origin: 85px 15px;
  background: hsl(51, 50%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.9s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(19) {
  transform-origin: 86px 13px;
  background: hsl(163, 56%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.4s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(20) {
  transform-origin: 86px 13px;
  background: hsl(327, 50%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(21) {
  transform-origin: 85px 15px;
  background: hsl(90, 54%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.5s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(22) {
  transform-origin: 83px 15px;
  background: hsl(202, 48%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.4s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(23) {
  transform-origin: 89px 16px;
  background: hsl(165, 52%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.9s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(24) {
  transform-origin: 83px 12px;
  background: hsl(55, 53%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.9s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(25) {
  transform-origin: 81px 19px;
  background: hsl(19, 54%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.4s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(26) {
  transform-origin: 82px 16px;
  background: hsl(39, 54%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.6s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(27) {
  transform-origin: 83px 18px;
  background: hsl(171, 51%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.3s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(28) {
  transform-origin: 89px 12px;
  background: hsl(74, 51%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.5s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(29) {
  transform-origin: 81px 14px;
  background: hsl(74, 53%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(30) {
  transform-origin: 82px 17px;
  background: hsl(228, 52%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.3s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(31) {
  transform-origin: 86px 18px;
  background: hsl(175, 54%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.5s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(32) {
  transform-origin: 80px 16px;
  background: hsl(33, 51%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.8s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(33) {
  transform-origin: 81px 10px;
  background: hsl(18, 52%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.3s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(34) {
  transform-origin: 80px 17px;
  background: hsl(179, 52%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.4s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(35) {
  transform-origin: 82px 10px;
  background: hsl(218, 47%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.7s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(36) {
  transform-origin: 88px 16px;
  background: hsl(53, 56%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.4s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(37) {
  transform-origin: 81px 11px;
  background: hsl(345, 47%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.9s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(38) {
  transform-origin: 88px 13px;
  background: hsl(190, 56%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.9s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(39) {
  transform-origin: 86px 19px;
  background: hsl(121, 56%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.9s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(40) {
  transform-origin: 87px 13px;
  background: hsl(237, 51%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.7s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(41) {
  transform-origin: 87px 10px;
  background: hsl(100, 48%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.9s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(42) {
  transform-origin: 88px 15px;
  background: hsl(61, 55%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.6s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(43) {
  transform-origin: 88px 10px;
  background: hsl(315, 49%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.5s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(44) {
  transform-origin: 84px 13px;
  background: hsl(202, 55%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.8s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(45) {
  transform-origin: 84px 11px;
  background: hsl(110, 49%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.9s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(46) {
  transform-origin: 80px 12px;
  background: hsl(43, 53%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.4s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(47) {
  transform-origin: 87px 12px;
  background: hsl(251, 48%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.9s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(48) {
  transform-origin: 81px 17px;
  background: hsl(66, 47%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(49) {
  transform-origin: 80px 12px;
  background: hsl(9, 49%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 2s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(50) {
  transform-origin: 88px 19px;
  background: hsl(169, 48%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.4s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(51) {
  transform-origin: 81px 18px;
  background: hsl(324, 54%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.3s;
}
.brought-wrap .title h4 .button_spots:nth-of-type(52) {
  transform-origin: 89px 17px;
  background: hsl(266, 56%, 65%);
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.12);
  transition: all 1.8s;
}
.brought-wrap .title h4:before,
.brought-wrap .title h4:after {
  width: 20px;
  height: 20px;
  border: 2px solid rgba(38, 88, 182, 0.3);
  content: "";
  position: absolute;
  top: 7px;
  left: 4px;
  transform: rotate(45deg);
}
.brought-wrap .title h4:after {
  left: inherit;
  right: 4px;
}

@keyframes spot-1 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(-18px);
    opacity: 0.6;
  }
}
@keyframes spot-2 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(-16px);
    opacity: 0.6;
  }
}
@keyframes spot-3 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(-14px);
    opacity: 0.6;
  }
}
@keyframes spot-4 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(-12px);
    opacity: 0.6;
  }
}
@keyframes spot-5 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(-10px);
    opacity: 0.6;
  }
}
@keyframes spot-6 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(-8px);
    opacity: 0.6;
  }
}
@keyframes spot-7 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(-6px);
    opacity: 0.6;
  }
}
@keyframes spot-8 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(-4px);
    opacity: 0.6;
  }
}
@keyframes spot-9 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(-2px);
    opacity: 0.6;
  }
}
@keyframes spot-10 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(0px);
    opacity: 0.6;
  }
}
@keyframes spot-11 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(2px);
    opacity: 0.6;
  }
}
@keyframes spot-12 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(4px);
    opacity: 0.6;
  }
}
@keyframes spot-13 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(6px);
    opacity: 0.6;
  }
}
@keyframes spot-14 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(8px);
    opacity: 0.6;
  }
}
@keyframes spot-15 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(10px);
    opacity: 0.6;
  }
}
@keyframes spot-16 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(12px);
    opacity: 0.6;
  }
}
@keyframes spot-17 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(14px);
    opacity: 0.6;
  }
}
@keyframes spot-18 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(16px);
    opacity: 0.6;
  }
}
@keyframes spot-19 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(18px);
    opacity: 0.6;
  }
}
@keyframes spot-20 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(30px) translatex(20px);
    opacity: 0.6;
  }
}
@keyframes spot-20 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(-10px);
    opacity: 0.6;
  }
}
@keyframes spot-21 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(-8px);
    opacity: 0.6;
  }
}
@keyframes spot-22 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(-6px);
    opacity: 0.6;
  }
}
@keyframes spot-23 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(-4px);
    opacity: 0.6;
  }
}
@keyframes spot-24 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(-2px);
    opacity: 0.6;
  }
}
@keyframes spot-25 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(0px);
    opacity: 0.6;
  }
}
@keyframes spot-26 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(2px);
    opacity: 0.6;
  }
}
@keyframes spot-27 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(4px);
    opacity: 0.6;
  }
}
@keyframes spot-28 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(6px);
    opacity: 0.6;
  }
}
@keyframes spot-29 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(8px);
    opacity: 0.6;
  }
}
@keyframes spot-30 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(10px);
    opacity: 0.6;
  }
}
@keyframes spot-31 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(12px);
    opacity: 0.6;
  }
}
@keyframes spot-32 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(14px);
    opacity: 0.6;
  }
}
@keyframes spot-33 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(16px);
    opacity: 0.6;
  }
}
@keyframes spot-34 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(18px);
    opacity: 0.6;
  }
}
@keyframes spot-35 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(20px);
    opacity: 0.6;
  }
}
@keyframes spot-36 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(22px);
    opacity: 0.6;
  }
}
@keyframes spot-37 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(24px);
    opacity: 0.6;
  }
}
@keyframes spot-38 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(26px);
    opacity: 0.6;
  }
}
@keyframes spot-39 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(28px);
    opacity: 0.6;
  }
}
@keyframes spot-40 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-30px) translatex(30px);
    opacity: 0.6;
  }
}
@keyframes spot-40 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-6px) translatex(40px);
    opacity: 0.6;
  }
}
@keyframes spot-41 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-4px) translatex(40px);
    opacity: 0.6;
  }
}
@keyframes spot-42 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-2px) translatex(40px);
    opacity: 0.6;
  }
}
@keyframes spot-43 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(0px) translatex(40px);
    opacity: 0.6;
  }
}
@keyframes spot-44 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(2px) translatex(40px);
    opacity: 0.6;
  }
}
@keyframes spot-45 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(4px) translatex(40px);
    opacity: 0.6;
  }
}
@keyframes spot-46 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-7px) translatex(-40px);
    opacity: 0.6;
  }
}
@keyframes spot-47 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-5px) translatex(-40px);
    opacity: 0.6;
  }
}
@keyframes spot-48 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-3px) translatex(-40px);
    opacity: 0.6;
  }
}
@keyframes spot-49 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(-1px) translatex(-40px);
    opacity: 0.6;
  }
}
@keyframes spot-50 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(1px) translatex(-40px);
    opacity: 0.6;
  }
}
@keyframes spot-51 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(3px) translatex(-40px);
    opacity: 0.6;
  }
}
@keyframes spot-52 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(5px) translatex(-40px);
    opacity: 0.6;
  }
}
@keyframes spot-53 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(7px) translatex(-40px);
    opacity: 0.6;
  }
}
@keyframes spot-54 {
  from {
    opacity: 0;
  }
  to {
    transform: translateY(9px) translatex(-40px);
    opacity: 0.6;
  }
}
@keyframes opacity {
  to {
    opacity: 0;
  }
}
@keyframes rotate {
  from {
    opacity: 0.8;
  }
  to {
    transform: rotate(360deg);
    opacity: 0.8;
  }
}
@keyframes down {
  from {
    left: 10px;
  }
  to {
    left: 57px;
  }
}
@keyframes spew {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.8;
  }
}
@keyframes final {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes finalbox {
  to {
    width: 50px;
  }
}
@keyframes tick {
  to {
    transform: scale(1) rotate(-90deg);
  }
}
.brought-slide {
  padding-top: 40px;
}
.brought-slide .slick-arrow {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 90px;
  font-size: 0;
}
.brought-slide .slick-arrow:focus {
  outline: none !important;
}
.brought-slide .slick-arrow.slick-prev {
  background: url(../images/slide-arrow-prev.png) no-repeat center;
  left: -50px;
}
.brought-slide .slick-arrow.slick-next {
  background: url(../images/slide-arrow-next.png) no-repeat center;
  right: -50px;
}
.brought-slide .slick-slide {
  margin-left: 15px;
  margin-right: 15px;
}

.brought-box {
  float: left;
  width: 100%;
  padding: 30px 0 20px;
  border: 1px solid #04355e;
  border-radius: 12px;
  min-height: 250px;
  position: relative;
  background: #060919;
}
.brought-box::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(
    to bottom,
    rgba(30, 80, 128, 0) 0%,
    rgb(30, 80, 128) 100%
  );
  border-radius: 10px;
}
.brought-box .img-box {
  display: inline-block;
  vertical-align: middle;
  line-height: 175px;
  position: relative;
}
.brought-box .img-box img {
  display: inline-block;
  vertical-align: middle;
}
.brought-box .text-box {
  padding-top: 40px;
  text-align: center;
  position: relative;
}
.brought-box .text-box h5 {
  float: none;
  display: block;
  width: 80%;
  min-width: 150px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 26px;
  padding: 0 10px 5px;
  margin: 0 auto 10px;
  color: white;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#00d8ff, #009cff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  border-bottom: 1px solid rgba(38, 88, 182, 0.3);
}
.brought-box .text-box .price {
  float: left;
  width: 100%;
  font-family: "D-DIN Condensed";
  font-size: 20px;
  color: white;
  letter-spacing: 1px;
  font-weight: bold;
}
.brought-box .text-box .price span {
  font-weight: normal;
  margin-left: 10px;
  letter-spacing: 0;
}
.brought-box .text-box .btn-join,
.brought-box .text-box .btn-ic {
  margin: 30px 7px 0;
}
.brought-box .text-box .btn-join {
  padding: 8px 35px 8px 20px;
  text-shadow: 0px 0px 15px white;
  -webkit-text-shadow: 0px 0px 15px white;
}
.brought-box .text-box .btn-ic {
  background: url(../images/ic-cart.png) left 13px center no-repeat;
  padding: 8px 10px 8px 24px;
}

.addcart-next-raw {
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  margin-top: 30px;
}
.addcart-next-raw .add-to-cart {
  width: 120px;
  display: inline-block;
  vertical-align: middle;
  margin-top: 0px !important;
  font-size: 12px;
}
.addcart-next-raw .add-qty-col {
  display: none;
  vertical-align: middle;
  width: 120px;
}
.addcart-next-raw .add-qty-col span {
  display: inline-block;
  vertical-align: middle;
}
.addcart-next-raw .add-qty-col span.minus-col,
.addcart-next-raw .add-qty-col span.plus-col {
  width: 32px;
  height: 32px;
  line-height: 30px;
  vertical-align: middle;
  border-radius: 100px;
  border: 2px solid #3486cc;
  font-size: 30px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: normal;
}
.addcart-next-raw .add-qty-col span.minus-col {
  line-height: 30px;
}
.addcart-next-raw .add-qty-col span.qty-col {
  font-family: "D-DIN";
  font-weight: bold;
  font-size: 18px;
  color: white;
  margin: 0 10px;
}
.addcart-next-raw .add-qty-col span.plus-col {
  line-height: 24px;
}
.addcart-next-raw .btn-join {
  display: inline-block;
  vertical-align: middle;
  margin-top: 0px !important;
  font-size: 12px;
}

/* Cart  End*/
.news-details-raw {
  padding: 50px 0 100px;
}

.details-head .img-box {
  display: inline-block;
  width: 186px;
  height: 186px;
}
.details-head .img-box img {
  border: 2px solid #3385cc;
}
.details-head h1 {
  font-family: "D-DIN Condensed";
  margin: 40px 0 25px;
}

.btn-ac {
  background-color: rgba(0, 156, 255, 0.6);
  display: inline-block;
  border: 2px solid #009cff;
  min-width: 160px;
  height: 40px;
  line-height: 35px;
  padding: 0 15px;
  margin: 0 10px;
  border-radius: 100px;
}
.btn-ac:hover {
  background-color: rgb(0, 156, 255);
}
.btn-ac span {
  font-size: 16px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  padding-left: 20px;
  letter-spacing: 1px;
  text-shadow: 0 0 13px rgba(255, 255, 255, 0.5);
  -webkit-text-shadow: 0 0 13px rgba(255, 255, 255, 0.5);
}
.btn-ac.like span {
  background: url(../images/ic-like.png) no-repeat left;
  padding-left: 25px;
}
.btn-ac.comment span {
  background: url(../images/ic-comment.png) no-repeat left;
}
.btn-ac.share span {
  background: url(../images/ic-share.png) no-repeat left;
}
.btn-ac.ic-rules span {
  background: url(../images/ic-rules.png) no-repeat left;
}
.btn-ac.ic-team span {
  background: url(../images/ic-team.png) no-repeat left;
  padding-left: 30px;
}
.btn-ac.ic-brackets span {
  background: url(../images/ic-arrow.png) no-repeat left;
}
.btn-ac.ic-checked-in span {
  background: url(../images/ic-check.png) no-repeat left;
}
.btn-ac.ic-lobby {
  box-shadow: 0 0 10px white;
  border: 1px solid white;
}
.btn-ac.ic-lobby span {
  background: url(../images/ic-lobby.png) no-repeat left;
}

.line-arrow {
  position: relative;
  border-top: 2px solid #2e313e;
  padding-top: 100px !important;
}
.line-arrow::before {
  position: absolute;
  content: "";
  left: 50%;
  top: 0px;
  background: white;
  width: 2px;
  height: 60px;
  box-shadow: 0 0 10px rgb(255, 255, 255);
  transition: all 0.25s ease 0s;
  transform: translate(-50%, -50%);
}

.details-body .description {
  float: left;
  width: 100%;
}
.details-body .description p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1rem;
}

.news-comment {
  padding-top: 60px;
  text-align: center;
}
.news-comment .item-comment {
  margin-bottom: 1.5rem;
}
.news-comment .news-comment-heading {
  font-family: "D-DIN Condensed";
  margin: 30px 0 25px 0;
  text-align: left;
  color: #fff;
  font-size: 20px;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}
.news-comment .news-comment-heading::before {
  content: "";
  position: absolute;
  left: -90px;
  top: 50%;
  border-top: 1px solid #ddd;
  height: 1px;
  width: 70px;
  left: 155px;
  width: -webkit-fill-available;
}
.news-comment .form-control {
  min-height: 50px;
  max-height: 250px;
  height: 100%;
  border: 2px solid #0e1f39;
  background-color: #0b162b;
  border-radius: 12px;
  padding: 15px;
  color: rgb(255, 255, 255);
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
.news-comment .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.news-comment .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.news-comment .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.news-comment .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.news-comment .btn-main {
  float: none;
  display: inline-block;
  min-width: inherit;
  padding: 0 25px;
  height: 40px;
  line-height: 35px;
  color: white;
  border-color: #009cff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.register-modal.modal-raw .modal-content {
  padding: 0;
}
.register-modal.modal-raw .modal-content .modal-body {
  padding: 30px 60px 60px;
  background: #090e26;
}
.register-modal .modal-dialog {
  max-width: 784px;
}
.register-modal .already-member-col {
  display: block;
  clear: both;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  margin-top: 20px;
}
.register-modal .already-member-col a {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #018feb;
  text-transform: uppercase;
}
.register-modal .already-member-col a:hover {
  color: #00c0ff;
}
.login-modal .modal-dialog {
  /* background: #121e58 !important; 
  background: linear-gradient(to bottom, #121e58 24%, #0b1233 100%) !important; */
}
#after_vote{
  background: none !important;
}
/* .login-modal .logsec {
background: none !important;
} */
.login-modal .modal-dialog {
  max-width: 600px;
  border-radius: 15px !important;
  overflow: hidden !important;
}

.login-modal .modal-dialog .modal-body {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
  background: #090e26;
}
.login-modal .forgot-pass-col {
  float: right;
  margin-top: 15px;
  font-size: 14px;
  color: #009cff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.login-modal .forgot-pass-col:hover {
  color: #00c0ff;
}
.login-modal .already-member-col {
  clear: both;
  display: block;
  margin: 20px 0 0;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
}
.login-modal .already-member-col a {
  display: inline-block;
  margin-top: 5px;
  font-size: 14px;
  color: #009cff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.login-modal .already-member-col a:hover {
  color: #00c0ff;
}
.login-modal .ic-email {
  background-image: url(../images/ic-mail.png);
  background-position: left 15px center;
  background-repeat: no-repeat;
  padding-left: 45px;
  background-size: 20px;
}
.login-modal .ic-pass {
  background-image: url(../images/ic-pass.png);
  background-position: left 18px center;
  background-repeat: no-repeat;
  padding-left: 45px;
  background-size: 14px;
}

.terms-and-policy-col {
  margin-top: 20px;
}
.terms-and-policy-col li {
  display: inline-block;
  vertical-align: middle;
  padding: 8px 15px;
  border-right: 1px solid #081c3a;
}
.terms-and-policy-col li:last-child {
  border-right: 0px solid #081c3a;
}
.terms-and-policy-col li a {
  color: rgba(255, 255, 255, 0.4);
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 12px;
}
.terms-and-policy-col li a:hover {
  color: rgb(255, 255, 255);
}

.terms-raw {
  display: inline-block;
  width: 70%;
  text-align: left;
  margin-top: 10px;
}
.terms-raw label {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  margin: 8px 0;
}
.terms-raw label a {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #018feb;
}
.terms-raw label a:hover {
  color: #00c0ff;
}
.terms-raw label input[type="radio"] {
  margin-right: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 100px;
  border: 2px solid #cbccd1;
  margin-top: -2px;
}
.terms-raw label input[type="radio"]:checked {
  background: #0097e6;
  border: 0px solid #0097e6;
  box-shadow: none;
  border-radius: 100px;
  outline: none;
  background: -webkit-linear-gradient(#00d3ff, #3385cc);
}

.title-divider {
  position: relative;
  text-align: center;
  font-size: 32px;
  text-transform: uppercase;
  background: -webkit-linear-gradient(#00d8ff, #009cff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  letter-spacing: 4px;
}
.title-divider::before,
.title-divider::after {
  position: absolute;
  content: "";
  width: 34%;
  height: 2px;
  transform: translate(0%, -50%);
  background: rgb(0, 156, 255);
  background: linear-gradient(
    to right,
    rgb(0, 156, 255) 0%,
    rgb(0, 216, 255) 100%
  );
}
.title-divider::before {
  left: 0;
  top: 50%;
}
.title-divider::after {
  right: 0;
  top: 50%;
}
.title-divider.line-none {
  padding: 20px 0;
}
.title-divider.line-none::before,
.title-divider.line-none::after {
  display: none;
}

.btn-link {
  font-size: 18px;
  text-transform: uppercase;
  color: white;
  display: inline-block;
  border-bottom: 2px solid #50aeff;
  padding-bottom: 10px;
  font-family: "D-DIN";
  font-weight: bold;
  letter-spacing: 1px;
  background: -webkit-linear-gradient(white, white);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  transition: all 0.25s ease 0s;
}
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  background: -webkit-linear-gradient(#00d8ff, #009cff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-decoration: none;
}

.ig-tv-raw .title-divider::before,
.ig-tv-raw .title-divider::after {
  width: 44%;
}
.ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col {
  border: 0px;
  box-shadow: none;
  border-radius: 0px;
  overflow: inherit;
  background: transparent;
}
.ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  text-align: center;
}
.ig-tv-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn {
  width: 100%;
}
.ig-tv-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn
  h5 {
  background: -webkit-linear-gradient(#00d8ff, #009cff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 22px;
}
.ig-tv-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn
  h6 {
  color: white;
  background: none;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  font-size: 18px;
}
.ig-tv-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw::before {
  display: none;
}
.ig-tv-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw::after {
  top: -10px;
}
.ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
  overflow: unset;
  height: 145px;
  box-shadow: 0 0 20px rgba(80, 174, 255, 0.3),
    inset 0 0 20px rgba(80, 174, 255, 0.3);
  -webkit-box-shadow: 0 0 20px rgba(80, 174, 255, 0.3),
    inset 0 0 20px rgba(80, 174, 255, 0.3);
  border-radius: 10px;
  overflow: hidden;
}
.ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box img {
  border-radius: 10px;
}
.ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box .btn-play {
  position: relative;
  float: left;
  width: 100%;
}
.ig-tv-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .img-box
  .btn-play
  img {
  transition: all 0.45s ease 0s;
}
.ig-tv-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .img-box
  .btn-play::before {
  background: transparent url(../images/play-button.png) center center no-repeat;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.45s ease 0s;
}
.ig-tv-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .img-box
  .btn-play:hover::before {
  opacity: 1;
}
.ig-tv-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .img-box
  .btn-play:hover
  img {
  opacity: 0.1;
}
.ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col:hover .img-box img {
  transform: scale(1);
}

.social-connects-raw {
  position: fixed;
  right: 30px;
  bottom: 6%;
  width: auto;
  z-index: 11;
}
.social-connects-raw li {
  display: inline-block;
  margin: 0px;
}
.social-connects-raw li span .img-fluid {
  max-width: 50px;
}

.challenge-raw::before,
.challenge-raw::after {
  display: none;
}
.challenge-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn
  h5 {
  font-size: 22px;
}
.challenge-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn
  h6 {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: white;
  padding-left: 0;
  background: no-repeat;
}
.challenge-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn
  h6
  span {
  color: #009cff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.challenge-raw .gm-li-raw .gm-li:first-child::before,
.challenge-raw .gm-li-raw .gm-li:last-child::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 45%;
  width: 140%;
  height: 140%;
  background: radial-gradient(
    ellipse at center,
    rgb(51, 133, 204) 0%,
    rgba(51, 133, 204, 0) 70%
  );
  transform: translate(-50%, -50%);
  opacity: 0.2;
}
.challenge-raw .challenge-info-raw {
  padding-top: 70px;
  text-align: center;
}
.challenge-raw .challenge-info-raw ul {
  position: relative;
}
.challenge-raw .challenge-info-raw ul::before {
  position: absolute;
  left: 0;
  top: 5px;
  height: 1px;
  width: 100%;
  background: #2e313e;
  content: "";
}
.challenge-raw .challenge-info-raw ul li {
  display: inline-block;
  vertical-align: top;
  background: #05091a;
  position: relative;
  margin: 0 30px;
}
.challenge-raw .challenge-info-raw ul li > span {
  background: -webkit-linear-gradient(#00d8ff, #009cff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 18px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.challenge-raw .challenge-info-raw ul li h6 {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 10px;
}
.challenge-raw .challenge-info-raw ul li.price-money-raw h6 {
  font-size: 42px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  line-height: 100%;
  text-shadow: 0px 0px 30px #00d4ff;
  -webkit-text-shadow: 0px 0px 30px #00d4ff;
}
.challenge-raw .challenge-info-raw ul li.price-money-raw h6 strong {
  font-size: 16px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  vertical-align: top;
  line-height: 100%;
}
.challenge-raw .disclaimer-raw p {
  font-size: 12px;
  color: #009cff;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 20px;
}
.challenge-raw .disclaimer-raw .btn-main {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  height: 42px;
  line-height: 38px;
  padding: 0 40px;
}

.challenges-banner-raw .btn-ic {
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
}
.challenges-banner-raw .inner-banner .container-fluid .inner-banner-con {
  top: 70%;
}

.challenges-raw.game-lists-raw .gm-li-raw .row {
  margin-right: -35px;
  margin-left: -35px;
}
.challenges-raw.game-lists-raw .gm-li-raw .row .gm-li {
  padding-right: 35px;
  padding-left: 35px;
}
.challenges-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw::after {
  top: -5px;
}
.challenges-raw.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .about-game-conn
  .left-conn {
  width: 100%;
  text-align: center;
}
.challenges-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
  height: 180px;
}

.btn-plus {
  display: inline-block;
  width: 35px;
  height: 35px;
  line-height: 31px;
  vertical-align: middle;
  text-align: center;
  background: #009cff;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.11);
  border-radius: 100px;
  color: white;
  font-size: 30px;
  position: absolute;
  bottom: -14px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

/*--------------leaderboard-start--------------*/
.leaderboard-raw .leader-list-raw {
  padding: 0 15%;
  position: relative;
  z-index: 11;
}
.leaderboard-raw .leader-list-raw .filter-raw .search-all {
  background: transparent;
  border: 0;
  float: right;
  box-shadow: none;
}
.leaderboard-raw .leader-list-raw .filter-raw .search-all:hover {
  outline: none;
}
.leaderboard-raw .leader-list-raw .leader-list-col {
  margin-top: 60px;
}
.leaderboard-raw .leader-list-raw .leader-list-col table tr th span {
  background: #0f223d;
  background: -webkit-linear-gradient(#00d3ff, #3385cc);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.leaderboard-raw
  .leader-list-raw
  .leader-list-col
  table
  tr
  th.choose-game:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #070919;
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 195, 236) 50%,
    rgba(1, 195, 236, 0) 100%
  );
}
.leaderboard-raw
  .leader-list-raw
  .leader-list-col
  table
  tr
  th.choose-game:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #070919;
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 195, 236) 50%,
    rgba(1, 195, 236, 0) 100%
  );
}
.leaderboard-raw
  .leader-list-raw
  .leader-list-col
  table
  tr
  th:first-child:before {
  content: "";
  position: absolute;
  width: 100%;
  float: left;
  height: 1px;
  left: 0;
  top: 0;
  background: linear-gradient(
    to right,
    rgb(1, 195, 236) 0%,
    rgba(15, 33, 60, 0.01) 99%,
    rgba(15, 33, 60, 0) 100%
  );
}
.leaderboard-raw
  .leader-list-raw
  .leader-list-col
  table
  tr
  th:last-child:before {
  content: "";
  position: absolute;
  width: 100%;
  float: left;
  height: 1px;
  left: 0;
  top: 0;
  background: linear-gradient(
    to right,
    rgba(15, 33, 60, 0) 0%,
    rgb(1, 195, 236) 100%
  );
}
.leaderboard-raw .leader-list-raw .leader-list-col table tr td {
  padding: 16px 10px;
}
.leaderboard-raw
  .leader-list-raw
  .leader-list-col
  table
  tr
  td:first-child:after,
.leaderboard-raw
  .leader-list-raw
  .leader-list-col
  table
  tr
  td:last-child:after {
  display: none;
}
.leaderboard-raw .leader-list-raw.tournament-lobby-raw {
  padding: 0 8%;
}

.hexa-img {
  clip-path: polygon(48% 0, 100% 25%, 100% 75%, 53% 100%, 1% 75%, 0 25%);
  -webkit-clip-path: polygon(
    48% 0,
    100% 25%,
    100% 75%,
    53% 100%,
    1% 75%,
    0 25%
  );
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-object-fit: cover;
  width: 32px;
  height: 38px;
  border: 2px solid #036b61;
}

/*---------------leaderboard-end--------------*/
/*--------------igtv-start--------------*/
.most-popular-raw {
  padding: 0 10%;
}
.most-popular-raw ul {
  margin-top: 30px;
  text-align: center;
  position: relative;
}
.most-popular-raw ul::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    ellipse at center,
    rgb(51, 133, 204) 0%,
    rgba(51, 133, 204, 0) 70%
  );
  transform: translate(-50%, -50%);
  opacity: 0.7;
}
.most-popular-raw ul li {
  display: inline-block;
  width: 19.5%;
  margin-top: 20px;
  padding: 0 15px;
  position: relative;
}
.most-popular-raw ul li::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 10px;
  width: 50%;
  height: 30px;
  background: radial-gradient(
    ellipse at center,
    rgb(0, 68, 254) 0%,
    rgba(0, 174, 255, 0) 72%,
    rgba(0, 215, 255, 0) 100%
  );
  transform: translate(-50%, -50%);
}
.most-popular-raw ul li a {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.most-popular-raw ul li a img {
  width: 100%;
  border: 1px solid rgba(51, 133, 204, 0.3);
  border-radius: 10px;
}
.most-popular-raw ul li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -50%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    ellipse at center,
    rgb(51, 133, 204) 0%,
    rgba(51, 133, 204, 0) 70%
  );
  opacity: 0.7;
}
.most-popular-raw ul li h5 {
  text-transform: uppercase;
  font-size: 22px;
  letter-spacing: 1px;
  font-family: "D-DIN Condensed";
  font-weight: 700;
  margin-top: 10px;
}

.search-game-raw .form-control {
  background-color: #060919;
  background-image: url(../images/search-ic.png);
  background-position: right 4px center;
  background-repeat: no-repeat;
  background-size: 34px;
  padding-right: 45px;
  letter-spacing: 1px;
  height: 45px;
  border-radius: 100px;
  position: relative;
  z-index: 1;
}
.search-game-raw .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.search-game-raw .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.search-game-raw .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.search-game-raw .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}

.tab-and-search-raw .igtv-raw .search-game-raw {
  width: 40%;
  float: right;
  padding-right: 60px;
}
.tab-and-search-raw .igtv-raw .nav-tabs {
  width: 50%;
  float: left;
  border: 0px;
  padding-left: 60px;
}
.tab-and-search-raw .igtv-raw .nav-tabs .nav-link {
  border: 1px solid #051830;
  background: #051830;
  font-size: 18px;
  line-height: 120%;
  padding: 5px 30px;
  text-transform: uppercase;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  color: white;
  border-radius: 20px;
  margin-right: 20px;
  letter-spacing: 1px;
}
.tab-and-search-raw .igtv-raw .nav-tabs .nav-link.active {
  border: 1px solid #009cff;
  background: #009cff;
  color: white;
  font-weight: bold;
}
.tab-and-search-raw .game-lists-raw .gm-li-raw .row .gm-li {
  margin-top: 0px;
  margin-bottom: 40px;
}

.mCustomScrollbar {
  width: 100%;
  float: left;
  max-height: 550px;
  overflow: auto;
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_draggerRail {
  background-color: rgba(66, 69, 85, 0.5);
}
.mCustomScrollbar .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: rgba(0, 156, 255, 0.75);
}

.follow-cointer-col .user-follower-col {
  display: inline-block;
}
.follow-cointer-col .user-follower-col span {
  font-size: 18px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.follow-cointer-col .user-follower-col .counter-col {
  font-family: "D-DIN Condensed";
  font-weight: bold;
  text-transform: uppercase;
  color: white;
  font-size: 50px;
  text-shadow: 0px 0px 30px #00d4ff;
  -webkit-text-shadow: 0px 0px 30px #00d4ff;
  height: 120px;
  width: 120px;
  line-height: 100px;
  background: transparent url(../images/pentagonal.png) center center no-repeat;
  margin-top: 8px;
}

.user-avtar-col {
  width: 220px;
  display: inline-block;
  background: url(../images/hexagon-bg.png) center center no-repeat;
  background-size: cover;
  padding: 28px 13px;
  margin: 0 40px;
  position: relative;
}
.user-avtar-col img {
  clip-path: polygon(50% 2%, 100% 25%, 100% 75%, 51% 100%, 1% 74%, 0 25%);
  -webkit-clip-path: polygon(
    50% 2%,
    100% 25%,
    100% 75%,
    51% 100%,
    1% 74%,
    0 25%
  );
}

.main-wrapper.profile-raw {
  background: #080718 url(../images/profile-bg-new.jpg) top 115px center
    no-repeat;
  background-size: contain !important;
  overflow: hidden;
}
.main-wrapper.profile-raw .user-avtar-col {
  float: left;
  bottom: 70px;
}
.main-wrapper.profile-raw .profile-intro {
  float: left;
  width: auto;
  text-align: left;
}
.main-wrapper.profile-raw .profile-intro h1 {
  background: linear-gradient(to bottom, #80ebff 0%, #039eff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 36px;
}
.main-wrapper.profile-raw .profile-intro p {
  color: white;
  font-size: 14px;
}
.main-wrapper.profile-raw .profile-intro p span {
  font-size: 24px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
  padding-left: 5px;
}
.main-wrapper.profile-raw .follow-points {
  width: 100%;
  float: left;
}

@media screen and (min-width: 479px) and (max-width: 600px) {
  .main-wrapper.profile-raw .follow-points {
    width: 100%;
    float: none;
  }
}
.main-wrapper.profile-raw .follow-points ul {
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.main-wrapper.profile-raw .follow-points ul li {
  display: inline-block;
  vertical-align: top;
  margin: 0 10px;
  position: relative;
  height: 74px;
  width: 100%;
}
.main-wrapper.profile-raw .follow-points ul li::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 50%;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(to bottom, #80ebff 0%, #039eff 100%);
}
.main-wrapper.profile-raw .follow-points ul li:last-child::before {
  display: none;
}
.main-wrapper.profile-raw .follow-points ul li span {
  background: linear-gradient(to bottom, #80ebff 0%, #039eff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.24;
}
.main-wrapper.profile-raw .follow-points ul li h6 {
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
  padding-top: 10px;
}
.main-wrapper.profile-raw .horizontal-tab-raw {
  padding: 0px;
  z-index: 111;
}
.main-wrapper.profile-raw .horizontal-tab-raw .nav-tabs-dropdown {
  display: none !important;
}
.main-wrapper.profile-raw .horizontal-tab-raw .nav-tabs-horizontal {
  display: block !important;
  background: linear-gradient(
    to right,
    rgba(0, 156, 255, 0) 0%,
    rgb(0, 154, 253) 50%,
    rgba(0, 156, 255, 0) 100%
  );
  overflow: visible;
  transition: all 1s ease 0s;
}
.main-wrapper.profile-raw .horizontal-tab-raw .nav-tabs-horizontal > li {
  display: inline-block;
  width: auto;
}
.main-wrapper.profile-raw .horizontal-tab-raw .nav-tabs-horizontal > li a {
  padding: 20px 30px;
  background: transparent;
  position: relative;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.8);
}
.main-wrapper.profile-raw
  .horizontal-tab-raw
  .nav-tabs-horizontal
  > li
  a.active {
  text-shadow: 0px 0px 30px #00d4ff;
  -webkit-text-shadow: 0px 0px 30px #00d4ff;
  font-weight: bold;
  color: rgb(255, 255, 255);
}
.main-wrapper.profile-raw
  .horizontal-tab-raw
  .nav-tabs-horizontal
  > li
  a.active::after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0px;
  background: #fff;
  width: 75%;
  height: 2px;
  box-shadow: 0 0 10px #fff;
  transition: all 0.25s ease 0s;
  transform: translate(-50%, 0);
  z-index: 1;
}
.main-wrapper.profile-raw
  .horizontal-tab-raw
  .nav-tabs-horizontal
  > li
  a::before {
  right: 0;
  top: 50%;
  height: 60%;
  width: 2px;
  background: #33709c;
  content: "";
  position: absolute;
  transform: translate(0, -50%);
  opacity: 0.6;
}
.main-wrapper.profile-raw
  .horizontal-tab-raw
  .nav-tabs-horizontal
  > li:last-child
  a::before {
  display: none;
}
.main-wrapper.profile-raw .horizontal-tab-raw .divide-raw::before {
  height: 1px;
}
.main-wrapper.profile-raw .horizontal-tab-raw .divide-raw::after {
  bottom: 0;
  top: inherit;
  height: 1px;
}

.balance-earned-raw {
  margin-top: 40px;
}
.balance-earned-raw ul {
  text-align: center;
}
.balance-earned-raw ul li {
  display: inline-block;
  margin: 0 20px;
}
.balance-earned-raw ul li span {
  font-size: 16px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 5px;
}
.balance-earned-raw ul li .coin-label {
  border: 1px solid #3385cc;
  border-radius: 4px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 28px;
  color: white;
  text-transform: uppercase;
  background: rgb(18, 30, 88);
  background: linear-gradient(
    to bottom,
    rgb(18, 30, 88) 0%,
    rgb(11, 18, 51) 76%
  );
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  -webkit-text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  padding: 20px 40px;
  margin-top: 10px;
}

/*--------------igtv-end--------------*/
.title-shadow {
  text-align: center;
}
.title-shadow h2,
.title-shadow h4 {
  width: auto;
  display: inline-block;
  float: none;
  position: relative;
  text-transform: uppercase;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 5px;
  background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.title-shadow h2::after,
.title-shadow h4::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -95%;
  width: 200%;
  height: 100%;
  transform: translate(-50%, 0%);
  background: transparent url(../images/title-shadow.png) center center
    no-repeat;
  background-size: contain;
}

.profile-details-raw .team-overview-raw .the-team-raw .team-li {
  margin-top: 100px;
}
.profile-details-raw .team-overview-raw .the-team-raw .team-li .team-li-conn {
  position: relative;
  background: #060919;
  box-shadow: 0 0 10px rgba(80, 174, 255, 0.3),
    inset 0 0 10px rgba(80, 174, 255, 0.3);
  -webkit-box-shadow: 0 0 10px rgba(80, 174, 255, 0.3),
    inset 0 0 10px rgba(80, 174, 255, 0.3);
  border: 1px solid rgba(51, 133, 204, 0.3);
  border-radius: 10px;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .btn-edit {
  position: absolute;
  left: 20px;
  top: 10px;
  background: transparent;
  box-shadow: none;
  border: 0;
  z-index: 1;
  padding: 10px;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .btn-edit:focus {
  outline: none;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .btn-close {
  position: absolute;
  right: 20px;
  top: 10px;
  background: transparent;
  box-shadow: none;
  border: 0;
  z-index: 1;
  padding: 10px;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(
    to bottom,
    rgb(30, 80, 128) 0%,
    rgba(30, 80, 128, 0.02) 100%
  );
  border-radius: 10px;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-img {
  display: block;
  position: relative;
  margin: -70px auto 0;
  float: none;
  width: 130px;
  height: 150px;
  line-height: 110px;
  background: transparent url(../images/hexagon-team-bg.png) center center
    no-repeat;
  background-size: contain;
  padding: 10px;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-img
  img {
  width: 100%;
  height: 100%;
  clip-path: polygon(
    51.5% 0%,
    0% 26.5%,
    0% 74.5%,
    50.5% 100%,
    100% 75.5%,
    100% 25.5%
  );
  object-fit: cover;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  h4 {
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 20px;
  background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin: 30px 0;
  position: relative;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-details-col {
  position: relative;
  overflow: hidden;
  padding: 30px;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-details-col::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 60%;
  height: 2px;
  background: #062f56;
  opacity: 0.8;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-details-col::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  width: 60%;
  height: 45px;
  background: radial-gradient(
    ellipse at center,
    #3385cc 0%,
    rgba(125, 185, 232, 0) 70%
  );
  opacity: 0.4;
  transform: translate(-50%, -50%);
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-details-col
  table
  tr
  td {
  border: 0px;
  text-align: center;
  color: #009cff;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding: 10px;
  text-transform: capitalize;
  vertical-align: middle;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-details-col
  table
  tr
  td:first-child {
  text-align: right;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-details-col
  table
  tr
  td:last-child {
  text-align: left;
}
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .team-details-col
  table
  tr
  td
  h5 {
  color: #fefefe;
  font-size: 20px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  text-transform: capitalize;
  letter-spacing: initial;
}
.profile-details-raw .title-shadow h2 {
  font-size: 34px;
  position: relative;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  letter-spacing: 3px;
}
.profile-details-raw .title-shadow h2::after {
  width: 150%;
}

.edit-member-btn {
  cursor: pointer;
}

.fixed .horizontal-tab-raw.section,
.fixed .create-team-modal .modal-form .horizontal-tab-raw.form-grop,
.create-team-modal .modal-form .fixed .horizontal-tab-raw.form-grop,
.fixed .create-team-modal .modal-form .horizontal-tab-raw.section-title,
.create-team-modal .modal-form .fixed .horizontal-tab-raw.section-title,
.fixed .challenger-list-col ul li.horizontal-tab-raw,
.challenger-list-col ul .fixed li.horizontal-tab-raw,
.fixed .horizontal-tab-raw.challenger-list-col,
.fixed .contact-gaming-raw .contact-sub .horizontal-tab-raw.form-group,
.contact-gaming-raw .contact-sub .fixed .horizontal-tab-raw.form-group,
.fixed .tournament-details-col ul li strong.horizontal-tab-raw,
.tournament-details-col ul li .fixed strong.horizontal-tab-raw,
.fixed .trophy-raw .trophy-li .horizontal-tab-raw.trophy-col,
.trophy-raw .trophy-li .fixed .horizontal-tab-raw.trophy-col,
.fixed table.horizontal-tab-raw,
.fixed .horizontal-tab-raw.scroll-wrap,
.fixed .horizontal-tab-raw.main-wrapper,
.fixed
  .league-wrapper
  .expert-master
  .progress-wrap
  .horizontal-tab-raw.redius-col,
.league-wrapper
  .expert-master
  .progress-wrap
  .fixed
  .horizontal-tab-raw.redius-col,
.fixed .horizontal-tab-raw.select-dropdown,
.fixed .horizontal-tab-raw.select-game,
.fixed .step-section-raw ul li .horizontal-tab-raw.step-col,
.step-section-raw ul li .fixed .horizontal-tab-raw.step-col,
.fixed .horizontal-tab-raw.title-raw,
.fixed .game-lists-raw .gm-li-raw .row .gm-li .horizontal-tab-raw.gm-col,
.game-lists-raw .gm-li-raw .row .gm-li .fixed .horizontal-tab-raw.gm-col,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .horizontal-tab-raw.img-box,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .fixed
  .horizontal-tab-raw.img-box,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .horizontal-tab-raw.about-game-raw,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .fixed
  .horizontal-tab-raw.about-game-raw,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.about-game-conn,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.about-game-conn,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.plateform-players-col,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.plateform-players-col,
.fixed
  .trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.view-raw,
.trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.view-raw,
.fixed
  .header
  .notification
  .dropdown-menu-noti
  .notification-list
  li.horizontal-tab-raw,
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .fixed
  li.horizontal-tab-raw,
.fixed .header .dropdown-list li.horizontal-tab-raw,
.header .dropdown-list .fixed li.horizontal-tab-raw,
.fixed .header .dropdown-list li a.horizontal-tab-raw,
.header .dropdown-list li .fixed a.horizontal-tab-raw,
.fixed .record-list-raw .record-list-col ul li h3 span.horizontal-tab-raw,
.record-list-raw .record-list-col ul li h3 .fixed span.horizontal-tab-raw,
.fixed .store-raw .horizontal-tab-raw.tab-content,
.store-raw .fixed .horizontal-tab-raw.tab-content,
.fixed .terms-raw label.horizontal-tab-raw,
.terms-raw .fixed label.horizontal-tab-raw,
.fixed .challenge-raw .challenge-info-raw ul li > span.horizontal-tab-raw,
.challenge-raw .challenge-info-raw ul .fixed li > span.horizontal-tab-raw,
.fixed .most-popular-raw ul li a.horizontal-tab-raw,
.most-popular-raw ul li .fixed a.horizontal-tab-raw,
.fixed .horizontal-tab-raw.balance-earned-raw,
.fixed
  .profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .horizontal-tab-raw.team-li-conn,
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .fixed
  .horizontal-tab-raw.team-li-conn,
.fixed
  .profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .horizontal-tab-raw.team-details-col,
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .fixed
  .horizontal-tab-raw.team-details-col {
  position: fixed;
  left: 0;
  top: 146px;
}
.fixed .horizontal-tab-raw.section .nav-tabs-horizontal,
.fixed
  .create-team-modal
  .modal-form
  .horizontal-tab-raw.form-grop
  .nav-tabs-horizontal,
.create-team-modal
  .modal-form
  .fixed
  .horizontal-tab-raw.form-grop
  .nav-tabs-horizontal,
.fixed
  .create-team-modal
  .modal-form
  .horizontal-tab-raw.section-title
  .nav-tabs-horizontal,
.create-team-modal
  .modal-form
  .fixed
  .horizontal-tab-raw.section-title
  .nav-tabs-horizontal,
.fixed .challenger-list-col ul li.horizontal-tab-raw .nav-tabs-horizontal,
.challenger-list-col ul .fixed li.horizontal-tab-raw .nav-tabs-horizontal,
.fixed .horizontal-tab-raw.challenger-list-col .nav-tabs-horizontal,
.fixed
  .contact-gaming-raw
  .contact-sub
  .horizontal-tab-raw.form-group
  .nav-tabs-horizontal,
.contact-gaming-raw
  .contact-sub
  .fixed
  .horizontal-tab-raw.form-group
  .nav-tabs-horizontal,
.fixed
  .tournament-details-col
  ul
  li
  strong.horizontal-tab-raw
  .nav-tabs-horizontal,
.tournament-details-col
  ul
  li
  .fixed
  strong.horizontal-tab-raw
  .nav-tabs-horizontal,
.fixed
  .trophy-raw
  .trophy-li
  .horizontal-tab-raw.trophy-col
  .nav-tabs-horizontal,
.trophy-raw
  .trophy-li
  .fixed
  .horizontal-tab-raw.trophy-col
  .nav-tabs-horizontal,
.fixed table.horizontal-tab-raw .nav-tabs-horizontal,
.fixed .horizontal-tab-raw.scroll-wrap .nav-tabs-horizontal,
.fixed .horizontal-tab-raw.main-wrapper .nav-tabs-horizontal,
.fixed
  .league-wrapper
  .expert-master
  .progress-wrap
  .horizontal-tab-raw.redius-col
  .nav-tabs-horizontal,
.league-wrapper
  .expert-master
  .progress-wrap
  .fixed
  .horizontal-tab-raw.redius-col
  .nav-tabs-horizontal,
.fixed .horizontal-tab-raw.select-dropdown .nav-tabs-horizontal,
.fixed .horizontal-tab-raw.select-game .nav-tabs-horizontal,
.fixed
  .step-section-raw
  ul
  li
  .horizontal-tab-raw.step-col
  .nav-tabs-horizontal,
.step-section-raw
  ul
  li
  .fixed
  .horizontal-tab-raw.step-col
  .nav-tabs-horizontal,
.fixed .horizontal-tab-raw.title-raw .nav-tabs-horizontal,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .horizontal-tab-raw.gm-col
  .nav-tabs-horizontal,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .fixed
  .horizontal-tab-raw.gm-col
  .nav-tabs-horizontal,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .horizontal-tab-raw.img-box
  .nav-tabs-horizontal,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .fixed
  .horizontal-tab-raw.img-box
  .nav-tabs-horizontal,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .horizontal-tab-raw.about-game-raw
  .nav-tabs-horizontal,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .fixed
  .horizontal-tab-raw.about-game-raw
  .nav-tabs-horizontal,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.about-game-conn
  .nav-tabs-horizontal,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.about-game-conn
  .nav-tabs-horizontal,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.plateform-players-col
  .nav-tabs-horizontal,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.plateform-players-col
  .nav-tabs-horizontal,
.fixed
  .trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.view-raw
  .nav-tabs-horizontal,
.trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.view-raw
  .nav-tabs-horizontal,
.fixed
  .header
  .notification
  .dropdown-menu-noti
  .notification-list
  li.horizontal-tab-raw
  .nav-tabs-horizontal,
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .fixed
  li.horizontal-tab-raw
  .nav-tabs-horizontal,
.fixed .header .dropdown-list li.horizontal-tab-raw .nav-tabs-horizontal,
.header .dropdown-list .fixed li.horizontal-tab-raw .nav-tabs-horizontal,
.fixed .header .dropdown-list li a.horizontal-tab-raw .nav-tabs-horizontal,
.header .dropdown-list li .fixed a.horizontal-tab-raw .nav-tabs-horizontal,
.fixed
  .record-list-raw
  .record-list-col
  ul
  li
  h3
  span.horizontal-tab-raw
  .nav-tabs-horizontal,
.record-list-raw
  .record-list-col
  ul
  li
  h3
  .fixed
  span.horizontal-tab-raw
  .nav-tabs-horizontal,
.fixed .store-raw .horizontal-tab-raw.tab-content .nav-tabs-horizontal,
.store-raw .fixed .horizontal-tab-raw.tab-content .nav-tabs-horizontal,
.fixed .terms-raw label.horizontal-tab-raw .nav-tabs-horizontal,
.terms-raw .fixed label.horizontal-tab-raw .nav-tabs-horizontal,
.fixed
  .challenge-raw
  .challenge-info-raw
  ul
  li
  > span.horizontal-tab-raw
  .nav-tabs-horizontal,
.challenge-raw
  .challenge-info-raw
  ul
  .fixed
  li
  > span.horizontal-tab-raw
  .nav-tabs-horizontal,
.fixed .most-popular-raw ul li a.horizontal-tab-raw .nav-tabs-horizontal,
.most-popular-raw ul li .fixed a.horizontal-tab-raw .nav-tabs-horizontal,
.fixed .horizontal-tab-raw.balance-earned-raw .nav-tabs-horizontal,
.fixed
  .profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .horizontal-tab-raw.team-li-conn
  .nav-tabs-horizontal,
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .fixed
  .horizontal-tab-raw.team-li-conn
  .nav-tabs-horizontal,
.fixed
  .profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .horizontal-tab-raw.team-details-col
  .nav-tabs-horizontal,
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .fixed
  .horizontal-tab-raw.team-details-col
  .nav-tabs-horizontal {
  background: linear-gradient(
    to right,
    rgba(5, 7, 21, 0) 0%,
    rgb(5, 7, 21) 25%,
    rgb(5, 7, 21) 51%,
    rgb(5, 7, 21) 75%,
    rgba(5, 7, 21, 0) 100%
  );
}
.fixed .horizontal-tab-raw.section .divide-raw::before,
.fixed
  .create-team-modal
  .modal-form
  .horizontal-tab-raw.form-grop
  .divide-raw::before,
.create-team-modal
  .modal-form
  .fixed
  .horizontal-tab-raw.form-grop
  .divide-raw::before,
.fixed
  .create-team-modal
  .modal-form
  .horizontal-tab-raw.section-title
  .divide-raw::before,
.create-team-modal
  .modal-form
  .fixed
  .horizontal-tab-raw.section-title
  .divide-raw::before,
.fixed .challenger-list-col ul li.horizontal-tab-raw .divide-raw::before,
.challenger-list-col ul .fixed li.horizontal-tab-raw .divide-raw::before,
.fixed .horizontal-tab-raw.challenger-list-col .divide-raw::before,
.fixed
  .contact-gaming-raw
  .contact-sub
  .horizontal-tab-raw.form-group
  .divide-raw::before,
.contact-gaming-raw
  .contact-sub
  .fixed
  .horizontal-tab-raw.form-group
  .divide-raw::before,
.fixed
  .tournament-details-col
  ul
  li
  strong.horizontal-tab-raw
  .divide-raw::before,
.tournament-details-col
  ul
  li
  .fixed
  strong.horizontal-tab-raw
  .divide-raw::before,
.fixed
  .trophy-raw
  .trophy-li
  .horizontal-tab-raw.trophy-col
  .divide-raw::before,
.trophy-raw
  .trophy-li
  .fixed
  .horizontal-tab-raw.trophy-col
  .divide-raw::before,
.fixed table.horizontal-tab-raw .divide-raw::before,
.fixed .horizontal-tab-raw.scroll-wrap .divide-raw::before,
.fixed .horizontal-tab-raw.main-wrapper .divide-raw::before,
.fixed
  .league-wrapper
  .expert-master
  .progress-wrap
  .horizontal-tab-raw.redius-col
  .divide-raw::before,
.league-wrapper
  .expert-master
  .progress-wrap
  .fixed
  .horizontal-tab-raw.redius-col
  .divide-raw::before,
.fixed .horizontal-tab-raw.select-dropdown .divide-raw::before,
.fixed .horizontal-tab-raw.select-game .divide-raw::before,
.fixed .step-section-raw ul li .horizontal-tab-raw.step-col .divide-raw::before,
.step-section-raw ul li .fixed .horizontal-tab-raw.step-col .divide-raw::before,
.fixed .horizontal-tab-raw.title-raw .divide-raw::before,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .horizontal-tab-raw.gm-col
  .divide-raw::before,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .fixed
  .horizontal-tab-raw.gm-col
  .divide-raw::before,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .horizontal-tab-raw.img-box
  .divide-raw::before,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .fixed
  .horizontal-tab-raw.img-box
  .divide-raw::before,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .horizontal-tab-raw.about-game-raw
  .divide-raw::before,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .fixed
  .horizontal-tab-raw.about-game-raw
  .divide-raw::before,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.about-game-conn
  .divide-raw::before,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.about-game-conn
  .divide-raw::before,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.plateform-players-col
  .divide-raw::before,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.plateform-players-col
  .divide-raw::before,
.fixed
  .trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.view-raw
  .divide-raw::before,
.trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.view-raw
  .divide-raw::before,
.fixed
  .header
  .notification
  .dropdown-menu-noti
  .notification-list
  li.horizontal-tab-raw
  .divide-raw::before,
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .fixed
  li.horizontal-tab-raw
  .divide-raw::before,
.fixed .header .dropdown-list li.horizontal-tab-raw .divide-raw::before,
.header .dropdown-list .fixed li.horizontal-tab-raw .divide-raw::before,
.fixed .header .dropdown-list li a.horizontal-tab-raw .divide-raw::before,
.header .dropdown-list li .fixed a.horizontal-tab-raw .divide-raw::before,
.fixed
  .record-list-raw
  .record-list-col
  ul
  li
  h3
  span.horizontal-tab-raw
  .divide-raw::before,
.record-list-raw
  .record-list-col
  ul
  li
  h3
  .fixed
  span.horizontal-tab-raw
  .divide-raw::before,
.fixed .store-raw .horizontal-tab-raw.tab-content .divide-raw::before,
.store-raw .fixed .horizontal-tab-raw.tab-content .divide-raw::before,
.fixed .terms-raw label.horizontal-tab-raw .divide-raw::before,
.terms-raw .fixed label.horizontal-tab-raw .divide-raw::before,
.fixed
  .challenge-raw
  .challenge-info-raw
  ul
  li
  > span.horizontal-tab-raw
  .divide-raw::before,
.challenge-raw
  .challenge-info-raw
  ul
  .fixed
  li
  > span.horizontal-tab-raw
  .divide-raw::before,
.fixed .most-popular-raw ul li a.horizontal-tab-raw .divide-raw::before,
.most-popular-raw ul li .fixed a.horizontal-tab-raw .divide-raw::before,
.fixed .horizontal-tab-raw.balance-earned-raw .divide-raw::before,
.fixed
  .profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .horizontal-tab-raw.team-li-conn
  .divide-raw::before,
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .fixed
  .horizontal-tab-raw.team-li-conn
  .divide-raw::before,
.fixed
  .profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .horizontal-tab-raw.team-details-col
  .divide-raw::before,
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .fixed
  .horizontal-tab-raw.team-details-col
  .divide-raw::before,
.fixed .horizontal-tab-raw.section .divide-raw::after,
.fixed
  .create-team-modal
  .modal-form
  .horizontal-tab-raw.form-grop
  .divide-raw::after,
.create-team-modal
  .modal-form
  .fixed
  .horizontal-tab-raw.form-grop
  .divide-raw::after,
.fixed
  .create-team-modal
  .modal-form
  .horizontal-tab-raw.section-title
  .divide-raw::after,
.create-team-modal
  .modal-form
  .fixed
  .horizontal-tab-raw.section-title
  .divide-raw::after,
.fixed .challenger-list-col ul li.horizontal-tab-raw .divide-raw::after,
.challenger-list-col ul .fixed li.horizontal-tab-raw .divide-raw::after,
.fixed .horizontal-tab-raw.challenger-list-col .divide-raw::after,
.fixed
  .contact-gaming-raw
  .contact-sub
  .horizontal-tab-raw.form-group
  .divide-raw::after,
.contact-gaming-raw
  .contact-sub
  .fixed
  .horizontal-tab-raw.form-group
  .divide-raw::after,
.fixed
  .tournament-details-col
  ul
  li
  strong.horizontal-tab-raw
  .divide-raw::after,
.tournament-details-col
  ul
  li
  .fixed
  strong.horizontal-tab-raw
  .divide-raw::after,
.fixed .trophy-raw .trophy-li .horizontal-tab-raw.trophy-col .divide-raw::after,
.trophy-raw .trophy-li .fixed .horizontal-tab-raw.trophy-col .divide-raw::after,
.fixed table.horizontal-tab-raw .divide-raw::after,
.fixed .horizontal-tab-raw.scroll-wrap .divide-raw::after,
.fixed .horizontal-tab-raw.main-wrapper .divide-raw::after,
.fixed
  .league-wrapper
  .expert-master
  .progress-wrap
  .horizontal-tab-raw.redius-col
  .divide-raw::after,
.league-wrapper
  .expert-master
  .progress-wrap
  .fixed
  .horizontal-tab-raw.redius-col
  .divide-raw::after,
.fixed .horizontal-tab-raw.select-dropdown .divide-raw::after,
.fixed .horizontal-tab-raw.select-game .divide-raw::after,
.fixed .step-section-raw ul li .horizontal-tab-raw.step-col .divide-raw::after,
.step-section-raw ul li .fixed .horizontal-tab-raw.step-col .divide-raw::after,
.fixed .horizontal-tab-raw.title-raw .divide-raw::after,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .horizontal-tab-raw.gm-col
  .divide-raw::after,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .fixed
  .horizontal-tab-raw.gm-col
  .divide-raw::after,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .horizontal-tab-raw.img-box
  .divide-raw::after,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .fixed
  .horizontal-tab-raw.img-box
  .divide-raw::after,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .horizontal-tab-raw.about-game-raw
  .divide-raw::after,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .fixed
  .horizontal-tab-raw.about-game-raw
  .divide-raw::after,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.about-game-conn
  .divide-raw::after,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.about-game-conn
  .divide-raw::after,
.fixed
  .game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.plateform-players-col
  .divide-raw::after,
.game-lists-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.plateform-players-col
  .divide-raw::after,
.fixed
  .trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .horizontal-tab-raw.view-raw
  .divide-raw::after,
.trending-videos-raw
  .gm-li-raw
  .row
  .gm-li
  .gm-col
  .about-game-raw
  .fixed
  .horizontal-tab-raw.view-raw
  .divide-raw::after,
.fixed
  .header
  .notification
  .dropdown-menu-noti
  .notification-list
  li.horizontal-tab-raw
  .divide-raw::after,
.header
  .notification
  .dropdown-menu-noti
  .notification-list
  .fixed
  li.horizontal-tab-raw
  .divide-raw::after,
.fixed .header .dropdown-list li.horizontal-tab-raw .divide-raw::after,
.header .dropdown-list .fixed li.horizontal-tab-raw .divide-raw::after,
.fixed .header .dropdown-list li a.horizontal-tab-raw .divide-raw::after,
.header .dropdown-list li .fixed a.horizontal-tab-raw .divide-raw::after,
.fixed
  .record-list-raw
  .record-list-col
  ul
  li
  h3
  span.horizontal-tab-raw
  .divide-raw::after,
.record-list-raw
  .record-list-col
  ul
  li
  h3
  .fixed
  span.horizontal-tab-raw
  .divide-raw::after,
.fixed .store-raw .horizontal-tab-raw.tab-content .divide-raw::after,
.store-raw .fixed .horizontal-tab-raw.tab-content .divide-raw::after,
.fixed .terms-raw label.horizontal-tab-raw .divide-raw::after,
.terms-raw .fixed label.horizontal-tab-raw .divide-raw::after,
.fixed
  .challenge-raw
  .challenge-info-raw
  ul
  li
  > span.horizontal-tab-raw
  .divide-raw::after,
.challenge-raw
  .challenge-info-raw
  ul
  .fixed
  li
  > span.horizontal-tab-raw
  .divide-raw::after,
.fixed .most-popular-raw ul li a.horizontal-tab-raw .divide-raw::after,
.most-popular-raw ul li .fixed a.horizontal-tab-raw .divide-raw::after,
.fixed .horizontal-tab-raw.balance-earned-raw .divide-raw::after,
.fixed
  .profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .horizontal-tab-raw.team-li-conn
  .divide-raw::after,
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .fixed
  .horizontal-tab-raw.team-li-conn
  .divide-raw::after,
.fixed
  .profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .horizontal-tab-raw.team-details-col
  .divide-raw::after,
.profile-details-raw
  .team-overview-raw
  .the-team-raw
  .team-li
  .team-li-conn
  .fixed
  .horizontal-tab-raw.team-details-col
  .divide-raw::after {
  display: none;
}
.fixed .tabbing-raw .tab-content {
  margin-top: 60px;
}

.personal-info-section .personal-info-back {
  background: url(../images/profile-section-bg.jpg) center center no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 574px;
  border-radius: 10px;
}
.personal-info-section .personal-info-back button {
  text-transform: uppercase;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: white;
}
.personal-info-section .personal-info-back button.btn-edit {
  position: absolute;
  right: 50px;
  top: 50px;
  background: transparent;
  box-shadow: none;
  border: 0;
  z-index: 1;
  padding: 10px;
}
.personal-info-section
  .personal-info-back
  .left-profile
  .player-info
  .img-wrap {
  width: 58px;
  height: 58px;
}
.personal-info-section
  .personal-info-back
  .left-profile
  .player-info
  .img-wrap
  img {
  display: inline-block;
  border: 2px solid #3385cc;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.personal-info-section
  .personal-info-back
  .left-profile
  .player-info
  .information-head
  h6 {
  width: calc(100% - 58px);
  font-size: 20px;
  float: left;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  padding-left: 10px;
  background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.personal-info-section
  .personal-info-back
  .left-profile
  .player-info-details
  ul {
  width: 100%;
  float: left;
}
.personal-info-section
  .personal-info-back
  .left-profile
  .player-info-details
  ul
  li {
  display: flex;
  width: 100%;
  position: relative;
  padding-top: 40px;
  align-items: flex-end;
}
.personal-info-section
  .personal-info-back
  .left-profile
  .player-info-details
  ul
  li::before {
  position: absolute;
  content: "";
  background: linear-gradient(
    to right,
    rgb(2, 86, 148) 0%,
    rgba(4, 75, 123, 0.1) 100%
  );
  width: 100%;
  height: 1px;
  left: 0;
  bottom: -20px;
}
.personal-info-section
  .personal-info-back
  .left-profile
  .player-info-details
  ul
  li:last-child::before {
  display: none;
}
.personal-info-section
  .personal-info-back
  .left-profile
  .player-info-details
  ul
  li
  span {
  width: auto;
  color: rgba(255, 255, 255, 0.3);
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-size: 12px;
}
.personal-info-section
  .personal-info-back
  .left-profile
  .player-info-details
  ul
  li
  h6 {
  width: auto;
  padding-left: 10px;
  font-size: 18px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
}
.personal-info-section .personal-info-back .left-profile button.btn-edit {
  position: absolute;
  right: 0px;
  top: 130px;
  background: transparent;
  box-shadow: none;
  border: 0;
  z-index: 1;
  padding: 10px;
}
.personal-info-section .list-col .con-box {
  padding: 20px 0;
  position: relative;
}
.personal-info-section .list-col .con-box::before {
  position: absolute;
  right: -15px;
  top: 0;
  height: 100%;
  width: 2px;
  content: "";
  background: transparent url(../images/line-dot.png) center center no-repeat;
}
.personal-info-section .list-col .con-box h6 {
  font-size: 20px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  letter-spacing: 1px;
  background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.personal-info-section .list-col .con-box h5 {
  position: relative;
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 1px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  padding-top: 20px;
  margin-top: 15px;
  overflow: hidden;
}
.personal-info-section .list-col .con-box h5::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 1px;
  background: #062f56;
}
.personal-info-section .list-col .con-box h5::after {
  content: "";
  position: absolute;
  left: 50%;
  top: -5%;
  width: 50%;
  height: 100%;
  background: radial-gradient(
    ellipse at center,
    #3385cc 0%,
    rgba(125, 185, 232, 0) 70%
  );
  opacity: 0.6;
  transform: translate(-50%, -50%);
}
.personal-info-section .list-col:last-child .con-box::before {
  display: none;
}

.gaming-plateform-raw .con-box {
  padding: 35px 0;
}
.gaming-plateform-raw .con-box h5 {
  position: relative;
  text-transform: uppercase;
  font-size: 30px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.2) !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
  padding-top: 20px;
  margin-top: 15px;
  overflow: hidden;
}
.gaming-plateform-raw .con-box h5::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 1px;
  background: #062f56;
}
.gaming-plateform-raw .con-box h5::after {
  content: "";
  position: absolute;
  left: 50%;
  top: -5%;
  width: 50%;
  height: 100%;
  background: radial-gradient(
    ellipse at center,
    #3385cc 0%,
    rgba(125, 185, 232, 0) 70%
  );
  opacity: 0.6;
  transform: translate(-50%, -50%);
}
.gaming-plateform-raw .con-box .img-box {
  margin: 0;
  width: 100%;
  height: 120px;
  line-height: 120px;
  transition: all 0.25s ease 0s;
}
.gaming-plateform-raw .con-box .img-box img {
  display: inline-block;
  vertical-align: middle;
  transition: all 0.25s ease 0s;
  transform: scale(1);
}
.gaming-plateform-raw .con-box .img-box:hover {
  transform: scale(1.1);
  transition: all 0.25s ease 0s;
}
.gaming-plateform-raw .list-col {
  background: transparent url(../images/Line-dot-1.png) right bottom 20px
    no-repeat;
  position: relative;
}
.gaming-plateform-raw .list-col:last-child {
  background: none !important;
}
.gaming-plateform-raw .list-col:last-child::after {
  display: none;
}
.gaming-plateform-raw .list-col:last-child::before {
  transform: rotate(180deg);
  background-position: right 15px center;
}
.gaming-plateform-raw .list-col:nth-child(2)::before,
.gaming-plateform-raw .list-col:nth-child(3)::before {
  background: transparent url(../images/Line-3.png) bottom center no-repeat;
  background-size: 90%;
}
.gaming-plateform-raw .list-col::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: transparent url(../images/Line-dot-2.png) right 20px center
    no-repeat;
  width: 100%;
  height: 2px;
}
.gaming-plateform-raw .list-col::after {
  width: 4px;
  height: 4px;
  content: "";
  background: #009cff;
  position: absolute;
  right: 0;
  bottom: 0;
}
.gaming-plateform-raw .platform-list-row {
  margin-top: 50px;
}
.gaming-plateform-raw
  .platform-list-row
  .platform-row:last-child
  .list-col::before,
.gaming-plateform-raw
  .platform-list-row
  .platform-row:last-child
  .list-col::after {
  display: none;
}
.gaming-plateform-raw .platform-list-row .platform-row:nth-child(2n) .list-col {
  background: transparent url(../images/Line-dot-1-reverse.png) right top 15px
    no-repeat;
}

.team-tournaments-raw .title-shadow {
  margin-bottom: 60px;
}
.team-tournaments-raw p {
  font-size: 18px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  letter-spacing: 0.5px;
}

.progress-circle-raw.progress {
  width: 160px;
  height: 160px;
  line-height: 160px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
  overflow: inherit;
}
.progress-circle-raw.progress::before {
  position: absolute;
  left: -10%;
  top: -10%;
  background: rgba(0, 156, 255, 0.1);
  content: "";
  width: 120%;
  height: 120%;
  border-radius: 100%;
  border: 1px solid rgba(0, 156, 255, 0.2);
}
.progress-circle-raw.progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 10px solid #3cff00;
  position: absolute;
  top: 0;
  left: 0;
}
.progress-circle-raw.progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.progress-circle-raw.progress .progress-left {
  left: 0;
}
.progress-circle-raw.progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 10px;
  border-style: solid;
  position: absolute;
  top: 0;
  border-color: #10e6ff;
}
.progress-circle-raw.progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  transform-origin: center left;
}
.progress-circle-raw.progress .progress-right {
  right: 0;
}
.progress-circle-raw.progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  transform-origin: center right;
}
.progress-circle-raw.progress .progress-value {
  display: flex;
  border-radius: 50%;
  font-size: 1rem;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-weight: 300;
}
.progress-circle-raw.progress .progress-value > div {
  font-size: 30px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  padding: 10px;
}
.progress-circle-raw.progress .progress-value > div.won-col {
  color: #10e6ff;
}
.progress-circle-raw.progress .progress-value > div.lost-col {
  color: #3cff00;
}
.progress-circle-raw.progress .progress-value > div:first-child {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.progress-circle-raw.progress .progress-value > div:last-child {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.progress-circle-raw.progress .progress-value span {
  font-size: 12px;
  text-transform: uppercase;
}

/* This for loop creates the necessary css animation names 
Due to the split circle of progress-left and progress right, we must use the animations on each side. 
*/
.progress[data-percentage="1"] .progress-right .progress-bar {
  animation: loading-1 0.5s linear forwards;
}
.progress[data-percentage="1"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="2"] .progress-right .progress-bar {
  animation: loading-2 0.5s linear forwards;
}
.progress[data-percentage="2"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="3"] .progress-right .progress-bar {
  animation: loading-3 0.5s linear forwards;
}
.progress[data-percentage="3"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="4"] .progress-right .progress-bar {
  animation: loading-4 0.5s linear forwards;
}
.progress[data-percentage="4"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="5"] .progress-right .progress-bar {
  animation: loading-5 0.5s linear forwards;
}
.progress[data-percentage="5"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="6"] .progress-right .progress-bar {
  animation: loading-6 0.5s linear forwards;
}
.progress[data-percentage="6"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="7"] .progress-right .progress-bar {
  animation: loading-7 0.5s linear forwards;
}
.progress[data-percentage="7"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="8"] .progress-right .progress-bar {
  animation: loading-8 0.5s linear forwards;
}
.progress[data-percentage="8"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="9"] .progress-right .progress-bar {
  animation: loading-9 0.5s linear forwards;
}
.progress[data-percentage="9"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="10"] .progress-right .progress-bar {
  animation: loading-10 0.5s linear forwards;
}
.progress[data-percentage="10"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="11"] .progress-right .progress-bar {
  animation: loading-11 0.5s linear forwards;
}
.progress[data-percentage="11"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="12"] .progress-right .progress-bar {
  animation: loading-12 0.5s linear forwards;
}
.progress[data-percentage="12"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="13"] .progress-right .progress-bar {
  animation: loading-13 0.5s linear forwards;
}
.progress[data-percentage="13"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="14"] .progress-right .progress-bar {
  animation: loading-14 0.5s linear forwards;
}
.progress[data-percentage="14"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="15"] .progress-right .progress-bar {
  animation: loading-15 0.5s linear forwards;
}
.progress[data-percentage="15"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="16"] .progress-right .progress-bar {
  animation: loading-16 0.5s linear forwards;
}
.progress[data-percentage="16"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="17"] .progress-right .progress-bar {
  animation: loading-17 0.5s linear forwards;
}
.progress[data-percentage="17"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="18"] .progress-right .progress-bar {
  animation: loading-18 0.5s linear forwards;
}
.progress[data-percentage="18"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="19"] .progress-right .progress-bar {
  animation: loading-19 0.5s linear forwards;
}
.progress[data-percentage="19"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="20"] .progress-right .progress-bar {
  animation: loading-20 0.5s linear forwards;
}
.progress[data-percentage="20"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="21"] .progress-right .progress-bar {
  animation: loading-21 0.5s linear forwards;
}
.progress[data-percentage="21"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="22"] .progress-right .progress-bar {
  animation: loading-22 0.5s linear forwards;
}
.progress[data-percentage="22"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="23"] .progress-right .progress-bar {
  animation: loading-23 0.5s linear forwards;
}
.progress[data-percentage="23"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="24"] .progress-right .progress-bar {
  animation: loading-24 0.5s linear forwards;
}
.progress[data-percentage="24"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="25"] .progress-right .progress-bar {
  animation: loading-25 0.5s linear forwards;
}
.progress[data-percentage="25"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="26"] .progress-right .progress-bar {
  animation: loading-26 0.5s linear forwards;
}
.progress[data-percentage="26"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="27"] .progress-right .progress-bar {
  animation: loading-27 0.5s linear forwards;
}
.progress[data-percentage="27"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="28"] .progress-right .progress-bar {
  animation: loading-28 0.5s linear forwards;
}
.progress[data-percentage="28"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="29"] .progress-right .progress-bar {
  animation: loading-29 0.5s linear forwards;
}
.progress[data-percentage="29"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="30"] .progress-right .progress-bar {
  animation: loading-30 0.5s linear forwards;
}
.progress[data-percentage="30"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="31"] .progress-right .progress-bar {
  animation: loading-31 0.5s linear forwards;
}
.progress[data-percentage="31"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="32"] .progress-right .progress-bar {
  animation: loading-32 0.5s linear forwards;
}
.progress[data-percentage="32"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="33"] .progress-right .progress-bar {
  animation: loading-33 0.5s linear forwards;
}
.progress[data-percentage="33"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="34"] .progress-right .progress-bar {
  animation: loading-34 0.5s linear forwards;
}
.progress[data-percentage="34"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="35"] .progress-right .progress-bar {
  animation: loading-35 0.5s linear forwards;
}
.progress[data-percentage="35"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="36"] .progress-right .progress-bar {
  animation: loading-36 0.5s linear forwards;
}
.progress[data-percentage="36"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="37"] .progress-right .progress-bar {
  animation: loading-37 0.5s linear forwards;
}
.progress[data-percentage="37"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="38"] .progress-right .progress-bar {
  animation: loading-38 0.5s linear forwards;
}
.progress[data-percentage="38"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="39"] .progress-right .progress-bar {
  animation: loading-39 0.5s linear forwards;
}
.progress[data-percentage="39"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="40"] .progress-right .progress-bar {
  animation: loading-40 0.5s linear forwards;
}
.progress[data-percentage="40"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="41"] .progress-right .progress-bar {
  animation: loading-41 0.5s linear forwards;
}
.progress[data-percentage="41"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="42"] .progress-right .progress-bar {
  animation: loading-42 0.5s linear forwards;
}
.progress[data-percentage="42"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="43"] .progress-right .progress-bar {
  animation: loading-43 0.5s linear forwards;
}
.progress[data-percentage="43"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="44"] .progress-right .progress-bar {
  animation: loading-44 0.5s linear forwards;
}
.progress[data-percentage="44"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="45"] .progress-right .progress-bar {
  animation: loading-45 0.5s linear forwards;
}
.progress[data-percentage="45"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="46"] .progress-right .progress-bar {
  animation: loading-46 0.5s linear forwards;
}
.progress[data-percentage="46"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="47"] .progress-right .progress-bar {
  animation: loading-47 0.5s linear forwards;
}
.progress[data-percentage="47"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="48"] .progress-right .progress-bar {
  animation: loading-48 0.5s linear forwards;
}
.progress[data-percentage="48"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="49"] .progress-right .progress-bar {
  animation: loading-49 0.5s linear forwards;
}
.progress[data-percentage="49"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="50"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="50"] .progress-left .progress-bar {
  animation: 0;
}

.progress[data-percentage="51"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="51"] .progress-left .progress-bar {
  animation: loading-1 0.5s linear forwards 0.5s;
}

.progress[data-percentage="52"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="52"] .progress-left .progress-bar {
  animation: loading-2 0.5s linear forwards 0.5s;
}

.progress[data-percentage="53"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="53"] .progress-left .progress-bar {
  animation: loading-3 0.5s linear forwards 0.5s;
}

.progress[data-percentage="54"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="54"] .progress-left .progress-bar {
  animation: loading-4 0.5s linear forwards 0.5s;
}

.progress[data-percentage="55"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="55"] .progress-left .progress-bar {
  animation: loading-5 0.5s linear forwards 0.5s;
}

.progress[data-percentage="56"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="56"] .progress-left .progress-bar {
  animation: loading-6 0.5s linear forwards 0.5s;
}

.progress[data-percentage="57"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="57"] .progress-left .progress-bar {
  animation: loading-7 0.5s linear forwards 0.5s;
}

.progress[data-percentage="58"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="58"] .progress-left .progress-bar {
  animation: loading-8 0.5s linear forwards 0.5s;
}

.progress[data-percentage="59"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="59"] .progress-left .progress-bar {
  animation: loading-9 0.5s linear forwards 0.5s;
}

.progress[data-percentage="60"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="60"] .progress-left .progress-bar {
  animation: loading-10 0.5s linear forwards 0.5s;
}

.progress[data-percentage="61"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="61"] .progress-left .progress-bar {
  animation: loading-11 0.5s linear forwards 0.5s;
}

.progress[data-percentage="62"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="62"] .progress-left .progress-bar {
  animation: loading-12 0.5s linear forwards 0.5s;
}

.progress[data-percentage="63"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="63"] .progress-left .progress-bar {
  animation: loading-13 0.5s linear forwards 0.5s;
}

.progress[data-percentage="64"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="64"] .progress-left .progress-bar {
  animation: loading-14 0.5s linear forwards 0.5s;
}

.progress[data-percentage="65"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="65"] .progress-left .progress-bar {
  animation: loading-15 0.5s linear forwards 0.5s;
}

.progress[data-percentage="66"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="66"] .progress-left .progress-bar {
  animation: loading-16 0.5s linear forwards 0.5s;
}

.progress[data-percentage="67"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="67"] .progress-left .progress-bar {
  animation: loading-17 0.5s linear forwards 0.5s;
}

.progress[data-percentage="68"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="68"] .progress-left .progress-bar {
  animation: loading-18 0.5s linear forwards 0.5s;
}

.progress[data-percentage="69"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="69"] .progress-left .progress-bar {
  animation: loading-19 0.5s linear forwards 0.5s;
}

.progress[data-percentage="70"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="70"] .progress-left .progress-bar {
  animation: loading-20 0.5s linear forwards 0.5s;
}

.progress[data-percentage="71"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="71"] .progress-left .progress-bar {
  animation: loading-21 0.5s linear forwards 0.5s;
}

.progress[data-percentage="72"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="72"] .progress-left .progress-bar {
  animation: loading-22 0.5s linear forwards 0.5s;
}

.progress[data-percentage="73"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="73"] .progress-left .progress-bar {
  animation: loading-23 0.5s linear forwards 0.5s;
}

.progress[data-percentage="74"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="74"] .progress-left .progress-bar {
  animation: loading-24 0.5s linear forwards 0.5s;
}

.progress[data-percentage="75"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="75"] .progress-left .progress-bar {
  animation: loading-25 0.5s linear forwards 0.5s;
}

.progress[data-percentage="76"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="76"] .progress-left .progress-bar {
  animation: loading-26 0.5s linear forwards 0.5s;
}

.progress[data-percentage="77"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="77"] .progress-left .progress-bar {
  animation: loading-27 0.5s linear forwards 0.5s;
}

.progress[data-percentage="78"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="78"] .progress-left .progress-bar {
  animation: loading-28 0.5s linear forwards 0.5s;
}

.progress[data-percentage="79"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="79"] .progress-left .progress-bar {
  animation: loading-29 0.5s linear forwards 0.5s;
}

.progress[data-percentage="80"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="80"] .progress-left .progress-bar {
  animation: loading-30 0.5s linear forwards 0.5s;
}

.progress[data-percentage="81"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="81"] .progress-left .progress-bar {
  animation: loading-31 0.5s linear forwards 0.5s;
}

.progress[data-percentage="82"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="82"] .progress-left .progress-bar {
  animation: loading-32 0.5s linear forwards 0.5s;
}

.progress[data-percentage="83"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="83"] .progress-left .progress-bar {
  animation: loading-33 0.5s linear forwards 0.5s;
}

.progress[data-percentage="84"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="84"] .progress-left .progress-bar {
  animation: loading-34 0.5s linear forwards 0.5s;
}

.progress[data-percentage="85"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="85"] .progress-left .progress-bar {
  animation: loading-35 0.5s linear forwards 0.5s;
}

.progress[data-percentage="86"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="86"] .progress-left .progress-bar {
  animation: loading-36 0.5s linear forwards 0.5s;
}

.progress[data-percentage="87"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="87"] .progress-left .progress-bar {
  animation: loading-37 0.5s linear forwards 0.5s;
}

.progress[data-percentage="88"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="88"] .progress-left .progress-bar {
  animation: loading-38 0.5s linear forwards 0.5s;
}

.progress[data-percentage="89"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="89"] .progress-left .progress-bar {
  animation: loading-39 0.5s linear forwards 0.5s;
}

.progress[data-percentage="90"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="90"] .progress-left .progress-bar {
  animation: loading-40 0.5s linear forwards 0.5s;
}

.progress[data-percentage="91"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="91"] .progress-left .progress-bar {
  animation: loading-41 0.5s linear forwards 0.5s;
}

.progress[data-percentage="92"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="92"] .progress-left .progress-bar {
  animation: loading-42 0.5s linear forwards 0.5s;
}

.progress[data-percentage="93"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="93"] .progress-left .progress-bar {
  animation: loading-43 0.5s linear forwards 0.5s;
}

.progress[data-percentage="94"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="94"] .progress-left .progress-bar {
  animation: loading-44 0.5s linear forwards 0.5s;
}

.progress[data-percentage="95"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="95"] .progress-left .progress-bar {
  animation: loading-45 0.5s linear forwards 0.5s;
}

.progress[data-percentage="96"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="96"] .progress-left .progress-bar {
  animation: loading-46 0.5s linear forwards 0.5s;
}

.progress[data-percentage="97"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="97"] .progress-left .progress-bar {
  animation: loading-47 0.5s linear forwards 0.5s;
}

.progress[data-percentage="98"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="98"] .progress-left .progress-bar {
  animation: loading-48 0.5s linear forwards 0.5s;
}

.progress[data-percentage="99"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="99"] .progress-left .progress-bar {
  animation: loading-49 0.5s linear forwards 0.5s;
}

.progress[data-percentage="100"] .progress-right .progress-bar {
  animation: loading-50 0.5s linear forwards;
}
.progress[data-percentage="100"] .progress-left .progress-bar {
  animation: loading-50 0.5s linear forwards 0.5s;
}

@keyframes loading-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(3.6deg);
  }
}
@keyframes loading-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(7.2deg);
  }
}
@keyframes loading-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(10.8deg);
  }
}
@keyframes loading-4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(14.4deg);
  }
}
@keyframes loading-5 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(18deg);
  }
}
@keyframes loading-6 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(21.6deg);
  }
}
@keyframes loading-7 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(25.2deg);
  }
}
@keyframes loading-8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(28.8deg);
  }
}
@keyframes loading-9 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(32.4deg);
  }
}
@keyframes loading-10 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(36deg);
  }
}
@keyframes loading-11 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(39.6deg);
  }
}
@keyframes loading-12 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(43.2deg);
  }
}
@keyframes loading-13 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(46.8deg);
  }
}
@keyframes loading-14 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(50.4deg);
  }
}
@keyframes loading-15 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(54deg);
  }
}
@keyframes loading-16 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(57.6deg);
  }
}
@keyframes loading-17 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(61.2deg);
  }
}
@keyframes loading-18 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(64.8deg);
  }
}
@keyframes loading-19 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(68.4deg);
  }
}
@keyframes loading-20 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(72deg);
  }
}
@keyframes loading-21 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(75.6deg);
  }
}
@keyframes loading-22 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(79.2deg);
  }
}
@keyframes loading-23 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(82.8deg);
  }
}
@keyframes loading-24 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(86.4deg);
  }
}
@keyframes loading-25 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes loading-26 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(93.6deg);
  }
}
@keyframes loading-27 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(97.2deg);
  }
}
@keyframes loading-28 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(100.8deg);
  }
}
@keyframes loading-29 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(104.4deg);
  }
}
@keyframes loading-30 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(108deg);
  }
}
@keyframes loading-31 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(111.6deg);
  }
}
@keyframes loading-32 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(115.2deg);
  }
}
@keyframes loading-33 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(118.8deg);
  }
}
@keyframes loading-34 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(122.4deg);
  }
}
@keyframes loading-35 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(126deg);
  }
}
@keyframes loading-36 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(129.6deg);
  }
}
@keyframes loading-37 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(133.2deg);
  }
}
@keyframes loading-38 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(136.8deg);
  }
}
@keyframes loading-39 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(140.4deg);
  }
}
@keyframes loading-40 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(144deg);
  }
}
@keyframes loading-41 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(147.6deg);
  }
}
@keyframes loading-42 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(151.2deg);
  }
}
@keyframes loading-43 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(154.8deg);
  }
}
@keyframes loading-44 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(158.4deg);
  }
}
@keyframes loading-45 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(162deg);
  }
}
@keyframes loading-46 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(165.6deg);
  }
}
@keyframes loading-47 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(169.2deg);
  }
}
@keyframes loading-48 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(172.8deg);
  }
}
@keyframes loading-49 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(176.4deg);
  }
}
@keyframes loading-50 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.bottom-shadow {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 16px;
  background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  position: relative;
  border-bottom: 1px solid rgba(0, 156, 255, 0.5);
  width: auto;
  display: inline-block;
  margin: 30px auto 0;
  float: none;
  text-transform: uppercase;
  padding: 0 0 10px;
  position: relative;
}
.bottom-shadow::before {
  background: transparent url(../images/sm-shadow.png) bottom center no-repeat;
  content: "";
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translate(-50%, 0) rotate(180deg);
  width: 100%;
  height: 20px;
}

.section-team-detail {
  display: none;
}
.section-team-detail.show {
  display: block;
}
.section-team-detail .team-details-col {
  text-align: left;
}
.section-team-detail .team-details-col .img-box {
  display: inline-block;
  vertical-align: middle;
}
.section-team-detail .team-details-col .img-box img,
.team-img img {
  border: 2px solid #3385cc;
  border-radius: 100%;
  width: 70px;
  object-fit: cover;
  aspect-ratio: 1/1;
  display: inline-block;
  vertical-align: middle;
}
.section-team-detail .team-details-col .member-con {
  display: inline-block;
  vertical-align: middle;
  padding-left: 20px;
}
.section-team-detail .team-details-col .member-con h5 {
  font-family: "D-DIN Condensed";
  font-size: 20px;
  font-weight: normal;
}
.section-team-detail .team-details-col .member-con h6 {
  color: #009cff;
  font-size: 12px;
  font-weight: 700;
  padding-top: 2px;
  font-family: "Montserrat", sans-serif;
}
.section-team-detail .the-team-raw .row {
  margin-right: -40px;
  margin-left: -40px;
}
.section-team-detail .the-team-raw .row .team-li {
  padding-right: 40px;
  padding-left: 40px;
}

.trophy-raw {
  text-align: center;
  padding: 0 100px;
}
.trophy-raw .trophy-li {
  margin-top: 100px;
}
.trophy-raw .trophy-li .trophy-col {
  padding: 30px;
  background: #060919;
  position: relative;
  border: 2px solid rgba(51, 133, 204, 0.3);
  border-radius: 14px;
}
.trophy-raw .trophy-li .trophy-col::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 80%;
  background: linear-gradient(
    to bottom,
    rgba(30, 80, 128, 0) 0%,
    rgb(30, 80, 128) 100%
  );
  border-radius: 12px;
}
.trophy-raw .trophy-li .trophy-col .trophy-img {
  position: relative;
  text-align: center;
  margin: -80px auto 30px;
  max-width: 180px;
  padding: 0 30px;
}
.trophy-raw .trophy-li .trophy-col .trophy-img::before {
  background: radial-gradient(
    ellipse at center,
    rgb(51, 133, 204) 0%,
    rgba(51, 133, 204, 0) 67%,
    rgba(51, 133, 204, 0) 100%
  );
  position: absolute;
  left: 50%;
  top: 50%;
  width: 70%;
  height: 160%;
  content: "";
  opacity: 0.4;
  transform: translate(-50%, -50%);
}
.trophy-raw .trophy-li .trophy-col .trophy-img img {
  position: relative;
  display: inline-block;
  margin: 0 auto;
}
.trophy-raw .trophy-li .trophy-col h5 {
  background: linear-gradient(to bottom, #80ebff 0%, #039eff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 24px;
  position: relative;
}
.trophy-raw .trophy-li .trophy-col .devider {
  height: 1px;
  width: 60%;
  background: #3385cc;
  margin: 0 auto;
  display: block;
  clear: both;
  position: relative;
}
.trophy-raw .trophy-li .trophy-col h6 {
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 16px;
  color: white;
  position: relative;
  margin: 10px 0;
}

.wallet-raw .wallet-ul {
  text-align: center;
}
.wallet-raw .wallet-ul .wallet-li {
  display: inline-block;
  width: 30%;
  position: relative;
  vertical-align: top;
  text-align: center;
}
.wallet-raw .wallet-ul .wallet-li::before {
  height: 140px;
  width: 2px;
  position: absolute;
  right: 0;
  top: 30px;
  content: "";
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgb(255, 255, 255) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  opacity: 0.6;
}
.wallet-raw .wallet-ul .wallet-li:last-child::before {
  display: none;
}
.wallet-raw .wallet-ul .wallet-li .box-conn {
  width: 220px;
  display: block;
  margin: 0 auto;
  float: none;
}
.wallet-raw .wallet-ul .wallet-li .box-conn h5 {
  background: -webkit-linear-gradient(#00d3ff, #3385cc);
  background-clip: border-box;
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-size: 22px;
  letter-spacing: 3px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  position: relative;
  margin: 10px 0 30px;
  padding-bottom: 10px;
  text-transform: uppercase;
}
.wallet-raw .wallet-ul .wallet-li .box-conn h5::before {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  width: 25px;
  height: 2px;
  content: "";
  background: #077796;
}
.wallet-raw .wallet-ul .wallet-li .box-conn .btn-main {
  font-size: 14px;
}
.wallet-raw .wallet-ul .wallet-li .box-conn p {
  font-size: 16px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  color: white;
  letter-spacing: 1px;
  line-height: 140%;
}
.wallet-raw .wallet-ul .wallet-li .box-conn .form-control {
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
}
.wallet-raw .wallet-ul .wallet-li .box-conn .form-control::placeholder {
  color: #fff9 !important;
}
.wallet-raw
  .wallet-ul
  .wallet-li
  .box-conn
  .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.1);
  text-align: center;
}
.wallet-raw .wallet-ul .wallet-li .box-conn .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.1);
  text-align: center;
}
.wallet-raw
  .wallet-ul
  .wallet-li
  .box-conn
  .form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.1);
  text-align: center;
}
.wallet-raw .wallet-ul .wallet-li .box-conn .form-control:-moz-placeholder {
  color: rgba(255, 255, 255, 0.1);
  text-align: center;
}

.filter-list-col {
  margin-top: 50px;
}
.filter-list-col tr th {
  background: #00d8ff;
  background: linear-gradient(to bottom, #00d8ff 1%, #009cff 100%);
  font-size: 20px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  position: relative;
}
.filter-list-col tr th::before {
  position: absolute;
  content: "";
  background: #00d3ff;
  width: 1px;
  height: 80%;
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}
.filter-list-col tr th:last-child::before {
  display: none;
}
.filter-list-col tr td {
  font-size: 20px;
  text-transform: capitalize;
}

.achievements-raw.pt10 {
  padding-top: 40px !important;
}
.achievements-raw .row {
  margin-left: -25px;
  margin-right: -25px;
}
.achievements-raw .row .achievements-li {
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 50px;
}
.achievements-raw .achievements-li .achievements-col {
  text-align: center;
  position: relative;
  background: transparent url(../images/achive-hexa.png) no-repeat;
  background-size: 100% 100%;
  padding: 20px 0 50px;
}
.achievements-raw .achievements-li .achievements-col .img-box {
  position: relative;
  padding: 50px 0 30px;
  background: transparent url(../images/achive-hexa-bg.png) center center
    no-repeat;
  background-size: 85% 100%;
}
.achievements-raw .achievements-li .achievements-col .img-box::before {
  background: radial-gradient(
    ellipse at center,
    rgb(239, 170, 54) 0%,
    rgba(239, 170, 54, 0) 60%,
    rgba(239, 170, 54, 0) 0%
  );
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  opacity: 0.3;
}
.achievements-raw .achievements-li .achievements-col .img-box .reflect {
  margin: 0 auto;
  position: relative;
}
.achievements-raw .achievements-li .achievements-col h3 {
  text-align: center;
  font-size: 30px;
  letter-spacing: 2px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  line-height: unset;
  margin-top: 20px;
  position: relative;
  text-shadow: 0px 0px 30px #00d4ff;
  -webkit-text-shadow: 0px 0px 30px #00d4ff;
}
.achievements-raw .achievements-li .achievements-col h3 span {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.achievements-raw .achievements-li .conn {
  text-align: center;
}
.achievements-raw .achievements-li .conn p {
  font-size: 22px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  line-height: 120%;
  margin: 20px 0;
}
.achievements-raw .achievements-li .conn p strong {
  font-weight: bold;
  font-family: "D-DIN Condensed";
}
.achievements-raw .achievements-li .conn h4 {
  font-family: "D-DIN Condensed";
  font-weight: normal;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.2);
  border: 1px solid #3385cc;
  border-radius: 4px;
  background: rgb(18, 30, 88);
  background: linear-gradient(
    to bottom,
    rgb(18, 30, 88) 0%,
    rgb(11, 18, 51) 76%
  );
  width: auto;
  float: none;
  display: inline-block;
  padding: 0 40px;
  position: relative;
  height: 60px;
  line-height: 58px;
  margin-top: 20px;
  min-width: 170px;
}
.achievements-raw .achievements-li .conn h4::before {
  background: transparent url(../images/sm-shadow.png) bottom center no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  left: 50%;
  top: -20px;
  transform: translate(-50%, 0);
  width: 90%;
  height: 20px;
}
.achievements-raw .achievements-li .conn h4::after {
  background: transparent url(../images/sm-shadow.png) bottom center no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translate(-50%, 0) rotate(180deg);
  width: 90%;
  height: 20px;
}
.achievements-raw .achievements-li .conn h4 strong {
  font-weight: bold;
  font-family: "D-DIN Condensed";
  background: linear-gradient(to bottom, #00d8ff 0%, #009eff 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  text-transform: uppercase;
}

.tournament-details-col .inner-banner .container-fluid .inner-banner-con {
  /* top: 65%; */
}
.tournament-details-col .inner-banner .container-fluid .inner-banner-con p {
  font-size: 24px;
  color: white;
  font-family: "D-DIN";
  font-weight: normal;
}
.tournament-details-col ul {
  text-align: center;
}

.tournament-details-col ul li {
  display: inline-block;
  text-align: center;
  padding: 20px;
  margin: 10px 0;
  color: rgba(255, 255, 255, 0.3);
  font-size: 16px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  position: relative;
}

.tournament-details-col ul li::before {
  position: absolute;
  right: 0;
  top: 50%;
  content: "";
  width: 2px;
  height: 30px;
  background: #00c7ff;
  transform: translate(-50%, 0%);
  border-radius: 5px;
  box-shadow: 0 0 8px #1f292d;
}

.tournament-details-col .btn-ac {
  margin-top: 20px;
}

.tournament-details-col .join-btn {
  background-color: rgba(0, 156, 255, 0);
  border: 2px solid #009cff;
  min-width: 160px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  padding-left: 30px;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}
.tournament-details-col .join-btn:hover{
  background-color: rgb(0, 156, 255) !important;
}
.tournament-details-col .join-btn i {
  width: 28px;
  height: 28px;
  border-radius: 100px;
  background: #009cff url(../images/ic-plus.png) center center no-repeat;
  display: inline-block;
  position: absolute;
  left: 4px;
  top: 4px;
}

.tournament-details-col .btm-btns .btn-ac {
  min-width: 200px;
  background: #009cff;
}
.tournament-details-col .btm-btns .btn-ac:hover {
  background: #007cdf;
}

.tournament-details-raw table {
  position: relative;
}
.tournament-details-raw table tr {
  position: relative;
}
.tournament-details-raw table tr td {
  border: 0px;
  text-align: center;
  color: #009cff;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  padding: 10px;
  text-transform: capitalize;
  vertical-align: middle;
}
.tournament-details-raw table tr td:first-child {
  text-align: right;
}
.tournament-details-raw table tr td:last-child {
  text-align: left;
}
.tournament-details-raw table tr th span {
  white-space: nowrap;
}
.tournament-details-raw table.first-col {
  text-align: center;
}
.tournament-details-raw table.first-col tr th {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
}
.tournament-details-raw table.first-col::before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 0;
  left: 0;
  background: #070919 url(../images/table-th-line.png) top center no-repeat;
  z-index: 1;
}
.tournament-details-raw table.first-col::after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #070919 url(../images/table-th-line.png) bottom center no-repeat;
  z-index: 1;
}
.tournament-details-raw table.second-table tr td {
  color: white;
  font-family: "D-DIN Condensed";
  font-size: 20px;
  font-weight: normal;
  border-top: 1px solid #002335;
}
.tournament-details-raw table.second-table tr td:first-child,
.tournament-details-raw table.second-table tr td:last-child {
  width: 45%;
}
.tournament-details-raw table.second-table tr td.desh {
  width: 10%;
  position: relative;
}
.tournament-details-raw table.second-table tr td.desh::before {
  position: absolute;
  left: 50%;
  top: 50%;
  content: "";
  background: #95eeff;
  height: 1px;
  width: 15px;
  box-shadow: 0 0 14px #009cff;
  transform: translate(-50%, -50%);
}
.tournament-details-raw table.second-table tr:first-child td {
  border-top: 0px;
}

.time-date-col {
  text-align: center;
}
.time-date-col .timesate-li > span {
  margin-bottom: 15px;
  display: inline-block;
}
.time-date-col .timesate-li .box-raw {
  background: #00518a;
  border: 2px solid #50aeff;
  border-radius: 10px;
  padding: 25px 20px;
  min-height: 105px;
}
.time-date-col .timesate-li .box-raw span {
  color: white;
  font-size: 16px;
  text-transform: uppercase;
}
.time-date-col .timesate-li .box-raw h4 {
  font-family: "D-DIN Condensed";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 22px;
  margin-top: 5px;
  line-height: 120%;
  background: -webkit-linear-gradient(#84ecff, #009cff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}
.time-date-col .timesate-li::before {
  position: absolute;
  content: "";
  left: 100%;
  top: 15%;
  background: white;
  width: 70%;
  height: 2px;
  box-shadow: 0 0 10px white;
  transition: all 0.25s ease 0s;
  transform: translate(-50%, -50%);
}
.time-date-col .timesate-li:last-child::before {
  display: none;
}

.tournament-team-col .the-team-raw .row {
  margin-right: -25px;
  margin-left: -25px;
}
.tournament-team-col .the-team-raw .row .team-li {
  padding-left: 25px;
  padding-right: 25px;
}

.upcoming-tournament-raw .leader-list-raw h3 {
  font-size: 42px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 120%;
  background: -webkit-linear-gradient(#84ecff, #009cff);
  text-transform: uppercase;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin: 0 0 40px;
}
.upcoming-tournament-raw .leader-list-col table tr td {
  text-transform: capitalize;
  letter-spacing: 0.5px;
}
.upcoming-tournament-raw .buttons-col {
  text-align: center;
}
.upcoming-tournament-raw .buttons-col .nav-tabs {
  display: inline-block;
  margin-top: 10px;
  float: none;
  text-align: center;
  border: 0px;
}
.upcoming-tournament-raw .buttons-col .nav-tabs li.nav-item {
  display: inline-block;
}
.upcoming-tournament-raw .buttons-col .nav-tabs li.nav-item a {
  min-width: 200px;
}
.upcoming-tournament-raw .buttons-col .btn-main {
  margin-left: 10px;
  margin-right: 10px;
}
.upcoming-tournament-raw .buttons-col .btn-main span {
  padding-left: 25px;
}
.upcoming-tournament-raw .buttons-col .btn-main.ic-round span {
  background: transparent url(../images/ic-first-round.png) left center
    no-repeat;
}
.upcoming-tournament-raw .buttons-col .btn-main.ic-winner span {
  background: transparent url(../images/ic-winner.png) left center no-repeat;
}
.upcoming-tournament-raw .buttons-col .btn-main.active,
.upcoming-tournament-raw .buttons-col .btn-main:hover,
.upcoming-tournament-raw .buttons-col .btn-main:focus {
  background-color: rgba(0, 156, 255, 0.4) !important;
  border-width: 2px;
}

.winners-col .win-img {
  position: relative;
  margin: -60px 0 0;
}
.winners-col .winner-li .win-img {
  margin-top: -110px;
}
.winners-col .winner-li .team-details-col {
  margin-top: 0px;
}
.winners-col .team-details-col {
  margin-top: 25px;
}
.winners-col .team-details-col::before,
.winners-col .team-details-col::after {
  display: none;
}
.winners-col .team-details-col table tr {
  position: relative;
}
.winners-col .team-details-col table tr::before {
  position: absolute;
  left: 0;
  top: 0;
  background: transparent url(../images/shadow-btm.png) top center no-repeat;
  content: "";
  width: 100%;
  height: 15px;
}
.winners-col .team-details-col table tr:first-child::before {
  background-image: url(../images/border-shadow.png);
}

.timeline {
  width: 100%;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
.timeline .events {
  position: relative;
  width: 100%;
  text-align: center;
}
.timeline .events ul {
  list-style: none;
  position: relative;
}
.timeline .events ul::before {
  position: absolute;
  left: 0;
  top: 8px;
  height: 2px;
  width: 100%;
  content: "";
  background: rgb(0, 122, 10);
  background: linear-gradient(
    to right,
    rgb(0, 122, 10) 0%,
    rgb(119, 115, 10) 66%,
    rgb(119, 122, 129) 86%
  );
}
.timeline .events ul li {
  display: inline-block;
  width: 24.65%;
  margin: 0;
  padding: 0;
}
.timeline .events ul li.winner-li a {
  line-height: 28px;
  box-shadow: 0 0 10px inset rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 10px inset rgba(255, 255, 255, 0.5);
}
.timeline .events ul li.winner-li h5 strong {
  text-shadow: 0px 0px 10px white;
}
.timeline .events ul li a {
  text-decoration: none;
  position: relative;
  top: -6px;
  width: 90px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  display: inline-block;
  border-radius: 100px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  z-index: 111;
}
.timeline .events ul li h5 {
  font-family: "D-DIN Condensed";
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.timeline .events ul li h5 strong {
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 16px;
}
.timeline .events ul li:first-child a,
.timeline .events ul li:nth-child(2) a {
  background: #001511;
  color: #00ff0c;
}
.timeline .events ul li:first-child h5,
.timeline .events ul li:nth-child(2) h5 {
  color: #00ff0c;
}
.timeline .events ul li:nth-child(3) a {
  background: #1a1d11;
  color: #fff000;
}
.timeline .events ul li:nth-child(3) h5 {
  color: #fff000;
}
.timeline .events ul li:nth-child(4) a {
  background: #272a37;
  color: white;
}
.timeline .events ul li:nth-child(4) h5 {
  color: white;
}

.tree {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}
.tree > ul > li {
  padding: 0px !important;
}
.tree ul {
  padding-right: 20px;
  position: relative;
  transition: all 0.5s;
  display: flex;
  flex-direction: row-reverse;
  flex-direction: column;
  justify-content: center;
}
.tree ul.last-child {
  padding-top: 10px;
  padding-bottom: 10px;
}
.tree ul.last-child li {
  padding-top: 0px;
  padding-bottom: 0px;
}
.tree ul.last-child li:first-child a {
  border-radius: 25px 25px 0 0;
  border-bottom: 2px solid #06091a;
}
.tree ul.last-child li:nth-child(2) a {
  border-radius: 0 0 25px 25px;
  border-top: 2px solid #06091a;
}
.tree ul li {
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 5px 20px 5px 0px;
  display: flex;
  flex-direction: row-reverse;
  transition: all 0.5s;
}
.tree ul li::before,
.tree ul li::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 50%;
  border-right: 2px solid #005a90;
  width: 20px;
  height: 50%;
}
.tree ul li::after {
  bottom: auto;
  top: 50%;
  border-top: 2px solid #005a90;
}
.tree ul li:only-child::after,
.tree ul li:only-child::before {
  display: none;
}
.tree ul li:only-child {
  padding-left: 0;
}
.tree ul li:first-child::before,
.tree ul li:last-child::after {
  border: 0 none;
}
.tree ul li:last-child::before {
  border-bottom: 2px solid #005a90;
}
.tree ul li a {
  background: #000b28;
  color: rgba(255, 255, 255, 0.2);
  padding: 12px 50px;
  text-decoration: none;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  align-self: center;
  border-radius: 25px;
  transition: all 0.5s;
  white-space: nowrap;
  min-width: 230px;
  position: relative;
  letter-spacing: 1px;
  text-align: left;
}
.tree ul li a:hover,
.tree ul li a:hover + ul li a {
  background: #009cff;
  color: white;
}
.tree ul li a:hover + ul li::after,
.tree ul li a:hover + ul li::before,
.tree ul li a:hover + ul::before,
.tree ul li a:hover + ul ul::before {
  border-color: #94a0b4;
}
.tree ul li a i {
  font-style: inherit;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  border-radius: 100px;
  position: absolute;
  text-align: center;
  font-size: 14px;
}
.tree ul li a i.ic-user {
  left: 6px;
  top: 6px;
  border: 1px solid #009cff;
  background: #333c53 url(../images/ic-user-icon1.png) center center no-repeat;
}
.tree ul li a i.ic-sts {
  right: 6px;
  top: 6px;
  line-height: 30px;
  font-weight: bold;
  letter-spacing: 1px;
}
.tree ul li a i.ic-sts.r2c {
  background: #001a24;
  color: #00ff0c;
}
.tree ul li a i.ic-sts.frc {
  background: #1a2224;
  color: #fff000;
}
.tree ul li a.win-tree {
  background: rgb(0, 156, 255);
  background: linear-gradient(
    to right,
    rgb(0, 156, 255) 0%,
    rgb(0, 216, 255) 100%
  );
  color: white;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
}
.tree ul li a.win-tree i.ic-user {
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url(../images/ic-user-icon.png);
}
.tree ul li a.win-tree i.winc {
  background: transparent url(../images/ic-winner.png) center center no-repeat;
}
.tree ul ul::before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  border-top: 2px solid #005a90;
  width: 20px;
  height: 0;
}

/*---------------------about-us-start----------------------*/
.about-main-raw {
  background-image: none;
}
.about-main-raw .about-inner-banner {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.about-main-raw .about-intro-raw {
  text-align: center;
  position: relative;
  padding: 0px 32%;
}
.about-main-raw .about-intro-raw p {
  font-size: 16px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  color: white;
  line-height: 140%;
  letter-spacing: 0.8px;
}
.about-main-raw .banner-con {
  margin: 450px 0 50px;
}
.about-main-raw .banner-con .img-box {
  margin-bottom: 20px;
}
.about-main-raw .banner-con .img-box a {
  position: relative;
  height: 100%;
  display: inline-block;
  width: auto;
  float: none;
  margin: 0 auto;
}
.about-main-raw .banner-con .img-box a img {
  width: 262px;
  height: 148px;
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
}
.about-main-raw .banner-con .img-box a:after {
  content: "";
  position: absolute;
  top: 45%;
  left: 50%;
  width: 85px;
  height: 85px;
  background: transparent url(../images/video-play.png) center center no-repeat;
  transform: translate(-50%, -50%);
  background-size: contain;
}
.about-main-raw .banner-con .img-box a::before {
  position: absolute;
  left: 50%;
  bottom: 0;
  content: "";
  background: transparent url(../images/video-light.png) center top no-repeat;
  width: 100%;
  height: 16px;
  transform: translate(-50%, 0);
}
.about-main-raw .banner-con .img-box .light-below {
  margin-top: 5px;
}

.myImg {
  position: relative;
  border: 0 none;
  box-shadow: none;
  margin: 0;
  padding: 0;
}
.myImg .panel-image-preview {
  width: 100%;
  margin-bottom: 10px;
}
.myImg .caption {
  /* width: inherit; */
  bottom: 230px;
  position: absolute;
  text-align: center;
  width: 100%;
}
.myImg .caption .glow {
  color: #fff;
  text-shadow: 3px 1px 0px #000000;
}
.myImg .caption .glow .sub-head-j {
  font: 400 29px/35px "Oswald", sans-serif;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
}
.myImg .caption .glow .sub-head-k {
  font: 400 23px/30px "Oswald", sans-serif;
  color: #7dc1fa;
  letter-spacing: 5px;
}

/*---------------------about-us-end----------------------*/
/*---------------------about-us-end----------------------*/
/*-----------notification start--------------*/
.notification-banner-rawm .inner-banner {
  height: 450px;
}

.notification-raw .btn-group .btn {
  transition: background-color 0.3s ease;
  float: left;
}
.notification-raw .btn-group .btn.btn-filter input {
  position: relative;
  z-index: 999999;
  cursor: pointer;
}
.notification-raw .panel-table .panel-body {
  padding: 0;
}
.notification-raw .table > thead > tr > th {
  border-bottom: none;
  border-top: 1px solid #03718c;
  border-bottom: 1px solid #03718c;
  background: transparent;
}
.notification-raw .table > thead > tr > th::after {
  display: none !important;
}
.notification-raw .panel-footer,
.notification-raw .panel-table .panel-body .table-bordered {
  border-style: none;
  margin: 0;
}
.notification-raw
  .panel-table
  .panel-body
  .table-bordered
  > thead
  > tr
  > th:first-of-type {
  text-align: center;
  width: 50px;
}
.notification-raw
  .panel-table
  .panel-body
  .table-bordered
  > thead
  > tr
  > th.col-tools {
  text-align: center;
  width: 120px;
}
.notification-raw
  .panel-table
  .panel-body
  .table-bordered
  > thead
  > tr
  > th:last-of-type,
.notification-raw
  .panel-table
  .panel-body
  .table-bordered
  > tbody
  > tr
  > td:last-of-type {
  border-right: 0;
}
.notification-raw
  .panel-table
  .panel-body
  .table-bordered
  > thead
  > tr
  > th:first-of-type,
.notification-raw
  .panel-table
  .panel-body
  .table-bordered
  > tbody
  > tr
  > td:first-of-type {
  border-left: 0;
}
.notification-raw
  .panel-table
  .panel-body
  .table-bordered
  > tbody
  > tr:first-of-type
  > td {
  border-bottom: 0;
}
.notification-raw
  .panel-table
  .panel-body
  .table-bordered
  > thead
  > tr:first-of-type
  > th {
  border-top: 0;
}
.notification-raw .notification-table-col {
  position: relative;
  z-index: 1;
  /* .table-list-notifies {
      // margin-top: 0;
      // border-collapse: separate;
      // border-spacing: 0 0.4em;
  } */
}
.notification-raw .notification-table-col .date-action-col .select-game {
  max-width: 150px;
}
.notification-raw .notification-table-col .date-action-col .select-game input {
  padding-right: 25px;
  background-image: url(../images/ic-calendar.png);
  background-position: center right 5px;
  background-repeat: no-repeat;
  background-size: 18px;
}
.notification-raw .notification-table-col .date-action-col .select-col {
  margin-left: 35px;
  position: relative;
}
.notification-raw
  .notification-table-col
  .date-action-col
  .select-col
  select.form-control {
  height: 40px;
  background: #009cff url(../images/ic-select.png) right 20px center no-repeat;
  color: white;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  border: 0px;
  padding: 0 40px 0 15px;
  min-width: 160px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.5px;
  cursor: pointer;
}
.notification-raw .notification-table-col tbody tr {
  background: rgb(0, 33, 55);
  background: linear-gradient(to right, rgb(0, 33, 55) 0%, rgb(0, 47, 78) 100%);
}
.notification-raw .notification-table-col tbody tr td {
  border: 0px;
}

.table-list-notifies {
  margin-top: 0;
  border-collapse: separate;
  border-spacing: 0 0.4em;
}
.table-list-notifies .date-time-col {
  text-align: right;
  font-family: "Montserrat", sans-serif;
  color: rgba(255, 255, 255, 0.3);
  text-transform: capitalize;
  font-size: 12px;
  vertical-align: middle;
  letter-spacing: 0.5px;
}
.table-list-notifies .check-col {
  text-align: center;
  vertical-align: middle;
}
.table-list-notifies .con-col .conn {
  display: flex;
  align-items: center;
}
.table-list-notifies .con-col .conn .img-fluid {
  width: 56px;
  height: 56px;
  border: 2px solid #3385cc;
  border-radius: 100px;
  margin-right: 20px;
}
.table-list-notifies .con-col .conn h6 {
  font-size: 20px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  line-height: 100%;
}
.table-list-notifies .con-col .conn h6 span {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: unset;
  color: rgba(255, 255, 255, 0.3);
  padding: 0;
  font-weight: 400;
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type="checkbox"].checkbox-circle {
    --active: #009cff;
    --active-inner: white;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: #009cff;
    --background: transparent;
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 25px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 1;
    margin: 0;
    cursor: pointer;
    border: 1px solid #3385cc;
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
  }
  input[type="checkbox"].checkbox-circle:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
  }
  input[type="checkbox"].checkbox-circle:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  input[type="checkbox"].checkbox-circle:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  input[type="checkbox"].checkbox-circle:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  input[type="checkbox"].checkbox-circle:hover:not(:checked):not(:disabled) {
    --bc: var(--border-hover);
  }
  input[type="checkbox"].checkbox-circle:focus {
    box-shadow: 0 0 0 var(--focus);
  }
  input[type="checkbox"].checkbox-circle:not(.switch) {
    width: 25px;
  }
  input[type="checkbox"].checkbox-circle:not(.switch):after {
    opacity: var(--o, 0);
  }
  input[type="checkbox"].checkbox-circle:not(.switch):checked {
    --o: 1;
  }
  input[type="checkbox"].checkbox-circle:not(.switch) {
    border-radius: 100px;
  }
  input[type="checkbox"].checkbox-circle:not(.switch):after {
    width: 4px;
    height: 8px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 5px;
    top: 2px;
    transform: rotate(var(--r, 20deg));
  }
  input[type="checkbox"].checkbox-circle:not(.switch):checked {
    --r: 43deg;
  }
  input[type="checkbox"].checkbox-circle.switch {
    width: 38px;
    border-radius: 11px;
  }
  input[type="checkbox"].checkbox-circle.switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    background: var(--ab, var(--border));
    transform: translateX(var(--x, 0));
  }
  input[type="checkbox"].checkbox-circle.switch:checked {
    --ab: var(--active-inner);
    --x: 17px;
  }
  input[type="checkbox"].checkbox-circle.switch:disabled:not(:checked):after {
    opacity: 0.6;
  }
}
.panel-heading .btn-group .btn-filter {
  border: 2px solid #009cff;
  color: white;
  border-radius: 25px !important;
  -webkit-border-radius: 25px !important;
  background: transparent;
  position: relative;
  line-height: 120%;
  padding: 10px 25px;
  margin-right: 10px;
  min-width: 100px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.panel-heading .btn-group .btn-filter:last-child {
  margin-right: 0px;
}
.panel-heading .btn-group .btn-filter.active {
  background: #009cff;
}
.panel-heading .btn-group .btn-filter:hover {
  outline: none;
  box-shadow: none;
}
.panel-heading .btn-group .btn-filter:focus,
.panel-heading .btn-group .btn-filter.focus {
  outline: none;
  box-shadow: none;
}
.panel-heading .btn-group .btn-filter input {
  position: absolute !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.modal-border-raw.modal-raw .modal-dialog {
  max-width: 600px;
}
.modal-border-raw.modal-raw .modal-dialog .modal-content {
  border: 2px solid #27678c;
  padding: 5px;
}
.modal-border-raw.modal-raw .modal-dialog .modal-content .title-raw h4 {
  color: white;
}

.title-raw.downarrow::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -15px;
  width: 30px;
  height: 26px;
  transform: translate(-50%, 0);
  background: transparent url(../images/multi-down-arrow.png) center center
    no-repeat;
}
.title-raw h3 span {
  font-weight: 600;
}

.challenge-sent-modal .title-raw h3 span {
  font-size: 44px;
  line-height: 92%;
}
.challenge-sent-modal .user-avtar-col {
  width: 150px;
  padding: 15px;
}
.challenge-sent-modal.modal-raw .modal-content .modal-body {
  background-color: #0f1949;
  background-image: url(../images/glow-line-box.png);
  background-position: bottom center;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-bottom: 40px;
}
.challenge-sent-modal.modal-raw .modal-content .modal-body::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -70%;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    ellipse at center,
    #3385cc 0%,
    rgba(51, 133, 204, 0) 70%
  );
  transform: translate(-50%, 0);
}
.challenge-sent-modal h5 {
  font-size: 18px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  margin: 0 0 20px;
  position: relative;
}
.challenge-sent-modal h6 {
  text-transform: uppercase;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 14px;
}
.challenge-sent-modal h4 {
  text-transform: uppercase;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 22px;
  color: white;
}
.challenge-sent-modal p {
  color: rgba(255, 255, 255, 0.2);
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 10px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
}

.challenge-modal-box h3 {
  font-size: 34px;
  color: white;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
.challenge-modal-box h3 span {
  font-size: 44px;
}
.challenge-modal-box h4 {
  font-size: 20px;
}
.challenge-modal-box .coins-col-box {
  margin: 25px 0 0;
}
.challenge-modal-box .coins-col-box h4 {
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  font-size: 24px;
}
.challenge-modal-box .coins-col-box .coins-col {
  text-align: center;
  margin-top: 55px;
}
.challenge-modal-box .coins-col-box .coins-col li {
  display: inline-block;
  margin: 0px 15px 0;
}
.challenge-modal-box .coins-col-box .coins-col li .img-box {
  margin-top: -30px;
}
.challenge-modal-box .coins-col-box .coins-col li .img-box canvas {
  opacity: 0.4;
}
.challenge-modal-box .coins-col-box .btn-main {
  vertical-align: middle;
  background: white;
  color: #00cbff;
  letter-spacing: 1px;
  position: relative;
  border-width: 2px;
}
.challenge-modal-box .coins-col-box .btn-main::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 2px solid #172649;
  border-radius: 25px;
}
.challenge-modal-box .coins-col-box .btn-main svg {
  width: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-top: -10px;
  margin-right: 5px;
  transition: all 0.25s ease 0s;
}
.challenge-modal-box .coins-col-box .btn-main:hover svg .cls-1 {
  stroke: white;
}
.challenge-modal-box .coins-col-box p {
  font-size: 16px;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  margin-top: 15px;
  letter-spacing: 0.5px;
}
.challenge-modal-box .modal-content .modal-body::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -75%;
  width: 130%;
  height: 100%;
  background: radial-gradient(
    ellipse at center,
    #3385cc 0%,
    rgba(51, 133, 204, 0) 70%
  );
  transform: translate(-50%, 0);
  opacity: 0.5;
}

/*---------------notification end---------------*/
/*--------------contact-us-start---------------*/
.mgtp-minus {
  margin-top: -50px;
}

.contact-gaming-raw .contact-line-raw {
  text-align: center;
}
.contact-gaming-raw .contact-line-raw ul li {
  width: 100%;
  float: left;
  padding-bottom: 30px;
}
.contact-gaming-raw .contact-line-raw ul li .location-raw {
  background: url(..//images/contact-location.png) no-repeat;
  background-size: contain;
  width: 18px;
  height: 27px;
  display: inline-block;
  margin-bottom: 20px;
}
.contact-gaming-raw .contact-line-raw ul li .mail-raw {
  background: url(..//images/contact-mail.png) no-repeat;
  background-size: contain;
  width: 22px;
  height: 15px;
  display: inline-block;
}
.contact-gaming-raw .contact-line-raw ul li .mobile-raw {
  background: url(..//images/contact-mobile.png) no-repeat;
  background-size: contain;
  width: 18px;
  height: 32px;
  display: inline-block;
}
.contact-gaming-raw .contact-line-raw ul li .contact-text-wrap {
  margin-bottom: 30px;
}
.contact-gaming-raw .contact-line-raw ul li .contact-text-wrap p {
  font-size: 18px;
  color: white;
}
.contact-gaming-raw .contact-line-raw ul li .contact-text-wrap p span {
  text-transform: uppercase;
  background: #009cff;
  background: linear-gradient(to top, #009cff, #00d8ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 17px;
}
.contact-gaming-raw .contact-sub {
  text-align: center;
  padding: 0% 25%;
}
.contact-gaming-raw .contact-sub h4 {
  color: white;
  text-transform: uppercase;
  font-size: 26px;
  line-height: 40px;
}
.contact-gaming-raw .contact-sub .form-group {
  margin-top: 20px;
}
.contact-gaming-raw .contact-sub .form-group textarea.form-control {
  height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
  font-size: 16px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  background: rgba(255, 255, 255, 0.01);
}
.contact-gaming-raw
  .contact-sub
  .form-group
  textarea.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
.contact-gaming-raw
  .contact-sub
  .form-group
  textarea.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
.contact-gaming-raw
  .contact-sub
  .form-group
  textarea.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
.contact-gaming-raw
  .contact-sub
  .form-group
  textarea.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
.contact-gaming-raw .contact-sub .btn-send {
  padding: 18px 36px;
  background: #009cff;
  background: linear-gradient(to right, #009cff 0%, #00d8ff 100%);
  font-size: 14px;
  color: white;
  line-height: 0.875em;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 0.5px;
  border-radius: 25px;
  top: -25px;
  position: relative;
}

.contact-us-form {
  width: 100%;
  float: left;
  margin-top: 8%;
}
.contact-us-form h1 {
  text-transform: uppercase;
  background: #009cff;
  background: linear-gradient(to top, #009cff, #00d8ff);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  font-weight: 800;
}
.contact-us-form form {
  padding: 0% 28% 0%;
}
.contact-us-form form .form-group {
  margin-top: 20px;
  margin-bottom: 0px;
}
.contact-us-form form .form-group .form-control {
  border-radius: 5px;
  background: transparent;
  height: 52px;
  background: rgba(6, 9, 25, 0.4);
  border-color: #036193;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.contact-us-form form .form-group .form-control:focus {
  font-weight: 700;
}
.contact-us-form form .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.7);
}
.contact-us-form form .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.7);
}
.contact-us-form form .form-group .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: rgba(255, 255, 255, 0.7);
}
.contact-us-form form .form-group .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: rgba(255, 255, 255, 0.7);
}
.contact-us-form form .form-group textarea.form-control {
  height: 100px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.contact-us-form form .form-group .btn-send {
  padding: 18px 36px;
  background: #009cff;
  background: linear-gradient(to right, #009cff 0%, #00d8ff 100%);
  font-size: 14px;
  color: white;
  line-height: 0.875em;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

/*------------------contact-us-end------------------*/
/*---------------------popups start------------------*/
.form-col {
  background: transparent url(../images/shadow-btm1.png) center bottom no-repeat;
  padding-bottom: 23px;
  margin-top: 20px;
}
.form-col .form-control {
  font-size: 12px;
  border-radius: 25px;
  height: 35px;
}
.form-col button {
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  color: white;
  text-shadow: 0 0 10px rgb(255, 255, 255);
  text-transform: uppercase;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  letter-spacing: 1px;
  padding: 9px 15px;
}
.form-col button.search-challenger-col {
  background: transparent url(../images/ic-search-white.png) center center
    no-repeat;
  height: 35px;
  padding-left: 25px;
  padding-right: 25px;
}

.devider-col {
  background: transparent url(../images/devider-line-shadow.png) center top
    no-repeat;
  height: 25px;
  position: relative;
  z-index: 1;
  background-size: contain;
}
.devider-col.reverse-col {
  transform: rotate(180deg);
}

.challenger-list-col ul li {
  background: rgba(0, 156, 255, 0.6);
  border-radius: 10px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.08);
  display: flex;
  padding: 20px;
  align-items: center;
  margin-bottom: 8px;
}
.challenger-list-col ul li .avtar {
  width: 55px;
  height: 55px;
  border-radius: 100px;
  text-align: left;
}
.challenger-list-col ul li .user-con {
  width: 65%;
  text-align: left;
  padding-left: 15px;
}
.challenger-list-col ul li .user-con h6 {
  color: white;
  font-size: 16px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  letter-spacing: 0.4px;
}
.challenger-list-col ul li .user-con h6.sub {
  font-size: 14px;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
}
.challenger-list-col ul li .sts {
  width: 20%;
  font-size: 10px;
  color: white;
  font-family: "Montserrat", sans-serif;
  text-align: right;
}
.challenger-list-col ul li .sts i {
  width: 10px;
  height: 10px;
  background: #06ff00;
  border-radius: 100px;
  display: inline-block;
  margin-right: 2px;
  margin-bottom: -1px;
}

.find-challenge-modal .modal-content .modal-body,
.choose-team-modal .modal-content .modal-body {
  padding-bottom: 0px;
}

.choose-team-modal .form-col button.search-challenger-col {
  background-image: url(../images/search-ic.png);
  background-size: 26px;
  padding-left: 18px;
  padding-right: 18px;
}
.choose-team-modal .challenger-list-col {
  margin-top: -23px;
  padding: 0 0px 0 25px;
}
.choose-team-modal .challenger-list-col ul li {
  background: #002e5c;
}
.choose-team-modal .mCSB_scrollTools {
  padding: 20px 0 !important;
}
.choose-team-modal .mCSB_scrollTools .mCSB_buttonUp {
  background-image: url(../images/scroll-up.png);
  background-position: center center;
  margin-top: -20px;
}
.choose-team-modal .mCSB_scrollTools .mCSB_buttonDown {
  background-image: url(../images/scroll-down.png);
  background-position: center center;
}
.choose-team-modal .mCSB_scrollTools .mCSB_draggerRail {
  background-color: rgba(255, 255, 255, 0.2);
}
.choose-team-modal .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background: white;
}
.choose-team-modal .devider-col {
  margin-top: 25px;
}

.btn-main.btn-two-line {
  vertical-align: middle;
  background: #00d8ff;
  background: linear-gradient(to right, #00d8ff 0%, #009cff 100%);
  color: white;
  letter-spacing: 1px;
  position: relative;
  border-width: 2px;
  font-size: 16px;
}
.btn-main.btn-two-line::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  border: 2px solid #172649;
  border-radius: 25px;
}

.create-team-modal.modal-border-raw.modal-raw .modal-dialog {
  max-width: 950px;
}
.create-team-modal .modal-form {
  margin-top: -23px;
}
.create-team-modal .modal-form .user-avtar-col {
  width: 170px;
  height: 170px;
  background-image: url(../images/polygon-bg.png);
  background-size: contain;
  margin-bottom: 20px;
}
.create-team-modal .modal-form .user-avtar-col .upload-img {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background: white url(../images/edit-image.png) center center no-repeat;
  position: absolute;
  right: 0;
  top: 25px;
  padding: 13px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
.create-team-modal .modal-form .form-grop,
.create-team-modal .modal-form .section-title {
  text-align: left;
  margin-bottom: 25px;
}
.create-team-modal .modal-form .form-grop label,
.create-team-modal .modal-form .section-title label {
  font-size: 14px;
  color: #009cff;
  font-family: "D-DIN";
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  padding-left: 15px;
}
.create-team-modal .modal-form .form-grop p,
.create-team-modal .modal-form .section-title p {
  font-family: "D-DIN Condensed";
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  line-height: 140%;
  padding-left: 15px;
}
.create-team-modal .modal-form .form-grop .form-control,
.create-team-modal .modal-form .section-title .form-control {
  height: 40px;
  border: 3px solid #565e7a;
  border-radius: 25px;
  background: white;
  margin-top: 10px;
  color: #333333;
  font-family: "D-DIN Condensed";
  font-weight: normal;
  padding: 0 15px;
}
.create-team-modal .modal-form .form-grop select.form-control,
.create-team-modal .modal-form .section-title select.form-control {
  background-image: url(../images/select-icon.png);
  background-position: right 15px center;
  background-repeat: no-repeat;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
}
.create-team-modal .modal-form .section-title {
  margin: 5px 0 15px !important;
  display: flex;
}
.create-team-modal .modal-form .section-title label {
  font-size: 16px;
  color: white;
}
.create-team-modal .modal-form .section-title .addmore-btn {
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  border-bottom: 2px solid white;
  padding-bottom: 3px;
}
.create-team-modal .modal-form form .modal-form-scroll {
  overflow: hidden;
  height: 500px;
}
.create-team-modal .modal-form form .modal-form-scroll .modal-form-scroll-col {
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-left: 17px;
  padding-right: 17px;
  padding-top: 30px;
  padding-top: 20px;
}

.choose-your-team-modalbox p {
  color: white;
  font-size: 16px;
  font-family: "D-DIN Condensed";
  letter-spacing: 1.5px;
}
.choose-your-team-modalbox p.uppercase {
  text-transform: uppercase;
}
.choose-your-team-modalbox .btn-main {
  margin-top: 10px;
}
.choose-your-team-modalbox .upper-section {
  background: transparent url(../images/shadow-btm1.png) center bottom no-repeat;
  padding-bottom: 30px;
  background-size: 350px;
}
.choose-your-team-modalbox .upper-section .check-label {
  color: rgba(255, 255, 255, 0.5);
  margin-top: 10px;
}
.choose-your-team-modalbox .upper-section .check-label .checkbox-circle {
  width: 16px !important;
  height: 16px !important;
  border: 1px solid white;
  margin-right: 8px;
}
.choose-your-team-modalbox .bottom-section {
  margin-top: 25px;
}
.choose-your-team-modalbox .bottom-section .team-col .title-divider {
  margin-bottom: 10px;
}
.choose-your-team-modalbox .bottom-section .team-col .title-divider::before,
.choose-your-team-modalbox .bottom-section .team-col .title-divider::after {
  width: 20%;
  height: 1px;
  background: rgba(255, 255, 255, 0.3);
}
.choose-your-team-modalbox .bottom-section .team-col h4 {
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  background: -webkit-linear-gradient(#00d3ff, #3385cc);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  position: relative;
}
.choose-your-team-modalbox .bottom-section .team-col ul {
  background: #002e5c;
  padding: 30px 30px;
  margin: 30px 0px;
  border-radius: 15px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.08);
  float: none;
  display: flex;
  text-align: center;
}
.choose-your-team-modalbox .bottom-section .team-col ul li {
  width: 33.33%;
  float: left;
}
.choose-your-team-modalbox .bottom-section .team-col ul li img {
  width: 45px;
  height: 45px;
  border-radius: 100%;
}
.choose-your-team-modalbox .bottom-section .team-col ul li p {
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.5px;
  margin-top: 5px;
}
.choose-your-team-modalbox .modal-dialog {
  width: 670px;
}
.choose-your-team-modalbox .modal-dialog .modal-content {
  border: 0px !important;
}
.choose-your-team-modalbox .modal-dialog .modal-content .btn-close-modal {
  right: 25px;
  top: 25px;
}

.pointer {
  cursor: pointer;
}

.tournament-lobby-raw .hexa-img {
  margin-right: 14px;
}
.tournament-lobby-raw tr td {
  padding: 8px 10px 6px !important;
}
.tournament-lobby-raw tr th {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
}

.round-tab-raw .nav-tabs {
  text-align: center;
  display: inline-block;
  float: none;
  margin: 20px 0;
  border: 0;
}
.round-tab-raw .nav-tabs .nav-item {
  display: inline-block;
  text-align: center;
  margin: 0 5px;
}
.round-tab-raw .nav-tabs .nav-item .btn-round {
  border: 1px solid #00a8f3;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #585a5f;
  font-size: 14px;
  font-weight: bold;
  font-family: "D-DIN Condensed";
  background: #000513;
  transition: all 0.25s ease 0s;
  border-radius: 100px;
  display: inline-block;
}
.round-tab-raw .nav-tabs .nav-item .btn-round.active,
.round-tab-raw .nav-tabs .nav-item .btn-round:hover {
  background: #00d3f9;
  background: linear-gradient(to bottom, #00d3f9 0%, #0099f9 100%);
  color: #000;
}

.modal-backdrop.show {
  opacity: 0.7 !important;
}

.desktop-mobile {
  display: none;
}

.admin.mobile-view {
  display: none !important;
}

/* .header .dropdown-list {
  display: block !important;
} */

/*---------------------popups end------------------*/

@media only screen and (min-width: 1600px) and (max-width: 1733px) {
  .header .logo.desktop {
    margin-left: 35px;
    margin-right: 35px;
  }
  .header .header-right .nav-main ul {
    min-width: 462px;
  }
  .header .header-right .nav-main ul li {
    margin-right: 20px;
  }
  .header .header-right .nav-main ul li a {
    letter-spacing: 1.5px;
    margin: 0px 10px;
  }
  .header .header-right .nav-main ul li a::before {
    right: -12px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1599px) {
  .container {
    max-width: 1180px;
  }
  .header .logo.desktop {
    margin: 0 16px -68px;
  }
  .header .logo {
    width: 80px;
    left: -2px;
    top: -8px;
  }
  .header .notification,
  .header .admin {
    width: auto;
    padding: 0;
  }
  .header .setting-head {
    top: 6px;
  }
  .header .admin .icon {
    width: 34px;
    height: 34px;
  }
  .header .notification .dropdown-menu-noti {
    right: 28px;
    max-width: 450px;
    top: 85px;
  }
  .header .notification ul li {
    margin-right: 10px;
  }
  .header .header-right {
    width: calc(100% - 180px) !important;
    padding: 20px;
  }
  .header .header-right .nav-main ul {
    min-width: 400px;
  }
  .header .header-right .nav-main ul li {
    margin-right: 18px;
  }
  .header .header-right .nav-main ul li a {
    letter-spacing: 0px;
    margin: 0 5px;
  }
  .header .header-right .nav-main ul li a::before {
    right: -4px;
  }
  .header .login-raw a.btn-login {
    padding-right: 15px;
  }
  .affix .header .logo {
    padding: 0;
  }
  .affix .header .setting-head {
    top: -4px;
  }
  .affix .header .notification .dropdown-menu-noti {
    top: 76px;
  }
  .topbar .left-side h6 strong {
    font-weight: 800;
    letter-spacing: 1px;
  }
  h1 {
    font-size: 48px;
  }
  .league-wrapper {
    padding-top: 220px;
  }
  .table-wrapper {
    padding: 110px 0 90px;
  }
  .faqs-wrapper .accordion .card .card-header button {
    font-size: 22px;
  }
  .tooltipText {
    width: 280px;
    font-size: 16px;
  }
  .coins .tooltipText {
    right: 40px;
  }
  .coins .tooltipText::after {
    left: calc(50% + 34px);
  }
  .left-side h6:first-child {
    padding-right: 20px;
    margin-right: 20px;
  }
  .main-wrapper {
    padding-top: 112px;
  }
  .step-section-raw ul {
    padding: 40px 30px;
  }
  .step-section-raw ul::before {
    width: 100.7%;
    height: 106%;
  }
  .step-section-raw ul li {
    width: 33%;
  }
  .step-section-raw ul li .step-col > span {
    font-size: 70px;
  }
  .step-section-raw ul li .step-col .step-conn p {
    font-size: 12px;
  }
  .game-lists-raw .gm-li-raw {
    padding: 0;
  }
  .game-lists-raw .gm-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li {
    padding-right: 15px;
    padding-left: 15px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 165px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw {
    padding: 25px 15px 20px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw .view-raw {
    font-size: 12px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn {
    width: 25%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3 {
    font-size: 28px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn {
    width: 75%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li {
    /*  &:last-child {
       width: 45%;
     } */
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join {
    padding: 8px 25px 8px 10px;
    background-position: right 5px center;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join:hover {
    background-position: right 3px center;
  }
  .title-raw h1 span,
  .title-raw h2 span {
    font-size: 94px;
  }
  .title-raw h1,
  .title-raw h2,
  .title-raw h3 {
    font-size: 44px;
  }
  .gamedetail .title-raw h1 {
    font-size: 56px;
  }
  #games.title-raw {
    margin-top: -150px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    /* height: 162px; */
    height: 300px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    font-size: 18px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h6 {
    font-size: 12px;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 125px;
  }
  .trending-videos-raw {
    padding: 48px 0 0 0;
  }
  .trending-videos-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 155px !important;
  }
  .record-list-raw .record-list-col {
    padding: 0 5%;
  }
  .record-list-raw .record-list-col ul li {
    padding: 0 3%;
  }
  .record-list-raw .record-list-col ul li h3 {
    font-size: 52px;
  }
  .record-list-raw .record-list-col ul li h3 .span {
    font-size: 14px;
  }
  .social-connects-raw li {
    margin: 0px;
  }
  .terms-raw {
    width: 70%;
  }
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw.latest-news-raw .latest-news-col {
    width: 90%;
  }
  .latest-news-raw .latest-news-col {
    padding: 0 8%;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box {
    padding: 30px 30px 30px 25px;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    h4 {
    font-size: 22px;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    p {
    font-size: 12px;
  }
  .btn-sm {
    padding: 12px 35px;
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw .about-intro-raw {
    padding: 0px 20%;
  }
  /*---------------------about-us-end----------------------*/
  /*------------------Store Start-------------------------*/
  .store-raw .tab-content {
    padding: 0%;
  }
  .store-raw.game-lists-raw .gm-li-raw {
    padding: 0 40px;
  }
  /*------------------Store-end-------------------------*/
  /*--------------leaderboard-start--------------*/
  .leaderboard-raw .leader-list-raw {
    padding: 0 7%;
  }
  .leaderboard-raw .leader-list-raw .leader-list-col {
    margin-top: 60px;
  }
  /*---------------leaderboard-end--------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw.game-lists-raw .gm-li-raw {
    padding: 0 60px;
  }
  .challenges-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .challenges-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 185px;
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw.game-lists-raw .gm-li-raw {
    padding: 0 60px;
  }
  .challenge-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    flex: 0 0 30%;
    max-width: 30%;
  }
  .challenge-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li.vs-raw {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .challenge-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 180px;
  }
  /*-------------challenge-end---------------*/
  /*-------------trophy-start---------------*/
  .trophy-raw {
    padding: 0 70px;
  }
  /*-------------trophy-end----------------*/
  /*-------------contact-us-start---------------*/
  .contact-us-form form {
    padding: 0% 24% 0%;
  }
  .contact-gaming-raw .contact-sub {
    padding: 0% 24%;
  }
  /*-------------contact-us-end---------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw .wallet-ul .wallet-li {
    width: 33%;
  }
  /*-------------Wallet-end---------------*/
  .common-page-raw::before {
    left: -30%;
    top: -30%;
    width: 800px;
    height: 800px;
  }
  .common-page-raw::after {
    right: -30%;
    top: -30%;
    width: 800px;
    height: 800px;
  }
  /*----------------profile menu start-------------------*/
  .affix .header .dropdown-list,
  .header .dropdown-list {
    top: 51px;
  }
  /*----------------profile menu end-------------------*/
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section,
  .fixed table.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.scroll-wrap,
  .fixed .horizontal-tab-raw.main-wrapper,
  .fixed
    .league-wrapper
    .expert-master
    .progress-wrap
    .horizontal-tab-raw.redius-col,
  .league-wrapper
    .expert-master
    .progress-wrap
    .fixed
    .horizontal-tab-raw.redius-col,
  .fixed .horizontal-tab-raw.select-dropdown,
  .fixed .horizontal-tab-raw.select-game,
  .fixed .step-section-raw ul li .horizontal-tab-raw.step-col,
  .step-section-raw ul li .fixed .horizontal-tab-raw.step-col,
  .fixed .horizontal-tab-raw.title-raw,
  .fixed .game-lists-raw .gm-li-raw .row .gm-li .horizontal-tab-raw.gm-col,
  .game-lists-raw .gm-li-raw .row .gm-li .fixed .horizontal-tab-raw.gm-col,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.img-box,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.img-box,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.about-game-raw,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.about-game-raw,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.about-game-conn,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.about-game-conn,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.plateform-players-col,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.plateform-players-col,
  .fixed
    .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.view-raw,
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.view-raw,
  .fixed
    .header
    .notification
    .dropdown-menu-noti
    .notification-list
    li.horizontal-tab-raw,
  .header
    .notification
    .dropdown-menu-noti
    .notification-list
    .fixed
    li.horizontal-tab-raw,
  .fixed .header .dropdown-list li.horizontal-tab-raw,
  .header .dropdown-list .fixed li.horizontal-tab-raw,
  .fixed .header .dropdown-list li a.horizontal-tab-raw,
  .header .dropdown-list li .fixed a.horizontal-tab-raw,
  .fixed .record-list-raw .record-list-col ul li h3 span.horizontal-tab-raw,
  .record-list-raw .record-list-col ul li h3 .fixed span.horizontal-tab-raw,
  .fixed .store-raw .horizontal-tab-raw.tab-content,
  .store-raw .fixed .horizontal-tab-raw.tab-content,
  .fixed .terms-raw label.horizontal-tab-raw,
  .terms-raw .fixed label.horizontal-tab-raw,
  .fixed .challenge-raw .challenge-info-raw ul li > span.horizontal-tab-raw,
  .challenge-raw .challenge-info-raw ul .fixed li > span.horizontal-tab-raw,
  .fixed .most-popular-raw ul li a.horizontal-tab-raw,
  .most-popular-raw ul li .fixed a.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.balance-earned-raw,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .horizontal-tab-raw.team-li-conn,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .fixed
    .horizontal-tab-raw.team-li-conn,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .horizontal-tab-raw.team-details-col,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .fixed
    .horizontal-tab-raw.team-details-col,
  .fixed .trophy-raw .trophy-li .horizontal-tab-raw.trophy-col,
  .trophy-raw .trophy-li .fixed .horizontal-tab-raw.trophy-col,
  .fixed .tournament-details-col ul li strong.horizontal-tab-raw,
  .tournament-details-col ul li .fixed strong.horizontal-tab-raw,
  .fixed .contact-gaming-raw .contact-sub .horizontal-tab-raw.form-group,
  .contact-gaming-raw .contact-sub .fixed .horizontal-tab-raw.form-group,
  .fixed .horizontal-tab-raw.challenger-list-col,
  .fixed .challenger-list-col ul li.horizontal-tab-raw,
  .challenger-list-col ul .fixed li.horizontal-tab-raw,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.form-grop,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.form-grop,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.section-title,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.section-title {
    top: 137px;
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col {
    padding: 30px 20px;
  }
  table tr td {
    letter-spacing: 1px;
  }
  /*-----------------profile team end-------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
  /*-----------------profile achievements end-------------------*/
  /*------------------cart-start--------------------------*/
  .cart-raw.all-games-raw {
    padding: 80px 0;
  }
  .cart-raw.all-games-raw .cart-wrap {
    padding: 0 25px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow {
    height: 35px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow.slick-prev {
    left: -30px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow.slick-next {
    right: -30px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box {
    padding: 20px 10px;
    min-height: 250px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box .img-box {
    line-height: 130px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .img-box
    img {
    width: 70%;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box .text-box {
    padding-top: 35px;
  }
  /*------------------cart-end--------------------------*/
  /*-------------------tournament-details-start-----------*/
  .time-date-col .timesate-li .box-raw {
    min-height: 118px;
  }
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw .timeline-raw .tree ul li a {
    min-width: 200px;
  }
  /*----tournaments-brackets-end----------------*/
}
@media only screen and (min-width: 1121px) and (max-width: 1279px) {
  .container {
    max-width: 1040px;
  }
  .header .logo.desktop {
    margin: 0 15px -68px;
  }
  .header .logo {
    width: 80px;
    left: -2px;
    top: -8px;
  }
  .header .notification,
  .header .admin {
    width: auto;
  }
  .header .admin .icon {
    width: 34px;
    height: 34px;
  }
  .header .notification .dropdown-menu-noti {
    right: 30px;
    max-width: 440px;
    top: 92px;
  }
  .header .notification ul li {
    margin-right: 10px;
  }
  .header .header-right {
    width: calc(100% - 225px) !important;
  }
  .header .header-right .nav-main ul {
    min-width: 360px;
    margin-top: 12px;
  }
  .header .header-right .nav-main ul li {
    margin-right: 12px;
  }
  .header .header-right .nav-main ul li a {
    letter-spacing: 0px;
    margin: 0 6px;
  }
  .header .header-right .nav-main ul li a::before {
    right: -8px;
  }
  .header .login-raw {
    min-width: 110px;
    padding: 5px 0;
    text-align: right;
  }
  .header .login-raw a.btn-login {
    padding-right: 0;
    font-size: 0px;
  }
  .header .login-raw a.btn-main {
    padding: 8px 10px;
  }
  .main-wrapper {
    padding-top: 115px;
  }
  .main-wrapper .carousel-inner .carousel-item {
    height: 450px;
  }
  .affix .header .logo {
    padding: 0;
  }
  .affix .header .header-right .nav-main ul {
    margin-top: 2px;
  }
  .affix .header .notification .dropdown-menu-noti {
    top: 82px;
  }
  h1 {
    font-size: 44px;
  }
  .title-raw h1 span,
  .title-raw h2 span {
    font-size: 84px;
  }
  .title-raw h1,
  .title-raw h2,
  .title-raw h3 {
    font-size: 40px;
  }
  #games.title-raw h1 {
    margin-top: -84px;
  }
  .league-wrapper {
    padding-top: 200px;
  }
  .table-wrapper {
    padding: 90px 0 90px;
  }
  .faqs-wrapper .accordion .card {
    padding-top: 40px;
  }
  .faqs-wrapper .accordion .card .card-header button {
    font-size: 20px;
  }
  .footer-box-wrap {
    padding-top: 80px;
  }
  .footer-box-wrap:before {
    top: -7px;
  }
  .tooltipText {
    width: 280px;
    font-size: 16px;
  }
  .coins .tooltipText {
    right: 90px;
  }
  .coins .tooltipText::after {
    left: calc(50% + 84px);
  }
  .step-section-raw ul {
    padding: 30px 20px;
  }
  .step-section-raw ul::before {
    width: 100.8%;
    height: 107%;
  }
  .step-section-raw ul li {
    width: 33%;
  }
  .step-section-raw ul li .step-col > span {
    font-size: 60px;
    padding-right: 10px;
  }
  .step-section-raw ul li .step-col .img-fluid {
    max-width: 44px;
    max-height: 44px;
  }
  .step-section-raw ul li .step-col .step-conn {
    padding-left: 15px;
    width: 54%;
  }
  .step-section-raw ul li .step-col .step-conn p {
    font-size: 12px;
  }
  /*----------------game--start---------------*/
  .all-games-raw {
    padding: 70px 0;
  }
  .all-games-raw.game-lists-raw
    .game-platform-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    text-align: center;
    font-size: 22px;
  }
  .game-lists-raw .gm-li-raw {
    padding: 0px;
  }
  .game-lists-raw .gm-li-raw .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li {
    padding-right: 10px;
    padding-left: 10px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 150px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw {
    padding: 25px 15px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn {
    width: 25%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3 {
    font-size: 26px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn {
    width: 75%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    font-size: 16px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h6 {
    font-size: 10px;
    padding-left: 8px;
    background-size: 6px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li {
    /* &:last-child {
      width: 45%;
    } */
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join {
    padding: 8px 25px 8px 10px;
    background-position: right 6px center;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join:hover {
    background-position: right 4px center;
  }
  .game-lists-raw .competition-tab-raw .nav-tabs li .nav-link {
    font-size: 20px;
  }
  .trending-videos-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 125px !important;
  }
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .view-raw
    .btn-view {
    font-size: 12px;
  }
  /*----------------game--end---------------*/
  /*------------------news--detail-start-----------*/
  .details-head .img-box {
    width: 150px;
    height: 150px;
  }
  .details-head h1 {
    margin-top: 30px;
  }
  .details-head .post-action {
    margin-top: 30px;
  }
  /*------------------news--detail-end-----------*/
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw.latest-news-raw .latest-news-col {
    width: 90%;
  }
  .latest-news-raw .latest-news-col {
    padding: 0 5%;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box {
    padding: 20px;
    height: 100%;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    h4 {
    font-size: 20px;
  }
  .ongoing-events-raw {
    padding-bottom: 150px;
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw .about-intro-raw {
    padding: 0px 10%;
  }
  /*---------------------about-us-end----------------------*/
  /*------------------Store Start-------------------------*/
  .store-raw .tab-content {
    padding: 0%;
  }
  .store-raw.game-lists-raw .gm-li-raw {
    padding: 0 30px;
  }
  .brought-box {
    padding: 30px 15px 20px;
    min-height: 250px;
  }
  .brought-box .img-box {
    line-height: 175px;
  }
  .brought-box .text-box {
    padding-top: 40px;
  }
  .brought-box .text-box h5 {
    font-size: 24px;
  }
  .brought-box .text-box .price {
    font-size: 20px;
  }
  .store-tab li .nav-link {
    font-size: 20px;
  }
  .addcart-next-raw {
    margin-top: 20px;
  }
  .addcart-next-raw .btn-ic,
  .addcart-next-raw .add-qty-col,
  .addcart-next-raw .btn-join {
    margin-top: 10px !important;
  }
  /*------------------Store-end-------------------------*/
  /*------------------leaderboard-start-----------------*/
  .leaderboard-raw .leader-list-raw {
    padding: 0 7%;
  }
  .leaderboard-raw .leader-list-raw .leader-list-col {
    margin-top: 60px;
  }
  /*-------------------leaderboard-end------------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw.game-lists-raw .gm-li-raw {
    padding: 0 50px;
  }
  .challenges-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .challenges-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 160px;
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw.game-lists-raw .gm-li-raw {
    padding: 0 50px;
  }
  .challenge-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .challenge-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li.vs-raw {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .challenge-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 205px;
  }
  /*-------------challenge-end---------------*/
  /*-------------trophy-start---------------*/
  .trophy-raw {
    padding: 0 50px;
  }
  /*-------------trophy-end----------------*/
  /*-------------contact-us----------------*/
  .contact-us-form form {
    padding: 0% 20% 0%;
  }
  .contact-gaming-raw .contact-sub {
    padding: 0% 20%;
  }
  /*-------------contact-us----------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw .wallet-ul .wallet-li {
    width: 33%;
  }
  /*-------------Wallet-end---------------*/
  table tr th.choose-game {
    width: 300px;
  }
  /*-------------------game detail start------------------*/
  .inner-banner .container-fluid .inner-banner-con .game-rules-col li h4 {
    font-size: 40px;
  }
  .record-list-raw .record-list-col {
    padding: 0 5%;
  }
  .record-list-raw .record-list-col ul li {
    padding: 0 3%;
  }
  .record-list-raw .record-list-col ul li h3 {
    font-size: 52px;
  }
  .record-list-raw .record-list-col ul li h3 span {
    font-size: 14px;
  }
  .about-section-raw {
    padding: 80px 4%;
  }
  .about-section-raw p {
    padding: 0 10%;
  }
  .title-divider {
    font-size: 30px;
  }
  .title-divider::before,
  .title-divider::after {
    width: 30%;
  }
  .ptb10 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .mt50 {
    margin-top: 40px !important;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 114px;
  }
  .ig-tv-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    font-size: 20px;
  }
  /*--------------------game detail end-------------------*/
  /*----------------profile menu start-------------------*/
  .affix .header .dropdown-list,
  .header .dropdown-list {
    top: 51px;
  }
  /*----------------profile menu start-------------------*/
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section,
  .fixed table.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.scroll-wrap,
  .fixed .horizontal-tab-raw.main-wrapper,
  .fixed
    .league-wrapper
    .expert-master
    .progress-wrap
    .horizontal-tab-raw.redius-col,
  .league-wrapper
    .expert-master
    .progress-wrap
    .fixed
    .horizontal-tab-raw.redius-col,
  .fixed .horizontal-tab-raw.select-dropdown,
  .fixed .horizontal-tab-raw.select-game,
  .fixed .step-section-raw ul li .horizontal-tab-raw.step-col,
  .step-section-raw ul li .fixed .horizontal-tab-raw.step-col,
  .fixed .horizontal-tab-raw.title-raw,
  .fixed .game-lists-raw .gm-li-raw .row .gm-li .horizontal-tab-raw.gm-col,
  .game-lists-raw .gm-li-raw .row .gm-li .fixed .horizontal-tab-raw.gm-col,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.img-box,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.img-box,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.about-game-raw,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.about-game-raw,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.about-game-conn,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.about-game-conn,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.plateform-players-col,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.plateform-players-col,
  .fixed
    .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.view-raw,
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.view-raw,
  .fixed
    .header
    .notification
    .dropdown-menu-noti
    .notification-list
    li.horizontal-tab-raw,
  .header
    .notification
    .dropdown-menu-noti
    .notification-list
    .fixed
    li.horizontal-tab-raw,
  .fixed .header .dropdown-list li.horizontal-tab-raw,
  .header .dropdown-list .fixed li.horizontal-tab-raw,
  .fixed .header .dropdown-list li a.horizontal-tab-raw,
  .header .dropdown-list li .fixed a.horizontal-tab-raw,
  .fixed .record-list-raw .record-list-col ul li h3 span.horizontal-tab-raw,
  .record-list-raw .record-list-col ul li h3 .fixed span.horizontal-tab-raw,
  .fixed .store-raw .horizontal-tab-raw.tab-content,
  .store-raw .fixed .horizontal-tab-raw.tab-content,
  .fixed .terms-raw label.horizontal-tab-raw,
  .terms-raw .fixed label.horizontal-tab-raw,
  .fixed .challenge-raw .challenge-info-raw ul li > span.horizontal-tab-raw,
  .challenge-raw .challenge-info-raw ul .fixed li > span.horizontal-tab-raw,
  .fixed .most-popular-raw ul li a.horizontal-tab-raw,
  .most-popular-raw ul li .fixed a.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.balance-earned-raw,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .horizontal-tab-raw.team-li-conn,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .fixed
    .horizontal-tab-raw.team-li-conn,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .horizontal-tab-raw.team-details-col,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .fixed
    .horizontal-tab-raw.team-details-col,
  .fixed .trophy-raw .trophy-li .horizontal-tab-raw.trophy-col,
  .trophy-raw .trophy-li .fixed .horizontal-tab-raw.trophy-col,
  .fixed .tournament-details-col ul li strong.horizontal-tab-raw,
  .tournament-details-col ul li .fixed strong.horizontal-tab-raw,
  .fixed .contact-gaming-raw .contact-sub .horizontal-tab-raw.form-group,
  .contact-gaming-raw .contact-sub .fixed .horizontal-tab-raw.form-group,
  .fixed .horizontal-tab-raw.challenger-list-col,
  .fixed .challenger-list-col ul li.horizontal-tab-raw,
  .challenger-list-col ul .fixed li.horizontal-tab-raw,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.form-grop,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.form-grop,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.section-title,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.section-title {
    top: 136px;
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    h4 {
    font-size: 18px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-img {
    width: 110px;
    height: 125px;
    line-height: 80px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col {
    padding: 20px 15px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td
    h5 {
    font-size: 18px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td {
    padding: 8px 5px;
  }
  table tr td {
    letter-spacing: 0px;
    font-size: 18px;
  }
  /*-----------------profile team end-------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li .conn h4 {
    font-size: 26px !important;
    padding: 0 30px;
  }
  /*-----------------profile achievements end-------------------*/
  .product-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .product-li-raw .row .gm-li {
    padding-right: 15px;
    padding-left: 15px;
  }
  /*------------------cart-start--------------------------*/
  .cart-raw.all-games-raw {
    padding: 60px 0;
  }
  .cart-raw.all-games-raw .cart-wrap {
    padding: 0 20px;
  }
  .cart-raw.all-games-raw .cart-wrap .cart-action {
    padding-top: 40px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow {
    height: 30px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow.slick-prev {
    left: -25px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow.slick-next {
    right: -25px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box {
    padding: 20px 10px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box .img-box {
    line-height: 100px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .img-box
    img {
    width: 60%;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box .text-box {
    padding-top: 30px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .text-box
    .addcart-next-raw
    .btn-join {
    margin-top: 10px !important;
  }
  /*------------------cart-end--------------------------*/
  /*-------------------tournament-details-start-----------*/
  .time-date-col .timesate-li .box-raw {
    padding: 20px 10px;
  }
  /*-------------------tournament-details-end-----------*/
  /*------------------Rules-start----------------------*/
  .modal-raw.rules-modal .modal-lg .modal-content .modal-body {
    padding: 10px 5% 40px;
  }
  /*------------------Rules-end------------------*/
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw .timeline-raw .tree ul li a {
    min-width: 190px;
  }
  .tournament-brackets-raw .timeline-raw .tree ul li a i {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  .tournament-brackets-raw .timeline-raw .tree ul li a i.ic-user {
    left: 10px;
    top: 10px;
  }
  .tournament-brackets-raw .timeline-raw .tree ul li a i.ic-sts {
    right: 10px;
    top: 10px;
    line-height: 30px;
  }
  /*----tournaments-brackets-end----------------*/
}
@media only screen and (min-width: 992px) and (max-width: 1120px) {
  .container {
    max-width: 100%;
    padding: 0 30px;
  }
  h1 {
    font-size: 40px;
  }
  h4 {
    font-size: 22px;
  }
  .title-raw h1 span,
  .title-raw h2 span {
    font-size: 78px;
  }
  .gamedetail .title-raw h1 {
    font-size: 42px;
  }
  .title-raw h1,
  .title-raw h2,
  .title-raw h3 {
    font-size: 38px;
  }
  #games.title-raw {
    margin-top: 78px;
  }
  .league-wrapper {
    padding-top: 170px;
  }
  table tr th {
    font-size: 18px;
  }
  table tr td {
    font-size: 18px;
    padding: 15px 20px;
  }
  table tr td img {
    height: 32px;
  }
  table tr td.team {
    padding: 8px 20px;
  }
  table tr td.slash {
    padding: 10px 20px;
  }
  table tr.active td.blank {
    background-size: contain;
  }
  .tooltip {
    margin-top: 2px;
  }
  .table-wrapper {
    padding: 70px 0;
  }
  .faqs-wrapper .accordion .card {
    padding-top: 35px;
  }
  .faqs-wrapper .accordion .card .card-header button {
    font-size: 18px;
  }
  .footer-box-wrap {
    padding-top: 60px;
  }
  .footer-box-wrap:before {
    top: -6px;
  }
  .footer-wrapper ul li a {
    font-size: 16px;
  }
  .tooltipText {
    width: 280px;
    font-size: 16px;
  }
  .coins .tooltipText {
    right: 90px;
  }
  .coins .tooltipText::after {
    left: calc(50% + 84px);
  }
  .step-section-raw ul {
    padding: 30px 20px;
  }
  .step-section-raw ul::before {
    width: 100.9%;
    height: 106.5%;
  }
  .step-section-raw ul li {
    width: 33%;
  }
  .step-section-raw ul li .step-col > span {
    font-size: 60px;
    padding-right: 10px;
  }
  .step-section-raw ul li .step-col .img-fluid {
    max-width: 44px;
    max-height: 44px;
  }
  .step-section-raw ul li .step-col .step-conn {
    padding-left: 15px;
    width: 54%;
  }
  .step-section-raw ul li .step-col .step-conn p {
    font-size: 12px;
  }
  .trending-videos-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 105px !important;
  }
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .view-raw
    .btn-view {
    font-size: 12px;
  }
  /*----------------game--start---------------*/
  .all-games-raw {
    padding: 50px 0;
  }
  .all-games-raw.game-lists-raw
    .game-platform-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    text-align: center;
    font-size: 22px;
  }
  .game-lists-raw .gm-li-raw {
    margin-top: 20px;
    padding: 0px;
  }
  .game-lists-raw .gm-li-raw .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li {
    padding-right: 10px;
    padding-left: 10px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 130px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw {
    padding: 20px 15px 25px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn {
    width: 30%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3 {
    font-size: 22px;
    margin: 5px 0 0;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3
    span {
    font-size: 12px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn {
    width: 70%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    font-size: 16px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h6 {
    font-size: 10px;
    padding-left: 8px;
    background-size: 6px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li {
    /* &:first-child,
    &:nth-child(2) {
      width: 48%;
    }
    &:last-child {
      text-align: left;
      width: 100%;
      margin-top: 10px;
    } */
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join {
    padding: 6px 25px 6px 10px;
    background-position: right 6px center;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join:hover {
    background-position: right 4px center;
  }
  .game-lists-raw .competition-tab-raw .nav-tabs li .nav-link {
    font-size: 20px;
  }
  .btn-sm {
    padding: 12px 30px;
  }
  /*----------------game--end---------------*/
  /*------------------news--detail-start-----------*/
  .details-head .img-box {
    width: 150px;
    height: 150px;
  }
  .details-head h1 {
    margin-top: 30px;
  }
  .details-head .post-action {
    margin-top: 30px;
  }
  .news-details-raw {
    padding: 0px 0px 70px;
  }
  .news-details-raw.line-arrow {
    padding-top: 90px !important;
  }
  .details-body .description {
    padding: 0 50px;
  }
  /*------------------news--detail-end-----------*/
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw.latest-news-raw .latest-news-col {
    width: 85%;
  }
  .latest-news-raw .latest-news-col {
    padding: 0 5%;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box {
    padding: 20px;
    height: 100%;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    h4 {
    font-size: 20px;
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw .about-intro-raw {
    padding: 0px 5%;
  }
  /*---------------------about-us-end----------------------*/
  /*------------------Store Start-------------------------*/
  .store-raw .tab-content {
    padding: 0%;
  }
  .brought-box .text-box {
    padding-top: 30px;
  }
  .brought-box .text-box h5 {
    font-size: 22px;
  }
  .brought-box .text-box .price {
    font-size: 20px;
  }
  /*------------------Store-end-------------------------*/
  .ongoing-events-raw {
    padding-bottom: 120px;
  }
  .mt500 {
    margin-top: 220px !important;
  }
  /*-------------------game detail start------------------*/
  .inner-banner {
    height: 450px;
  }
  .inner-banner .container-fluid .inner-banner-con .game-rules-col li h4 {
    font-size: 40px;
  }
  .record-list-raw .record-list-col {
    padding: 0 5%;
  }
  .record-list-raw .record-list-col ul li {
    padding: 0 3%;
  }
  .record-list-raw .record-list-col ul li h3 {
    font-size: 52px;
  }
  .record-list-raw .record-list-col ul li h3 span {
    font-size: 14px;
  }
  .about-section-raw {
    padding: 70px 4%;
  }
  .about-section-raw p {
    padding: 0 10%;
  }
  .title-divider {
    font-size: 26px;
  }
  .title-divider::before,
  .title-divider::after {
    width: 30%;
  }
  h1,
  h2 {
    font-size: 40px;
  }
  .ptb10 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 100px;
  }
  .ig-tv-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    font-size: 18px;
  }
  /*--------------------game detail end-------------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw.game-lists-raw .gm-li-raw {
    padding: 0 50px;
  }
  .challenges-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .challenges-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 140px;
  }
  .challenges-banner-raw .inner-banner .mt250 {
    margin-top: 60px !important;
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw.game-lists-raw .gm-li-raw {
    padding: 0 40px;
  }
  .challenge-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .challenge-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li.vs-raw {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .challenge-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 190px;
  }
  /*-------------challenge-end---------------*/
  /*-------------trophy-start---------------*/
  .trophy-raw {
    padding: 0 30px;
  }
  /*-------------trophy-end----------------*/
  /*-------------contact-us----------------*/
  .contact-us-form form {
    padding: 0% 17% 0%;
  }
  .contact-gaming-raw .contact-sub {
    padding: 0% 17%;
  }
  /*-------------contact-us----------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw .wallet-ul .wallet-li {
    width: 33%;
  }
  .wallet-raw .wallet-ul .wallet-li::before {
    height: 110px;
    top: 22px;
  }
  .wallet-raw .wallet-ul .wallet-li .box-conn img {
    width: 150px;
  }
  /*-------------Wallet-end---------------*/
  .ongoing-events-raw {
    padding-bottom: 120px;
  }
  .mt500 {
    margin-top: 220px !important;
  }
  /*--------------leaderboard-start--------------*/
  .leaderboard-raw .leader-list-raw {
    padding: 0 2%;
  }
  .leaderboard-raw
    .leader-list-raw
    .filter-raw
    .row.justify-content-center
    .text-center {
    flex: 0 0 30%;
    max-width: 30%;
    margin-top: 20px;
  }
  /*---------------leaderboard-end--------------*/
  table tr th.choose-game {
    width: 300px;
  }
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section,
  .fixed table.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.scroll-wrap,
  .fixed .horizontal-tab-raw.main-wrapper,
  .fixed
    .league-wrapper
    .expert-master
    .progress-wrap
    .horizontal-tab-raw.redius-col,
  .league-wrapper
    .expert-master
    .progress-wrap
    .fixed
    .horizontal-tab-raw.redius-col,
  .fixed .horizontal-tab-raw.select-dropdown,
  .fixed .horizontal-tab-raw.select-game,
  .fixed .step-section-raw ul li .horizontal-tab-raw.step-col,
  .step-section-raw ul li .fixed .horizontal-tab-raw.step-col,
  .fixed .horizontal-tab-raw.title-raw,
  .fixed .game-lists-raw .gm-li-raw .row .gm-li .horizontal-tab-raw.gm-col,
  .game-lists-raw .gm-li-raw .row .gm-li .fixed .horizontal-tab-raw.gm-col,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.img-box,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.img-box,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.about-game-raw,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.about-game-raw,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.about-game-conn,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.about-game-conn,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.plateform-players-col,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.plateform-players-col,
  .fixed
    .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.view-raw,
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.view-raw,
  .fixed
    .header
    .notification
    .dropdown-menu-noti
    .notification-list
    li.horizontal-tab-raw,
  .header
    .notification
    .dropdown-menu-noti
    .notification-list
    .fixed
    li.horizontal-tab-raw,
  .fixed .header .dropdown-list li.horizontal-tab-raw,
  .header .dropdown-list .fixed li.horizontal-tab-raw,
  .fixed .header .dropdown-list li a.horizontal-tab-raw,
  .header .dropdown-list li .fixed a.horizontal-tab-raw,
  .fixed .record-list-raw .record-list-col ul li h3 span.horizontal-tab-raw,
  .record-list-raw .record-list-col ul li h3 .fixed span.horizontal-tab-raw,
  .fixed .store-raw .horizontal-tab-raw.tab-content,
  .store-raw .fixed .horizontal-tab-raw.tab-content,
  .fixed .terms-raw label.horizontal-tab-raw,
  .terms-raw .fixed label.horizontal-tab-raw,
  .fixed .challenge-raw .challenge-info-raw ul li > span.horizontal-tab-raw,
  .challenge-raw .challenge-info-raw ul .fixed li > span.horizontal-tab-raw,
  .fixed .most-popular-raw ul li a.horizontal-tab-raw,
  .most-popular-raw ul li .fixed a.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.balance-earned-raw,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .horizontal-tab-raw.team-li-conn,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .fixed
    .horizontal-tab-raw.team-li-conn,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .horizontal-tab-raw.team-details-col,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .fixed
    .horizontal-tab-raw.team-details-col,
  .fixed .trophy-raw .trophy-li .horizontal-tab-raw.trophy-col,
  .trophy-raw .trophy-li .fixed .horizontal-tab-raw.trophy-col,
  .fixed .tournament-details-col ul li strong.horizontal-tab-raw,
  .tournament-details-col ul li .fixed strong.horizontal-tab-raw,
  .fixed .contact-gaming-raw .contact-sub .horizontal-tab-raw.form-group,
  .contact-gaming-raw .contact-sub .fixed .horizontal-tab-raw.form-group,
  .fixed .horizontal-tab-raw.challenger-list-col,
  .fixed .challenger-list-col ul li.horizontal-tab-raw,
  .challenger-list-col ul .fixed li.horizontal-tab-raw,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.form-grop,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.form-grop,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.section-title,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.section-title {
    top: 130px;
  }
  .main-wrapper.profile-raw .horizontal-tab-raw .nav-tabs-horizontal > li a {
    padding: 18px 25px;
  }
  .main-wrapper.profile-raw
    .horizontal-tab-raw
    .nav-tabs-horizontal
    > li
    a.active::after {
    width: 65%;
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw .title-shadow h2 {
    font-size: 30px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    h4 {
    font-size: 18px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-img {
    width: 90px;
    height: 115px;
    line-height: 75px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col {
    padding: 20px 15px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td
    h5 {
    font-size: 18px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td {
    padding: 8px 5px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .btn-edit {
    left: 10px;
    top: 5px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .btn-close {
    right: 10px;
    top: 5px;
  }
  table tr td {
    letter-spacing: 0px;
  }
  /*-----------------profile team end-------------------*/
  /*-----------------profile trophie start-------------------*/
  .trophy-raw .trophy-li .trophy-col {
    padding: 30px 15px;
  }
  /*------------------profile trophie end--------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li .conn h4 {
    font-size: 26px;
    padding: 0 30px;
  }
  /*-----------------profile achievements end-------------------*/
  .line-arrow {
    padding-top: 80px;
  }
  .filter-raw .filter-li {
    flex: 0 0 25%;
    max-width: 25%;
    margin-top: 25px;
  }
  .store-tab li .nav-link {
    font-size: 20px;
  }
  .product-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .product-li-raw .row .gm-li {
    padding-right: 15px;
    padding-left: 15px;
  }
  .addcart-next-raw {
    margin-top: 15px;
  }
  .addcart-next-raw .btn-ic,
  .addcart-next-raw .add-qty-col,
  .addcart-next-raw .btn-join {
    margin-top: 10px !important;
  }
  .panel-heading .btn-group .btn-filter {
    padding: 8px 20px;
  }
  /*------------------cart-start--------------------------*/
  .cart-raw.all-games-raw {
    padding: 40px 0;
  }
  .cart-raw.all-games-raw .cart-wrap {
    padding: 0 20px;
  }
  .cart-raw.all-games-raw .cart-wrap .cart-action {
    padding-top: 40px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow {
    height: 30px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow.slick-prev {
    left: -25px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow.slick-next {
    right: -25px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box {
    padding: 20px 10px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box .img-box {
    line-height: 100px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .img-box
    img {
    width: 60%;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box .text-box {
    padding-top: 30px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .text-box
    .addcart-next-raw
    .btn-join {
    margin-top: 10px !important;
  }
  /*------------------cart-end--------------------------*/
  /*-------------------tournament-details-start-----------*/
  .tournament-details-col .inner-banner {
    height: 650px;
  }
  .time-date-col .timesate-li .box-raw {
    padding: 20px 10px;
  }
  /*-------------------tournament-details-end-----------*/
}
@media (max-width: 1120px) {
  .select-dropdown select,
  .select-dropdown input,
  .select-game select,
  .select-game input,
  .select-dropdown label,
  .custom-date,
  .custom-date input {
    font-size: 11px;
  }
  .nav-open {
    overflow: hidden;
  }
  .nav-open .header .header-right .nav-main ul li {
    opacity: 1;
  }
  .menu_open {
    overflow: hidden;
  }
  .header .dropdown-list {
    top: 58px;
    left: inherit;
    border-left: 0px;
    right: -25px;
    border-left: 0px;
    text-align: right;
  }
  .header .dropdown-list li a {
    border-left: 0px solid rgba(255, 255, 255, 0);
    border-right: 2px solid rgba(255, 255, 255, 0);
  }
  .header .dropdown-list li a:hover,
  .header .dropdown-list li a.active {
    border-left: 0px solid rgba(255, 255, 255, 0);
    border-right: 2px solid rgb(255, 255, 255);
  }
  .header .dropdown-list li a:hover::after,
  .header .dropdown-list li a.active::after {
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      to right,
      rgba(0, 216, 255, 0) 0%,
      rgba(0, 216, 255, 0.2) 100%
    );
    z-index: -1;
  }
  .header .admin.login-search-ic {
    margin-top: 29px;
    position: absolute;
    left: 65px;
    width: 50px;
  }
  .header .login-raw {
    margin-top: 5px;
  }
  .header .setting-head {
    position: absolute;
    margin: 20px 0px;
    left: 70px;
  }
  .header .notification {
    width: auto;
    margin-top: 8px;
  }
  .header .notification .dropdown-menu-noti {
    right: 40px;
    max-width: 440px;
    top: 105px;
  }
  .header .notification .dropdown-menu-noti .top-section {
    border-bottom-width: 1px;
  }
  .header .notification .dropdown-menu-noti .bottom-section {
    border-bottom-width: 1px;
  }
  .header .notification .dropdown-menu-noti .bottom-section a {
    font-size: 12px;
  }
  .header
    .notification
    .dropdown-menu-noti
    .notification-list
    .table-list-notifies
    td {
    padding: 10px 0px;
  }
  .header .notification ul li {
    margin-right: 20px;
  }
  .header .logo {
    width: 58px;
    margin: 0 auto;
    float: none;
    left: 0;
  }
  .header .header-right .nav-main {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    background-color: #000513;
    top: 0px;
    overflow: auto;
    padding: 130px 20px 90px 20px;
    display: none;
  }
  .header .header-right .nav-main ul {
    width: 100%;
    float: left;
    margin-top: 0px;
  }
  .header .header-right .nav-main ul li {
    width: 100%;
    padding: 12px 0px;
    text-align: left;
    margin: 0px;
    opacity: 0;
  }
  .header .header-right .nav-main ul li a {
    float: none;
    display: inline-block;
    height: auto;
    line-height: 110%;
    font-size: 16px;
    padding: 0;
    color: white;
    margin: 0 !important;
  }
  .header .header-right .nav-main ul li a::after,
  .header .header-right .nav-main ul li a::before {
    display: none;
  }
  .header .header-right .nav-main ul li a:hover span {
    color: white;
  }
  .header .header-right .nav-main ul li a.active span {
    color: white;
    text-shadow: 0 0 15px rgb(80, 174, 255);
    -webkit-text-shadow: 0 0 15px rgb(80, 174, 255);
  }
  .header .header-right .nav-main ul li .hover {
    display: none;
  }
  .header .header-right .nav-main li a::before {
    display: none;
  }
  .header .header-right::after {
    display: none;
  }
  .header .admin {
    right: 0px;
    top: 0px;
    width: auto;
    float: right;
  }
  .header:after {
    content: "";
    width: 80%;
    height: 2px;
    position: absolute;
    bottom: -1px;
    left: 50%;
    background: #070919;
    background: linear-gradient(
      to right,
      #070919 0%,
      #0998f6 25%,
      #0998f6 50%,
      #0998f6 75%,
      #070919 100%
    );
  }
  .affix .header .logo {
    padding: 0px;
  }
  .affix .header .notification {
    padding: 8px 0;
    margin-top: 0px;
  }
  .affix .header .admin {
    top: 0px;
  }
  .affix .header .dropdown-list {
    top: 58px;
  }
  .affix .header .setting-head {
    top: 8px;
  }
  .affix .header .login-raw {
    top: 0px;
  }
  .scroll-wrap {
    width: 100%;
    float: left;
    overflow: auto;
    height: calc(100vh - 200px);
    display: block;
  }
  .nav_open {
    overflow: hidden;
  }
  .toggle-menu {
    display: block;
    width: 30px;
    left: 20px;
  }
  .topbar {
    padding: 12px 20px;
    position: relative;
    z-index: 999;
  }
  .mobile {
    display: block !important;
  }
  .desktop {
    /* display: none !important; */
    width: 50px !important;
  }
  .main-wrapper {
    padding-top: 125px;
  }
  .gamedetail {
    padding-top: 115px !important;
  }
  .common-page-raw::before,
  .common-page-raw::after {
    display: none;
  }
  .pt10 {
    padding-top: 90px !important;
  }
  .pb10 {
    padding-bottom: 90px !important;
  }
  .personal-info-section .list-col .con-box h6 {
    font-size: 18px;
  }
  .personal-info-section .list-col .con-box h5 {
    font-size: 14px;
  }
  .gaming-plateform-raw .con-box h5 {
    font-size: 14px;
  }
  .achievements-raw .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .achievements-raw .row .achievements-li {
    padding-left: 15px;
    padding-right: 15px;
  }
  /*------------------Rules-start----------------------*/
  .modal-raw.rules-modal .modal-lg .modal-content .modal-body {
    padding: 10px 2.5% 30px;
  }
  /*------------------Rules-end------------------*/
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw .timeline-raw .tree {
    overflow: auto;
  }
  .tournament-brackets-raw .timeline-raw .tree ul li a {
    min-width: 190px;
  }
  .tournament-brackets-raw .timeline-raw .tree ul li a i {
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
  .tournament-brackets-raw .timeline-raw .tree ul li a i.ic-user {
    left: 10px;
    top: 10px;
  }
  .tournament-brackets-raw .timeline-raw .tree ul li a i.ic-sts {
    right: 10px;
    top: 10px;
    line-height: 30px;
  }
  /*----tournaments-brackets-end----------------*/
  /*----------challenge--pop-up-start----------*/
  .modal-raw.challenge-modal-box.modal-border-raw .modal-dialog {
    margin: 10px auto;
  }
  .modal-raw.challenge-modal-box.modal-border-raw
    .modal-dialog
    .modal-content
    .modal-body {
    padding: 30px;
  }
  /* .modal-raw.login-modal .modal-dialog {
    margin: 10px auto;
  } */
  .modal-raw.login-modal .modal-dialog .modal-body .social-list-raw {
    padding-top: 30px;
  }
  .modal-raw.register-modal .modal-dialog {
    margin: 10px auto;
  }
  .modal-raw.register-modal .modal-dialog .social-list-raw {
    padding-top: 30px;
  }
  .modal-raw.challenge-sent-modal .modal-dialog {
    margin: 10px auto;
  }
  .modal-raw.choose-your-team-modalbox .modal-dialog {
    max-width: 100%;
    margin: 10px auto;
  }
  .header .login-raw a.btn-login {
    padding-right: 0;
    font-size: 0px;
  }
  .UnderMaintainance {
    line-height: 1;
  }
}
@media only screen and (min-width: 1120px) {
  .scroll-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .gamedetail .inner-banner {
    height: calc(100vh - 115px - 93px) !important;
  }
  .gamedetail.line-arrow {
    top: 48px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 100%;
    padding: 0 25px;
  }
  .header .header-right .nav-main {
    padding-top: 120px;
  }
  .header .notification .dropdown-menu-noti {
    max-width: 400px;
  }
  h1 {
    font-size: 36px;
  }
  .affix .header .notification .dropdown-menu-noti {
    top: 105px;
  }
  .topbar .left-side h6 {
    font-size: 12px;
  }
  .topbar .left-side h6:first-child {
    padding-right: 10px;
    margin-right: 10px;
  }
  .topbar .left-side h6.uppercase {
    letter-spacing: 0.5px;
  }
  .topbar .left-side h6 strong {
    font-size: 12px;
    letter-spacing: 0.5px;
  }
  .topbar .right-side h6 {
    padding-left: 10px;
    letter-spacing: 0.5px;
    margin-left: 3px;
  }
  ul.social-list-raw li {
    margin: 0 8px;
  }
  ul.social-list-raw li a {
    font-size: 14px;
  }
  h4 {
    font-size: 20px;
  }
  .title-raw h1 span,
  .title-raw h2 span {
    font-size: 70px;
  }
  .title-raw h1,
  .title-raw h2,
  .title-raw h3 {
    font-size: 36px;
  }
  #games.title-raw {
    margin-top: 70px;
  }
  .league-wrapper {
    padding-top: 150px;
  }
  table tr th {
    padding: 12px 15px;
    font-size: 16px;
  }
  table tr td {
    font-size: 16px;
    padding: 13px 15px;
  }
  table tr td img {
    height: 30px;
  }
  table tr td i {
    width: 5px;
    height: 24px;
  }
  table tr td.team {
    padding: 8px 15px;
  }
  table tr td.slash {
    padding: 10px 15px;
  }
  table tr th.rank {
    width: 80px;
  }
  table tr th.team {
    width: 190px;
  }
  table tr th.points {
    width: 110px;
  }
  table tr th.coins {
    width: 160px;
  }
  table tr.active td.blank {
    background-size: contain;
  }
  .tooltip {
    margin-top: 1px;
  }
  .table-wrapper {
    padding: 40px 0 70px;
  }
  .faqs-wrapper .accordion .card {
    padding-top: 25px;
  }
  .faqs-wrapper .accordion .card .card-header button {
    font-size: 16px;
    padding: 11px 60px;
  }
  .faqs-wrapper .accordion .card .card-header button:before {
    width: 28px;
    height: 28px;
  }
  .footer-box-wrap {
    padding-top: 60px;
  }
  .footer-box-wrap:before {
    top: -5px;
  }
  .footer-wrapper ul li a {
    font-size: 16px;
  }
  .footer-wrap .right-wrap .social-list-raw li {
    margin-right: 15px;
  }
  .tooltipText {
    width: 260px;
    font-size: 16px;
  }
  .coins .tooltipText {
    right: 90px;
  }
  .coins .tooltipText::after {
    left: calc(50% + 84px);
  }
  .step-section-raw ul {
    padding: 30px 20px;
  }
  .step-section-raw ul::before {
    width: 100.8%;
    height: 108%;
  }
  .step-section-raw ul li {
    width: 33%;
    background-size: 14px;
  }
  .step-section-raw ul li .step-col > span {
    font-size: 44px;
    padding-right: 10px;
  }
  .step-section-raw ul li .step-col .img-fluid {
    max-width: 34px;
    max-height: 34px;
  }
  .step-section-raw ul li .step-col .step-conn {
    padding: 0 10px;
    width: 54%;
  }
  .step-section-raw ul li .step-col .step-conn .h6 {
    font-size: 14px;
  }
  .step-section-raw ul li .step-col .step-conn p {
    font-size: 10px;
  }
  .ongoing-competition-raw {
    padding: 90px 0;
  }
  .main-wrapper {
    padding-top: 125px;
  }
  .main-banner .carousel.slide .carousel-control-arrow span {
    width: 16px;
    height: 34px;
  }
  .mt50 {
    margin-top: 40px !important;
  }
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw.latest-news-raw .latest-news-col {
    width: 85%;
  }
  .latest-news-raw .latest-news-col {
    padding: 0 5%;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box {
    padding: 20px;
    height: 100%;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    h4 {
    font-size: 20px;
  }
  .mt500 {
    margin-top: 150px !important;
  }
  .ongoing-events-raw {
    padding-bottom: 100px;
  }
  /*----------------------news-end-----------------------------*/
  /*----------------game--start---------------*/
  .all-games-raw {
    padding: 40px 0;
  }
  .all-games-raw.game-lists-raw
    .game-platform-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    text-align: center;
    font-size: 20px;
  }
  .game-lists-raw .gm-li-raw {
    margin-top: 20px;
    padding: 0px;
  }
  .game-lists-raw .gm-li-raw .row {
    margin-right: -10px;
    margin-left: -10px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li {
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 30px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li.col-sm-3 {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 100px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw {
    padding: 20px 15px 25px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn {
    width: 25%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3 {
    font-size: 20px;
    margin: 5px 0px 0px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3
    span {
    font-size: 10px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn {
    width: 75%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    font-size: 16px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h6 {
    font-size: 10px;
    padding-left: 8px;
    background-size: 6px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col {
    padding-top: 10px;
    margin-top: 10px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li {
    /* &:first-child,
    &:nth-child(2) {
      width: 48%;
    }
    &:last-child {
      text-align: left;
      width: 100%;
      margin-top: 10px;
    } */
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join {
    padding: 6px 25px 6px 10px;
    background-position: right 6px center;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join:hover {
    background-position: right 4px center;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join.btn-cancel {
    padding: 5px 14px;
  }
  .game-lists-raw .competition-tab-raw .nav-tabs li .nav-link {
    font-size: 20px;
  }
  .horizontal-tab-raw .nav-tabs-dropdown {
    font-size: 22px;
    padding: 15px 0;
  }
  .divide-raw::after {
    top: 57px;
  }
  .btn-sm {
    padding: 12px 30px;
  }
  .trending-videos-raw {
    padding: 0px;
  }
  .trending-videos-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 95px !important;
  }
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .view-raw
    .btn-view {
    font-size: 12px;
  }
  /*----------------game--end---------------*/
  /*------------------news--detail-start-----------*/
  .details-head .img-box {
    width: 120px;
    height: 120px;
  }
  .details-head h1 {
    margin-top: 30px;
  }
  .details-head .post-action {
    margin-top: 30px;
  }
  .news-details-raw {
    padding: 0px 0px 40px;
  }
  .news-details-raw.line-arrow {
    padding-top: 60px !important;
  }
  .details-body .description {
    padding: 0 30px;
  }
  /*------------------news--detail-end-----------*/
  /*------------------Store Start-------------------------*/
  .store-raw .tab-content {
    padding: 0 5%;
  }
  .brought-box {
    padding: 30px 15px 20px;
  }
  .brought-box .text-box {
    padding-top: 20px;
  }
  .brought-box .text-box h5 {
    font-size: 18px;
    min-width: inherit;
    width: 100%;
    padding: 0px;
  }
  .brought-box .text-box .price {
    font-size: 20px;
  }
  /*------------------Store-end-------------------------*/
  /*-------------------game detail start------------------*/
  .inner-banner {
    height: 400px;
  }
  .inner-banner .container-fluid .inner-banner-con .game-rules-col li h4 {
    font-size: 40px;
  }
  .line-arrow {
    padding-top: 70px !important;
  }
  .line-arrow::before {
    height: 50px;
  }
  .record-list-raw .record-list-col {
    padding: 0;
  }
  .record-list-raw .record-list-col ul li {
    padding: 0 2%;
  }
  .record-list-raw .record-list-col ul li h3 {
    font-size: 46px;
  }
  .record-list-raw .record-list-col ul li h3 span {
    font-size: 12px;
  }
  .about-section-raw {
    padding: 60px 0;
  }
  .about-section-raw p {
    padding: 0;
  }
  .btn-ic.ic-challenge,
  .btn-ic.ic-more-game {
    min-width: 200px;
  }
  .title-divider {
    font-size: 26px;
    letter-spacing: 2px;
  }
  .title-divider::before,
  .title-divider::after {
    width: 28%;
  }
  h1,
  h2 {
    font-size: 40px;
  }
  .ptb10 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 75px;
  }
  .ig-tv-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5,
  .ig-tv-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h6 {
    font-size: 16px;
    padding: 0px;
  }
  .btn-link {
    font-size: 16px;
  }
  /*--------------------game detail end-------------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw.game-lists-raw .gm-li-raw {
    padding: 0 20px;
  }
  .challenges-raw.game-lists-raw .gm-li-raw .row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .challenges-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding-right: 20px;
    padding-left: 20px;
  }
  .challenges-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 120px;
  }
  .challenges-banner-raw .inner-banner .mt250 {
    margin-top: 60px !important;
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw.game-lists-raw .gm-li-raw {
    padding: 0 20px;
  }
  .challenge-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    flex: 0 0 40%;
    max-width: 40%;
  }
  .challenge-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li.vs-raw {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .challenge-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 145px;
  }
  .challenge-raw.game-lists-raw .challenge-info-raw ul li.price-money-raw h6 {
    font-size: 26px;
  }
  .challenge-raw .challenge-info-raw ul li h6 {
    font-size: 16px;
  }
  /*-------------challenge-end---------------*/
  /*-------------contact-us----------------*/
  .contact-us-form form {
    padding: 0% 8% 0%;
  }
  .contact-gaming-raw .contact-sub {
    padding: 0% 8%;
  }
  /*-------------contact-us----------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw .wallet-ul .wallet-li {
    width: 33%;
  }
  .wallet-raw .wallet-ul .wallet-li::before {
    height: 90px;
    top: 12px;
  }
  .wallet-raw .wallet-ul .wallet-li .box-conn img {
    width: 110px;
  }
  /*-------------Wallet-end---------------*/
  /*--------------leaderboard-start--------------*/
  .leaderboard-raw .leader-list-raw {
    padding: 0 0%;
  }
  .leaderboard-raw
    .leader-list-raw
    .filter-raw
    .row.justify-content-center
    .text-center {
    flex: 0 0 30%;
    max-width: 30%;
    margin-top: 10px;
  }
  /*---------------leaderboard-end--------------*/
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section,
  .fixed table.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.scroll-wrap,
  .fixed .horizontal-tab-raw.main-wrapper,
  .fixed
    .league-wrapper
    .expert-master
    .progress-wrap
    .horizontal-tab-raw.redius-col,
  .league-wrapper
    .expert-master
    .progress-wrap
    .fixed
    .horizontal-tab-raw.redius-col,
  .fixed .horizontal-tab-raw.select-dropdown,
  .fixed .horizontal-tab-raw.select-game,
  .fixed .step-section-raw ul li .horizontal-tab-raw.step-col,
  .step-section-raw ul li .fixed .horizontal-tab-raw.step-col,
  .fixed .horizontal-tab-raw.title-raw,
  .fixed .game-lists-raw .gm-li-raw .row .gm-li .horizontal-tab-raw.gm-col,
  .game-lists-raw .gm-li-raw .row .gm-li .fixed .horizontal-tab-raw.gm-col,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.img-box,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.img-box,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.about-game-raw,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.about-game-raw,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.about-game-conn,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.about-game-conn,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.plateform-players-col,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.plateform-players-col,
  .fixed
    .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.view-raw,
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.view-raw,
  .fixed
    .header
    .notification
    .dropdown-menu-noti
    .notification-list
    li.horizontal-tab-raw,
  .header
    .notification
    .dropdown-menu-noti
    .notification-list
    .fixed
    li.horizontal-tab-raw,
  .fixed .header .dropdown-list li.horizontal-tab-raw,
  .header .dropdown-list .fixed li.horizontal-tab-raw,
  .fixed .header .dropdown-list li a.horizontal-tab-raw,
  .header .dropdown-list li .fixed a.horizontal-tab-raw,
  .fixed .record-list-raw .record-list-col ul li h3 span.horizontal-tab-raw,
  .record-list-raw .record-list-col ul li h3 .fixed span.horizontal-tab-raw,
  .fixed .store-raw .horizontal-tab-raw.tab-content,
  .store-raw .fixed .horizontal-tab-raw.tab-content,
  .fixed .terms-raw label.horizontal-tab-raw,
  .terms-raw .fixed label.horizontal-tab-raw,
  .fixed .challenge-raw .challenge-info-raw ul li > span.horizontal-tab-raw,
  .challenge-raw .challenge-info-raw ul .fixed li > span.horizontal-tab-raw,
  .fixed .most-popular-raw ul li a.horizontal-tab-raw,
  .most-popular-raw ul li .fixed a.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.balance-earned-raw,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .horizontal-tab-raw.team-li-conn,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .fixed
    .horizontal-tab-raw.team-li-conn,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .horizontal-tab-raw.team-details-col,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .fixed
    .horizontal-tab-raw.team-details-col,
  .fixed .trophy-raw .trophy-li .horizontal-tab-raw.trophy-col,
  .trophy-raw .trophy-li .fixed .horizontal-tab-raw.trophy-col,
  .fixed .tournament-details-col ul li strong.horizontal-tab-raw,
  .tournament-details-col ul li .fixed strong.horizontal-tab-raw,
  .fixed .contact-gaming-raw .contact-sub .horizontal-tab-raw.form-group,
  .contact-gaming-raw .contact-sub .fixed .horizontal-tab-raw.form-group,
  .fixed .horizontal-tab-raw.challenger-list-col,
  .fixed .challenger-list-col ul li.horizontal-tab-raw,
  .challenger-list-col ul .fixed li.horizontal-tab-raw,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.form-grop,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.form-grop,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.section-title,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.section-title {
    top: 129px;
  }
  .main-wrapper.profile-raw .horizontal-tab-raw .nav-tabs-horizontal > li a {
    padding: 16px 20px;
    font-size: 14px;
  }
  .main-wrapper.profile-raw
    .horizontal-tab-raw
    .nav-tabs-horizontal
    > li
    a.active::after {
    width: 65%;
  }
  .pt10 {
    padding-top: 80px !important;
  }
  .pb10 {
    padding-bottom: 80px !important;
  }
  .personal-info-section .list-col .con-box {
    padding: 60px 0;
  }
  .personal-info-section .list-col .con-box::before {
    background-size: contain;
  }
  .personal-info-section .list-col .con-box h6 {
    font-size: 18px;
  }
  .personal-info-section .list-col .con-box h5 {
    font-size: 14px;
  }
  .gaming-plateform-raw .con-box {
    margin-top: 10px;
  }
  .gaming-plateform-raw .con-box h5 {
    font-size: 14px;
  }
  .follow-cointer-col .user-follower-col .counter-col {
    font-size: 42px;
  }
  .user-avtar-col {
    width: 200px;
  }
  .balance-earned-raw ul li .coin-label {
    font-size: 24px;
  }
  .balance-earned-raw ul li span {
    letter-spacing: 4px;
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw .title-shadow h2 {
    font-size: 28px;
  }
  .profile-details-raw .team-overview-raw .the-team-raw .team-li {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    h4 {
    font-size: 18px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-img {
    width: 90px;
    height: 115px;
    line-height: 75px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col {
    padding: 20px 15px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td
    h5 {
    font-size: 18px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td {
    padding: 8px 5px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .btn-edit {
    left: 10px;
    top: 5px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .btn-close {
    right: 10px;
    top: 5px;
  }
  table tr td {
    letter-spacing: 0px;
  }
  /*-----------------profile team end-------------------*/
  /*-----------------profile trophie start-------------------*/
  .trophy-raw .trophy-li {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .trophy-raw .trophy-li .trophy-col {
    padding: 30px 15px;
  }
  .trophy-raw .trophy-li .trophy-col .trophy-img {
    margin-top: -60px;
  }
  /*------------------profile trophie end--------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li {
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .achievements-raw .achievements-li .conn h4 {
    font-size: 26px;
    padding: 0 30px;
  }
  /*-----------------profile achievements end-------------------*/
  .line-arrow {
    padding-top: 80px;
  }
  .filter-raw .filter-li {
    flex: 0 0 25%;
    max-width: 25%;
    margin-top: 25px;
  }
  .panel-heading .btn-group .btn-filter {
    padding: 8px 12px;
    min-width: auto;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  .notification-raw
    .notification-table-col
    .date-action-col
    .select-col
    select.form-control {
    height: 35px;
    padding: 0 20px 0 15px;
    min-width: 120px;
    font-size: 14px;
  }
  /*--------upcoming-tournament-lobby-end----------*/
  .tournament-details-col
    .upcoming-tournament-raw
    .leaderboard-raw
    .tournament-lobby-raw
    .leader-list-col
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .win-img
    img {
    width: 130px;
  }
  .tournament-details-col
    .upcoming-tournament-raw
    .leaderboard-raw
    .tournament-lobby-raw
    .leader-list-col
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td {
    padding: 8px 5px !important;
    font-size: 12px;
  }
  .tournament-details-col
    .upcoming-tournament-raw
    .leaderboard-raw
    .tournament-lobby-raw
    .leader-list-col
    .team-overview-raw
    .the-team-raw
    .team-li.winner-li
    .team-li-conn
    .win-img
    img {
    width: 150px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td {
    padding: 8px 5px !important;
    font-size: 12px;
  }
  .winners-col .winner-li .win-img img {
    width: 170px;
  }
  .winners-col .win-img img {
    width: 140px;
  }
  /*--------upcoming-tournament-lobby-start-------*/
}
@media (max-width: 991px) {
  .footer-box-wrap:before {
    top: -4px;
  }
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw.latest-news-raw .latest-news-col {
    width: 100%;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box {
    padding: 15px;
    height: 100%;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box .img-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .img-box
    img {
    width: 100px;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box .news-box {
    width: 100%;
    padding-left: 0px;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    p {
    margin-top: 15px;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    p
    a {
    margin-left: 0px;
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw .about-intro-raw {
    padding: 0% 2%;
  }
  /*---------------------about-us-end----------------------*/
  /*------------------Store Start-------------------------*/
  .store-raw.game-lists-raw .gm-li-raw .row .gm-li {
    padding-left: 10px;
    padding-right: 10px;
  }
  .store-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    margin-top: 20px;
  }
  .store-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .left-conn
    h5 {
    font-size: 20px;
  }
  .store-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .left-conn
    .price {
    font-size: 18px;
  }
  .store-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .left-conn
    .btn-join {
    padding: 8px 40px 9px 15px;
  }
  .store-raw.game-lists-raw .product-li-raw {
    margin-top: 0px;
  }
  .brought-box .text-box {
    padding-top: 20px;
  }
  .brought-box .text-box h5 {
    font-size: 18px;
    min-width: inherit;
    width: 100%;
    padding: 0px;
  }
  .brought-box .text-box .price {
    font-size: 20px;
  }
  .store-tab li .nav-link {
    font-size: 18px;
    padding: 12px 25px;
  }
  /*------------------Store-end-------------------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw .disclaimer-raw .btn-main {
    padding: 0 20px;
  }
  .challenge-raw .disclaimer-raw .btn-main.bg-color {
    margin-top: 10px;
  }
  .challenge-raw .disclaimer-raw .btn-main {
    padding: 0 20px;
  }
  .challenge-raw .disclaimer-raw .btn-main.bg-color {
    margin-top: 10px;
  }
  /*-------------challenge-end---------------*/
  /*-------------trophy-start---------------*/
  .trophy-raw {
    padding: 0 10px;
  }
  .trophy-raw .trophy-li {
    margin-top: 70px;
  }
  .trophy-raw .trophy-li .trophy-col {
    padding: 20px;
  }
  /*-------------trophy-end----------------*/
  .store-tab li .nav-link {
    font-size: 20px;
  }
  .product-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .product-li-raw .row .gm-li {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 33.333%;
    max-width: 33.333%;
  }
  .addcart-next-raw {
    margin-top: 15px;
  }
  .addcart-next-raw .btn-ic,
  .addcart-next-raw .add-qty-col,
  .addcart-next-raw .btn-join {
    margin-top: 10px !important;
  }
  .contact-banner .inner-banner {
    height: auto;
    padding: 60px 0;
  }
  .contact-banner .inner-banner .container-fluid .inner-banner-con {
    position: unset;
    transform: unset;
  }
  .contact-banner .inner-banner {
    padding-bottom: 0px;
  }
  /*------------------cart-start--------------------------*/
  .cart-raw.all-games-raw {
    padding: 20px 0;
  }
  .cart-raw.all-games-raw .cart-wrap {
    padding: 0px;
  }
  .cart-raw.all-games-raw .cart-wrap .cart-action {
    padding-top: 20px;
  }
  .cart-raw.all-games-raw .cart-wrap .cart-action .btn-main {
    font-size: 16px;
    padding: 0px 10px;
    height: 40px;
    line-height: 40px;
  }
  .cart-raw.all-games-raw .cart-wrap table tbody tr td:first-child {
    font-size: 24px;
  }
  .cart-raw.all-games-raw .cart-wrap table tbody tr.total td {
    font-size: 20px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow {
    height: 20px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow.slick-prev {
    left: -10px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .slick-arrow.slick-next {
    right: -10px;
    z-index: -1;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box {
    padding: 20px 10px;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box .img-box {
    line-height: 100px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .img-box
    img {
    width: 60%;
  }
  .cart-raw.all-games-raw .brought-wrap .brought-slide .brought-box .text-box {
    padding-top: 30px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .text-box
    .price {
    font-size: 16px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .text-box
    .price
    span {
    margin-left: 0px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .text-box
    .addcart-next-raw {
    margin-top: 20px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .text-box
    .addcart-next-raw
    .btn-join {
    margin-top: 10px !important;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .text-box
    .addcart-next-raw
    .btn-ic {
    margin: 0px;
  }
  .cart-raw.all-games-raw
    .brought-wrap
    .brought-slide
    .brought-box
    .text-box
    .addcart-next-raw
    .btn-ic.add-to-cart {
    width: 110px;
  }
  /*------------------cart-end--------------------------*/
  /*--------------igtv-start--------------*/
  .igl-tv-raw .most-popular-raw {
    padding: 0%;
  }
  .igl-tv-raw .most-popular-raw ul li h5 {
    font-size: 20px;
  }
  .igl-tv-raw
    .tab-and-search-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw {
    padding: 20px 10px;
  }
  /*--------------igtv-end--------------*/
  /*-------------------tournament-details-start-----------*/
  .tournament-details-col .inner-banner {
    height: 650px;
  }
  .tournament-details-raw .table-wrapper .time-date-col.pdlr15 {
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
  .tournament-details-raw
    .table-wrapper
    .time-date-col.pdlr15
    .timesate-li
    .box-raw {
    padding: 20px 10px;
  }
  /*-------------------tournament-details-end-----------*/
  .leaderboard-raw .leader-list-raw.tournament-lobby-raw {
    padding: 0 0%;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td {
    padding: 8px 5px !important;
    font-size: 12px;
  }
  /*--------------progress------------------*/
  .progress.progress-circle-raw {
    width: 120px;
    height: 120px;
  }
  .progress.progress-circle-raw .progress-value > div {
    font-size: 22px;
  }
}
@media (max-width: 767px) {
  .container {
    max-width: 100%;
    padding: 0 15px;
  }
  .header {
    max-height: 70px;
    height: 70px;
  }
  .header .logo {
    width: 55px;
  }
  .header .admin.login-search-ic {
    margin-top: 14px;
    width: 50px;
  }
  .header .admin .icon {
    width: 32px;
    height: 32px;
  }
  .header .login-raw {
    min-width: inherit;
    padding: 7px 0px;
  }
  .header .login-raw a {
    font-size: 0px;
  }
  .header .login-raw a.btn-main {
    padding: 10px 25px;
    background: url(../images/ic-reg-user.svg) center no-repeat;
    background-color: #009bfb;
    background-size: 22px;
    padding: 0;
    width: 40px;
    height: 35px;
    border-radius: 4px;
  }
  .header .toggle-menu {
    left: 15px;
  }
  .header .logo.desktop {
    margin: 0 25px -68px;
    width: 100px;
  }
  .header .header-right {
    margin: 0 auto;
    padding: 15px;
  }
  .header .header-right .nav-main {
    padding: 120px 20px 70px 20px;
  }
  .header .header-right .nav-main ul {
    min-width: inherit;
    overflow: hidden;
  }
  .header .header-right .nav-main ul li {
    margin-right: 25px !important;
    padding: 10px 0px !important;
  }
  .header::after {
    height: 1px;
    left: 0;
  }
  .admin.mobile-view {
    display: block !important;
    position: relative;
    top: 9px;
  }
  .header .notification {
    float: left;
    width: 100%;
    margin: 0px;
    text-align: left;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #000513;
    z-index: 999;
    padding: 5px 0;
  }
  .header .notification ul {
    min-width: inherit !important;
  }
  .header .notification ul li {
    margin-right: 15px !important;
    width: auto !important;
  }
  .header .setting-head {
    display: none;
  }
  .header .dropdown-list {
    top: 51px;
    min-width: 170px;
  }
  .affix .header .dropdown-list {
    top: 45px;
  }
  .main-wrapper {
    padding-top: 120px;
  }
  h1 {
    font-size: 30px;
  }
  h4 {
    font-size: 20px;
  }
  .desktop-view {
    display: none;
  }
  .desktop-mobile {
    display: block;
  }
  .topbar {
    padding: 10px 15px;
  }
  .topbar .left-side {
    width: 100%;
  }
  .topbar .left-side h6 {
    line-height: 120%;
    font-size: 12px;
    width: 50%;
    float: left;
  }
  .topbar .left-side h6:first-child {
    float: left;
    padding-right: 0;
    margin-right: 0;
    border: 0;
  }
  .topbar .left-side h6.uppercase {
    float: right;
    text-align: right;
    font-size: 10px;
  }
  .topbar .left-side h6.uppercase span::after {
    display: none;
  }
  .topbar .left-side h6.uppercase strong {
    font-size: 12px;
    margin: 0;
    width: 100%;
    float: left;
    letter-spacing: 1px;
  }
  .topbar .left-side h6 span {
    width: 100%;
    font-size: 10px;
    margin: 0px;
  }
  .topbar .right-side {
    display: none;
  }
  .title-raw h1 span,
  .title-raw h2 span {
    font-size: 60px;
  }
  .title-raw h1,
  .title-raw h2,
  .title-raw h3 {
    font-size: 30px;
  }
  #games.title-raw {
    margin-top: -60px;
  }
  .league-wrapper {
    padding-top: 130px;
  }
  .league-wrapper .leacue-table-img img {
    display: none;
  }
  .league-wrapper .leacue-table-img h1 {
    position: initial;
    top: 0;
  }
  .league-wrapper .expert-master .progress-wrap h5 {
    font-size: 16px;
    margin-top: 17px;
  }
  .league-wrapper .expert-master .progress-wrap img {
    width: 330px;
  }
  .league-wrapper .expert-master p {
    margin-top: 10px;
    padding-top: 0px;
  }
  .select-game select {
    max-width: 350px;
  }
  .faqs-wrapper .accordion .card {
    padding-top: 25px;
  }
  .faqs-wrapper .accordion .card .table-box .card-body {
    overflow: auto;
  }
  .faqs-wrapper .accordion .card .card-header button {
    font-size: 16px;
    padding: 11px 60px;
  }
  .faqs-wrapper .accordion .card .card-header button:before {
    width: 28px;
    height: 28px;
    right: 15px;
  }
  table {
    width: 650px;
    max-width: 100%;
  }
  table tr th {
    padding: 12px 15px;
    font-size: 16px;
  }
  table tr td {
    font-size: 16px;
    padding: 13px 15px;
  }
  table tr td img {
    height: 30px;
  }
  table tr td i {
    width: 5px;
    height: 24px;
  }
  table tr td.team {
    padding: 8px 15px;
  }
  table tr td.slash {
    padding: 10px 15px;
  }
  table tr th.rank {
    width: 80px;
  }
  table tr th.team {
    width: 190px;
  }
  table tr th.points {
    width: 110px;
  }
  table tr th.coins {
    width: 160px;
  }
  table tr th.choose-game {
    width: 300px;
  }
  table tr.active td.blank {
    background-size: contain;
  }
  .tooltip {
    margin-top: 1px;
  }
  .table-wrapper {
    padding: 40px 0 60px;
  }
  .footer-wrapper {
    margin-top: 0px;
    background-color: #051830;
    background-image: none;
  }
  .footer-wrapper ul li a {
    font-size: 16px;
  }
  .footer-box-wrap {
    padding-top: 50px;
  }
  .footer-box-wrap:before {
    display: none;
  }
  .tooltipText {
    width: 210px;
    font-size: 14px;
    bottom: initial;
    top: 220%;
  }
  .tooltipText::after {
    bottom: initial;
    top: -18px;
  }
  .coins .tooltipText {
    right: 90px;
  }
  .coins .tooltipText::after {
    left: calc(50% + 84px);
  }
  .footer-wrap {
    display: initial;
  }
  .footer-wrap .right-wrap {
    width: 100%;
    padding-bottom: 30px;
  }
  .footer-wrap .right-wrap .col-md-4:nth-child(1) {
    margin-bottom: 30px;
  }
  .footer-wrap .right-wrap .col-md-4:nth-child(2) {
    margin-bottom: 30px;
  }
  .footer-wrap .left-wrap {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
  .footer-logo ul {
    padding-top: 0px;
  }
  .scroll-wrap {
    height: calc(100vh - 180px);
  }
  .trending-videos-raw {
    padding: 42px 0px 0px 0;
  }
  .btn-view {
    font-size: 12px;
  }
  .step-section-raw {
    text-align: center;
    margin-top: 40px;
  }
  .step-section-raw ul {
    padding: 20px;
    width: 300px;
  }
  .step-section-raw ul::before {
    width: 102.8%;
    height: 102.8%;
  }
  .step-section-raw ul li {
    width: 100%;
    background-size: 14px;
    position: relative;
    background-image: none;
    padding-bottom: 50px;
  }
  .step-section-raw ul li::before {
    background: transparent url(../images/arrow-next.png) center bottom
      no-repeat;
    position: absolute;
    content: "";
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 14px;
    height: 28px;
    transform: rotate(90deg);
    bottom: 10px;
    background-size: contain;
  }
  .step-section-raw ul li:last-child {
    padding-bottom: 0px;
  }
  .step-section-raw ul li:last-child::before {
    display: none;
  }
  .step-section-raw ul li .step-col > span {
    font-size: 40px;
    padding-right: 15px;
  }
  .step-section-raw ul li .step-col .img-fluid {
    max-width: 34px;
    max-height: 34px;
  }
  .step-section-raw ul li .step-col .step-conn {
    padding: 0 0 0 15px;
    width: 68%;
  }
  .step-section-raw ul li .step-col .step-conn .h6 {
    font-size: 14px;
    display: block;
  }
  .step-section-raw ul li .step-col .step-conn p {
    font-size: 10px;
    margin: 0px;
  }
  .main-banner {
    margin-top: 121px;
  }
  .main-banner .carousel.slide .carousel-control-arrow span {
    width: 16px;
    height: 32px;
  }
  /*--------------progress------------------*/
  .progress.progress-circle-raw h5.bottom-shadow {
    margin-bottom: 40px !important;
  }
  /*----------------game--start---------------*/
  .all-games-raw {
    padding: 50px 0;
  }
  .all-games-raw.game-lists-raw
    .game-platform-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    text-align: center;
    font-size: 18px;
  }
  .game-lists-raw .gm-li-raw {
    margin-top: 0px;
    padding: 0px;
  }
  .game-lists-raw .gm-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li {
    padding-right: 15px;
    padding-left: 15px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: auto;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw {
    padding: 25px 15px 20px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw::after {
    top: -28px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn {
    width: 100%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3 {
    font-size: 24px;
    text-align: left;
    vertical-align: middle;
    display: inline-block;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3
    br {
    display: none;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3
    span {
    font-size: 14px;
    margin-left: 5px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn {
    width: 100%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    font-size: 18px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h6 {
    font-size: 10px;
    padding-left: 10px;
    background-size: 6px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li {
    /* &:last-child {
      width: 100%;
      text-align: left;
      margin-top: 10px;
    }
    &:first-child,
    &:nth-child(2) {
      width: 100%;
    } */
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join {
    padding: 8px 30px 8px 15px;
    background-position: right 10px center;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join:hover {
    background-position: right 8px center;
  }
  .game-lists-raw .competition-tab-raw {
    margin-top: 50px;
  }
  .game-lists-raw .competition-tab-raw .nav-tabs {
    flex-wrap: unset;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
  }
  .game-lists-raw .competition-tab-raw .nav-tabs li .nav-link {
    white-space: nowrap;
    letter-spacing: 1px;
    font-size: 18px;
  }
  .horizontal-tab-raw {
    padding: 0 5%;
  }
  .horizontal-tab-raw .nav-tabs-dropdown {
    font-size: 22px;
    padding: 15px 0;
  }
  .divide-raw::after {
    top: 58px;
  }
  .ongoing-competition-raw {
    padding: 56px 0 0 0;
  }
  .ongoing-events-raw {
    padding-bottom: 60px;
  }
  .btn-sm {
    font-size: 14px;
    padding: 12px 35px;
  }
  .mt500 {
    margin-top: 150px !important;
  }
  /*----------------game--end---------------*/
  /*------------------news--detail-start-----------*/
  .details-head .img-box {
    width: 120px;
    height: 120px;
  }
  .details-head h1 {
    margin: 35px 0 20px;
  }
  .details-head .post-action {
    margin-top: 30px;
  }
  .details-head .btn-ac.like {
    width: 50%;
    margin-top: 20px;
  }
  .details-head .btn-ac.comment {
    width: 50%;
    margin-top: 20px;
  }
  .details-head .btn-ac.share {
    width: 50%;
    margin-top: 20px;
  }
  .news-details-raw {
    padding: 0px 0px 40px;
  }
  .news-details-raw.line-arrow {
    padding-top: 60px !important;
  }
  .details-body .description {
    padding: 0 0px;
  }
  /*------------------news--detail-end-----------*/
  /*----------------------news-start----------------------------*/
  .latest-news-box-raw.latest-news-raw .latest-news-col {
    width: 100%;
  }
  .latest-news-raw .latest-news-col {
    margin-top: 0px;
    padding: 0px;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box {
    padding: 15px 15px 15px 15px;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box .news-box {
    padding-left: 15px;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    p {
    margin-top: 15px;
  }
  /*----------------------news-end-----------------------------*/
  /*---------------------about-us-start----------------------*/
  .about-main-raw .about-intro-raw {
    padding: 0% 0%;
  }
  /*---------------------about-us-end----------------------*/
  .store-raw .tab-content {
    padding: 0 0%;
  }
  /*-------------------game detail start------------------*/
  .inner-banner {
    height: 350px;
  }
  .inner-banner .container-fluid .inner-banner-con .game-rules-col li h4 {
    font-size: 34px;
    line-height: 100%;
  }
  .inner-banner .container-fluid .inner-banner-con .game-rules-col li h5 {
    font-size: 16px;
  }
  .inner-banner .container-fluid .inner-banner-con .title-raw h6 {
    font-size: 16px;
  }
  .line-arrow {
    padding-top: 70px !important;
    border-width: 1px;
  }
  .line-arrow::before {
    height: 40px;
    width: 1px;
  }
  .record-list-raw .record-list-col {
    padding: 0;
  }
  .record-list-raw .record-list-col ul li {
    padding: 0 1%;
  }
  .record-list-raw .record-list-col ul li h3 {
    font-size: 36px;
  }
  .record-list-raw .record-list-col ul li h3 span {
    font-size: 10px;
    letter-spacing: 1px;
  }
  .about-section-raw {
    padding: 60px 0;
  }
  .about-section-raw p {
    padding: 0;
  }
  .btn-ic {
    margin: 0px 10px;
  }
  .btn-ic.ic-challenge,
  .btn-ic.ic-more-game {
    min-width: 180px;
  }
  .title-divider {
    font-size: 22px;
    letter-spacing: 1px;
  }
  h1,
  h2 {
    font-size: 36px;
  }
  .ptb10 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .mt50 {
    margin-top: 40px !important;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 75px;
  }
  .ig-tv-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5,
  .ig-tv-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h6 {
    font-size: 16px;
    padding: 0px;
  }
  .btn-link {
    font-size: 16px;
  }
  .ig-tv-raw .gm-li-raw .row .gm-li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ig-tv-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 125px;
  }
  /*--------------------game detail end-------------------*/
  /*-------------challenges-start---------------*/
  .challenges-raw.game-lists-raw .gm-li-raw {
    padding: 0px;
  }
  .challenges-raw.game-lists-raw .gm-li-raw .row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .challenges-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    padding-right: 20px;
    padding-left: 20px;
  }
  .challenges-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 125px;
  }
  .challenges-banner-raw .inner-banner .mt250 {
    margin-top: 60px !important;
  }
  .challenges-banner-raw .inner-banner .container-fluid .inner-banner-con {
    top: 60%;
  }
  /*-------------challenges-end---------------*/
  /*-------------challenge-start---------------*/
  .challenge-raw.game-lists-raw .challenge-info-raw {
    padding-top: 30px;
  }
  .challenge-raw.game-lists-raw .challenge-info-raw ul {
    display: flex;
    padding-top: 20px;
  }
  .challenge-raw.game-lists-raw .challenge-info-raw ul li {
    width: 33.33%;
    margin: 0 4px;
    padding-top: 20px;
    background: transparent !important;
  }
  .challenge-raw.game-lists-raw .challenge-info-raw ul li > span {
    font-size: 16px;
  }
  .challenge-raw.game-lists-raw .challenge-info-raw ul li h6 {
    font-size: 16px;
  }
  .challenge-raw.game-lists-raw .challenge-info-raw ul li.price-money-raw {
    background: transparent !important;
  }
  .challenge-raw.game-lists-raw .challenge-info-raw ul li.price-money-raw h6 {
    font-size: 22px;
  }
  /*-------------challenge-end---------------*/
  /*-------------contact-us----------------*/
  .contact-us-form form {
    padding: 0%;
  }
  .contact-us-form form .form-group .btn-send {
    padding: 15px 20px;
  }
  .contact-gaming-raw .contact-sub {
    padding: 0% 0%;
  }
  .contact-gaming-raw .contact-sub .btn-send {
    padding: 15px 20px;
  }
  .contact-gaming-raw .contact-sub h4 {
    font-size: 18px;
    line-height: initial;
  }
  /*-------------contact-us----------------*/
  /*-------------Wallet-start---------------*/
  .wallet-raw .wallet-ul .wallet-li {
    width: 48%;
    padding-bottom: 50px;
  }
  .wallet-raw .wallet-ul .wallet-li::before {
    display: none;
  }
  .wallet-raw .wallet-ul .wallet-li .box-conn img {
    width: 110px;
  }
  .wallet-raw .wallet-ul .wallet-li .box-conn h5 {
    font-size: 18px;
    letter-spacing: 1.5px;
    margin: 10px 0 10px;
    padding-bottom: 10px;
  }
  /*-------------Wallet-end---------------*/
  /*--------------leaderboard-start--------------*/
  .leaderboard-raw .leader-list-raw {
    padding: 0;
  }
  /*---------------leaderboard-end--------------*/
  /*----------------profile start-------------------*/
  .fixed .horizontal-tab-raw.section,
  .fixed table.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.scroll-wrap,
  .fixed .horizontal-tab-raw.main-wrapper,
  .fixed
    .league-wrapper
    .expert-master
    .progress-wrap
    .horizontal-tab-raw.redius-col,
  .league-wrapper
    .expert-master
    .progress-wrap
    .fixed
    .horizontal-tab-raw.redius-col,
  .fixed .horizontal-tab-raw.select-dropdown,
  .fixed .horizontal-tab-raw.select-game,
  .fixed .step-section-raw ul li .horizontal-tab-raw.step-col,
  .step-section-raw ul li .fixed .horizontal-tab-raw.step-col,
  .fixed .horizontal-tab-raw.title-raw,
  .fixed .game-lists-raw .gm-li-raw .row .gm-li .horizontal-tab-raw.gm-col,
  .game-lists-raw .gm-li-raw .row .gm-li .fixed .horizontal-tab-raw.gm-col,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.img-box,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.img-box,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .horizontal-tab-raw.about-game-raw,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .fixed
    .horizontal-tab-raw.about-game-raw,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.about-game-conn,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.about-game-conn,
  .fixed
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.plateform-players-col,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.plateform-players-col,
  .fixed
    .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .horizontal-tab-raw.view-raw,
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .fixed
    .horizontal-tab-raw.view-raw,
  .fixed
    .header
    .notification
    .dropdown-menu-noti
    .notification-list
    li.horizontal-tab-raw,
  .header
    .notification
    .dropdown-menu-noti
    .notification-list
    .fixed
    li.horizontal-tab-raw,
  .fixed .header .dropdown-list li.horizontal-tab-raw,
  .header .dropdown-list .fixed li.horizontal-tab-raw,
  .fixed .header .dropdown-list li a.horizontal-tab-raw,
  .header .dropdown-list li .fixed a.horizontal-tab-raw,
  .fixed .record-list-raw .record-list-col ul li h3 span.horizontal-tab-raw,
  .record-list-raw .record-list-col ul li h3 .fixed span.horizontal-tab-raw,
  .fixed .store-raw .horizontal-tab-raw.tab-content,
  .store-raw .fixed .horizontal-tab-raw.tab-content,
  .fixed .terms-raw label.horizontal-tab-raw,
  .terms-raw .fixed label.horizontal-tab-raw,
  .fixed .challenge-raw .challenge-info-raw ul li > span.horizontal-tab-raw,
  .challenge-raw .challenge-info-raw ul .fixed li > span.horizontal-tab-raw,
  .fixed .most-popular-raw ul li a.horizontal-tab-raw,
  .most-popular-raw ul li .fixed a.horizontal-tab-raw,
  .fixed .horizontal-tab-raw.balance-earned-raw,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .horizontal-tab-raw.team-li-conn,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .fixed
    .horizontal-tab-raw.team-li-conn,
  .fixed
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .horizontal-tab-raw.team-details-col,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .fixed
    .horizontal-tab-raw.team-details-col,
  .fixed .trophy-raw .trophy-li .horizontal-tab-raw.trophy-col,
  .trophy-raw .trophy-li .fixed .horizontal-tab-raw.trophy-col,
  .fixed .tournament-details-col ul li strong.horizontal-tab-raw,
  .tournament-details-col ul li .fixed strong.horizontal-tab-raw,
  .fixed .contact-gaming-raw .contact-sub .horizontal-tab-raw.form-group,
  .contact-gaming-raw .contact-sub .fixed .horizontal-tab-raw.form-group,
  .fixed .horizontal-tab-raw.challenger-list-col,
  .fixed .challenger-list-col ul li.horizontal-tab-raw,
  .challenger-list-col ul .fixed li.horizontal-tab-raw,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.form-grop,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.form-grop,
  .fixed .create-team-modal .modal-form .horizontal-tab-raw.section-title,
  .create-team-modal .modal-form .fixed .horizontal-tab-raw.section-title {
    top: 120px;
  }
  .main-wrapper.profile-raw h1 {
    font-size: 34px;
  }
  .main-wrapper.profile-raw .horizontal-tab-raw .nav-tabs-horizontal {
    flex-wrap: unset;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex !important;
    padding: 0 15px;
  }
  .main-wrapper.profile-raw .horizontal-tab-raw .nav-tabs-horizontal > li a {
    padding: 16px 20px;
    font-size: 14px;
  }
  .main-wrapper.profile-raw
    .horizontal-tab-raw
    .nav-tabs-horizontal
    > li
    a::before {
    width: 1px;
  }
  .main-wrapper.profile-raw
    .horizontal-tab-raw
    .nav-tabs-horizontal
    > li
    a.active::after {
    width: 65%;
  }
  .pt10 {
    padding-top: 70px !important;
  }
  .pb10 {
    padding-bottom: 70px !important;
  }
  .personal-info-section .list-col .con-box {
    padding: 50px 0;
  }
  .personal-info-section .list-col .con-box::before {
    display: none;
  }
  .personal-info-section .list-col .con-box h6 {
    font-size: 18px;
    word-break: break-all;
  }
  .personal-info-section .list-col .con-box h5 {
    font-size: 14px;
    word-break: break-all;
  }
  .personal-info-section .list-col:nth-child(3n) .con-box::before,
  .personal-info-section .list-col:nth-child(3n) .con-box::after {
    display: none;
  }
  .personal-info-section .list-col:first-child {
    border-right: 1px solid rgba(0, 179, 255, 0.3607843137);
    border-bottom: 1px solid rgba(0, 179, 255, 0.3607843137);
  }
  .personal-info-section .list-col:nth-child(2) {
    border-bottom: 1px solid rgba(0, 179, 255, 0.3607843137);
  }
  .personal-info-section .list-col:nth-child(3) {
    border-right: 1px solid rgba(0, 179, 255, 0.3607843137);
  }
  .follow-cointer-col .user-follower-col span {
    font-size: 16px;
    letter-spacing: 3px;
  }
  .follow-cointer-col .user-follower-col .counter-col {
    font-size: 30px;
    height: 100px;
    width: 90px;
    line-height: 90px;
    background-size: contain;
  }
  .user-avtar-col {
    width: 160px;
  }
  .balance-earned-raw ul li {
    margin: 0 10px;
    width: 45%;
  }
  .balance-earned-raw ul li .coin-label {
    font-size: 22px;
    padding: 15px 25px;
  }
  .balance-earned-raw ul li span {
    letter-spacing: 4px;
  }
  .gaming-plateform-raw {
    margin-top: 10px;
  }
  .gaming-plateform-raw h5 {
    font-size: 14px;
  }
  .gaming-plateform-raw .platform-list-row .platform-row .list-col {
    background-image: none !important;
  }
  .gaming-plateform-raw .platform-list-row .platform-row .list-col::after,
  .gaming-plateform-raw .platform-list-row .platform-row .list-col::before {
    display: none !important;
  }
  .gaming-plateform-raw .platform-list-row .platform-row .list-col:first-child {
    border-right: 1px solid rgba(0, 179, 255, 0.3607843137);
    border-bottom: 1px solid rgba(0, 179, 255, 0.3607843137);
  }
  .gaming-plateform-raw .platform-list-row .platform-row .list-col:nth-child(2),
  .gaming-plateform-raw
    .platform-list-row
    .platform-row
    .list-col:nth-child(4) {
    border-bottom: 1px solid rgba(0, 179, 255, 0.3607843137);
  }
  .gaming-plateform-raw
    .platform-list-row
    .platform-row
    .list-col:nth-child(3) {
    border-right: 1px solid rgba(0, 179, 255, 0.3607843137);
    border-bottom: 1px solid rgba(0, 179, 255, 0.3607843137);
  }
  .gaming-plateform-raw
    .platform-list-row
    .platform-row:nth-child(2)
    .list-col:last-child,
  .gaming-plateform-raw
    .platform-list-row
    .platform-row:nth-child(2)
    .list-col:nth-child(3) {
    border-bottom: none;
  }
  .title-shadow h2,
  .title-shadow h4 {
    font-size: 20px;
  }
  /*----------------profile end-------------------*/
  /*----------------profile team start-------------------*/
  .profile-details-raw .title-shadow h2 {
    font-size: 22px;
  }
  .profile-details-raw .title-shadow h2::after {
    bottom: -85%;
  }
  .profile-details-raw .team-overview-raw .the-team-raw .team-li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    h4 {
    font-size: 18px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-img {
    width: 90px;
    height: 115px;
    line-height: 75px;
    margin: -55px auto 0;
    padding: 10px 5px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col {
    padding: 20px 15px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table {
    width: 100%;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td {
    padding: 8px 5px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .team-details-col
    table
    tr
    td
    h5 {
    font-size: 18px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .btn-edit {
    left: 10px;
    top: 5px;
  }
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .btn-close {
    right: 10px;
    top: 5px;
  }
  table tr td {
    letter-spacing: 0px;
  }
  .btn-main {
    font-size: 14px;
  }
  /*-----------------profile team end-------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .achievements-raw .achievements-li .conn h4 {
    font-size: 22px !important;
    padding: 0 30px;
  }
  /*-----------------profile achievements end-------------------*/
  .filter-raw .filter-li {
    flex: 0 0 50%;
    max-width: 50%;
    margin-top: 25px;
  }
  .filter-raw .filter-li .select-game select {
    font-size: 14px;
  }
  .store-tab li .nav-link {
    font-size: 18px;
    min-width: 170px;
  }
  .product-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .product-li-raw .row .gm-li {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .addcart-next-raw {
    margin-top: 15px;
  }
  .addcart-next-raw .btn-ic,
  .addcart-next-raw .add-qty-col,
  .addcart-next-raw .btn-join {
    margin-top: 10px !important;
  }
  .news-detail-raw .inner-banner,
  .contact-banner .inner-banner,
  .tournament-details-col .inner-banner {
    height: auto;
    padding: 60px 0;
  }
  .news-detail-raw .inner-banner .container-fluid .inner-banner-con,
  .contact-banner .inner-banner .container-fluid .inner-banner-con,
  .tournament-details-col .inner-banner .container-fluid .inner-banner-con {
    position: unset;
    transform: unset;
  }
  .contact-banner .inner-banner {
    padding-bottom: 0px;
  }
  .details-head .post-by span {
    font-size: 18px;
  }
  .news-detail-raw .news-comment {
    padding: 0;
  }
  .news-detail-raw .details-head .post-action {
    margin-top: 0px;
  }
  .news-detail-raw .details-head .post-action .btn-ac {
    width: auto;
    min-width: auto;
    padding: 0px 25px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .contact-gaming-raw .contact-sub .form-group textarea.form-control {
    font-size: 14px;
  }
  .about-main-raw .banner-con .img-box a img {
    width: 210px;
    height: auto;
  }
  .about-main-raw .banner-con .img-box a::after {
    width: 60px;
    height: 60px;
  }
  .panel-heading .btn-group .btn-filter {
    padding: 8px 12px;
    min-width: auto;
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  .notification-raw
    .notification-table-col
    .date-action-col
    .select-col
    select.form-control {
    height: 35px;
    padding: 0 20px 0 15px;
    min-width: 120px;
    font-size: 14px;
  }
  .panel-heading {
    display: flex;
    flex-direction: column-reverse;
  }
  .panel-heading .btn-group {
    display: flex;
    overflow: auto;
  }
  .panel-heading .btn-group .btn-filter {
    white-space: nowrap;
  }
  .panel-heading .date-action-col {
    margin-bottom: 20px;
  }
  .panel-heading > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .table-list-notifies .con-col .conn .img-fluid {
    width: 46px;
    height: 46px;
  }
  .table-list-notifies .con-col .conn h6 {
    font-size: 18px;
  }
  input[type="checkbox"].checkbox-circle,
  input[type="checkbox"].checkbox-circle:not(.switch) {
    height: 18px;
    width: 18px;
  }
  input[type="checkbox"].checkbox-circle:not(.switch)::after {
    width: 4px;
    height: 9px;
    left: 7px;
    top: 3px;
  }
  /*--------------igtv-start--------------*/
  .igl-tv-raw .most-popular-raw ul li {
    width: 49%;
  }
  .igl-tv-raw .tab-and-search-raw .igtv-raw .nav-tabs {
    width: 100%;
    padding-left: 0px;
  }
  .igl-tv-raw .tab-and-search-raw .igtv-raw .nav-tabs .nav-item {
    width: 50%;
  }
  .igl-tv-raw .tab-and-search-raw .igtv-raw .nav-tabs .nav-item .nav-link {
    padding: 5px 10px;
    text-align: center;
  }
  .igl-tv-raw .tab-and-search-raw .igtv-raw .search-game-raw {
    width: 100%;
    margin: 20px 0px;
    padding-right: 20px;
  }
  /*--------------igtv-end--------------*/
  /*-------------------tournament-details-start-----------*/
  .tournament-details-col .inner-banner .container-fluid .inner-banner-con {
    /* top: 50%; */
  }
  .tournament-details-col .inner-banner ul li {
    padding: 10px 5px;
  }
  .tournament-details-col .inner-banner ul li:first-child {
    padding-left: 0;
  }
  .tournament-details-col .inner-banner ul li:last-child {
    padding-right: 0;
  }
  .tournament-details-col .inner-banner .text-center .btn-ac {
    width: auto;
    min-width: inherit;
    padding-left: 15px;
    padding-right: 15px;
  }
  .tournament-details-col .inner-banner .text-center .btn-ac span {
    font-size: 14px;
  }
  .tournament-details-col .inner-banner .text-center .btn-ac.join-btn {
    min-width: 150px !important;
    padding-left: 40px !important;
  }
  .tournament-details-raw .table-wrapper .time-date-col.pdlr15 {
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
  .tournament-details-raw .table-wrapper .time-date-col.pdlr15 .timesate-li {
    margin-top: 20px;
  }
  .tournament-details-raw
    .table-wrapper
    .time-date-col.pdlr15
    .timesate-li
    .box-raw {
    padding: 20px 10px;
  }
  .tournament-details-raw
    .table-wrapper
    .time-date-col.pdlr15
    .timesate-li::before {
    display: none;
  }
  .tournament-details-raw
    .table-wrapper
    .leader-list-raw
    .leader-list-col.pdlr10 {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .tournament-details-raw
    .table-wrapper
    .leader-list-raw
    .leader-list-col
    table.second-table
    tr
    td {
    font-size: 16px;
  }
  .upcoming-tournament-raw .leader-list-raw .leader-list-col.pdlr10 {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .tournaments-raw .table-wrapper .time-date-col.pdlr15 {
    padding-right: 0% !important;
    padding-left: 0% !important;
  }
  .tournaments-raw .table-wrapper .time-date-col.pdlr15 .timesate-li {
    margin-top: 20px;
  }
  .tournaments-raw .table-wrapper .time-date-col.pdlr15 .timesate-li .box-raw {
    padding: 20px 10px;
    min-height: auto;
  }
  .tournaments-raw .table-wrapper .time-date-col.pdlr15 .timesate-li::before {
    display: none;
  }
  .tournaments-raw .tournaments-tab-raw.section.mobiles,
  .tournaments-raw table.tournaments-tab-raw.mobiles,
  .tournaments-raw .tournaments-tab-raw.mobiles.scroll-wrap,
  .tournaments-raw .tournaments-tab-raw.mobiles.main-wrapper,
  .tournaments-raw
    .league-wrapper
    .expert-master
    .progress-wrap
    .tournaments-tab-raw.mobiles.redius-col,
  .league-wrapper
    .expert-master
    .progress-wrap
    .tournaments-raw
    .tournaments-tab-raw.mobiles.redius-col,
  .tournaments-raw .tournaments-tab-raw.mobiles.select-dropdown,
  .tournaments-raw .tournaments-tab-raw.mobiles.select-game,
  .tournaments-raw
    .step-section-raw
    ul
    li
    .tournaments-tab-raw.mobiles.step-col,
  .step-section-raw
    ul
    li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.step-col,
  .tournaments-raw .tournaments-tab-raw.mobiles.title-raw,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .tournaments-tab-raw.mobiles.gm-col,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.gm-col,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-tab-raw.mobiles.img-box,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-raw
    .tournaments-tab-raw.mobiles.img-box,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-tab-raw.mobiles.about-game-raw,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-raw
    .tournaments-tab-raw.mobiles.about-game-raw,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-tab-raw.mobiles.about-game-conn,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-raw
    .tournaments-tab-raw.mobiles.about-game-conn,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-tab-raw.mobiles.plateform-players-col,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-raw
    .tournaments-tab-raw.mobiles.plateform-players-col,
  .tournaments-raw
    .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-tab-raw.mobiles.view-raw,
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-raw
    .tournaments-tab-raw.mobiles.view-raw,
  .tournaments-raw
    .header
    .notification
    .dropdown-menu-noti
    .notification-list
    li.tournaments-tab-raw.mobiles,
  .header
    .notification
    .dropdown-menu-noti
    .notification-list
    .tournaments-raw
    li.tournaments-tab-raw.mobiles,
  .tournaments-raw .header .dropdown-list li.tournaments-tab-raw.mobiles,
  .header .dropdown-list .tournaments-raw li.tournaments-tab-raw.mobiles,
  .tournaments-raw .header .dropdown-list li a.tournaments-tab-raw.mobiles,
  .header .dropdown-list li .tournaments-raw a.tournaments-tab-raw.mobiles,
  .tournaments-raw
    .record-list-raw
    .record-list-col
    ul
    li
    h3
    span.tournaments-tab-raw.mobiles,
  .record-list-raw
    .record-list-col
    ul
    li
    h3
    .tournaments-raw
    span.tournaments-tab-raw.mobiles,
  .tournaments-raw .store-raw .tournaments-tab-raw.mobiles.tab-content,
  .store-raw .tournaments-raw .tournaments-tab-raw.mobiles.tab-content,
  .tournaments-raw .terms-raw label.tournaments-tab-raw.mobiles,
  .terms-raw .tournaments-raw label.tournaments-tab-raw.mobiles,
  .tournaments-raw
    .challenge-raw
    .challenge-info-raw
    ul
    li
    > span.tournaments-tab-raw.mobiles,
  .challenge-raw
    .challenge-info-raw
    ul
    .tournaments-raw
    li
    > span.tournaments-tab-raw.mobiles,
  .tournaments-raw .most-popular-raw ul li a.tournaments-tab-raw.mobiles,
  .most-popular-raw ul li .tournaments-raw a.tournaments-tab-raw.mobiles,
  .tournaments-raw .tournaments-tab-raw.mobiles.balance-earned-raw,
  .tournaments-raw
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .tournaments-tab-raw.mobiles.team-li-conn,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.team-li-conn,
  .tournaments-raw
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .tournaments-tab-raw.mobiles.team-details-col,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .tournaments-raw
    .tournaments-tab-raw.mobiles.team-details-col,
  .tournaments-raw
    .trophy-raw
    .trophy-li
    .tournaments-tab-raw.mobiles.trophy-col,
  .trophy-raw
    .trophy-li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.trophy-col,
  .tournaments-raw
    .tournament-details-col
    ul
    li
    strong.tournaments-tab-raw.mobiles,
  .tournament-details-col
    ul
    li
    .tournaments-raw
    strong.tournaments-tab-raw.mobiles,
  .tournaments-raw
    .contact-gaming-raw
    .contact-sub
    .tournaments-tab-raw.mobiles.form-group,
  .contact-gaming-raw
    .contact-sub
    .tournaments-raw
    .tournaments-tab-raw.mobiles.form-group,
  .tournaments-raw .tournaments-tab-raw.mobiles.challenger-list-col,
  .tournaments-raw .challenger-list-col ul li.tournaments-tab-raw.mobiles,
  .challenger-list-col ul .tournaments-raw li.tournaments-tab-raw.mobiles,
  .tournaments-raw
    .create-team-modal
    .modal-form
    .tournaments-tab-raw.mobiles.form-grop,
  .create-team-modal
    .modal-form
    .tournaments-raw
    .tournaments-tab-raw.mobiles.form-grop,
  .tournaments-raw
    .create-team-modal
    .modal-form
    .tournaments-tab-raw.mobiles.section-title,
  .create-team-modal
    .modal-form
    .tournaments-raw
    .tournaments-tab-raw.mobiles.section-title {
    padding: 0;
  }
  .tournaments-raw .tournaments-tab-raw.section.mobiles ul li,
  .tournaments-raw table.tournaments-tab-raw.mobiles ul li,
  .tournaments-raw .tournaments-tab-raw.mobiles.scroll-wrap ul li,
  .tournaments-raw .tournaments-tab-raw.mobiles.main-wrapper ul li,
  .tournaments-raw
    .league-wrapper
    .expert-master
    .progress-wrap
    .tournaments-tab-raw.mobiles.redius-col
    ul
    li,
  .league-wrapper
    .expert-master
    .progress-wrap
    .tournaments-raw
    .tournaments-tab-raw.mobiles.redius-col
    ul
    li,
  .tournaments-raw .tournaments-tab-raw.mobiles.select-dropdown ul li,
  .tournaments-raw .tournaments-tab-raw.mobiles.select-game ul li,
  .tournaments-raw
    .step-section-raw
    ul
    li
    .tournaments-tab-raw.mobiles.step-col
    ul
    li,
  .step-section-raw
    ul
    li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.step-col
    ul
    li,
  .tournaments-raw .tournaments-tab-raw.mobiles.title-raw ul li,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .tournaments-tab-raw.mobiles.gm-col
    ul
    li,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.gm-col
    ul
    li,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-tab-raw.mobiles.img-box
    ul
    li,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-raw
    .tournaments-tab-raw.mobiles.img-box
    ul
    li,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-tab-raw.mobiles.about-game-raw
    ul
    li,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-raw
    .tournaments-tab-raw.mobiles.about-game-raw
    ul
    li,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-tab-raw.mobiles.about-game-conn
    ul
    li,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-raw
    .tournaments-tab-raw.mobiles.about-game-conn
    ul
    li,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-tab-raw.mobiles.plateform-players-col
    ul
    li,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-raw
    .tournaments-tab-raw.mobiles.plateform-players-col
    ul
    li,
  .tournaments-raw
    .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-tab-raw.mobiles.view-raw
    ul
    li,
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-raw
    .tournaments-tab-raw.mobiles.view-raw
    ul
    li,
  .tournaments-raw
    .header
    .notification
    .dropdown-menu-noti
    .notification-list
    li.tournaments-tab-raw.mobiles
    ul
    li,
  .header
    .notification
    .dropdown-menu-noti
    .notification-list
    .tournaments-raw
    li.tournaments-tab-raw.mobiles
    ul
    li,
  .tournaments-raw .header .dropdown-list li.tournaments-tab-raw.mobiles ul li,
  .header .dropdown-list .tournaments-raw li.tournaments-tab-raw.mobiles ul li,
  .tournaments-raw
    .header
    .dropdown-list
    li
    a.tournaments-tab-raw.mobiles
    ul
    li,
  .header
    .dropdown-list
    li
    .tournaments-raw
    a.tournaments-tab-raw.mobiles
    ul
    li,
  .tournaments-raw
    .record-list-raw
    .record-list-col
    ul
    li
    h3
    span.tournaments-tab-raw.mobiles
    ul
    li,
  .record-list-raw
    .record-list-col
    ul
    li
    h3
    .tournaments-raw
    span.tournaments-tab-raw.mobiles
    ul
    li,
  .tournaments-raw .store-raw .tournaments-tab-raw.mobiles.tab-content ul li,
  .store-raw .tournaments-raw .tournaments-tab-raw.mobiles.tab-content ul li,
  .tournaments-raw .terms-raw label.tournaments-tab-raw.mobiles ul li,
  .terms-raw .tournaments-raw label.tournaments-tab-raw.mobiles ul li,
  .tournaments-raw
    .challenge-raw
    .challenge-info-raw
    ul
    li
    > span.tournaments-tab-raw.mobiles
    ul
    li,
  .challenge-raw
    .challenge-info-raw
    ul
    .tournaments-raw
    li
    > span.tournaments-tab-raw.mobiles
    ul
    li,
  .tournaments-raw .most-popular-raw ul li a.tournaments-tab-raw.mobiles ul li,
  .most-popular-raw ul li .tournaments-raw a.tournaments-tab-raw.mobiles ul li,
  .tournaments-raw .tournaments-tab-raw.mobiles.balance-earned-raw ul li,
  .tournaments-raw
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .tournaments-tab-raw.mobiles.team-li-conn
    ul
    li,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.team-li-conn
    ul
    li,
  .tournaments-raw
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .tournaments-tab-raw.mobiles.team-details-col
    ul
    li,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .tournaments-raw
    .tournaments-tab-raw.mobiles.team-details-col
    ul
    li,
  .tournaments-raw
    .trophy-raw
    .trophy-li
    .tournaments-tab-raw.mobiles.trophy-col
    ul
    li,
  .trophy-raw
    .trophy-li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.trophy-col
    ul
    li,
  .tournaments-raw
    .tournament-details-col
    ul
    li
    strong.tournaments-tab-raw.mobiles
    ul
    li,
  .tournament-details-col
    ul
    li
    .tournaments-raw
    strong.tournaments-tab-raw.mobiles
    ul
    li,
  .tournaments-raw
    .contact-gaming-raw
    .contact-sub
    .tournaments-tab-raw.mobiles.form-group
    ul
    li,
  .contact-gaming-raw
    .contact-sub
    .tournaments-raw
    .tournaments-tab-raw.mobiles.form-group
    ul
    li,
  .tournaments-raw .tournaments-tab-raw.mobiles.challenger-list-col ul li,
  .tournaments-raw .challenger-list-col ul li.tournaments-tab-raw.mobiles ul li,
  .challenger-list-col ul .tournaments-raw li.tournaments-tab-raw.mobiles ul li,
  .tournaments-raw
    .create-team-modal
    .modal-form
    .tournaments-tab-raw.mobiles.form-grop
    ul
    li,
  .create-team-modal
    .modal-form
    .tournaments-raw
    .tournaments-tab-raw.mobiles.form-grop
    ul
    li,
  .tournaments-raw
    .create-team-modal
    .modal-form
    .tournaments-tab-raw.mobiles.section-title
    ul
    li,
  .create-team-modal
    .modal-form
    .tournaments-raw
    .tournaments-tab-raw.mobiles.section-title
    ul
    li {
    width: auto;
  }
  .tournaments-raw .tournaments-tab-raw.section.mobiles ul li a,
  .tournaments-raw table.tournaments-tab-raw.mobiles ul li a,
  .tournaments-raw .tournaments-tab-raw.mobiles.scroll-wrap ul li a,
  .tournaments-raw .tournaments-tab-raw.mobiles.main-wrapper ul li a,
  .tournaments-raw
    .league-wrapper
    .expert-master
    .progress-wrap
    .tournaments-tab-raw.mobiles.redius-col
    ul
    li
    a,
  .league-wrapper
    .expert-master
    .progress-wrap
    .tournaments-raw
    .tournaments-tab-raw.mobiles.redius-col
    ul
    li
    a,
  .tournaments-raw .tournaments-tab-raw.mobiles.select-dropdown ul li a,
  .tournaments-raw .tournaments-tab-raw.mobiles.select-game ul li a,
  .tournaments-raw
    .step-section-raw
    ul
    li
    .tournaments-tab-raw.mobiles.step-col
    ul
    li
    a,
  .step-section-raw
    ul
    li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.step-col
    ul
    li
    a,
  .tournaments-raw .tournaments-tab-raw.mobiles.title-raw ul li a,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .tournaments-tab-raw.mobiles.gm-col
    ul
    li
    a,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.gm-col
    ul
    li
    a,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-tab-raw.mobiles.img-box
    ul
    li
    a,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-raw
    .tournaments-tab-raw.mobiles.img-box
    ul
    li
    a,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-tab-raw.mobiles.about-game-raw
    ul
    li
    a,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .tournaments-raw
    .tournaments-tab-raw.mobiles.about-game-raw
    ul
    li
    a,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-tab-raw.mobiles.about-game-conn
    ul
    li
    a,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-raw
    .tournaments-tab-raw.mobiles.about-game-conn
    ul
    li
    a,
  .tournaments-raw
    .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-tab-raw.mobiles.plateform-players-col
    ul
    li
    a,
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-raw
    .tournaments-tab-raw.mobiles.plateform-players-col
    ul
    li
    a,
  .tournaments-raw
    .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-tab-raw.mobiles.view-raw
    ul
    li
    a,
  .trending-videos-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .tournaments-raw
    .tournaments-tab-raw.mobiles.view-raw
    ul
    li
    a,
  .tournaments-raw
    .header
    .notification
    .dropdown-menu-noti
    .notification-list
    li.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .header
    .notification
    .dropdown-menu-noti
    .notification-list
    .tournaments-raw
    li.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .tournaments-raw
    .header
    .dropdown-list
    li.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .header
    .dropdown-list
    .tournaments-raw
    li.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .tournaments-raw
    .header
    .dropdown-list
    li
    a.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .header
    .dropdown-list
    li
    .tournaments-raw
    a.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .tournaments-raw
    .record-list-raw
    .record-list-col
    ul
    li
    h3
    span.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .record-list-raw
    .record-list-col
    ul
    li
    h3
    .tournaments-raw
    span.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .tournaments-raw .store-raw .tournaments-tab-raw.mobiles.tab-content ul li a,
  .store-raw .tournaments-raw .tournaments-tab-raw.mobiles.tab-content ul li a,
  .tournaments-raw .terms-raw label.tournaments-tab-raw.mobiles ul li a,
  .terms-raw .tournaments-raw label.tournaments-tab-raw.mobiles ul li a,
  .tournaments-raw
    .challenge-raw
    .challenge-info-raw
    ul
    li
    > span.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .challenge-raw
    .challenge-info-raw
    ul
    .tournaments-raw
    li
    > span.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .tournaments-raw
    .most-popular-raw
    ul
    li
    a.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .most-popular-raw
    ul
    li
    .tournaments-raw
    a.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .tournaments-raw .tournaments-tab-raw.mobiles.balance-earned-raw ul li a,
  .tournaments-raw
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .tournaments-tab-raw.mobiles.team-li-conn
    ul
    li
    a,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.team-li-conn
    ul
    li
    a,
  .tournaments-raw
    .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .tournaments-tab-raw.mobiles.team-details-col
    ul
    li
    a,
  .profile-details-raw
    .team-overview-raw
    .the-team-raw
    .team-li
    .team-li-conn
    .tournaments-raw
    .tournaments-tab-raw.mobiles.team-details-col
    ul
    li
    a,
  .tournaments-raw
    .trophy-raw
    .trophy-li
    .tournaments-tab-raw.mobiles.trophy-col
    ul
    li
    a,
  .trophy-raw
    .trophy-li
    .tournaments-raw
    .tournaments-tab-raw.mobiles.trophy-col
    ul
    li
    a,
  .tournaments-raw
    .tournament-details-col
    ul
    li
    strong.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .tournament-details-col
    ul
    li
    .tournaments-raw
    strong.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .tournaments-raw
    .contact-gaming-raw
    .contact-sub
    .tournaments-tab-raw.mobiles.form-group
    ul
    li
    a,
  .contact-gaming-raw
    .contact-sub
    .tournaments-raw
    .tournaments-tab-raw.mobiles.form-group
    ul
    li
    a,
  .tournaments-raw .tournaments-tab-raw.mobiles.challenger-list-col ul li a,
  .tournaments-raw
    .challenger-list-col
    ul
    li.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .challenger-list-col
    ul
    .tournaments-raw
    li.tournaments-tab-raw.mobiles
    ul
    li
    a,
  .tournaments-raw
    .create-team-modal
    .modal-form
    .tournaments-tab-raw.mobiles.form-grop
    ul
    li
    a,
  .create-team-modal
    .modal-form
    .tournaments-raw
    .tournaments-tab-raw.mobiles.form-grop
    ul
    li
    a,
  .tournaments-raw
    .create-team-modal
    .modal-form
    .tournaments-tab-raw.mobiles.section-title
    ul
    li
    a,
  .create-team-modal
    .modal-form
    .tournaments-raw
    .tournaments-tab-raw.mobiles.section-title
    ul
    li
    a {
    font-size: 15px;
    padding: 0px 16px;
  }
  /*-------------------tournament-details-end-----------*/
  /*------------------Rules-start----------------------*/
  .modal-raw.rules-modal .modal-lg .modal-content .modal-body {
    padding: 10px 0% 10px;
  }
  /*------------------Rules-end------------------*/
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw .timeline-raw.pdlr15 {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .tournament-brackets-raw .timeline-raw.pdlr15 .timeline .events ul li {
    width: 23.65%;
  }
  /*----tournaments-brackets-end----------------*/
  /*----------challenge--pop-up-start----------*/
  .modal-raw.challenge-modal-box.modal-border-raw
    .modal-dialog
    .modal-content
    .modal-body
    .coins-col-box
    .coins-col
    li {
    margin: 20px 15px 0;
  }
  .modal-raw.login-modal .modal-dialog .modal-body {
    padding-right: 5%;
    padding-left: 5%;
  }
  .modal-raw.register-modal .modal-dialog .modal-body {
    padding-right: 5%;
    padding-left: 5%;
  }
  .modal-raw.register-modal .modal-dialog .terms-raw {
    width: 100%;
  }
  .modal-raw.choose-your-team-modalbox .bottom-section .team-col ul,
  .modal-raw.create-team-modal .bottom-section .team-col ul {
    padding: 15px;
  }
  .modal-raw.choose-your-team-modalbox .bottom-section .team-col ul li img,
  .modal-raw.create-team-modal .bottom-section .team-col ul li img {
    width: 30px;
    height: 30px;
  }
  .modal-raw.choose-your-team-modalbox .bottom-section .team-col ul li p,
  .modal-raw.create-team-modal .bottom-section .team-col ul li p {
    font-size: 14px;
  }
  .modal-raw.trending-modal .modal-dialog .modal-body .title-raw h3 span {
    font-size: 36px;
    width: 100%;
  }
  .btn-ac {
    min-width: 140px;
    padding: 0 10px;
    margin: 0 3px;
    width: auto;
  }
  .tournament-details-col .btm-btns .btn-ac {
    min-width: inherit;
  }
  .tournament-details-col ul li {
    font-size: 12px;
  }
  .tournament-details-col ul li strong {
    font-size: 14px;
  }
  .tournament-details-col .inner-banner .container-fluid .inner-banner-con p {
    font-size: 18px;
    line-height: 120%;
  }
  .inner-banner .container-fluid .back-btn {
    top: -30px;
    font-size: 16px;
    padding: 2px 14px 2px 32px;
    background-size: contain;
  }
  .time-date-col .timesate-li::before {
    display: none;
  }
  .time-date-col .timesate-li .box-raw {
    padding: 20px 10px;
    min-height: auto;
  }
  .leaderboard-raw .leader-list-raw.tournament-lobby-raw {
    padding: 0%;
  }
  .winners-col .winner-li .win-img img {
    width: 140px;
  }
  .winners-col .win-img img {
    width: 110px;
  }
  .title-divider::before,
  .title-divider::after {
    width: 20%;
  }
  .igl-tv-raw .most-popular-raw .title-divider::before,
  .igl-tv-raw .most-popular-raw .title-divider::after {
    width: 20%;
  }
  .main-wrapper.about-main-raw .about-inner-banner {
    background-size: 250%;
  }
  .main-wrapper.about-main-raw .about-inner-banner .banner-con {
    margin: 280px 0 50px;
  }
  .cart-wrap table thead tr th {
    min-width: inherit;
  }
  .cart-wrap table thead tr th.cart-price {
    width: 20px;
  }
  .cart-wrap table thead tr th.quantity {
    width: 50px;
  }
  .cart-wrap .qty-drop select {
    min-width: 65px;
  }
  .myImg .caption {
    bottom: 3%;
  }
  .myImg .caption .glow .play_icon {
    width: 50px;
  }
  .myImg .caption .glow .sub-head-j {
    font-size: 16px;
    line-height: 1.5;
  }
  .ComingSoonSection {
    margin: 60px auto;
  }
  .ComingSoonSection .ComingSoon {
    font-size: 42px;
  }
  .ComingSoonSection .InprogressText {
    font-size: 16px;
  }
  .ComingSoonSection .GoBack {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1500px) {
  .gaming-plateform-raw {
    padding: 0 50px 50px 120px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .game-lists-raw .gm-li-raw .row .gm-li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn {
    width: 70%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn {
    width: 30%;
  }
}
@media only screen and (max-device-width: 480px) {
  html {
    -webkit-text-size-adjust: none;
  }
  .thankyou p {
    font-size: 16px;
  }
  .header .dropdown-list.notification-list {
    width: 100vw;
    right: 0;
    left: 0;
    top: 75px;
  }
  .affix .header .dropdown-list.notification-list {
    top: 75px;
  }
}
@media screen and (min-width: 480px) and (max-width: 575px) {
  .main-wrapper.profile-raw .follow-points {
    float: left;
    margin: 40px 0;
    overflow: scroll;
    padding: 0;
    top: 0;
    width: 100%;
  }
  .main-wrapper.profile-raw .profile-intro {
    float: none;
    text-align: center;
  }
  .main-wrapper.profile-raw .profile-intro {
    text-align: center;
    float: none;
  }
  .main-wrapper.profile-raw .follow-points ul {
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    max-width: 500px;
  }
  .main-wrapper.profile-raw .follow-points ul li h6 {
    font-size: 10px;
    white-space: nowrap;
  }
  .main-wrapper.profile-raw .follow-points ul li span {
    font-size: 28px;
  }
  .main-wrapper.profile-raw .user-avtar-col {
    bottom: 0;
    float: none;
  }
  .main-wrapper.profile-raw {
    background-size: 2500px !important;
  }
  .main-wrapper.profile-raw .follow-points ul li:nth-child(1) {
    margin-left: 100px;
  }
}
@media (max-width: 479px) {
  .gamedetail .title-raw h1 {
    font-size: 30px;
  }
  .gamedetail {
    padding-top: 30px !important;
  }
  .header .logo {
    width: 60px;
  }
  .header .notification {
    margin-top: 0px;
  }
  .header .notification ul li {
    margin-right: 10px;
  }
  .header .header-right .nav-main {
    padding: 115px 20px 0 10px;
  }
  .affix .header .dropdown-list,
  .header .dropdown-list {
    top: 45px;
  }
  h1,
  h2 {
    font-size: 26px;
  }
  .league-wrapper {
    padding-top: 110px;
  }
  .league-wrapper .expert-master .progress-wrap h5 {
    font-size: 12px;
    margin: 0 5px;
  }
  .league-wrapper .expert-master .progress-wrap img {
    margin-bottom: -10px;
  }
  .league-wrapper
    .expert-master
    .progress-wrap
    .progress-bar-raw
    .progress-bar {
    font-size: 14px;
    height: 20px;
  }
  .league-wrapper .expert-master p {
    margin-top: 5px;
  }
  .faqs-wrapper .accordion .card {
    padding-top: 20px;
  }
  .faqs-wrapper .accordion .card .card-header > span {
    font-size: 16px;
  }
  .faqs-wrapper .accordion .card .card-header button {
    font-size: 14px;
    padding: 11px 50px;
    letter-spacing: 1px;
  }
  .faqs-wrapper .accordion .card .card-header button:before {
    width: 24px;
    height: 24px;
    right: 12px;
    background-size: 12px;
  }
  .table-wrapper {
    padding: 40px 0 50px;
  }
  .footer-box-wrap {
    padding-top: 40px;
  }
  .ongoing-competition-raw {
    padding: 40px 0;
  }
  .title-raw h1 span,
  .title-raw h2 span {
    font-size: 42px;
    width: 98%;
  }
  .title-raw h1,
  .title-raw h2,
  .title-raw h3 {
    font-size: 22px;
  }
  #games.title-raw {
    margin-top: -42px;
  }
  #games.title-raw span {
    font-size: 75px;
  }
  #games.title-raw h1 {
    font-size: 35px;
  }
  .title-raw p {
    font-size: 14px;
  }
  .main-wrapper {
    padding-top: 125px;
  }
  .mt80 {
    margin-top: 50px !important;
  }
  .game-lists-raw .gm-li-raw {
    margin-top: 0px;
    padding: 0px;
  }
  .game-lists-raw .gm-li-raw .row {
    margin-right: -15px;
    margin-left: -15px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li {
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: auto;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw {
    padding: 25px 15px;
  }
  .game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw::after {
    top: -18px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn {
    width: auto;
    text-align: center;
    float: right;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3 {
    font-size: 24px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3
    br {
    display: inherit;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .right-conn
    h3
    span {
    font-size: 14px;
    margin-left: 0px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn {
    width: 70%;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5 {
    font-size: 18px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h6 {
    font-size: 10px;
    padding-left: 10px;
    background-size: 6px;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li {
    /* &:last-child {
      width: 42%;
      text-align: right;
      margin-top: 2px;
    }
    &:first-child,
    &:nth-child(2) {
      width: 28%;
    }
    &:last-child {
      // width: 100%;
      // text-align: left;
      a {
        margin-top: 10px;
      }
    } */
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join {
    padding: 8px 25px 8px 10px;
    background-position: right 5px center;
  }
  .game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li
    .btn-join:hover {
    background-position: right 8px center;
  }
  .game-lists-raw .competition-tab-raw {
    margin-top: 30px;
  }
  .game-lists-raw .competition-tab-raw .nav-tabs li .nav-link {
    white-space: nowrap;
    letter-spacing: 1px;
    font-size: 18px;
    padding: 15px 0;
  }
  .game-lists-raw .competition-tab-raw .nav-tabs li .nav-link::before {
    display: none;
  }
  .store-raw.game-lists-raw #game-tabs-1 .gm-li-raw .row .gm-li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mt500 {
    margin-top: 70px !important;
  }
  .mt50 {
    margin-top: 35px !important;
  }
  .step-section-raw ul {
    width: 280px;
    padding: 20px 15px;
  }
  .step-section-raw ul li .step-col > span {
    font-size: 36px;
  }
  /*------------------news--detail-start-----------*/
  .details-head .post-by {
    margin-top: 10px;
  }
  .details-head .post-by span {
    margin-bottom: 15px;
  }
  .details-head .post-by span:nth-child(2) {
    margin-bottom: 0px;
  }
  /*------------------news--detail-end-----------*/
  /*----------------------news-start----------------------------*/
  .latest-news-raw .latest-news-col {
    margin-top: 0;
    padding: 0px;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box .img-box {
    width: 100%;
    margin-bottom: 20px;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .img-box
    img {
    width: 100px;
  }
  .latest-news-raw .latest-news-col .latest-news-li .latest-news-box .news-box {
    width: 100%;
    padding-left: 0px;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    p {
    margin-top: 15px;
  }
  .latest-news-raw
    .latest-news-col
    .latest-news-li
    .latest-news-box
    .news-box
    p
    a {
    margin-left: 0px;
  }
  /*----------------------news-end-----------------------------*/
  /*------------------Store Start-------------------------*/
  .brought-box {
    padding: 20px 10px 20px;
  }
  .brought-box .text-box {
    padding-top: 20px;
  }
  .brought-box .text-box h5 {
    font-size: 18px;
    min-width: inherit;
    width: 100%;
    padding: 0px;
  }
  .brought-box .text-box .price {
    font-size: 20px;
  }
  .brought-box .text-box .btn-join,
  .brought-box .text-box .btn-ic {
    margin: 0;
  }
  .store-tab li .nav-link {
    font-size: 16px;
    padding: 12px 15px;
    min-width: 150px;
  }
  .product-li-raw .row .gm-li {
    padding-right: 15px;
    padding-left: 15px;
  }
  .addcart-next-raw .add-to-cart {
    width: auto;
  }
  /*------------------Store-end-------------------------*/
  .horizontal-tab-raw .nav-tabs-dropdown {
    font-size: 20px;
    padding: 10px 0;
  }
  .divide-raw::after {
    top: 45px;
  }
  .line-arrow {
    padding-top: 60px !important;
  }
  .line-arrow::before {
    height: 40px;
  }
  .inner-banner
    .container-fluid
    .inner-banner-con
    .game-rules-col
    li
    .hexagon-col {
    width: 88px;
    height: 96px;
  }
  .inner-banner
    .container-fluid
    .inner-banner-con
    .game-rules-col
    li
    .hexagon-col
    h4 {
    font-size: 26px;
  }
  .record-list-raw .record-list-col ul li h3 {
    font-size: 26px;
  }
  .record-list-raw .record-list-col ul li h3 span {
    letter-spacing: 0px;
    font-size: 8px;
  }
  .inner-banner {
    height: 300px;
  }
  .about-section-raw .title-sec h2 {
    margin-bottom: 20px;
  }
  .about-section-raw .btn-ic {
    margin: 10px 15px;
  }
  .ig-tv-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .img-box {
    height: 195px;
  }
  .ig-tv-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h5,
  .ig-tv-raw.game-lists-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .about-game-conn
    .left-conn
    h6 {
    font-size: 18px;
  }
  /*-------------challenges-start---------------*/
  .challenges-raw.game-lists-raw .gm-li-raw {
    padding: 0 0px;
  }
  .challenges-raw.game-lists-raw .gm-li-raw .row {
    margin-right: -20px;
    margin-left: -20px;
  }
  .challenges-raw.game-lists-raw .gm-li-raw .row.align-items-center .gm-li {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 20px;
    padding-left: 20px;
  }
  .challenges-raw.game-lists-raw
    .gm-li-raw
    .row.align-items-center
    .gm-li
    .gm-col
    .img-box {
    height: 170px;
  }
  .challenges-banner-raw .inner-banner .btn-ic.ic-challenge {
    margin-top: 10px;
  }
  .challenges-banner-raw .inner-banner .mt250 {
    margin-top: 40px !important;
  }
  /*-------------challenges-end---------------*/
  /*----------------profile start-------------------*/
  .follow-cointer-col .user-follower-col span {
    font-size: 14px;
    letter-spacing: 0.2px;
  }
  .follow-cointer-col .user-follower-col .counter-col {
    font-size: 20px;
    height: 70px;
    width: 60px;
    line-height: 65px;
    margin: 0 auto;
  }
  .user-avtar-col {
    margin: 0 15px;
    width: 120px;
  }
  .pt10 {
    padding-top: 60px !important;
  }
  .pb10 {
    padding-bottom: 60px !important;
  }
  .main-wrapper.profile-raw h1 {
    font-size: 24px;
    margin-top: 30px;
  }
  .balance-earned-raw ul li {
    margin: 0 5px;
  }
  .balance-earned-raw ul li .coin-label {
    font-size: 18px;
    padding: 15px 10px;
  }
  .balance-earned-raw ul li span {
    letter-spacing: 1px;
    font-size: 14px;
  }
  .personal-info-section .list-col .con-box {
    padding: 30px 0;
  }
  /*-----------------profile end-------------------*/
  .wallet-raw .wallet-ul .wallet-li {
    width: 100%;
  }
  /*----------------profile team start-------------------*/
  .profile-details-raw .title-shadow h2 {
    font-size: 22px;
  }
  .profile-details-raw .team-overview-raw .the-team-raw .team-li {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 80px;
  }
  .trophy-raw .trophy-li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .trophy-raw .trophy-li .trophy-col .trophy-img {
    margin-top: -60px;
  }
  /*-----------------profile team end--------------------*/
  /*----------------profile achievements start-------------------*/
  .pdlr15 {
    padding-left: 6% !important;
    padding-right: 6% !important;
  }
  .achievements-raw .achievements-li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .achievements-raw .achievements-li .conn h4 {
    font-size: 18px !important;
    padding: 0 30px;
    height: 50px;
    line-height: 48px;
  }
  /*-----------------profile achievements end-------------------*/
  .filter-raw .filter-li .select-game select {
    font-size: 12px;
  }
  .product-li-raw .row .gm-li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .challenge-raw.game-lists-raw .challenge-info-raw ul li h6 {
    font-size: 14px;
  }
  .footer-wrap .right-wrap .social-list-raw li {
    margin-right: 15px;
  }
  .table-list-notifies .con-col .conn .img-fluid {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
  .table-list-notifies .con-col .conn h6 {
    font-size: 16px;
  }
  .table-list-notifies .con-col .conn h6 span {
    font-size: 10px;
  }
  /*------tournaments-brackets-start-------------*/
  .tournament-brackets-raw
    .timeline-raw.pdlr15
    .timeline
    .events
    ul
    li
    h5
    strong {
    font-size: 12px;
  }
  /*----tournaments-brackets-end----------------*/
  /*----------challenge--pop-up-start----------*/
  .modal-raw .modal-body {
    padding: 30px 16px !important;
  }
  .modal-raw .form-col {
    background-size: contain;
    padding-bottom: 10px;
  }
  .modal-raw.challenge-modal-box.modal-border-raw
    .modal-dialog
    .modal-content
    .modal-body {
    padding: 30px;
  }
  .modal-raw.challenge-modal-box.modal-border-raw
    .modal-dialog
    .modal-content
    .modal-body
    .coins-col-box
    .coins-col
    li {
    width: calc(50% - 32px);
    margin: 0px 15px 0px;
  }
  .modal-raw.challenge-modal-box.modal-border-raw
    .modal-dialog
    .modal-content
    .modal-body
    .coins-col-box
    .coins-col
    li
    .img-box
    .reflect
    canvas {
    width: 100%;
  }
  .modal-raw.challenge-modal-box.modal-border-raw
    .modal-dialog
    .modal-content
    .modal-body
    .challenger-list-col
    ul
    li {
    padding: 10px;
  }
  .modal-raw.challenge-modal-box.modal-border-raw
    .modal-dialog
    .modal-content
    .modal-body
    .challenger-list-col
    ul
    li
    .avtar {
    width: 30px;
    height: 30px;
  }
  .tournament-details-col ul{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
  }
  .tournament-details-col ul li {
    width: 32%;
  }
  .tournament-details-col ul li:nth-child(3)::before{
    background: none;
  }
  .tournament-details-col ul li::before {
    right: -3px;
  }
  .tournament-details-col ul li strong {
    letter-spacing: 0px;
    font-size: 14px;
  }
  .modal-raw .form-control {
    font-size: 12px;
  }
  .winners-col .winner-li .win-img {
    margin-top: -60px;
  }
  .winners-col .winner-li .win-img img {
    width: 110px;
  }
  .winners-col .win-img {
    margin-top: -40px;
  }
  .winners-col .win-img img {
    width: 90px;
  }
  /* .ongoing-events-raw
    .game-lists-raw
    .competition-tab-raw
    .gm-li-raw
    .row
    .gm-li
    .gm-col
    .about-game-raw
    .plateform-players-col
    li:last-child,
  .tournaments-raw.game-lists-raw .gm-li-raw .row .gm-li .gm-col .about-game-raw .plateform-players-col li:last-child {
    width: 40%;
    margin-top: 0;
    a {
      margin-top: 0px;
    }
  } */
  .igl-tv-raw .most-popular-raw .title-divider::before,
  .igl-tv-raw .most-popular-raw .title-divider::after {
    width: 16%;
  }
  .main-wrapper.about-main-raw .about-inner-banner .banner-con {
    margin: 230px 0 50px;
  }
  .achievements-raw .row .achievements-li {
    text-align: center;
  }
  .achievements-raw .row .achievements-li .achievements-col {
    max-width: 228px;
    float: none;
    display: inline-block;
  }
  /*------6 march-------change---parth---end------*/
  /*------------------------*/
  .main-wrapper.profile-raw {
    background-size: 2500px !important;
  }
  .main-wrapper.profile-raw .user-avtar-col {
    bottom: 0;
    float: none;
  }
  .main-wrapper.profile-raw .profile-intro {
    text-align: center;
    /* vinayak */
    float: none;
  }
  .main-wrapper.profile-raw .profile-intro h1 {
    margin-top: 20px;
    font-size: 26px;
  }
  .main-wrapper.profile-raw .profile-intro p span {
    font-size: 20px;
  }
  .main-wrapper.profile-raw .follow-points {
    top: 0;
    margin: 40px 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    float: left;
  }
  .main-wrapper.profile-raw .follow-points ul {
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    max-width: 500px;
  }
  .main-wrapper.profile-raw .follow-points ul li {
    padding: 10px 0;
    margin: 0 20px;
  }
  .main-wrapper.profile-raw .follow-points ul li::before {
    right: -20px;
  }
  .main-wrapper.profile-raw .follow-points ul li span {
    font-size: 28px;
  }
  .main-wrapper.profile-raw .follow-points ul li:first-child {
    margin-left: 85%;
  }
  .main-wrapper.profile-raw .follow-points ul li h6 {
    font-size: 10px;
    white-space: nowrap;
  }
  .follow-cointer-col {
    margin-bottom: 15px;
  }
  .personal-info-section .personal-info-back .left-conn,
  .personal-info-section .personal-info-back .right-conn {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .personal-info-section .personal-info-back .left-profile button.btn-edit {
    right: 42px;
  }
  .personal-info-section .personal-info-back button.btn-edit {
    right: 30px;
    top: 110px;
  }
  .personal-info-section .personal-info-back .left-profile.ptb10 {
    padding-bottom: 10px !important;
  }
  .gaming-plateform-raw {
    padding: 0px;
  }
  .modal-dialog {
    max-width: 100%;
  }
  /*------------------------*/
  .ComingSoonSection {
    margin: 60px auto;
  }
  .ComingSoonSection .ComingSoon {
    font-size: 42px;
  }
  .ComingSoonSection .InprogressText {
    font-size: 16px;
  }
  .ComingSoonSection .GoBack {
    font-size: 24px;
  }
  .thankyou p {
    font-size: 16px;
  }
}
@media (max-width: 359px) {
  .record-list-raw .record-list-col ul li {
    padding: 0;
  }
  .store-tab li .nav-link {
    min-width: 130px;
  }
  .ComingSoonSection {
    margin: 60px auto;
  }
  .ComingSoonSection .ComingSoon,
  .ComingSoonSection .UnderMaintainance {
    font-size: 42px;
  }
  .ComingSoonSection .InprogressText {
    font-size: 16px;
  }
  .ComingSoonSection .GoBack {
    font-size: 24px;
  }
  .thankyou p {
    font-size: 16px;
  }
}
.tournaments-raw .tabbing-raw .tournaments-tab-raw {
  padding: 0px 70px;
}
.tournaments-raw .tabbing-raw ul.nav {
  display: flex !important;
  justify-content: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #052647 !important;
}
.tournaments-raw .tabbing-raw ul.nav li a {
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  letter-spacing: 2px;
  font-size: 26px;
  background: none !important;
  padding: 10px 55px;
  position: relative;
}
.tournaments-raw .tabbing-raw ul.nav li a.active {
  color: rgb(255, 255, 255);
  border-color: none !important;
}
.tournaments-raw .tabbing-raw ul.nav li a.active:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: white;
  bottom: -15px;
  left: 0;
  box-shadow: 0px 0px 15px 4px rgba(80, 174, 255, 0.43);
  -webkit-box-shadow: 0px 0px 15px 4px rgba(80, 174, 255, 0.43);
  -moz-box-shadow: 0px 0px 15px 4px rgba(80, 174, 255, 0.43);
}
.tournaments-raw .tabbing-raw ul.nav li a:hover {
  border: none !important;
  background: none !important;
}
.tournaments-raw .tabbing-raw ul.nav li a.nav-link {
  border: none !important;
  border-top-left-radius: inherit !important;
  border-top-right-radius: inherit !important;
}

.bracket_round_2 .bracket-mrgin1 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.bracket_round_3 .bracket-mrgin1 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.bracket_round_4 .bracket-mrgin1 {
  padding-top: 280px;
  padding-bottom: 280px;
}

.bracket_round_5 .bracket-mrgin1 {
  padding-top: 600px;
  padding-bottom: 600px;
}

.bracket_round_6 .bracket-mrgin1 {
  padding-top: 1240px;
  padding-bottom: 1240px;
}

.bracket_round_7 .bracket-mrgin1 {
  padding-top: 2440px;
  padding-bottom: 2440px;
}

.bracket_round_8 .bracket-mrgin1 {
  padding-top: 4920px;
  padding-bottom: 4920px;
}

.bracket-mrgin1 {
  margin-bottom: 40px;
}

.bracket-mrgin2 {
  margin-bottom: 150px;
}

.bracket-mrgin3 {
  margin-bottom: 360px;
}

.bracket-mrgin4 {
  margin-bottom: 720px;
}

a.bracket-compatitore-link.left-br:hover {
  background: #009cff !important;
  color: white !important;
}

.tournament {
  position: relative;
  min-height: 300px;
  padding: 30px 0 0;
  /* overflow-x: scroll; */
  overflow-x: auto;
}

.tournament__grid {
  font-size: 0;
  margin-bottom: 30px;
  line-height: 0;
  display: flex;
  align-items: stretch;
  min-height: 400px;
  /* width: 1290px; */
  width: 100%;
}

.tournament__round {
  position: relative;
  flex: 1 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.tournament__round:last-child .tournament__match__team:after {
  display: none;
}
.tournament__round:first-child .tournament__match__team:before {
  display: none;
}

.tournament__match {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 0;
  align-items: center;
  min-height: 110px;
  white-space: nowrap;
  min-width: auto;
}
.tournament__match a:last-child {
  border-radius: 25px;
  border-top: 2px solid #06091a;
}
.tournament__match a:first-child {
  border-radius: 25px;
  border-bottom: 2px solid #06091a;
}
.tournament__match a i.ic-sts.r2c {
  background: #001a24;
  color: #00ff0c;
  width: 90px;
  border-radius: 100px;
}
.tournament__match a img {
  height: 30px;
  width: 30px;
  object-fit: contain;
  aspect-ratio: 1/1;
  border: 1px solid #009cff;
  border-radius: 50%;
}
.tournament__match a i.ic-user {
  left: 6px;
  top: 6px;
  border: 1px solid #009cff;
  background: #333c53 url(../images/ic-user-icon1.png) center center no-repeat;
}
.tournament__match a i {
  font-style: inherit;
  width: 25px;
  height: 25px;
  line-height: 28px;
  text-align: center;
  border-radius: 50%;
  /* position: absolute; */
  text-align: center;
  font-size: 14px;
  right: 4px;
  top: 6px;
  aspect-ratio: 1/1;
}
.tournament__match:first-child {
  margin: 0 !important;
}
.tournament__match:after {
  content: "";
  position: absolute;
  right: 0;
  width: 2px;
  background: #005a90;
  top: 25%;
  bottom: 25%;
}
.tournament__match.right-bracket:after {
  left: 0 !important;
}
.tournament__match.final-round-only-left:after {
  left: 7.5% !important;
  right: auto !important;
}
.tournament__match.final-round-with-right:after {
  visibility: hidden;
}
.tournament-winner.tournament-winner:after {
  display: none;
}
.tournament__match:after .tournament__round--first-round .tournament__match {
  padding-left: 0;
}

.tournament__round--winner .tournament__match {
  padding-right: 0;
  height: 32px;
}
.tournament__round--winner .tournament__match:after {
  display: none;
}

.tournament__round--end-point .tournament__match:after {
  display: none;
}
.tournament__round--end-point .tournament__match__team:after {
  display: none;
}

.tournament__match__team {
  font-size: 13px;
  font-weight: normal;
  transition: color 0.3s ease;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  background: #000b28;
  display: block;
  position: relative;
  height: 40px;
  line-height: 40px;
  padding: 0 36px 0 36px;
  margin: auto;
  text-transform: uppercase;
  font-family: "D-DIN Condensed";
  align-self: center;
  letter-spacing: 1px;
  text-align: left;
  min-width: auto;
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tournament__match__team:focus {
  color: #fff;
}
.tournament__match__team:hover {
  color: #fff;
}
.tournament__match__team:before {
  content: "";
  position: absolute;
  top: 50%;
  width: 999px;
  height: 2px;
  margin-top: -1px;
  background: #005a90;
  right: 100%;
}
.tournament__match__team:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 999px;
  height: 2px;
  margin-top: -1px;
  background: #005a90;
  left: 100%;
}

.tournament__round.tournament__round--first-round li {
  /* width: 33% !important; */
  width: 100% !important;
}

.tournament__round.tournament__round--final li {
  /* width: 33% !important; */
  width: 100% !important;
}

.tournament__round.tournament__round--winner li {
  /* width: 33% !important; */
  width: 100% !important;
}

.rounds {
  margin-top: 10px;
}

.brradiusf {
  border-radius: 25px 25px 0 0 !important;
}

.brradiusl {
  border-radius: 0 0 25px 25px !important;
}

.pagination {
  display: flex;
  overflow-x: auto;
}
.pagination .page-link {
  color: #fff !important;
  background-color: #060919 !important;
}
.pagination .active .page-link {
  color: #060919 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}
.pagination .page-link.active {
  color: #060919 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-border {
  border: 1px solid #065f9f;
}

.btn-text {
  color: #fff;
}

.news-detail-raw .img-box img {
  display: inline-block;
  /* border: 2px solid #3385cc; */
  border: none;
  width: 100%;
  height: 240px;
  object-fit: cover;
}
.news-detail-raw h4 {
  background: linear-gradient(to bottom, #00d8ff 0, #009cff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  letter-spacing: 0.2px;
  font-family: "D-DIN Condensed";
  font-weight: 400;
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
}
.news-detail-raw p {
  font-size: 14px;
  color: #fff;
  font-family: "Montserrat Regular", sans-serif;
  font-weight: 100;
  line-height: 140%;
  letter-spacing: 0.5px;
}
.news-detail-raw p a {
  color: #fff;
  font-family: "Montserrat Regular", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 6px;
  color: white;
  margin-left: 0;
  text-decoration: underline;
}
.news-detail-raw .post-by ul,
.news-detail-raw .post-card-info ul {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.news-detail-raw .post-by ul li,
.news-detail-raw .post-card-info ul li {
  font-size: 1rem;
  color: #fff;
  text-transform: capitalize;
  font-weight: 500;
}
.news-detail-raw .box-author {
  display: flex;
  align-items: center;
}
.news-detail-raw .box-author img {
  display: inline-block;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
}

.AuthWrapper {
  padding: 12rem 0 8rem;
}

.ComingSoonSection {
  margin: 80px auto;
}

.ComingSoon {
  max-width: 100%;
  font-family: "Goldman";
  font-style: normal;
  font-weight: 400;
  font-size: 140px;
  letter-spacing: 0.05em;
  background: radial-gradient(
    82% 82% at 50% 50%,
    #00bfff 0%,
    rgba(0, 0, 0, 0) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-align: center;
}
.UnderMaintainance {
  font-size: 80px;
}
.news-detail-image {
  border-radius: 15px;
  height: 400px;
}
.InprogressText {
  color: #fff;
  text-align: center;
  margin: 16px auto;
  font-size: 24px;
}

.GoBack {
  font-family: "Goldman";
  font-size: 32px;
  line-height: 33px;
  cursor: pointer;
  color: #ffffff;
  text-align: center;
} /*# sourceMappingURL=style.css.map */
/* about us page */
.about-us-img {
  width: 100%;
  height: 75vh;
  margin-left: auto;
  object-fit: contain;
  margin-right: auto;
}
/* home page find us on */

.find_us_bg {
  background: url(../images/right_side_img.png);
  background-size: 650px;
  background-repeat: no-repeat;
  background-position: right;
}
@media only screen and (max-device-width: 480px) {
  .find_us_bg {
    background-image: none;
  }
  .find_us_on_box {
    border-radius: 15px;
  }
}
.find_us_on_box {
  background: linear-gradient(to bottom, #0499d0 0, #0e3555 100%);
  border-radius: 40px;
}
.div_contain {
  padding: 25px;
}
.btn span h4 {
  font-size: 18px;
  color: #fff;
}
.app_store h4,
.play_store h4 {
  font-weight: 500;
}
.app_store h4:hover {
  color: #00bfff;
}
.main-banner .carousel.slide .carousel-inner .banner-con {
  position: absolute;
  left: 50%;
  top: 86%;
  text-align: center !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.modal-raw .modal-content {
  border: 2px solid #3385cc;
  background: 0 0;
  -webkit-border-radius: 18px;
  border-radius: 18px;
  position: relative;
}
.confirm-vote-modal .modal-body::before {
  background: none !important;
}
#after_vote .modal-body {
  background: linear-gradient(180deg, rgb(0 0 0 / 58%) 0%, #000000 100%),
    /* url(https://iglnetwork.com/assets/images/logo.png); */
      url(../images/logo-3d-compressed.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.modal-raw .modal-content {
  border: 2px solid #3385cc;
  background: 0 0;
  -webkit-border-radius: 18px;
  border-radius: 18px;
  position: relative;
}
@media only screen and (min-width: 1600px) and (max-width: 1920px) {
  #games.title-raw {
    margin-top: -165px;
  }
  .gamedetail .title-raw h1 {
    font-size: 70px;
  }
}
.gamedetail .title-raw h1 {
  filter: drop-shadow(0px 0px 25px black);
}

/* required field styling start*/
.required-field::after {
  content: "*";
  color: red;
  margin-left: 3px;
  font-size: 16px;
}
/* required field styling end*/
.MuiAutocomplete-input {
  font-size: 11px !important;
  padding: 0 0px 0px 6px !important;
}
.create-team-modal .modal-form .form-grop .form-control,
.create-team-modal .modal-form .section-title input[type="file"] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px;
}
.custom-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
}
.custom-date input {
  color: #fff;
  background: transparent;
  opacity: 0.6;
  border: none;
  text-transform: uppercase;
  font-family: D-DIN;
  padding-bottom: 6px !important;
}
.custom-date input::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.custom-date button {
  height: 100%;
  border: 1px solid;
  aspect-ratio: 1/1;
  position: absolute;
  right: 0px;
  top: 0;
  background: transparent;
}
.scrims-sticker {
  position: absolute;
  background: rgb(6 11 27);
  color: #fff;
  box-shadow: inset 1px -1px 11px 0px #0096ff;
  right: 10px;
  left: auto;
  top: 10px;
  width: min-content;
  padding: 5px 10px;
  /* box-shadow: none; */
  text-shadow: none;
  border-radius: 4px;
  /* margin-right: 31px; */
  font-family: "Montserrat";
  font-weight: 900;
  font-size: 14px;
}
.togglesearch {
  position: absolute;
  top: 69px;
  right: 84px;
  width: 350px;
  height: auto;
  display: none;
}
.togglesearch:before {
  content: "";
  position: absolute;
  top: -28px;
  right: 23px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 14px solid transparent;
  border-bottom: 14px solid #009cff;
}
@media screen and (max-width: 479px) {
  .togglesearch {
    top: 70px;
    right: 50%;
    transform: translateX(50%);
  }
  .togglesearch:before {
    right: 87.5px;
  }
}
.filter-btn {
  min-height: 40px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  letter-spacing: 0.5px;
  background: transparent;
  border-radius: 50px;
  text-transform: uppercase;
  width: calc(50% - 5px);
  border: 2px solid rgb(0, 156, 255);
}
.filter-btn-coloured {
  background: rgb(0, 156, 255);
  border: 2px solid rgb(0, 156, 255);
}

/* Media query for mobile devices (e.g., screen width less than 768px) */
@media (max-width: 767px) {
  .tournament__grid {
    /* Mobile-specific styles */
    overflow-x: auto;
    white-space: nowrap;
    touch-action: auto;
  }
}
.dragging {
  user-select: none;
}

.scrollbarHide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbarHide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.color-itc {
  color: #ffcd1e;
}
.bg-itc {
  background: #ffcd1e;
  color: #000;
}
.bg-black {
  background: #000000;
}


/* bingo  */

.tournament-details-col ul li:last-child::before {
  display: none;
}
.tournament-details-col ul li strong {
  color: white;
  padding-top: 12px;
  letter-spacing: 1px;
  font-size: 20px;
  font-family: "D-DIN Condensed";
  font-weight: bold;
  text-transform: uppercase;
}
.itc-tournament-details-col ul li {
  color: rgba(255, 255, 255, 0.50);
  font-family: "Montserrat";
}
.itc-tournament-details-col ul li strong {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}
.itc-tournament-details-col ul li::before {
  background: #ffcd1e;
  box-shadow: 0 0 8px #1f292d;
}
.itc-tournament-details-col .btn-ac{
  border: 2px solid #ffcd1e;
  background-color: rgba(255, 205, 30, 0.30) !important ;
  text-shadow: none ;
}

.itc-tournament-details-col .btn-ac span{
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
}
.itc-tournament-details-col .btm-btns .btn-ac:hover ,
 .itc-tournament-details-col .btn-ac:hover,
.itc-tournament-details-col .join-btn:hover{
  background-color: #ffcd1e !important ;
}
.itc-tournament-details-col .join-btn{
  border: 2px solid #ffcd1e;
  text-shadow: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0px;
}
.itc-tournament-details-col .join-btn i {
  background: #ffcd1e url(../images/ic-plus.png) center center no-repeat;
}
.inner-banner::before{
  background:none;
}
.itc-tournament-details-col .common-page-raw {
  background: none;
}
.itc-tournament-details-col .common-page-raw:before, .itc-tournament-details-col .common-page-raw:after{
  background:none;
}

.itc-tournament-details-col .time-date-col .timesate-li .box-raw{
  border: 1px solid #FFCD1E;
  background: rgba(255, 205, 30, 0.30);
}
.itc-tournament-details-col .time-date-col .timesate-li .box-raw  span{
  font-family:'Montserrat';
  font-size: 14px;
  letter-spacing: 0px;
}
.itc-tournament-details-col .time-date-col .timesate-li .box-raw h4{
  background: none;
  color:#fff;
  -webkit-text-fill-color:white;
  font-family:'Montserrat';
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 1px;
}
.itc-tournament-details-col table tr th{
  background-color: transparent !important;
}
.itc-tournament-details-col .tournament-details-raw table.first-col::after,
.itc-tournament-details-col .tournament-details-raw table.first-col::before,
.itc-tournament-details-col .leaderboard-raw .leader-list-raw .leader-list-col table tr th:first-child:before,
.itc-tournament-details-col .leaderboard-raw .leader-list-raw .leader-list-col table tr th:first-child:after{
  background: none;
  -webkit-text-fill-color:#f6ff00;
  color:#f6ff00;
  font-family:'Montserrat';
}
.itc-tournament-details-col .tournament-details-raw table.second-table tr td{
  font-family:'Montserrat';
  font-size:16px;
  letter-spacing: 0px;
  /* background: #FFCD1E; */
  /* box-shadow: 0px 0px 12px 0px #FFCD1E; */
  /* border-top: 1px solid #ffcd1e80; */
  border-top: 1px solid transparent;
}
.itc-tournament-details-col .tournament-details-raw table.second-table tr td.desh::before{
  background: #ffcd1e;
}
.itc-tournament-details-col .leaderboard-raw .leader-list-raw .leader-list-col table tr th span{
  font-family:'Montserrat';
  background-color: transparent;
  -webkit-text-fill-color:#ffcd1e;
  color:#ffcd1e;
}
.itc-tournament-details-col.modal-raw .modal-content .modal-body , #create-team-modalbox.itc-tournament-details-col .modal-content .modal-body, .itc-tournament-details-col.rules-modal .modal-body {
background: url('../images/itc-main-banner.jpg'), #111 50%  !important;
background-size: 100% 100% !important;
background-repeat:  no-repeat;
box-shadow: 0px 0px 15px 1px #FFCD1E;
}
.itc-tournament-details-col.modal-raw .modal-content .modal-body.logsec{
  /* background:none;
  box-shadow: none; */
  border: 1px solid #f6ff00;
}
.itc-tournament-details-col.rules-modal .title-raw-itc{
  background: none !important;
  border-bottom: 1px solid #f6ff00;
}

.itc-tournament-details-col.modal-raw .modal-content .modal-body::before{
  background:transparent;
}
.itc-tournament-details-col .modal-content{
  /* border: 1px solid #f6ff00; */
  border: none !important;
}
.itc-tournament-details-col.title-raw h1 span, .itc-tournament-details-col .title-raw h2 span,.itc-tournament-details-col .title-raw h3 span, .itc-tournament-details-col .title-raw h3 span{
  -webkit-text-stroke-color:transparent;
}
.itc-tournament-details-col .title-raw h3 , .itc-tournament-details-col h4, .itc-tournament-details-col .title-raw h3{
  background:-webkit-linear-gradient(#ffcd1e, #ffcd1e) !important;
  background-clip: text;
  -webkit-background-clip: text !important;
}
.itc-tournament-details-col .btn-main.btn-two-line{
  background: linear-gradient(to right, #ffcd1e 0%, rgba(255, 205, 30, 0.50) 100%);
  border-color: #ffcd1e;
  font-family:'MontserratRegular';
  /* border: 2px solid #ffcd1e; */
}
.itc-tournament-details-col .btn-main.btn-two-line:hover, #create-team-modalbox.itc-tournament-details-col .btn-main.btn-two-line:hover{
  border-color: #ffcd1e;
}
.itc-tournament-details-col .btn-main:hover, #create-team-modalbox.itc-tournament-details-col .btn-main:hover, .itc-tournament-details-col .btn-sm:focus,.itc-tournament-details-col .btn-main:focus{
  /* background: linear-gradient(to right, rgba(255, 205, 30, 0.30) 0%, #ffcd1e  100%) !important; */
  background-color:  #ffcd1e !important;
  border-color: rgba(255, 205, 30, 1) !important;
}
.itc-tournament-details-col .bottom-section .team-col ul{
  border: 1px solid #3A3A3A;
  background: linear-gradient(180deg, #111 0%, #1C1C1C 100%);
}
.itc-tournament-details-col .upper-section{
  background:none;
}
.itc-tournament-details-col .upper-section .check-label, .itc-tournament-details-col p, .itc-tournament-details-col .modal-lg .modal-content .modal-body div{
  font-family:'Montserrat';
  letter-spacing: 0px;
}
#create-team-modalbox.itc-tournament-details-col .modal-content{
  border-color: transparent;
} 

.itc-tournament-details-col .modal-form .form-grop label, #create-team-modalbox.itc-tournament-details-col  .modal-form .section-title label, .itc-tournament-details-col .modal-lg .modal-content .modal-body h4{
  color:  #ffcd1e !important;
  font-family:'Montserrat';
} 
.itc-tournament-details-col .modal-form .form-grop p, .itc-tournament-details-col .modal-form .section-title p{
color:#fff;
font-family:'Montserrat';
letter-spacing: 0px;
}
#create-team-modalbox.itc-tournament-details-col .modal-form .form-grop .form-control, #create-team-modalbox.itc-tournament-details-col .modal-form .section-title .form-control{
  font-family:'Montserrat';
}
.itc-tournament-details-col .modal-form .form-grop .form-control, .itc-tournament-details-col .modal-form .section-title .form-control{
border:2px solid #dbac06;
}
#create-team-modalbox.itc-tournament-details-col .btn-close-modal, .itc-tournament-details-col  .btn-close-modal, .itc-tournament-details-col .modal-lg .modal-content .btn-close-modal{
  border-radius: 50px;
  border:2px solid #FFCD1E;
  padding: 5px;
  width: 25px !important;
  height: 25px !important;
  background: transparent url(../images/ic-close-modal.png) center center no-repeat !important;
}
.itc-tournament-details-col .bingo-chip, .choose-your-team-modalbox .bingo-chip{
  background-color: transparent;
  border: none;
  padding: 0;
}
.itc-tournament-details-col .search-game-raw .form-control{
 border: 1px solid #FFCD1E;
background: #241c014d;
font-family: 'Montserrat';
font-size: 16px;
box-shadow: 0 0 4px rgb(255 193 7), inset 0 0 4px rgb(255 205 30);
-webkit-box-shadow: 0 0 4px rgb(255 193 7), inset 0 0 4px rgb(255 205 30);
}
.itc-tournament-details-col .team-overview-raw .the-team-raw .team-li .team-li-conn{
  background-color:#000000
}
.itc-tournament-details-col .team-overview-raw .the-team-raw .team-li .team-li-conn::before{
  background:linear-gradient( to bottom, rgb(22 20 12) 0%, rgba(30, 80, 128, 0.02) 100% );
}
.itc-tournament-details-col .team-li-conn h4 a, .itc-tournament-details-col .team-overview-raw .the-team-raw .team-li .team-li-conn .team-details-col table tr td h5, .itc-tournament-details-col .team-overview-raw .the-team-raw .team-li .team-li-conn .team-details-col table tr td {
  font-family: 'Montserrat' !important;
  font-weight: 400;
  letter-spacing: 0px;
  font-size: 16px;
}
.itc-tournament-details-col .team-overview-raw .the-team-raw .team-li .team-li-conn .team-details-col table tr td{
  color:#FFCD1E
}
.itc-tournament-details-col .team-overview-raw .the-team-raw .team-li .team-li-conn{
  box-shadow: 0 0 6px rgb(255 193 7), inset 0 0 6px rgb(255 205 30);
  -webkit-box-shadow: 0 0 6px rgb(255 193 7), inset 0 0 6px rgb(255 205 30);
  border: 1px solid #ffcd1e;
}
.itc-tournament-details-col .team-overview-raw .the-team-raw .team-li .team-li-conn .team-details-col::after{
  background: radial-gradient( ellipse at center, #ffc107 0%, rgba(125, 185, 232, 0) 70% );
}
.itc-tournament-details-col .team-li-conn h4 a{
  font-weight: 700;
}
.itc-tournament-details-col .back-btn{
  font-family: 'Montserrat' !important;
}
.itc-tournament-details-col .team-overview-raw .the-team-raw .team-li .team-li-conn .team-img{
  background: transparent url('../images/itc-bingo/profile\ bg2.png') center center no-repeat !important;
  filter: drop-shadow(0px 0px 4px rgb(255 205 30));
  clip-path: polygon(
    51.5% 0%,
    0% 26.5%,
    0% 74.5%,
    50.5% 100%,
    100% 75.5%,
    100% 25.5%
  );
}
.itc-tournament-details-col .btn-sm{
  border: 1px solid #FFCD1E !important;
  background: #241c014d !important;
  box-shadow: 0 0 4px rgb(255 193 7), inset 0 0 4px rgb(255 205 30);
  -webkit-box-shadow: 0 0 4px rgb(255 193 7), inset 0 0 4px rgb(255 205 30);
}
.itc-tournament-details-col .team-overview-raw .the-team-raw .team-li .team-li-conn .team-img img{
  border:none;
  border-radius: 0% !important;
}
.itc-tournament-details-col .inner-banner .container-fluid .back-btn{
  color:#FFCD1E;
}
.itc-tournament-details-col .inner-banner .container-fluid .back-btn {
  background: url('../images/itc-bingo/back.png');
  background-repeat: no-repeat;
}
.itc-tournament-details-col #after_vote .modal-content{
  background: none;
  padding: 12px;
}
/* .campaing-img a::before{
background:none !important;
} */
.campaing-img img{
  width: 35px;
}
.campaing-img a::after{
  background: #ffcd1e !important;
}
.itc-tournament-details-col .btn-main.btn-sqr-gradient{
  background:#ffcd1e;
  color:#111111
}
.itc-tournament-details-col .forgot-pass-col,.itc-tournament-details-col .already-member-col a{
  color:#fff;
}
.itc-tournament-details-col .forgot-pass-col:hover, .itc-tournament-details-col .already-member-col a:hover{
  color:#ffcd1e;

}
.itc-tournament-details-col .social-list-raw li a{
  background-color: #ffcd1e;
}
.itc-tournament-details-col .social-list-raw li a i{
  color:#111;
  transition: 0.2s ease-in-out;
}
.itc-tournament-details-col .social-list-raw li a:hover  i{
  color:#fff
}
.itc-tournament-details-col .form-control{
  border:1px solid #ffcd1e;
  background-color: rgba(255, 205, 30, 0.30);
}
.itc-tournament-details-col .terms-raw label a{
  color:#ffcd1e;
}
.itc-tournament-details-col .followus-carousel.bg-black{
  background: none !important;
}
.itc-tournament-details-col input[type="checkbox"].checkbox-circle{
  background-color: #ffcd1e;
}
  @media (max-width: 767px){ 
  .itc-tournament-details-col .leaderboard-raw .leader-list-raw .leader-list-col table tr th span{
    font-size: 16px;
  }
.itc-tournament-details-col .tournament-details-raw .table-wrapper .leader-list-raw .leader-list-col table.second-table tr td {
  font-size: 14px;
  text-align: left;
}
}
.itc-tournament-details-col .inner-banner-con p, .itc-tournament-details-col .chk-style{
font-family: 'Montserrat' !important;
}
.itc-tournament-details-col .modal-dialog{
  background:none !important;
}