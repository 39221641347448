@import url("https://fonts.googleapis.com/css2?family=Urbanist:wght@400;700;900&display=swap");
.font-Urbanist {
  font-family: "Urbanist", sans-serif;
  font-style: normal;
}
#itc-bingo {
  margin-top: 70px;
}
#itc-bingo .carousel-indicators button {
  background: #cccccc;
  width: 16px;
  height: 12px;
  border-radius: 50%;
  border-width: 2px;
}
#itc-bingo .carousel-indicators button.active {
  background: #ffcd1e;
  /* width: 72px; */
  height: 12px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
  border-radius: 16px;
  border-width: 2px;
}
#itc-bingo .carousel-control-prev,
#itc-bingo .carousel-control-next {
  display: none;
}
#itc-bingo .content {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  padding-left: 60px;
}

#itc-bingo .poweredBy,
#IglBanner .poweredBy {
  font-size: 32px;
}
#itc-bingo .heading {
  font-family: "Goldman";
  font-style: normal;
  font-weight: 400;
  font-size: 110px;
  line-height: 138px;
}
#IglBanner .heading {
  font-family: "Goldman";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 1.25;
}
#itc-bingo img {
  max-height: calc(100vh - 125px);
}
#itc-bingo .bingoLogo,
#IglBanner .bingoLogo {
    width: 125px;
    margin-top: 15px;
    object-fit: contain;
    margin-bottom: 15px;
}
#itc-bingo .content button,
#IglBanner .content button {
  margin-top: 20px;
  height: 45px;
  width: 300px;
}
#IglBanner .games-banner {
  width: 100%;
  background: linear-gradient(to bottom, #ffcd1e, #000000 50%, #ffcd1e);
  padding: 2px 0 2px 2px;
  border-top-left-radius: 20px;
  box-shadow: 0px 4px 11px 0px #ffcd1e;
}
#IglBanner .games-banner .bg-black {
  padding: 0px 0 2px 0px;
}
#IglBanner .games-banner .card-container {
  height: 90px;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
#IglBanner .games-banner .card-container img {
  position: absolute;
}
#IglBanner .games-banner .card-container p {
  z-index: 1;
  position: relative;
}
#IglBanner .games-banner .card-container p a {
  font-family: Goldman;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 40px;
  float: none;
  width: 100%;
  text-align: start;
  display: block;
}
#IglBanner .games-banner .card-container:first-child,
#IglBanner .games-banner:first-child,
#IglBanner .games-banner .bg-black {
  border-top-left-radius: 20px;
}
#IglBanner .games-banner .card-container:last-child,
#IglBanner .games-banner:last-child,
#IglBanner .games-banner .bg-black {
  border-bottom-left-radius: 20px;
}
#IglBanner .campaign-banner {
  aspect-ratio: 16/9 !important;
  height: auto;
  margin: 0;
  width: 100%;
  max-height: calc(100vh - 80px);
}
.itc-body {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.mini-game-card {
  border: 1px solid white;
  border-radius: 14px;
  padding: 12px;
  flex-basis: 280px;
  flex-grow: 0;
  flex-shrink: 0;
}
.mini-game-card img {
  border-radius: 8px;
}
.follow-bg {
  background-color: rgba(0, 0, 0, 0.2);
}
/* .follow-us-links{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
} */
.follow-us-links a {
  /* width: 50%; */
  /* display: inline-block; */
  text-decoration: underline;
  color: #fff;
  font-family: Urbanist;
  margin-bottom: 22px;
  text-transform: uppercase;
  font-size: 23px;
}
.followus-carousel {
  padding: 25px 0 25px 0;
}
.followus-carousel .carousel-item {
  border-radius: 12px;
}

.followus-carousel div.img-container {
  /* height: 250px; */
  width: 190px;
  /* height: 210px; */
  flex-shrink: 0;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition:0.2s ease-in-out;
}
.followus-carousel div.img-container img {
  height: 168px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
}
.followus-carousel div.img-container.purple:hover{
  filter: drop-shadow(0 0 12px purple);
}
.followus-carousel div.img-container.green:hover{
  filter: drop-shadow(0 0 12px green);
}
/* itc bingo moega tournament card */
.itc-TournamentCard,
.description-section {
  position: relative;
  z-index: 1;
  background: linear-gradient(to bottom, #ffcd1e, #000000 50%, #ffcd1e);
  border-radius: 8px;
  padding: 1px;
  overflow: hidden;
}
.itc-TournamentCard .bg-black,
.description-section .bg-black {
  border-radius: 8px;
  overflow: hidden;
}
.itc-TournamentCard img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.itc-TournamentCard .date-time {
  display: flex;
  justify-content: space-evenly;
  border-radius: 50px;
  border: 1px solid #222;
  background: #111;
  margin-top: 32px;
  margin-bottom: 32px;
}
.itc-TournamentCard .date-time div {
  color: #ffcd1e;
  text-align: center;
  font-family: "Urbanist", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 5px 0 5px 0;
}
.itc-TournamentCard .breaker {
  background-color: #ffcd1e;
  width: 2px;
  display: block;
  height: 100%;
}
.itc-TournamentCard .body-text {
  font-size: 15px;
}
.itc-TournamentCard .btn-join-black {
  border-radius: 4px;
  border: 1px solid #212d48;
  padding: 10px;
  background: #000;
}
.itc-TournamentCard .btn-join-black,
.itc-TournamentCard .btn-join-black span {
  text-align: center;
  color: #ffcd1e;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02px;
}
.itc-TournamentCard .btn-join-color {
  color: #010a20;
  text-align: center;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.02px;
  border-radius: 4px;
  background: #ffcd1e;
  padding: 10px;
  box-shadow: 0px 0px 5px 0px #000;
}
.social-container {
  border-radius: 20px;
  border: 1px solid #303030;
  background: #111;
  padding: 32px 0;
}
.social-container .social-heading-yellow {
  color: #ffcd1e;
  font-family: Goldman;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 164.706% */
}
.social-container .social-heading-blue {
  color: #00c5ff;
  font-family: Goldman;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px; /* 164.706% */
}

.bingo-chip {
  text-align: center;
  border-radius: 20px;
  border: 1px solid #303030;
  background: #1a1a1a;
  padding: 32px 0;
}
.bingo-chip img {
  width: 120px;
  aspect-ratio: 1/1;
  object-fit: contain;
}
.slick-arrow.slick-prev {
  border-radius: 20px 0px 0px 20px;
}
.slick-arrow.slick-next {
  border-radius: 0px 20px 20px 0px;
}
.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  border: 1px solid #303030;
  background: #111;
  height: 100%;
  width: 60px;
}
.slick-prev {
  left: -70px !important;
}
.slick-next {
  right: -70px !important;
}

.slick-prev:before,
.slick-next:before {
  content: "" !important;
  background-repeat: no-repeat;
  width: 100% !important;
  display: block;
  aspect-ratio: 1/1;
}
.slick-prev:before {
  background: url("../assets/images/itc-bingo/carousel-left-grey.png");
}
.slick-prev:hover::before {
  background: url("../assets/images/itc-bingo/carousel-left-colored.png");
  aspect-ratio: 1/1;
  width: 100% !important;
}
.slick-next:before {
  background: url("../assets/images/itc-bingo/carousel-right-grey.png");
}
.slick-next:hover::before {
  background: url("../assets/images/itc-bingo/carousel-right-colored.png");
  aspect-ratio: 1/1;
  width: 100% !important;
}
.scrims-sticker-new {
  position: absolute;
  right: 10px;
  left: auto;
  top: 10px;
  width: 94px;
  height: 29px;
}
.scrims-sticker-new img {
  width: 94px;
  height: 29px;
}

.description-section .description-heading {
  text-transform: uppercase;
  letter-spacing: 0.6px;
  font-family: "Urbanist", sans-serif;
  font-weight: 800;
  font-size: 40px;
}

.description-section .description-list {
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
}


.fanart-button{
  color: #FFEC01;
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px; /* 90.909% */
  letter-spacing: 0.055px;
  margin:0 auto;
  border-radius: 120px;
  background: #000;
  padding: 10px 25px; 
  box-shadow: 0px 0px 8px 1px #FFCD1E;
}
.fanart-button:hover{
  box-shadow: 0px 0px 15px 1px #FFCD1E;
  color: #FFEC01;
}

@media (max-width: 767px) {
  #itc-bingo img {
    height: 400px;
  }
  #IglBanner .campaign-banner {
    background-size: cover !important;
    background-position: 45% 0% !important;
    background-repeat: no-repeat;
  }
  #itc-bingo .heading,
  #IglBanner .heading {
    font-size: 32px;
    line-height: 40px;
  }
  #itc-bingo .bingoLogo,
  #IglBanner .bingoLogo {
    width: 70px;
    height: auto;
    /* aspect-ratio: 1/1; */
    /* margin-bottom: 15px; */
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  #itc-bingo .poweredBy,
  #IglBanner .poweredBy {
    font-size: 14px;
    margin-bottom: 0px;
  }
  #IglBanner .games-banner .card-container p a {
    font-size: 20px;
    padding-left: 22px;
  }
  .carousel-inner img,
  #itc-bingo .content,
  #IglBanner .content {
    height: 288px;
  }
  #IglBanner .games-banner .card-container:first-child,
  #IglBanner .games-banner:first-child,
  #IglBanner .games-banner .bg-black {
    border-top-left-radius: 8px;
  }
  #IglBanner .games-banner .card-container {
    height: 62px;
  }
  #IglBanner .games-banner {
    box-shadow: 0px 2px 5px 0px #ffcd1e;
  }
  #itc-bingo .carousel-body,
  #IglBanner .carousel-body {
    color: #fff;
    text-align: center;
    font-family: Urbanist;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }
  #itc-bingo .content button,
  #IglBanner .content button {
    height: 25px;
    width: 125px;
    font-size: 14px;
    display: block;
    margin: 25px auto 0 auto;
  }
  #itc-bingo .content,
  #IglBanner .content {
    padding: 0 20px;
  }
  #itc-bingo .carousel-indicators button.active {
    height: 4px;
    /* width: 19px; */
  }
  #itc-bingo .carousel-indicators button {
    height: 4px;
    width: 7px;
  }
  .itc-body {
    font-size: 14px;
    line-height: 25px;
  }
  .followus-carousel div.img-container {
    width: 90px;
    height: auto;
    aspect-ratio: 1/1;
  }
  .followus-carousel div.img-container img {
    height: 78px;
  }
  .followus-carousel {
    padding: 25px 0 0 0;
  }
  .social-container,
  .bingo-chip {
    border-radius: 8px;
  }
  .social-container .social-heading-yellow,
  .social-container .social-heading-blue {
    font-size: 25px;
    line-height: 30px;
  }
  .social-container img {
    width: 30px;
    height: 30px;
  }
  .bingo-chip {
    padding: 10px 0;
  }
  .bingo-chip img {
    width: 70px;
  }
  .description-heading {
    font-size: 24px !important;
  }
 
}

@media (max-width: 420px) {
  .fanart-button{
    font-size: 16px;
    line-height: 20px; 
  }
  #itc-bingo .bingoLogo,
  #IglBanner .bingoLogo {
    width: 30px;
    margin-top: 0px;
    margin-bottom: 8px;
  }
  #itc-bingo .heading,
  #IglBanner .heading {
    font-size: 10px;
    line-height: 16px;
  }
  #IglBanner .carousel-body {
    font-size: 8px;
    line-height: 1.2;
  }
  #IglBanner .content {
    height: auto;
  }
  #IglBanner .content button {
    margin: 8px auto 0 auto;
    font-size: 10px;
    height: 22px;
    width: 88px;
  }
  #IglBanner .games-banner .card-container p a {
    font-size: 14px;
    padding-left: 12px;
  }
  #IglBanner .games-banner .card-container {
    height: 42px;
  }
}
@media (min-width: 767px) {
  .col-megatournament-10 {
    width: calc(83.33% + 12px);
    padding-left: 0;
    padding-right: 0;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 767px) and (max-width: 991px) {
  #itc-bingo .heading,
  #IglBanner .heading {
    font-size: 46px;
    line-height: 52px;
  }
  #itc-bingo .poweredBy,
  #IglBanner .poweredBy {
    font-size: 16px;
  }
  #itc-bingo .carousel-body,
  #IglBanner .carousel-body {
    font-size: 14px;
  }
  #itc-bingo .carousel-body br,
  #IglBanner .carousel-body br {
    display: none;
  }
  #itc-bingo .content button,
  #IglBanner .content button {
    margin-top: 12px;
    height: 26px;
    width: 170px;
    font-size: 14px;
  }
  #itc-bingo .content,
  #IglBanner .content {
    padding-left: 20px;
  }
  #itc-bingo .bingoLogo,
  #IglBanner .bingoLogo {
    width: 100px;
    height: auto;
    /* margin-bottom: 15px; */
  }
  #IglBanner .games-banner .card-container {
    height: 85px;
  }
  #IglBanner .campaign-banner {
    padding-top: 35px;
    padding-bottom: 35px;
    max-height: 500px;
  }
}

/* // Large devices (desktops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199px) {
  #itc-bingo .heading,
  #IglBanner .heading {
    font-size: 65px;
    line-height: 1;
  }
  #itc-bingo .poweredBy {
    font-size: 18px;
  }
  #itc-bingo .carousel-body {
    font-size: 18px;
  }
  #itc-bingo .carousel-body br {
    display: none;
  }
  #itc-bingo .content button {
    margin-top: 12px;
    height: 30px;
    width: 190px;
    font-size: 18px;
  }
  #itc-bingo .content {
    padding-left: 45px;
  }
  #itc-bingo .bingoLogo {
    width: 100px;
    height: auto;
    /* margin-bottom: 15px; */
  }
  #IglBanner .games-banner .card-container {
    height: 85px;
  }
  #IglBanner .campaign-banner {
    padding-top: 35px;
    padding-bottom: 35px;
    max-height: 500px;
  }
}

/* // X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
  #itc-bingo .heading {
    font-size: 95px;
    line-height: 1;
    margin-left: -8px;
  }
  #itc-bingo .poweredBy {
    font-size: 20px;
  }
  #itc-bingo .carousel-body,
  #IglBanner .carousel-body {
    font-size: 20px;
  }
  #itc-bingo .carousel-body br,
  #IglBanner .carousel-body br {
    display: block;
  }
  #itc-bingo .content button,
  #IglBanner .content button {
    margin-top: 16px;
    height: 51px;
    width: 225px;
    font-size: 20px;
  }
  #itc-bingo .content,
  #IglBanner .content {
    padding-left: 45px;
  }
  #itc-bingo .bingoLogo,
  #IglBanner .bingoLogo {
    width: 100px;
    height: auto;
    margin-bottom: 15px;
  }
}
.itc-TournamentCard-mobile {
  border-radius: 8px;
  border: 1px solid #383838;
  background: #1f1f1f;
}
.itc-TournamentCard-mobile a .bg-img {
  width: 100%;
  height: 92.871px;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
}
.itc-TournamentCard-mobile a .scrims-sticker-new {
  width: 35px;
  height: 14px;
}
.itc-TournamentCard-mobile a .scrims-sticker-new img {
  width: 35px;
  height: 12px;
}
.itc-TournamentCard-mobile {
  color: #fff;
  font-family: Urbanist;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  height: 100%;
}
.itc-TournamentCard-mobile img {
  width: 100%;
  height: 92.871px;
  object-fit: cover;
  object-position: center;
}
.itc-TournamentCard-mobile ul {
  padding-top: 15px;
  border-top: 1px solid #383838;
  margin: 16px 0;
}
.itc-TournamentCard-mobile ul li {
  font-family: Urbanist;
  font-size: 12px;
  padding: 0px 2px;
}
.itc-TournamentCard-mobile a.btn {
  border-radius: 50px;
  border: 0.5px solid #ffcd1e;
  padding: 4px 0px;
  background: rgba(255, 205, 30, 0.3);
  font-size: 12px;
  font-family: Urbanist;
  gap: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.itc-TournamentCard-mobile a.btn i {
  font-size: 8px;
}