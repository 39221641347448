.midway-container {
  background: #111;
}
.midway-container .heading {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 48px; /* 150% */
  /* margin-top: 42px; */
  margin-top: 22px;
  margin-bottom: 32px;
}
.midway-container .heading img{
  height: 150px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
}
.bg-graphics {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-height: 50vh;
  height: 100%;
  min-height: 350px;
}
.bg-graphics svg {
  height: 42px;
    width: 42px;
  opacity: 0.6;
}
.gallery-card-loading {
  height: 350px;
  background: linear-gradient(to right, #ffce1e7c, rgba(25, 23, 6, 0.5));
  animation: hintloading 2s ease-in-out 0s infinite reverse;
  -webkit-animation: hintloading 2s ease-in-out 0s infinite reverse;
  padding: 5px 10px;
  border-radius: 20px;
}
@keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@-webkit-keyframes hintloading {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.gallery-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease-in-out;
}
.gallery-card .img-container {
  border-radius: 20px;
  height: 450px;
  width: 100%;
  overflow: hidden;
}
.gallery-card:hover {
  cursor: pointer;
  box-shadow: #ffcd1e;
}
/* .gallery-card .user-name { */
.gallery-card .discord-name {
  color: #fff;
  font-family: Urbanist;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-top: 16px;

}
.gallery-card .user-name {
  color: #a5a5a5;
  font-family: Urbanist;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 45px;
  min-height: 28px;
}
.gallery-card .discord-name i {
  margin-right: 16px;
  color: #ffcd1e;
}
.gallery-card:hover img {
  transform: scale(1.1);
}
.gallery-card.not-active:hover img {
  transform: scale(1.0);
}
.gallery-card:hover .discord-name {
  color: #ffcd1e;
}
.gallery-card.not-active {
  opacity: 0.15;
}
.lightbox .gallery-card.not-active:hover {
  transform: none;
}
/* lightbox code  */
.lightBox {
  /* position: fixed; */
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgb(0, 0, 0); */
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 999999; */
  z-index: 1;
}

.lightBox .nav,
.lightBox .close {
  color: #ffcd1e;
  /* padding: 22px; */
  border-radius: 50px;
  font-size: 32px;
  background-color: #111;
  height: 68px;
  width: 68px;
  box-shadow: 0px 0px 3px #ffcd1e;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  z-index: 1;
}
.lightBox .close {
  top: -72px;
  right: 0px;
  /* top: 15px;
  right: 20px; */
  font-size: 18px;
  height: 42px;
  width: 42px;
}
.lightBox .gallery-card {
  flex-shrink: 0;
  width: 400px;
}
.lightBox .gallery-card .img-container {
  height: 68vh;
}
.right-box, .left-box {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
}
.left-box{
  justify-content:flex-end;
}
.right-box{
  justify-content:flex-start;
}
.left-box .nav {
  transform: translateY(-50%);
  left: 25%;
}
.left-box .nav i{
  margin-left: -5px;
}
.right-box .nav i{
  margin-left: 5px;
}
.right-box .nav {
  transform: translateY(-50%);
  right: 25%;
}
.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  margin-top: 15px;
  padding:10px 0px;
}
.pagination .nav-item{
  border-radius: 10px;
  background: #1E1E1E;
  width: 46px;
  height: 46px;
  color: #6F6F6F;
  text-align: center;
  font-family: Urbanist;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 26px; /* 81.25% */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
.pagination .nav-item.active, .pagination .nav-item:not([disabled]):hover{
  color: #FFEC01;
  border-radius: 10px;
  background: #0F0F0F;
  box-shadow: 0px 0px 6px 2px #FFEC01;
}
.pagination .nav-item:not([disabled]) .fa-angle-right,.pagination .nav-item:not([disabled]) .fa-angle-left {
  color:white
}
@media screen and (max-width: 768px) {
  .pagination{
    margin-top: 15px;
    gap:16px;
  }
  .pagination .nav-item{
    width: 40px;
height: 40px;
font-size: 20px;
line-height: 26px; /* 130% */
}
.pagination .nav-item.active, .pagination .nav-item:not([disabled]):hover{
box-shadow:  0px 0px 4px 1px #FFEC01;
}
.left-box .nav i{
  margin-left: -1px;
}
.right-box .nav i{
  margin-left: 1px;
}

  .right-box, .left-box{
    position: unset;
  }
  .left-box .nav {
    transform: translateY(0);
    left: 20%;
    bottom: auto;
    top: 72%;
  }
  .right-box .nav {
    transform: translateY(0);
    right: 20%;
    bottom: auto;
    top: 72%;
  }
  .lightBox .gallery-card{
    border-radius: 10px;
    width: 75vw;
  }
  .gallery-card.not-active {
    opacity: 0.35;
}
  .lightBox .close{
    top: -54px;
    right: 0px;
  }
  .lightBox .nav, .lightBox .close{
    
    font-size: 16px;
    height: 42px;
    width: 42px;
  }
  .gallery-card .discord-name {
    font-size: 14px;
    margin-top: 12px;
  
  }
  .gallery-card .discord-name i {
    margin-right: 12px;
  }
  .midway-container .heading {
    font-size: 20px;
    line-height: 1.5;
    /* margin-top: 32px; */
    margin-top: 10px;
    margin-bottom: 16px;
  }
  .midway-container .heading img{
    width: 75px;
    height: auto;
    margin-top: 0;
  }
  .bg-graphics {
    display: none;
  }
  .gallery-card .user-name {
    min-height: 19px;
    font-size: 16px;
    line-height: normal;
    padding-left: 28px;
  }
  .gallery-card-loading {
    height: 180px;
    border-radius: 10px;
  }
  .gallery-card .img-container {
    height: 194.736px;
    border-radius: 10px;
  }
}

@media only screen and (min-width: 1500px) {
  .left-box .nav {
    left: 75%;
}
  .right-box .nav {
    right: 75%;
}
}
@media only screen and (min-width:769px) and (max-width: 991px) {
  .gallery-card .img-container{
    height: 250px;
    border-radius: 12px;
  }
}
@media only screen and (min-width:992px) and (max-width: 1599px) {
  .gallery-card .img-container{
    height: 325px;
    border-radius: 12px;
  }
}
